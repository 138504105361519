import * as React from 'react';

import { queryAPI } from '../api/data';

import { reducer } from '../reducers/reducer';
import { ReducerActions, ReducerState } from '../../models/reducers/ReducerType';

import { errorDispatcher } from '../../utils/errorDispatcher';

type State = ReducerState;
type SetState = React.Dispatch<React.SetStateAction<string | null>>;
type Dispatch = (action: ReducerActions) => void;
type StoryProviderProps = { children: React.ReactNode };

const StoryStateContext = React.createContext<State | undefined>(undefined);
const StoryDispatchContext = React.createContext<Dispatch | undefined>(
    undefined,
);

const initialState = { isLoading: false, data: undefined };

function StoryProvider({ children }: StoryProviderProps) {
    const [state, dispatch] = React.useReducer(reducer, initialState);

    return (
        <StoryStateContext.Provider value={state}>
            <StoryDispatchContext.Provider value={dispatch}>
                {children}
            </StoryDispatchContext.Provider>
        </StoryStateContext.Provider>
    )
}

function useStoryState() {
    const context = React.useContext(StoryStateContext)
    if (context === undefined) {
        throw new Error('useStoryState must be used within a StoryProvider')
    }
    return context
}

function useStoryDispatch() {
    const context = React.useContext(StoryDispatchContext)
    if (context === undefined) {
        throw new Error('useStoryDispatch must be used within a StoryProvider')
    }
    return context
}

async function getLessonContent(dispatch: Dispatch, lessonId: string, refresh?: boolean) {
    dispatch({ type: 'REQUEST' });
    try {
        const data: any = await queryAPI('getLessonContent', { lessonId }, { refresh: refresh });
        dispatch({ type: 'SUCCESS', data: data[0] })
    } catch (error) {
        //@ts-ignore
        errorDispatcher(error, dispatch);
    }
}

async function getQuizzesForCategory(dispatch: Dispatch, categoryId: string, refresh?: boolean) {
    dispatch({ type: 'REQUEST' });
    try {
        const data: any = await queryAPI('getQuizzesForCategory', { categoryId }, { refresh: refresh });
        dispatch({ type: 'SUCCESS', data: JSON.parse(data) })
    } catch (error) {
        //@ts-ignore
        errorDispatcher(error, dispatch);
    }
}

async function getQuizzes(dispatch: Dispatch, quizIds: Array<string>, refresh?: boolean) {
    dispatch({ type: 'REQUEST' });
    try {
        const data: any = await queryAPI('getQuizzes', { quizIds }, { refresh: refresh });
        dispatch({ type: 'SUCCESS', data: JSON.parse(data) })
    } catch (error) {
        //@ts-ignore
        errorDispatcher(error, dispatch);
    }
}

async function countQuizzes(dispatch: Dispatch, refresh?: boolean) {
    dispatch({ type: 'REQUEST' });
    try {
        const data: any = await queryAPI('countQuizzes', {}, { refresh: refresh });
        dispatch({ type: 'SUCCESS', data: JSON.parse(data) })
    } catch (error) {
        //@ts-ignore
        errorDispatcher(error, dispatch);
    }
}

async function getPodcastUrl(setPodcastUrl: SetState, lessonID: string, premium: boolean) {
    try {
        const data: any = await queryAPI('getPodcastUrl', { lessonID, premium }, { refresh: true });
        const url = JSON.parse(data);
        if (url.statusCode === 200) {
            setPodcastUrl(url.body);
        } else {
            setPodcastUrl(null);
        }
    } catch (error) {
        console.log(error);
    }
}

export {
    StoryProvider,
    useStoryState,
    useStoryDispatch,
    getLessonContent,
    getQuizzesForCategory,
    getQuizzes,
    countQuizzes,
    getPodcastUrl
};