import React, { useRef } from 'react';
import { TouchableOpacity, Animated, View, Platform, Linking } from 'react-native';

import Text from './Text';
import Icon from './Icon';

import { hexProvider } from '../utils/hexProvider';
import { translate } from '../services/localization/translations';
import constants from '../utils/constants';


const SupportCard = () => {

    const animatedScaleValue = useRef(new Animated.Value(1)).current

    const scaleVal = animatedScaleValue.interpolate({
        inputRange: [0, 1],
        outputRange: [.8, 1]
    })

    const onPressIn = () =>
        Animated.timing(animatedScaleValue, {
            toValue: 0,
            duration: 200,
            useNativeDriver: true
        }).start();

    const onPressOut = () =>
        Animated.timing(animatedScaleValue, {
            toValue: 1,
            duration: 200,
            useNativeDriver: true
        }).start();

    const handleOpenLink = async (link: string) => {
        await Linking.openURL(link);
    }

    let bgcolor = hexProvider('grey');
    let primarycolor = hexProvider('primary');

    return (
        <Animated.View style={{
            flex: 1,
            margin: 4,
            paddingVertical: 16,
            borderRadius: 8 / 1,
            backgroundColor: bgcolor,
            transform: [
                {
                    scale: scaleVal
                }
            ]
        }}>
            <TouchableOpacity
                style={{
                    flex: 1,
                    alignItems: 'center',
                    justifyContent: 'space-around'
                }}
                onPress={() => handleOpenLink(constants.SUPPORT)}
                onPressIn={onPressIn}
                onPressOut={onPressOut}
            >
                {Platform.OS === 'android' ?
                    <View
                        style={{
                            flex: 1,
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                            maxHeight: 48,
                            paddingHorizontal: 16,
                            paddingVertical: 8,
                            borderRadius: 48 / 2,
                            backgroundColor: primarycolor
                        }}
                    >
                        <Icon
                            icon='commenting'
                            color='white'
                            iconStyle={{
                                marginRight: 8
                            }}
                        />
                        <Text
                            type='h4'
                            style='bold'
                            color='white'>
                            {translate('support')}
                        </Text>
                    </View>
                    :
                    <View
                        style={{
                            flex: 1,
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                            maxHeight: 48,
                            paddingHorizontal: 16,
                            paddingVertical: 8,
                            borderRadius: 48 / 2,
                            backgroundColor: primarycolor
                        }}
                    >
                        <View
                            style={{
                                position: "absolute",
                                backgroundColor: primarycolor,
                                width: 20,
                                height: 25,
                                bottom: 0,
                                borderBottomLeftRadius: 25,
                                right: -10
                            }}
                        />
                        <View
                            style={{
                                position: "absolute",
                                backgroundColor: bgcolor,
                                width: 20,
                                height: 35,
                                bottom: -6,
                                borderBottomLeftRadius: 18,
                                right: -20
                            }}
                        />
                        <Text
                            type='h4'
                            style='bold'
                            color='white'>
                            {translate('support')}
                        </Text>
                    </View>
                }
            </TouchableOpacity>
        </Animated.View>
    );
}
export default SupportCard;