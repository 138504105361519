/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getCategories = /* GraphQL */ `
  query GetCategories {
    getCategories {
      id
      name
      description
      thumbnailUrl
      thumbnailColors
      position
      lessons {
        id
        name
        description
        premium
        time
        position
        podcastDate
        categoryId
        stories {
          id
          text
          media
          position
          lessonId
        }
        choiceQuizzes {
          id
          answers
          correct
          question
          description
          media
          position
          lessonId
        }
        sortQuizzes {
          id
          answers
          question
          description
          media
          position
          lessonId
        }
        textQuizzes {
          id
          correct
          question
          description
          media
          position
          lessonId
        }
      }
    }
  }
`;
export const getLessonsForCategory = /* GraphQL */ `
  query GetLessonsForCategory($categoryId: String) {
    getLessonsForCategory(categoryId: $categoryId) {
      id
      name
      description
      premium
      time
      position
      podcastDate
      categoryId
      stories {
        id
        text
        media
        position
        lessonId
      }
      choiceQuizzes {
        id
        answers
        correct
        question
        description
        media
        position
        lessonId
      }
      sortQuizzes {
        id
        answers
        question
        description
        media
        position
        lessonId
      }
      textQuizzes {
        id
        correct
        question
        description
        media
        position
        lessonId
      }
    }
  }
`;
export const getLessonContent = /* GraphQL */ `
  query GetLessonContent($lessonId: String) {
    getLessonContent(lessonId: $lessonId) {
      id
      name
      description
      premium
      time
      position
      podcastDate
      categoryId
      stories {
        id
        text
        media
        position
        lessonId
      }
      choiceQuizzes {
        id
        answers
        correct
        question
        description
        media
        position
        lessonId
      }
      sortQuizzes {
        id
        answers
        question
        description
        media
        position
        lessonId
      }
      textQuizzes {
        id
        correct
        question
        description
        media
        position
        lessonId
      }
    }
  }
`;
export const getQuizzes = /* GraphQL */ `
  query GetQuizzes($quizIds: [String]) {
    getQuizzes(quizIds: $quizIds)
  }
`;
export const getQuizzesForCategory = /* GraphQL */ `
  query GetQuizzesForCategory($categoryId: String) {
    getQuizzesForCategory(categoryId: $categoryId)
  }
`;
export const getPodcastUrl = /* GraphQL */ `
  query GetPodcastUrl($lessonID: String, $premium: Boolean) {
    getPodcastURL(lessonID: $lessonID, premium: $premium)
  }
`;
export const countQuizzes = /* GraphQL */ `
  query CountQuizzes {
    countQuizzes
  }
`;
export const premiumStatus = /* GraphQL */ `
  query PremiumStatus {
    premiumStatus
  }
`;
export const getTeam = /* GraphQL */ `
  query GetTeam {
    getTeam
  }
`;
export const setTeamName = /* GraphQL */ `
  query SetTeamName($name: String) {
    setTeamName(name: $name)
  }
`;
export const setTeamMember = /* GraphQL */ `
  query SetTeamMember($email: String, $name: String, $add: Boolean) {
    setTeamMember(email: $email, name: $name, add: $add)
  }
`;
export const createCheckoutSession = /* GraphQL */ `
  query CreateCheckoutSession(
    $stripePK: String
    $price: String
    $quantity: String
    $success_url: String
    $cancel_url: String
  ) {
    createCheckoutSession(
      stripePK: $stripePK
      price: $price
      quantity: $quantity
      success_url: $success_url
      cancel_url: $cancel_url
    )
  }
`;
export const createCustomerportalSession = /* GraphQL */ `
  query CreateCustomerportalSession($stripePK: String, $domain: String) {
    createCustomerportalSession(stripePK: $stripePK, domain: $domain)
  }
`;
export const generateTranslation = /* GraphQL */ `
  query GenerateTranslation($lessonIDLanguage: String) {
    generateTranslation(lessonIDLanguage: $lessonIDLanguage)
  }
`;
export const deleteUser = /* GraphQL */ `
  query DeleteUser {
    deleteUser
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      currentCategory
      nextLesson
      textAlign
      textSize
      primaryColor
      tutorials {
        name
        done
      }
      premium
      quizProgessSharing {
        right
        wrong
        done
        updatedAt
      }
      sharing
      owner
      createdAt
      updatedAt
    }
  }
`;
export const getUserProgress = /* GraphQL */ `
  query GetUserProgress($owner: String!, $id: String!) {
    getUserProgress(owner: $owner, id: $id) {
      id
      storiesProgress
      quizzeProgress {
        id
        done
        failed
      }
      owner
      createdAt
      updatedAt
    }
  }
`;
export const getTranslation = /* GraphQL */ `
  query GetTranslation($lessonIDLanguage: String!) {
    getTranslation(lessonIDLanguage: $lessonIDLanguage) {
      lessonIDLanguage
      content {
        position
        text
      }
      createdAt
      updatedAt
    }
  }
`;
