import React from 'react';
import { ViewStyle } from 'react-native';

import { LinearGradient as LG } from 'expo-linear-gradient';

type Props = {
    style?: ViewStyle;
    colors?: string[];
    children?: React.ReactNode;
};

const LinearGradient = ({
    colors = ['#3880FF', '#FFCC00', '#FF9500', '#FF2D55', '#AF52DE'],
    style = {
        position: 'absolute',
        flex: 1,
        flexDirection: 'column',
        alignItems: 'stretch',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0
    },
    children }: Props) => {
    return (
        <LG style={style}
            start={{ x: 0, y: 0.4 }}
            end={{ x: 1, y: 0.6 }}
            colors={colors} >
            { children}
        </LG >
    );
};

export default LinearGradient;