import * as Haptics from 'expo-haptics';
import { Platform } from 'react-native';

export async function successTap(): Promise<void> {
    if (Platform.OS !== 'web') {
        try {
            Haptics.notificationAsync(Haptics.NotificationFeedbackType['Success']);
        } catch (error) {
            console.log(error);
        }
    }
}

export async function errorTap(): Promise<void> {
    if (Platform.OS !== 'web') {
        try {
            Haptics.notificationAsync(Haptics.NotificationFeedbackType['Error']);
        } catch (error) {
            console.log(error);
        }
    }
}

export async function selectTap(): Promise<void> {
    if (Platform.OS !== ('web' || 'android')) {
        try {
            Haptics.selectionAsync();
        } catch (error) {
            console.log(error);
        }
    }
}