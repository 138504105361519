import * as React from 'react';

import * as Localization from 'expo-localization';
import i18n from 'i18n-js';
import { translations } from '../localization/translations';
import { SupportedLanguagesCode } from '../../models/localization/SupportedLanguagesType';

import { queryAPI } from '../api/data';

import { successTap } from '../haptics/haptics';

import { reducer } from '../reducers/reducer';
import { ReducerActions, ReducerState } from '../../models/reducers/ReducerType';
import { errorDispatcher } from '../../utils/errorDispatcher';



type State = ReducerState;
type Dispatch = (action: ReducerActions) => void;
type TranslationProviderProps = { children: React.ReactNode };

const TranslationStateContext = React.createContext<State | undefined>(undefined);
const TranslationDispatchContext = React.createContext<Dispatch | undefined>(
    undefined,
);

const initialState = { isLoading: false, data: undefined };

function TranslationProvider({ children }: TranslationProviderProps) {
    const [state, dispatch] = React.useReducer(reducer, initialState);

    i18n.translations = translations;
    i18n.locale = Localization.locale;
    i18n.fallbacks = true;

    return (
        <TranslationStateContext.Provider value={state}>
            <TranslationDispatchContext.Provider value={dispatch}>
                {children}
            </TranslationDispatchContext.Provider>
        </TranslationStateContext.Provider>
    )
}

function useTranslationState() {
    const context = React.useContext(TranslationStateContext)
    if (context === undefined) {
        throw new Error('useTranslationState must be used within a TranslationProvider')
    }
    return context
}

function useTranslationDispatch() {
    const context = React.useContext(TranslationDispatchContext)
    if (context === undefined) {
        throw new Error('useTranslationDispatch must be used within a TranslationProvider')
    }
    return context
}

async function generateTranslation(lessonIDLanguage: string) {
    try {
        await queryAPI('generateTranslation', { lessonIDLanguage }, { refresh: true });
    } catch (error) {
        //@ts-ignore
        return new Error(error);
    }
}

async function getTranslation(dispatch: Dispatch, lessonID: string, language: SupportedLanguagesCode) {
    const lessonIDLanguage = lessonID + language;
    dispatch({ type: 'REQUEST' });
    try {
        let data: any = await queryAPI('getTranslation', { lessonIDLanguage });
        if (data === null) {
            await generateTranslation(lessonIDLanguage);
            await getTranslation(dispatch, lessonID, language);
        } else {
            dispatch({ type: 'SUCCESS', data: data });
            successTap();
        }
    } catch (error) {
        //@ts-ignore
        errorDispatcher(error, dispatch);
    }
}

export {
    TranslationProvider,
    useTranslationState,
    useTranslationDispatch,
    getTranslation
};