import React from "react";
import { View, StyleSheet } from 'react-native';

import Button from './Button';
import Text from './Text';

import { translate } from '../services/localization/translations';
import { hexProvider } from '../utils/hexProvider';

type Props = {
    errorName: string;
    handleResetError: () => void;
};

const PWlessErrorCard = ({
    errorName,
    handleResetError
}: Props) => {

    const styles = StyleSheet.create({
        container: {
            margin: 4,
            padding: 16,
            borderRadius: 8 / 1,
            backgroundColor: hexProvider('grey')
        }
    });

    return (
        //@ts-ignore
        <View
            style={[styles.container]}
        >
            <View
                style={{
                    paddingVertical: 16,
                    paddingHorizontal: 8
                }}
            >
                <Text
                    children={translate(errorName)}
                    type='h5'
                    color='text'
                />
            </View>
            <Button
                title={translate('tryAgainPassword')}
                bold
                type='solid'
                color='error'
                textColor='white'
                style={{
                    height: 56,
                    borderRadius: 56 / 4,
                    margin: 4,
                }}
                icon={{
                    icon: 'undo',
                    color: 'white',
                    style: {
                        paddingRight: 8
                    }
                }}
                //@ts-ignore
                onPress={handleResetError}
            />
        </View>
    );
}

export default PWlessErrorCard;