import React, { useRef } from 'react';
import { StyleSheet, Platform, View, ViewStyle, Animated } from 'react-native';

import Text from './Text';
import Button from './Button';
import ProgressBar from './ProgressBar';

import '@expo/match-media'
import { useMediaQuery } from "react-responsive";
import { hexProvider } from '../utils/hexProvider';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

type SizeObject = {
  maxWidth?: number,
  minWidth?: number,
  query?: string
}

let mobile: SizeObject = { maxWidth: 599 }
let tablet: SizeObject = { minWidth: 600, maxWidth: 999 }
let desktop: SizeObject = { minWidth: 1000 }
let portrait: SizeObject = { query: "(orientation: portrait)" };

if (Platform.OS !== 'web') {
  mobile = { query: `(max-device-width: ${mobile.maxWidth}px)` }
  tablet = { query: `(min-device-width: ${tablet.minWidth}px) and (max-device-width: ${tablet.maxWidth}px)` }
  desktop = { query: `(min-device-width: ${desktop.minWidth}px)` }
}

interface Props {
  podcastAction?: (() => void);
  storyAction?: {
    isVisibleState: boolean;
    setIsVisibleState: React.Dispatch<React.SetStateAction<boolean>>;
  }
  title: string;
  current: number;
  total: number;
  style?: ViewStyle;
};

export const StoryHeader = ({
  podcastAction,
  storyAction,
  title,
  current,
  total,
  style = {
    flexShrink: 1,
    margin: 8,
    marginBottom: 0,
    paddingVertical: 8,
    borderRadius: 8 / 1,
    overflow: 'hidden'
  }
}: Props) => {
  let insets = useSafeAreaInsets();

  const animatedStoryHeaderValue = useRef(new Animated.Value(1)).current;

  const cogButtonXPosition = useRef(new Animated.Value(1)).current;
  const timesButtonXPosition = useRef(new Animated.Value(0)).current;

  const cogButtonOpacity = useRef(new Animated.Value(1)).current;
  const timesButtonOpacity = useRef(new Animated.Value(0)).current;

  const cogXVal = cogButtonXPosition.interpolate({
    inputRange: [0, 1],
    outputRange: [16, 0],
  });

  const timesXVal = timesButtonXPosition.interpolate({
    inputRange: [0, 1],
    outputRange: [16, 0],
  });

  if (storyAction?.isVisibleState) {
    Animated.sequence([
      Animated.parallel([
        Animated.timing(cogButtonXPosition, {
          toValue: 0,
          useNativeDriver: true,
          duration: 200
        }),
        Animated.timing(cogButtonOpacity, {
          toValue: 0,
          useNativeDriver: true,
          duration: 200
        }),
      ]),
      Animated.delay(100),
      Animated.parallel([
        Animated.timing(timesButtonXPosition, {
          toValue: 1,
          useNativeDriver: true,
          duration: 200
        }),
        Animated.timing(timesButtonOpacity, {
          toValue: 1,
          useNativeDriver: true,
          duration: 200
        })
      ]),
    ]).start()
  } else {
    Animated.sequence([
      Animated.parallel([
        Animated.timing(timesButtonXPosition, {
          toValue: 0,
          useNativeDriver: true,
          duration: 200
        }),
        Animated.timing(timesButtonOpacity, {
          toValue: 0,
          useNativeDriver: true,
          duration: 200
        }),
      ]),
      Animated.delay(100),
      Animated.parallel([
        Animated.timing(cogButtonXPosition, {
          toValue: 1,
          useNativeDriver: true,
          duration: 200
        }),
        Animated.timing(cogButtonOpacity, {
          toValue: 1,
          useNativeDriver: true,
          duration: 200
        })
      ]),
      Animated.timing(cogButtonOpacity, {
        toValue: 1,
        useNativeDriver: true,
        duration: 200
      })
    ]).start()
  }

  if (storyAction !== undefined) {
    if (current < total - 1) {
      animatedStoryHeaderValue.setValue(1);
    } else {
      Animated.timing(animatedStoryHeaderValue, {
        toValue: 0,
        duration: 400,
        useNativeDriver: true
      }).start();
    }
  }

  // const isMobile = useMediaQuery(mobile);
  const isTablet = useMediaQuery(tablet);
  const isDesktop = useMediaQuery(desktop);
  const isPortrait = useMediaQuery(portrait);

  let isWeb = Platform.OS === 'web';

  const specific = () => {
    if (isTablet && isPortrait) {
      return [styles.mediumSpecific];
    }
    if (isDesktop || isTablet) {
      return [styles.bigSpecific];
    }
    return styles.smallSpecific;
  }

  return (
    <Animated.View
      style={[
        style,
        { marginTop: isWeb ? undefined : insets.top },
        {
          backgroundColor: hexProvider('grey'),
          opacity: animatedStoryHeaderValue,
          transform: [{
            translateY: animatedStoryHeaderValue.interpolate({
              inputRange: [0, 1],
              outputRange: [-80, 0]
            }),
          }]
        },
        specific()]}>
      <View style={{ flexShrink: 1, alignItems: 'center', justifyContent: 'center', position: 'absolute', top: 0, bottom: 0, right: 0, zIndex: 1 }}>
        {storyAction ?
          Platform.OS === 'android' ?
            <Button
              type='clear'
              icon={{
                color: 'text',
                icon: storyAction?.isVisibleState ? 'times' : 'cog',
                style: undefined
              }}
              onPress={() => storyAction?.setIsVisibleState(true)}
              style={{
                height: 64,
                width: 64,
                margin: 8
              }}
            />
            :
            <>
              <Animated.View style={{
                flexShrink: 1,
                position: 'absolute',
                opacity: timesButtonOpacity,
                left: -80,
                zIndex: storyAction.isVisibleState ? 1 : 0,
                justifyContent: 'center',
                alignItems: 'center',
                transform: [
                  {
                    translateX: timesXVal,
                  }
                ]
              }}>
                <Button
                  type='clear'
                  icon={{ color: 'text', icon: 'times', style: undefined }}
                  onPress={() => storyAction?.setIsVisibleState(false)}
                  style={{
                    height: 64,
                    width: 64,
                    margin: 8
                  }}
                />
              </Animated.View>
              <Animated.View style={{
                flexShrink: 1,
                position: 'absolute',
                opacity: cogButtonOpacity,
                left: -80,
                zIndex: !storyAction.isVisibleState ? 1 : 0,
                justifyContent: 'center',
                alignItems: 'center',
                transform: [
                  {
                    translateX: cogXVal,
                  }
                ]
              }}>
                <Button
                  type='clear'
                  icon={{ color: 'text', icon: 'cog', style: undefined }}
                  onPress={() => storyAction?.setIsVisibleState(true)}
                  style={{
                    height: 64,
                    width: 64,
                    margin: 8
                  }}
                />
              </Animated.View>
            </>
          :
          <Button
            type='clear'
            icon={{ color: 'text', icon: 'times', style: undefined }}
            onPress={podcastAction}
            style={{
              height: 64,
              width: 64,
              margin: 8
            }} />
        }
      </View>
      <View style={{ flexShrink: 1, paddingLeft: 4, paddingVertical: 4 }}>
        <Text type='h5' style='bold' textAlign='left' color='text'>{title}</Text>
      </View>
      <View style={{ flexShrink: 1, flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', paddingLeft: 4, paddingVertical: 4 }}>
        <Text type='h5' color='primary'>{current + 1}</Text>
        <Text type='h5' color='text'>{` / ${total}`}</Text>
      </View>
      <View style={{ flexShrink: 1, position: 'absolute', bottom: 0, right: 0, left: 0 }}>
        {isWeb && <ProgressBar color='primary' progress={Math.round(((current + 1) / total) * 100)} duration={160} />}
      </View>
    </Animated.View>
  )
}

const styles = StyleSheet.create({
  smallSpecific: {
    paddingHorizontal: 8,
  },
  mediumSpecific: {
    paddingHorizontal: 16,
    maxWidth: 599,
  },
  bigSpecific: {
    paddingHorizontal: 16,
    maxWidth: 599,
  }
});

export default StoryHeader;