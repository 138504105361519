import React, { useState } from 'react';
import { StyleSheet, View } from 'react-native';

import { ResponsiveContainer } from '../../components/ResponsiveContainer';
import QuizButton from '../../components/QuizButton';
import Text from '../../components/Text';
import Button from '../../components/Button';
import UniversalAlert from '../../components/UniversalAlert';

import { hexProvider } from '../../utils/hexProvider';
import { translate } from '../../services/localization/translations';
import { queryAPI } from '../../services/api/data';

import { StackNavigationProp } from '@react-navigation/stack';
import { RouteProp } from '@react-navigation/native';
import { MainStackParamList } from '../../models/stackParams/MainStackParamListType';

type MemberScreenNavigationProp = StackNavigationProp<
    MainStackParamList,
    'MemberScreen'
>;

type MemberScreenRouteProp = RouteProp<
    MainStackParamList,
    'MemberScreen'
>;


type Props = {
    navigation: MemberScreenNavigationProp;
    route: MemberScreenRouteProp;
};

export const MemberScreen = ({ navigation, route }: Props) => {

    const { name, username, email, quizProgessSharing } = route.params;

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const styles = StyleSheet.create({
        container: {
            margin: 4,
            padding: 8,
            borderRadius: 8 / 1,
            backgroundColor: hexProvider('grey'),
        }
    });

    const handleRemoveMember = async () => {
        setIsLoading(true);
        try {
            const data = await queryAPI('setTeamMember', {
                email: email,
                add: false
            }, { refresh: true });
            const response = JSON.parse(data);
            if (response.statusCode !== 200) {
                console.log(response.body);
            }
            navigation.navigate('PremiumScreen', { refresh: true });
        } catch (error) {
            console.log(error);
            navigation.goBack();
        }
    }

    const handleAlert = () => {
        UniversalAlert(
            translate('removeMemberAlert'),
            undefined,
            [
                {
                    text: translate('cancel'),
                    onPress: () => { return },
                    style: 'cancel'
                },
                {
                    text: translate('confirm'),
                    onPress: handleRemoveMember
                }
            ]
        );
    }

    return (
        <ResponsiveContainer
            isLoading={isLoading}
            scrollable
        >
            <>
                {(quizProgessSharing && quizProgessSharing?.done !== undefined && quizProgessSharing?.right !== undefined && quizProgessSharing?.wrong !== undefined) &&
                    //@ts-ignore
                    <View
                        style={[
                            styles.container,
                            {
                                height: 240
                            }
                        ]}
                    >
                        < QuizButton
                            quizzeDone={quizProgessSharing.right}
                            quizzeFailed={quizProgessSharing.wrong}
                            quizzeTotal={quizProgessSharing.done + quizProgessSharing.right + quizProgessSharing.wrong}
                            parentFlexDirectionColumn={true}
                        />
                    </View>
                }
                {/* @ts-ignore */}
                <View
                    style={[
                        styles.container,
                        {
                            padding: 16,
                            justifyContent: 'center',
                            alignItems: 'center'
                        }
                    ]}
                >
                    <Text color='text' style='bold' type='h5' textAlign='left'>{name}</Text>
                    {/* @ts-ignore */}
                    <View style={{ marginVertical: 4 }} />
                    <Text color='text' style='normal' type='p' textAlign='left'>{email}</Text>
                </View>
                {/* @ts-ignore */}
                <View
                    style={[
                        styles.container
                    ]}
                >
                    <Button
                        title={translate('removeMember')}
                        bold
                        type='clear'
                        color='error'
                        textColor='error'
                        style={{
                            height: 56,
                            borderRadius: 56 / 4,
                            margin: 4,
                            paddingRight: 8
                        }}
                        icon={{
                            icon: 'times',
                            color: 'error',
                            style: { padding: 8 }
                        }}
                        onPress={handleAlert}
                    />
                </View>
            </>
        </ResponsiveContainer>
    );
}