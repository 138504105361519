import React, {/* useState, useEffect, useRef */ } from 'react';
import { View, ScrollView, /*TouchableOpacity, Animated*/ } from 'react-native';

import Text, { TextDirection, TextType } from './Text';
// import Button from './Button';

import { hexProvider } from '../utils/hexProvider';

export type Props = {
    text: string;
    textType: TextType;
    textAlign: Exclude<TextDirection, "auto">;
    margin?: number;
};

export const TextCard = ({
    text,
    textAlign,
    textType,
    margin = 4
}: Props) => {
    // const [firstRenderDone, setFirstRenderDone] = useState<boolean>(false);
    // const [lastTap, setLastTap] = useState<number | null>(null);
    // const [textAlignState, setTextAlignState] = useState<TextDirection>(textAlign);

    // const iconOpacity = useRef(new Animated.Value(0)).current

    // useEffect(() => {
    //     if (firstRenderDone) {
    //         showTextAlignChangeAnimation();
    //     } else {
    //         setFirstRenderDone(true);
    //     }
    // }, [textAlignState])

    // const handleDoubleTap = (): void => {
    //     const now = Date.now();
    //     const DOUBLE_PRESS_DELAY = 300;
    //     if (lastTap && (now - lastTap) < DOUBLE_PRESS_DELAY) {
    //         toggleTextAlign();
    //     } else {
    //         setLastTap(now);
    //     }
    // }

    // const toggleTextAlign = (): void => {
    //     switch (textAlignState) {
    //         case 'left':
    //             setTextAlignState('center');
    //             break;
    //         case 'center':
    //             setTextAlignState('right');
    //             break;
    //         default:
    //             setTextAlignState('left');
    //             break;
    //     }
    // }

    // const showTextAlignChangeAnimation = () => {
    //     Animated.sequence([
    //         Animated.timing(iconOpacity, {
    //             toValue: 0.8,
    //             duration: 300,
    //             useNativeDriver: true
    //         }),
    //         Animated.delay(400),
    //         Animated.timing(iconOpacity, {
    //             toValue: 0,
    //             duration: 300,
    //             useNativeDriver: true
    //         })
    //     ]).start();
    // }

    const greyColor = hexProvider('grey');

    return (
        <View
            style={{
                flex: 1,
                overflow: 'hidden',
                borderRadius: 8 / 1,
                margin: margin ? margin : undefined,
                backgroundColor: greyColor
            }}>
            {/* <Animated.View
                style={{
                    flexShrink: 1,
                    alignItems: 'center',
                    justifyContent: 'center',
                    position: 'absolute',
                    top: '50%',
                    bottom: '50%',
                    right: '50%',
                    left: '50%',
                    zIndex: 1,
                    opacity: iconOpacity,
                }}>
                <Button
                    type='solid'
                    color='textinverted'
                    icon={{
                        icon: textAlignState === 'left'
                            ? 'align-left'
                            : textAlignState === 'center'
                                ? 'align-center'
                                : 'align-right',
                        color: 'text',
                        style: undefined
                    }}
                    style={{
                        height: 64,
                        width: 64,
                        borderRadius: 64 / 2,
                    }}
                    onPress={undefined
                        // handleDoubleTap
                    } />
            </Animated.View> */}
            <ScrollView
                style={{
                    flex: 1,
                    height: 1
                }}
                contentContainerStyle={{
                    flex: 1,
                    alignItems: 'stretch',
                    justifyContent: 'center',
                    padding: 16
                }}>
                {/* <TouchableOpacity
                    style={{ flex: 1 }}
                    onPress={handleDoubleTap }> */}
                <Text
                    type={textType}
                    style='bold'
                    color='text'
                    textAlign={textAlign}>{text}</Text>
                {/* </TouchableOpacity> */}
            </ScrollView>
        </View>
    );
};


export default TextCard;