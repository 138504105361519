import React from 'react';
import { FlatList } from 'react-native';

import { ResponsiveContainer } from '../../components/ResponsiveContainer';
import Text from '../../components/Text';
import Divider from '../../components/Divider';

import LegalFile from '../../assets/legal.json';

import { StackNavigationProp } from '@react-navigation/stack';
import { RouteProp } from '@react-navigation/native';

import { RootStackParamList } from '../../models/stackParams/RootStackParamListType';

type LegalScreenNavigationProp = StackNavigationProp<
    RootStackParamList,
    'LegalScreen'
>;

type LegalScreenRouteProp = RouteProp<
    RootStackParamList,
    'LegalScreen'
>;


type Props = {
    navigation: LegalScreenNavigationProp;
    route: LegalScreenRouteProp;
};

export const LegalScreen = ({ navigation, route }: Props) => {

    return (
        <ResponsiveContainer
            isLoading={LegalFile === undefined}
        >
            <FlatList
                data={LegalFile}
                keyExtractor={(item, index) => index.toString()}
                ItemSeparatorComponent={() =>
                    <Divider />
                }
                renderItem={(item) => <Text
                    key={item.index}
                    type='p'
                    textAlign='auto'
                    color='text'
                >
                    {item.item.content + '\n'}
                </Text>
                }
            >
            </FlatList>
        </ResponsiveContainer >
    );
}