import React from 'react';
import { View } from 'react-native';

import Text from './Text';
import Button from './Button';

import { hexProvider } from '../utils/hexProvider';

type Props = {
    title: string;
    message: string;
    onPress: (() => void);
};

export const InfoCard = ({
    title,
    message,
    onPress
}: Props) => {

    const bgColor = hexProvider('grey');

    return (
        <View
            style={{
                borderRadius: 8 / 1,
                padding: 8,
                backgroundColor: bgColor
            }}
        >
            <View
                style={{
                    padding: 8,
                }}
            >
                <Text
                    type='h4'
                    style='bold'
                    color='text'
                    textAlign='center'>{title}
                </Text>
            </View>
            <View
                style={{
                    padding: 8,
                }}
            >
                <Text
                    type='h5'
                    style='normal'
                    color='text'
                    textAlign='auto'>{message}
                </Text>
            </View>

            <View style={{ padding: 8 }}>
                <Button
                    title={'verstanden'}
                    bold={true}
                    type='solid'
                    color='primary'
                    textColor='white'
                    icon={{ icon: 'check', color: 'white', style: { paddingRight: 8 } }}
                    onPress={onPress}
                    style={{ height: 56, borderRadius: 56 / 4 }} />
            </View>
        </View>
    );
};

export default InfoCard;