const constants = {
    INSTAGRAM: 'https://www.instagram.com/u1dent.app',
    FACEBOOK: 'https://www.facebook.com/u1dent',
    WEBSITE: 'https://www.u1dent.app/',
    SUPPORT: 'mailto:support@u1dent.app',
    PRICES: 'https://www.u1dent.app/pricing',
    AGB: 'https://www.u1dent.app/agb',
    IMPRINT: 'https://www.u1dent.app/imprint',
    PRIVACY: 'https://www.u1dent.app/privacy',
    COOKIE: 'https://www.u1dent.app/cookie',
    EULA: 'https://www.apple.com/legal/internet-services/itunes/dev/stdeula/',
    AUTHSTORAGEKEYMOBILE: '@MemoryStorage',
    AUTHSTORAGEKEYWEB: 'CognitoIdentityServiceProvider.',
    ALLOWEDROUTES: ['PremiumScreen', 'SuccessScreen'],
    MOBILEREDIRECTHOST: 'https://redirect.u1dent.app',
    BZAEKRAHMENLEHRPLAN: 'https://www.bzaek.de/fileadmin/PDFs/za/ZFA/ausbildungsverordnung-zfa-1.pdf',
    APPSTORE: 'https://apps.apple.com/de/app/u1dent-app/id1603638405',
    PLAYSTORE: 'https://play.google.com/store/apps/details?id=com.u1learning.u1dent.app',
};

export default constants;