import React from "react";
import { Platform, Pressable, View } from "react-native";

import Text from "./Text";

import { openBrowserAsync } from 'expo-web-browser';
import { translate } from '../services/localization/translations';

type Props = {
    text: string;
    link: string;
};

const SourceFooter = ({
    text,
    link
}: Props) => {

    const handleOpenLink = async (url: string) => {
        await openBrowserAsync(url);
    }

    return (
        <Pressable
            style={{
                padding: 8,
                paddingHorizontal: 16,
                paddingBottom: Platform.OS === 'web' ? undefined : 32
            }}
            onPress={() => handleOpenLink(link)}
        >
            <Text
                type='p'
                textAlign='left'
                color='text'
            >
                {text}
            </Text>
            <View style={{ paddingVertical: 4 }} />
            <Text
                type='p'
                textAlign='left'
                color='primary'
            >
                {translate('showSource')}
            </Text>
        </Pressable>
    );
};

export default SourceFooter;