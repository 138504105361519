import React, { useState } from 'react';
import { View, ScrollView } from 'react-native';

import { ResponsiveContainer } from '../../components/ResponsiveContainer';
import LegalFooter from '../../components/LegalFooter';
import Logo from '../../components/Logo';
import PWlessSignInCard from '../../components/PWlessSignInCard';
import PWlessSignUpCard from '../../components/PWlessSignUpCard';
import PWlessCodeCard from '../../components/PWlessCodeCard';
import PWlessErrorCard from '../../components/PWlessErrorCard';

import { useAuthState, useAuthDispatch, answerCustomChallenge, signUp, signIn } from '../../services/context/authContext';

import { StackNavigationProp } from '@react-navigation/stack';
import { RouteProp } from '@react-navigation/native';
import { RootStackParamList } from '../../models/stackParams/RootStackParamListType';

type PremiumScreenNavigationProp = StackNavigationProp<
    RootStackParamList,
    'AuthScreen'
>;

type PremiumScreenRouteProp = RouteProp<
    RootStackParamList,
    'AuthScreen'
>;
type Props = {
    navigation: PremiumScreenNavigationProp;
    route: PremiumScreenRouteProp;
};

export const AuthScreen = ({ navigation, route }: Props) => {

    const { isLoading, error: authError } = useAuthState();
    const authDispatch = useAuthDispatch();

    const [authMode, setAuthMode] = useState<'SIGNIN' | 'CONFIRMSIGNIN'>('SIGNIN');

    const [emailInput, setEmailInput] = useState<string>('');
    const [codeInput, setCodeInput] = useState<string>('');

    const handleSignIn = async () => {
        await signIn(emailInput, authDispatch, setAuthMode);
    };

    const handleSignUp = async () => {
        await signUp(emailInput, authDispatch, setAuthMode);
        await signIn(emailInput, authDispatch, setAuthMode);
    };

    const handleAnswerCustomChallenge = async () => {
        await answerCustomChallenge(codeInput, authDispatch);
    };

    const handleResetError = async () => {
        setEmailInput('');
        setCodeInput('');
        setAuthMode('SIGNIN');
        authDispatch({ type: 'RESET' });
    };

    return (
        <ResponsiveContainer
            isLoading={isLoading}
            scrollable={false}
        >
            <ScrollView
                style={{
                    flex: 1,
                    height: 1
                }}
                contentContainerStyle={{
                    flex: 1,
                    alignItems: 'stretch',
                    justifyContent: 'center',
                }}>
                <View style={{ padding: 8 }} />
                <Logo
                    showName
                />
                {authError?.name ?
                    authError?.name === 'UserNotFoundException' ?
                        <PWlessSignUpCard
                            emailInput={emailInput}
                            handleSignUp={handleSignUp}
                            handleResetError={handleResetError}
                        />
                        :
                        <PWlessErrorCard
                            errorName={authError?.name}
                            handleResetError={handleResetError}
                        />
                    :
                    authMode === 'SIGNIN' ?
                        <PWlessSignInCard
                            emailInput={emailInput}
                            setEmailInput={setEmailInput}
                            handleSignIn={handleSignIn}
                        />
                        :
                        <PWlessCodeCard
                            emailInput={emailInput}
                            codeInput={codeInput}
                            setCodeInput={setCodeInput}
                            setAuthMode={setAuthMode}
                            handleAnswerCustomChallenge={handleAnswerCustomChallenge}
                        />
                }
                <LegalFooter />
            </ScrollView>
        </ResponsiveContainer >
    );
}