import * as React from 'react';

import { queryAPI } from '../api/data';
import { getUser as getCurrentUser } from '../api/auth';
import { updateUser } from './userContext';
import { storeData, getData } from '../store/store';
import { hasInternet } from '../network/checkNetwork';

import { reducer } from '../reducers/reducer';
import { ReducerActions, ReducerState } from '../../models/reducers/ReducerType';
import { errorDispatcher } from '../../utils/errorDispatcher';

import { Category } from '../../models/data/CategoryType';
import { Progress } from '../../models/data/ProgressType';

type QuizProgressType = {
    quizzeDone: number;
    quizzeFailed: number;
    quizzeTotal: number;
}

type State = ReducerState;
type Dispatch = (action: ReducerActions) => void;
type QuizProgressProviderProps = { children: React.ReactNode };

const QuizProgressStateContext = React.createContext<State | undefined>(undefined);
const QuizProgressDispatchContext = React.createContext<Dispatch | undefined>(
    undefined,
);

const storeKey = 'quizProgressContext';

const initialState = { isLoading: false, data: undefined };

function QuizProgressProvider({ children }: QuizProgressProviderProps) {
    const [state, dispatch] = React.useReducer(reducer, initialState);

    return (
        <QuizProgressStateContext.Provider value={state}>
            <QuizProgressDispatchContext.Provider value={dispatch}>
                {children}
            </QuizProgressDispatchContext.Provider>
        </QuizProgressStateContext.Provider>
    )
}

function useQuizProgressState() {
    const context = React.useContext(QuizProgressStateContext)
    if (context === undefined) {
        throw new Error('useQuizProgressState must be used within a QuizProgressProvider')
    }
    return context
}

function useQuizProgressDispatch() {
    const context = React.useContext(QuizProgressDispatchContext)
    if (context === undefined) {
        throw new Error('useQuizProgressDispatch must be used within a QuizProgressProvider')
    }
    return context
}

async function getQuizProgress(dispatch: Dispatch, userDispatch: Dispatch) {
    dispatch({ type: 'REQUEST' });
    try {

        let quizProgress: QuizProgressType = {
            quizzeDone: 0,
            quizzeFailed: 0,
            quizzeTotal: 935
        };

        const internet: boolean = await hasInternet();

        if (internet) {
            //getting all quizzes
            const quizzeTotal: number | any = await queryAPI('countQuizzes', {}, { refresh: true });
            if (quizzeTotal) quizProgress.quizzeTotal = quizzeTotal;

            //getting all quizze done + failed from progress of collectionIds/categoryIds
            const { username } = await getCurrentUser();
            const categories: Category[] | null | undefined = await queryAPI('getCategories', {}, { refresh: true });

            if (categories) {
                let collectionIds: string[] = categories.map(a => a.id);

                for (let i = 0; i < collectionIds.length; i++) {
                    const progress: Progress | null | undefined = await queryAPI(
                        'getUserProgress',
                        { id: collectionIds[i], owner: username },
                        { refresh: true }
                    );
                    if (progress && progress.quizzeProgress) {
                        for (let i = 0; i < progress.quizzeProgress.length; i++) {
                            let done: boolean | undefined = progress.quizzeProgress[i].done;
                            if (done !== undefined) {
                                done ? quizProgress.quizzeDone = quizProgress.quizzeDone + 1 : quizProgress.quizzeFailed = quizProgress.quizzeFailed + 1
                            }
                        }
                    }
                }
            }
        }

        await storeData(storeKey, quizProgress);

        await updateUser(userDispatch, {
            id: '',
            quizProgessSharing: {
                right: quizProgress.quizzeDone,
                wrong: quizProgress.quizzeFailed,
                done: quizProgress.quizzeTotal - quizProgress.quizzeDone - quizProgress.quizzeFailed,
                updatedAt: Math.floor(Date.now() / 1000)
            }
        });

        dispatch({ type: 'SUCCESS', data: quizProgress });

    } catch (error) {
        //@ts-ignore
        errorDispatcher(error, dispatch);
    }
}

async function updateQuizProgress(
    dispatch: Dispatch,
    quizzeDonePlus?: number,
    quizzeFailedPlus?: number,
    quizzeDoneMinus?: number,
    quizzeFailedMinus?: number
) {
    dispatch({ type: 'REQUEST' });
    try {

        let quizProgress: QuizProgressType;

        let store: null | QuizProgressType = await getData(storeKey);

        if (store === null) {
            quizProgress = {
                quizzeDone: 0,
                quizzeFailed: 0,
                quizzeTotal: 935
            };
        } else {
            quizProgress = store;
        }

        if (quizzeDonePlus) quizProgress.quizzeDone += quizzeDonePlus;
        if (quizzeFailedPlus) quizProgress.quizzeFailed += quizzeFailedPlus;

        if (quizzeDoneMinus) quizProgress.quizzeDone -= quizzeDoneMinus;
        if (quizzeFailedMinus) quizProgress.quizzeFailed -= quizzeFailedMinus;

        await storeData(storeKey, quizProgress);

        dispatch({ type: 'SUCCESS', data: quizProgress });
    } catch (error) {
        //@ts-ignore
        errorDispatcher(error, dispatch);
    }
}

export {
    QuizProgressProvider,
    useQuizProgressState,
    useQuizProgressDispatch,
    getQuizProgress,
    updateQuizProgress
};