import React from 'react';
import { View } from 'react-native';

import { StackNavigationProp } from '@react-navigation/stack';
import { RouteProp } from '@react-navigation/native';

import { useAuthDispatch } from '../../services/context/authContext';
import { useCategoryListDispatch } from '../../services/context/categoryListContext';
import { useProgressDispatch } from '../../services/context/progressContext';
import { useQuizProgressDispatch } from '../../services/context/quizProgressContext';
import { useStoryDispatch } from '../../services/context/storyContext';
import { useStoryListDispatch } from '../../services/context/storyListContext';
import { useTranslationDispatch } from '../../services/context/translationContext';
import { useUserDispatch } from '../../services/context/userContext';

import { ResponsiveContainer } from '../../components/ResponsiveContainer';
import InfoCard from '../../components/InfoCard';

import { translate } from '../../services/localization/translations';

import { RootStackParamList } from '../../models/stackParams/RootStackParamListType';

type InfoScreenNavigationProp = StackNavigationProp<
    RootStackParamList,
    'InfoScreen'
>;

type InfoScreenRouteProp = RouteProp<
    RootStackParamList,
    'InfoScreen'
>;


type Props = {
    navigation: InfoScreenNavigationProp;
    route: InfoScreenRouteProp;
};

export const InfoScreen = ({ navigation, route }: Props) => {

    const authDispatch = useAuthDispatch();
    const categoryListDispatch = useCategoryListDispatch();
    const progressDispatch = useProgressDispatch();
    const quizProgressDispatch = useQuizProgressDispatch();
    const storyDispatch = useStoryDispatch();
    const storyListDispatch = useStoryListDispatch();
    const translationDispatch = useTranslationDispatch();
    const userDispatch = useUserDispatch();

    const handleResetInternetState = () => {
        authDispatch({ type: 'NETWORKRESET' });
        categoryListDispatch({ type: 'NETWORKRESET' });
        progressDispatch({ type: 'NETWORKRESET' });
        quizProgressDispatch({ type: 'NETWORKRESET' });
        storyDispatch({ type: 'NETWORKRESET' });
        storyListDispatch({ type: 'NETWORKRESET' });
        translationDispatch({ type: 'NETWORKRESET' });
        userDispatch({ type: 'NETWORKRESET' });
        navigation.navigate('Main');
    }

    return (
        <ResponsiveContainer >
            <View
                style={{
                    flex: 1,
                    margin: 8,
                    justifyContent: 'center'
                }}
            >
                <InfoCard
                    title={translate('networkError')}
                    message={translate('networkErrorMessage')}
                    onPress={handleResetInternetState}
                />
            </View>
        </ResponsiveContainer >
    );
}