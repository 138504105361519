import { useContext } from 'react';
import { ViewStyle, useColorScheme } from 'react-native';
import { ThemeContext } from 'react-native-elements';

export type Colors =
    | "primary"
    | "white"
    | "black"
    | "grey"
    | "text"
    | "textinverted"
    | "success"
    | "warning"
    | "error";

export const hexProvider = (color: Colors) => {
    const { theme } = useContext(ThemeContext);

    switch (color) {
        case 'primary':
            return theme.colors?.primary !== undefined ? theme.colors?.primary : '#0000FF';
        case 'white':
            return theme.colors?.grey0 !== undefined ? theme.colors?.grey0 : '#FFFFFF';
        case 'black':
            return theme.colors?.grey1 !== undefined ? theme.colors?.grey1 : '#000000';
        case 'grey':
            return theme.colors?.grey3 !== undefined ? theme.colors?.grey3 : '#808080';
        case 'text':
            return theme.colors?.grey4 !== undefined ? theme.colors?.grey4 : '#000000';
        case 'textinverted':
            return theme.colors?.grey5 !== undefined ? theme.colors?.grey5 : '#FFFFFF';
        case 'success':
            return theme.colors?.success !== undefined ? theme.colors?.success : '#008000';
        case 'warning':
            return theme.colors?.warning !== undefined ? theme.colors?.warning : '#FFFF00';
        case 'error':
            return theme.colors?.error !== undefined ? theme.colors?.error : '#FF0000';
        default:
            return theme.colors?.grey1 !== undefined ? theme.colors?.grey1 : '#000000';
    }
}

export const shadowProvider = (): ViewStyle => {
    let colorScheme = useColorScheme();
    let shadowColor = hexProvider('black');

    return {
        overflow: 'visible',
        shadowColor: shadowColor,
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: colorScheme === 'dark' ? 0.8 : 0.3,
        shadowRadius: 4,
        elevation: 8,
        zIndex: 999
    }
}