import React, { useState, useEffect } from 'react';
import { StyleSheet, View } from 'react-native';

import * as yup from 'yup';

import { ResponsiveContainer } from '../../components/ResponsiveContainer';
import TextInput from '../../components/TextInput';
import Button from '../../components/Button';

import { hexProvider } from '../../utils/hexProvider';
import { translate } from '../../services/localization/translations';
import { queryAPI } from '../../services/api/data';

import { StackNavigationProp } from '@react-navigation/stack';
import { RouteProp } from '@react-navigation/native';
import { MainStackParamList } from '../../models/stackParams/MainStackParamListType';
import Text from '../../components/Text';

type AddMemberScreenNavigationProp = StackNavigationProp<
    MainStackParamList,
    'AddMemberScreen'
>;

type AddMemberScreenRouteProp = RouteProp<
    MainStackParamList,
    'AddMemberScreen'
>;


type Props = {
    navigation: AddMemberScreenNavigationProp;
    route: AddMemberScreenRouteProp;
};

export const AddMemberScreen = ({ navigation, route }: Props) => {

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [rendered, setRendered] = useState<boolean>(false);
    const [submitted, setSubmitted] = useState<boolean>(false);
    const [submitPossible, setSubmitPossible] = useState<boolean>(false);

    const [memberName, setMemberName] = useState<string>('');
    const [memberNameErr, setMemberNameErr] = useState<string | undefined>(undefined);

    const [memberEmail, setMemberEmail] = useState<string>('');
    const [memberEmailErr, setMemberEmailErr] = useState<string | undefined>(undefined);

    const [memberSubmissionError, setMemberSubmissionError] = useState<string | undefined>(undefined);

    useEffect(() => {
        setRendered(true)
    }, [])

    const addTeamMember = async () => {
        setIsLoading(true);
        try {
            const data = await queryAPI('setTeamMember', { name: memberName, email: memberEmail, add: true }, { refresh: true });
            const response = JSON.parse(data);
            if (response.statusCode !== 200) {
                setIsLoading(false);
                setMemberSubmissionError(response.body);
                return;
            }
            navigation.navigate('PremiumScreen', { refresh: true });
        } catch (error) {
            setIsLoading(false);
            //@ts-ignore
            setMemberSubmissionError(error);
        }
    }

    const handleAddTeamMember = () => {
        setSubmitted(true);
        setSubmitPossible(false);
        addTeamMember();
    }

    const handleResetAddTeamMember = () => {
        setSubmitPossible(false);
        setSubmitted(false);

        setMemberName('');
        setMemberEmail('');

        setMemberNameErr(undefined);
        setMemberEmailErr(undefined);

        setMemberSubmissionError(undefined);
    }

    let validationSchema = yup.object().shape({
        memberName: yup.string()
            .required(translate('requiredField'))
            .min(3, translate('memberNameMin'))
            .max(255, translate('memberNameMax')),
        memberEmail: yup.string()
            .required(translate('requiredField'))
            .email(translate('emailNotValid'))
    });

    rendered && validationSchema
        .validate({
            memberName: memberName,
            memberEmail: memberEmail
        }, { abortEarly: true, strict: true })
        .then(() => {
            setMemberNameErr(undefined);
            setMemberEmailErr(undefined);

            setSubmitPossible(true);
        })
        .catch(err => {
            setMemberNameErr(err.path === 'memberName' ? err.message : undefined);
            setMemberEmailErr(err.path === 'memberEmail' ? err.message : undefined);

            setSubmitPossible(false);
        })

    const styles = StyleSheet.create({
        container: {
            margin: 4,
            padding: 8,
            borderRadius: 8 / 1,
            backgroundColor: hexProvider('grey'),
        }
    });

    return (
        <ResponsiveContainer
            isLoading={isLoading}
            scrollable
        >
            <View
                style={[styles.container, { padding: 16 }]}
            >
                <Text
                    type='h5'
                    style='normal'
                    color='text'
                    textAlign='left'
                >
                    {translate('addMemberExplain')}
                </Text>
            </View>
            <View
                style={styles.container}
            >
                {memberSubmissionError ?
                    <Text type='h5' style='bold' color='error'>{memberSubmissionError}</Text>
                    :
                    <>
                        <TextInput
                            label={translate('memberName')}
                            placeholder={translate('memberNamePlaceholder')}
                            disabled={submitted}
                            submitable={submitPossible}
                            errorMessage={memberName === '' ? undefined : memberNameErr}
                            secureTextEntry={false}
                            icon='user-circle'
                            onChangeText={value => setMemberName(value)}
                            value={memberName}
                            type='name'
                        />
                        <TextInput
                            label={translate('memberEmail')}
                            placeholder={translate('emailPlaceholder')}
                            disabled={submitted}
                            submitable={submitPossible}
                            errorMessage={memberEmail === '' ? undefined : memberEmailErr}
                            secureTextEntry={false}
                            icon='envelope'
                            onChangeText={value => setMemberEmail(value)}
                            value={memberEmail}
                            type='email'
                        />
                    </>
                }
                <Button
                    title={memberSubmissionError ? translate('tryAgainPassword') : translate('addMember')}
                    bold
                    type='solid'
                    color={memberSubmissionError ? 'error' : 'primary'}
                    textColor='white'
                    style={{ height: 56, borderRadius: 56 / 4, marginVertical: 8 }}
                    icon={memberSubmissionError ?
                        {
                            icon: 'repeat',
                            color: 'error',
                            style: { padding: 8 }
                        }
                        :
                        {
                            icon: 'user-plus',
                            color: 'white',
                            style: { padding: 8 }
                        }}
                    disabled={memberSubmissionError ? false : (!submitPossible && !submitted)}
                    onPress={memberSubmissionError ? handleResetAddTeamMember : handleAddTeamMember}
                />
            </View>
        </ResponsiveContainer>
    );
}