import React, { useEffect, useRef, useState } from 'react';
import { FlatList, View, Animated, ViewStyle, TouchableOpacity } from 'react-native';

import Text from './Text';
import Icon from './Icon';
import Confetti from './Confetti';
import Button from './Button';

import { hexProvider, shadowProvider } from '../utils/hexProvider';
import { translate } from '../services/localization/translations';

type Props = {
    quizId: string;
    answers: string[];
    correct: number;
    rightQuizzesState: string[];
    wrongQuizzesState: string[];
    setRightQuizzesState: React.Dispatch<React.SetStateAction<string[]>>
    setWrongQuizzesState: React.Dispatch<React.SetStateAction<string[]>>
};

export const SingleChoiceQuizCard = ({
    quizId,
    answers,
    correct,
    rightQuizzesState,
    wrongQuizzesState,
    setRightQuizzesState,
    setWrongQuizzesState,
}: Props) => {
    const [firstRenderDone, setFirstRenderDone] = useState<boolean>(false);

    const [selected, setSelected] = useState<number | undefined>(undefined);
    const [submitted, setSubmitted] = useState<boolean>(false);
    const [answeredCorrrect, setAnsweredCorrect] = useState<boolean | undefined>(undefined);
    const [showConfetti, setShowConfetti] = useState<boolean>(false);

    useEffect(() => {
        if (firstRenderDone) {
            if (answeredCorrrect === true) {
                setShowConfetti(true);
                const timeOut = setTimeout(() => setShowConfetti(false), 2000);
                return () => {
                    clearTimeout(timeOut);
                };
            }
        } else {
            setFirstRenderDone(true);
        }
    }, [answeredCorrrect])

    const animatedOpacityValueButton = useRef(new Animated.Value(0)).current;
    const animatedYValueButton = useRef(new Animated.Value(0)).current;

    const animatedOpacityValueText = useRef(new Animated.Value(0)).current;
    const animatedYValueText = useRef(new Animated.Value(0)).current;

    const yValButton = animatedYValueButton.interpolate({
        inputRange: [0, 1],
        outputRange: [16, 0]
    });

    const yValtext = animatedYValueText.interpolate({
        inputRange: [0, 1],
        outputRange: [16, 0]
    });

    if ((selected === undefined && answeredCorrrect !== undefined) || (selected !== undefined && !submitted)) {
        Animated.parallel([
            Animated.timing(animatedOpacityValueButton, {
                toValue: 1,
                duration: 500,
                useNativeDriver: true
            }),
            Animated.timing(animatedYValueButton, {
                toValue: 1,
                duration: 500,
                useNativeDriver: true
            })
        ]).start()
    } else if (submitted) {
        Animated.sequence([
            Animated.parallel([
                Animated.timing(animatedOpacityValueButton, {
                    toValue: 0,
                    duration: 500,
                    useNativeDriver: true
                }),
                Animated.timing(animatedYValueButton, {
                    toValue: 0,
                    duration: 500,
                    useNativeDriver: true
                })
            ]),
            Animated.parallel([
                Animated.timing(animatedOpacityValueText, {
                    toValue: 1,
                    duration: 500,
                    useNativeDriver: true
                }),
                Animated.timing(animatedYValueText, {
                    toValue: 1,
                    duration: 500,
                    useNativeDriver: true
                })
            ]),
        ]).start();
    }

    const primaryColor = hexProvider('primary');
    const successColor = hexProvider('success');
    const errorColor = hexProvider('error');
    const greyColor = hexProvider('grey');

    const shadow = shadowProvider();
    const borderProvider = (index: number): ViewStyle => {
        if (selected === index) {
            if (answeredCorrrect) {
                return {
                    borderColor: successColor,
                    borderWidth: 4
                }
            } else if (answeredCorrrect === false) {
                return {
                    borderColor: errorColor,
                    borderWidth: 4
                }
            } else {
                return {
                    borderColor: primaryColor,
                    borderWidth: 4
                }
            }
        } else {
            if (((correct - 1) === index)) {
                if (answeredCorrrect !== undefined) {
                    return {
                        borderColor: successColor,
                        borderWidth: 4
                    }
                }
            }
            return {
                borderColor: undefined,
                borderWidth: undefined

            }
        }

    }

    const handleSubmitAnswer = () => {
        setSubmitted(true);
        if (selected === (correct - 1)) {
            setAnsweredCorrect(true);
            setRightQuizzesState([...rightQuizzesState, quizId]);
        } else {
            setAnsweredCorrect(false);
            setWrongQuizzesState([...wrongQuizzesState, quizId]);
        }
    }

    return (
        <View
            style={{
                flex: 1,
                borderRadius: 8 / 1,
                overflow: 'hidden',
                alignItems: 'stretch',
                backgroundColor: greyColor
            }} >
            {showConfetti && <Confetti
                icon='check-circle'
                timeInMs={2000}
                zIndex={3}
            />}
            <FlatList
                style={{
                    flex: 1,
                }}
                contentContainerStyle={{ padding: 8 }}
                ItemSeparatorComponent={() => <View style={{ margin: 2 }} />}
                data={answers}
                keyExtractor={(item) => item}
                renderItem={({ item, index }) =>
                    <TouchableOpacity
                        style={[
                            {
                                flexShrink: 1,
                                padding: 16,
                                backgroundColor: greyColor,
                                borderRadius: 8 / 1,
                            },
                            shadow,
                            borderProvider(index)
                        ]}
                        onPress={answeredCorrrect === undefined ?
                            !submitted ?
                                () => setSelected(index) :
                                undefined :
                            undefined}
                    >
                        <View style={{
                            flexShrink: 1,
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}>
                            <View style={{
                                flex: 1,
                                alignItems: 'flex-start',
                                justifyContent: 'center',
                            }}>
                                <Text type='h5' style='normal' textAlign='left' color='text'>{item}</Text>
                            </View>
                            <View style={{
                                flexShrink: 1,
                                alignItems: 'center',
                                justifyContent: 'center',
                                top: 0,
                                bottom: 0,
                                right: 0,
                                zIndex: 1,
                            }}>
                                {((answeredCorrrect === true && (correct - 1) === index) || selected === index) && <Icon
                                    icon={answeredCorrrect === false ?
                                        'times-circle' :
                                        'check-circle'
                                    }
                                    color={answeredCorrrect ?
                                        'success' :
                                        answeredCorrrect === false ?
                                            'error' :
                                            'primary'
                                    }
                                    style={{
                                        padding: 1
                                    }} />}
                            </View>
                        </View>
                    </TouchableOpacity>
                }
            />
            <Animated.View
                style={{
                    flexShrink: 1,
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginBottom: 16,
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    zIndex: 2,
                    opacity: animatedOpacityValueButton,
                    transform: [
                        {
                            translateY: yValButton
                        }
                    ],
                }}>
                <Button
                    title={translate('check')}
                    bold={true}
                    icon={{
                        icon: 'check',
                        color: 'white',
                        style: { padding: 8 }
                    }}
                    type='solid'
                    color='primary'
                    textColor='white'
                    style={{ height: 56, borderRadius: 56 / 4 }}
                    onPress={!submitted ? () => handleSubmitAnswer() : undefined}
                />
            </Animated.View>
            <Animated.View
                style={{
                    flexShrink: 1,
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: 16,
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    zIndex: 1,
                    opacity: animatedOpacityValueText,
                    transform: [
                        {
                            translateY: yValtext
                        }
                    ],
                    backgroundColor: greyColor.concat('CC')
                }}>
                <Text
                    type='h4'
                    style='bold'
                    color={answeredCorrrect ?
                        'success' :
                        'error'} >
                    {answeredCorrrect ?
                        translate('quizRight') :
                        translate('quizWrong')}
                </Text>
            </Animated.View>
        </View>
    );
};

export default SingleChoiceQuizCard;