import React, { useRef, useEffect } from "react";
import { StyleSheet, Animated } from "react-native";

import Icon from "./Icon";

import { hexProvider } from "../utils/hexProvider";

const TabIndicator = () => {

    const animatedBg = useRef(new Animated.Value(0));
    const animatedIcon = useRef(new Animated.Value(1));

    useEffect(() => {
        Animated.sequence([
            Animated.delay(2000),
            Animated.spring(animatedBg.current, {
                toValue: 1,
                useNativeDriver: true
            }),
            Animated.loop(
                Animated.sequence([
                    Animated.timing(animatedIcon.current, {
                        toValue: 0.8,
                        duration: 500,
                        useNativeDriver: true
                    }),
                    Animated.timing(animatedIcon.current, {
                        toValue: 1,
                        duration: 1000,
                        useNativeDriver: true
                    }),
                ])
            )
        ]).start();
    }, []);

    const styles = StyleSheet.create({
        indicatorBg: {
            height: 64,
            width: 64,
            borderRadius: 64 / 2,
            backgroundColor: hexProvider('textinverted'),
            opacity: 0.8,
            alignItems: 'center',
            justifyContent: 'center',
            transform: [{ scale: animatedBg.current as unknown as number }]
        },
        indicatorIcon: {
            transform: [{ scale: animatedIcon.current as unknown as number }],
            justifyContent: 'center',
            alignItems: 'center'
        }
    });

    return (
        <Animated.View
            style={styles.indicatorBg}
        >
            <Animated.View
                style={styles.indicatorIcon}
            >
                <Icon
                    icon='hand-pointer-o'
                    color='text'
                    style={{ padding: 1 }}
                />
            </Animated.View>
        </Animated.View>
    );
};

export default TabIndicator;