import * as React from 'react';

import { updateAPI, queryAPI } from '../api/data';
import { getUser as getCurrentUser } from '../api/auth';

import { reducer } from '../reducers/reducer';
import { ReducerActions, ReducerState } from '../../models/reducers/ReducerType';
import { errorDispatcher } from '../../utils/errorDispatcher';

import { mutationTypes } from '../../models/api/mutationTypes';

type State = ReducerState;
type Dispatch = (action: ReducerActions) => void;
type ProgressProviderProps = { children: React.ReactNode };

const ProgressStateContext = React.createContext<State | undefined>(undefined);
const ProgressDispatchContext = React.createContext<Dispatch | undefined>(
    undefined,
);

const initialState = { isLoading: false, data: undefined };

function ProgressProvider({ children }: ProgressProviderProps) {
    const [state, dispatch] = React.useReducer(reducer, initialState);

    return (
        <ProgressStateContext.Provider value={state}>
            <ProgressDispatchContext.Provider value={dispatch}>
                {children}
            </ProgressDispatchContext.Provider>
        </ProgressStateContext.Provider>
    )
}

function useProgressState() {
    const context = React.useContext(ProgressStateContext)
    if (context === undefined) {
        throw new Error('useProgressState must be used within a ProgressProvider')
    }
    return context
}

function useProgressDispatch() {
    const context = React.useContext(ProgressDispatchContext)
    if (context === undefined) {
        throw new Error('useProgressDispatch must be used within a ProgressProvider')
    }
    return context
}

async function getProgress(dispatch: Dispatch, collectionId: string, refresh?: boolean) {
    dispatch({ type: 'REQUEST' });
    try {
        const { username } = await getCurrentUser();
        const key = collectionId;

        const progress = await queryAPI('getUserProgress', { id: key, owner: username }, { refresh: refresh });
        if (progress) {
            dispatch({ type: 'SUCCESS', data: progress });
        } else {
            await updateProgress(dispatch, {
                id: collectionId
            });
        }
    } catch (error) {
        //@ts-ignore
        errorDispatcher(error, dispatch);
    }
}

async function updateProgress(dispatch: Dispatch, input: Omit<mutationTypes['updateUserProgress']['variablesType']['input'], 'owner'>) {
    dispatch({ type: 'REQUEST' });
    try {
        const { username } = await getCurrentUser();
        //@ts-ignore
        const key = input.id;

        await updateAPI('updateUserProgress', { ...input, id: key, owner: username }, 'createUserProgress');
        const progress = await queryAPI('getUserProgress', { id: key, owner: username }, { refresh: true, mutationQuery: 'updateUserProgress' });
        dispatch({ type: 'SUCCESS', data: progress });
    } catch (error) {
        //@ts-ignore
        errorDispatcher(error, dispatch);
    }
}

export {
    ProgressProvider,
    useProgressState,
    useProgressDispatch,
    getProgress,
    updateProgress,
};