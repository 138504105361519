import React, { useState, useEffect } from 'react';
import { Platform, View } from 'react-native';

import { ResponsiveContainer } from '../../components/ResponsiveContainer';
import CardsList from '../../components/CardsList';
import ResumeStoryCard from '../../components/ResumeStoryCard';

import { StackNavigationProp } from '@react-navigation/stack';
import { RouteProp } from '@react-navigation/native';
import { MainStackParamList } from '../../models/stackParams/MainStackParamListType';

import { useCategoryListState } from '../../services/context/categoryListContext';
import { useStoryListDispatch, useStoryListState, getLessonsForCategory } from '../../services/context/storyListContext';
import { useProgressDispatch, useProgressState, getProgress } from '../../services/context/progressContext';
import { useUserState } from '../../services/context/userContext';

type CategoryScreenNavigationProp = StackNavigationProp<
    MainStackParamList,
    'CategoryScreen'
>;

type CategoryScreenRouteProp = RouteProp<
    MainStackParamList,
    'CategoryScreen'
>;


type Props = {
    navigation: CategoryScreenNavigationProp;
    route: CategoryScreenRouteProp;
};

export const CategoryScreen = ({ navigation, route }: Props) => {

    const { categoryId } = route.params;

    const { data: user } = useUserState();
    const { data: categories } = useCategoryListState();

    const { data: list, isLoading: isLoadingStory } = useStoryListState();
    const listDispatch = useStoryListDispatch();

    const { data: progress, isLoading: isLoadingProgress } = useProgressState();
    const progressDispatch = useProgressDispatch();

    const [viewHeight, setViewHeight] = useState<number>(0);
    const [selectedIndex, setSelectedIndex] = useState<number>(0);

    const loadInitialData = async () => {
        if (categoryId) {
            await getLessonsForCategory(listDispatch, categoryId);
            await getProgress(progressDispatch, categoryId, true);
        }
    };

    useEffect(() => {
        loadInitialData();
        //@ts-ignore
        Platform.OS === 'web' && navigation.setOptions({ title: categories ? categories.find(c => c.id === categoryId).name + ' | u1Dent.app' : '' });
        return () => {
            listDispatch({ type: 'RESET' });
            setSelectedIndex(0);
        }
    }, [categoryId]);

    useEffect(() => {
        if (user?.nextLesson && list) {
            //@ts-ignore
            let i = list.findIndex(e => e.id === user?.nextLesson);
            (i >= 0) && setSelectedIndex(i < list.length ? i : 0);
        }
    }, [list]);

    return (
        <ResponsiveContainer
            isLoading={isLoadingStory || isLoadingProgress}
        >
            <View
                style={{
                    flex: 1,
                    margin: 4
                }}
                onLayout={e => setViewHeight(e.nativeEvent.layout.height)}
            >
                <View
                    style={{
                        height: viewHeight / 3,
                        paddingVertical: 4
                    }}>
                    <ResumeStoryCard
                        storyName={list ? list[selectedIndex].name : ''}
                        storyMinutes={list ? list[selectedIndex].time : ''}
                        onPressPodcast={
                            //@ts-ignore
                            list?.some(e => e.podcastDate)
                                ?
                                () => navigation.navigate('PodcastScreen', {
                                    lessons: list ? list : '',
                                    selectedIndex: selectedIndex
                                })
                                :
                                undefined
                        }
                        onPressStory={
                            () => navigation.navigate('Story', {
                                screen: "StoryScreen", params: {
                                    ids: [list ? list[selectedIndex].id : ''],
                                    type: 'STORY',
                                    language: undefined
                                }
                            })
                        }
                        //@ts-ignore
                        imageBackgroundUrl={categories ? categories.find(x => x.id === categoryId).thumbnailUrl : undefined}
                    />
                </View>
                <View
                    style={{
                        height: (viewHeight / 3) * 2,
                        paddingVertical: 4
                    }}>
                    <CardsList
                        expanded={false}
                        data={list}
                        done={progress?.storiesProgress}
                        selectedItem={list ? list[selectedIndex].id : ''}
                        setSelectedIndex={setSelectedIndex}
                        type='story'
                    />
                </View>
            </View>

        </ResponsiveContainer >

    );
}