import React, { useState } from 'react';
import { Platform, View, ScrollView } from 'react-native';

import { ResponsiveContainer } from '../../components/ResponsiveContainer';
import Text from '../../components/Text';
import Button from '../../components/Button';
import LegalFooter from '../../components/LegalFooter';

import { translate } from '../../services/localization/translations';

import { StackNavigationProp } from '@react-navigation/stack';
import { RouteProp } from '@react-navigation/native';

import { MainStackParamList } from '../../models/stackParams/MainStackParamListType';
import ProductCard from '../../components/ProductCard';
import ProductQuantityCard from '../../components/ProductQuantityCard';
import ProductCouponCard from '../../components/ProductCouponCard';

type OrderScreenNavigationProp = StackNavigationProp<
    MainStackParamList,
    'OrderScreen'
>;

type OrderScreenRouteProp = RouteProp<
    MainStackParamList,
    'OrderScreen'
>;


type Props = {
    navigation: OrderScreenNavigationProp;
    route: OrderScreenRouteProp;
};

export const OrderScreen = ({ navigation, route }: Props) => {

    const {
        name,
        price,
        month,
        group,
        productIndentifier
    } = route.params;

    //just for testing
    // const name = translate('SixMonths');
    // const price = 79.99;
    // const month = 6;
    // const group = 'TEAM';
    // const productIndentifier = '789';

    const [quantity, setQuantity] = useState(1);
    const [validCouponCode, setValidCouponCode] = useState<string | undefined>(undefined);

    const onCouponCodeVerify = (couponCode: string | undefined = undefined) => {
        couponCode ? setValidCouponCode(couponCode) : setValidCouponCode(undefined);
    }

    return (
        <ResponsiveContainer >
            <ScrollView
                contentContainerStyle={{
                    flexGrow: 1,
                    paddingTop: 8,
                    padding: 4
                }}
            >
                <ProductCard
                    product={{
                        name: name,
                        price: price,
                        month: month,
                        group: group,
                        productIndentifier: productIndentifier
                    }}
                    selected={true}
                    onPress={undefined}
                />
                {group === 'TEAM' &&
                    <ProductQuantityCard
                        quantity={quantity}
                        setQuantity={setQuantity}
                    />
                }
                {Platform.OS === 'web' &&
                    <ProductCouponCard
                        onCouponCodeVerify={onCouponCodeVerify}
                    />
                }
            </ScrollView>
            <View
                style={{
                    paddingBottom: 8
                }}
            >
                <Text
                    color='text'
                    type='p'
                >
                    {translate('agbHint')}
                </Text>
            </View>
            <View
                style={{
                    flexShrink: 1,
                    paddingBottom: 8
                }}
            >
                <Button
                    title={translate('subcribe')}
                    bold
                    color='primary'
                    textColor='white'
                    style={{
                        height: 56,
                        margin: 8
                    }}
                    onPress={() => console.log(
                        'productIndentifier: ', productIndentifier,
                        'quantity: ', quantity,
                        'validCouponCode: ', validCouponCode
                    )}
                />
            </View>
            <LegalFooter />
        </ResponsiveContainer >
    );
}