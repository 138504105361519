import React from "react";
import { StyleSheet, View } from "react-native";

import Text from "./Text";

import { hexProvider } from "../utils/hexProvider";
import { translate } from "../services/localization/translations";

type Props = {
    auth: any;
}

const EmailCard = ({
    auth
}: Props) => {

    const email: string | undefined = auth?.attributes['email'];

    const style = StyleSheet.create({
        container: {
            margin: 4,
            padding: 8,
            borderRadius: 8 / 1,
            backgroundColor: hexProvider('grey')
        }
    });

    return (
        <View
            style={style.container}
        >
            <Text
                type='h5'
                style='bold'
                color='text'
            >
                {translate('email')}
            </Text>
            <View style={{ marginVertical: 4 }} />
            {email && <Text
                type='h5'
                style='normal'
                color='text'
            >
                {email}
            </Text>}
        </View>
    );
}

export default EmailCard;