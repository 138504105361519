import React from 'react';
import { View, ViewStyle } from 'react-native';

import Card from './Card';
import Container from './Container';
import Text from './Text';
import Icon from './Icon';
import ProgressBar from './ProgressBar';

import { translate } from '../services/localization/translations';

type Props = {
    quizzeDone: number;
    quizzeFailed: number;
    quizzeTotal: number;
    style?: ViewStyle;
    parentFlexDirectionColumn?: boolean;
    onPress?: (() => void);
};

const QuizButton = ({
    quizzeDone,
    quizzeFailed,
    quizzeTotal,
    style = { flex: 1, overflow: 'hidden' },
    parentFlexDirectionColumn = false,
    onPress
}: Props) => {

    const intForProgressBar = (current: number, maximum: number): number => Math.round(100 * (current / maximum));

    return (
        <Card style={style} parentFlexDirectionColumn={parentFlexDirectionColumn} onPress={onPress}>
            <Container style={{ flex: 1, height: 1 / 3, flexDirection: 'column', justifyContent: 'flex-end', alignItems: 'center' }}>
                <Text type='h3' color='text' style='bold'>{translate('quizzes')}</Text>
            </Container>
            <Container style={{ flex: 1, height: 1 / 3, flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: 8 }}>
                <ProgressBar progress={intForProgressBar(quizzeDone, quizzeTotal)} color='success' thickness={16} fullBorderRadius={true} positionAbsolute={true} zIndex={3} />
                <ProgressBar progress={intForProgressBar(quizzeFailed, quizzeTotal) + intForProgressBar(quizzeDone, quizzeTotal)} color='error' thickness={16} fullBorderRadius={true} positionAbsolute={true} zIndex={2} />
                <ProgressBar progress={100} color='text' thickness={16} fullBorderRadius={true} positionAbsolute={true} zIndex={1} />
            </Container>
            <Container style={{ flex: 1, height: 1 / 3, flexDirection: 'row', justifyContent: 'space-around', alignItems: 'flex-start' }}>
                <View style={{ flex: 1 }}>
                    <Icon icon='check-circle' color='success' />
                    <Text
                        type='h4'
                        color='success'
                        style='bold'
                    >
                        {quizzeDone}
                    </Text>
                </View>
                <View style={{ flex: 1 }}>
                    <Icon icon='times-circle' color='error' />
                    <Text
                        type='h4'
                        color='error'
                        style='bold'
                    >
                        {quizzeFailed}
                    </Text>
                </View>
                <View style={{ flex: 1 }}>
                    <Icon icon='question-circle' color='text' />
                    <Text
                        type='h4'
                        color='text'
                        style='bold'
                    >
                        {quizzeTotal - quizzeDone - quizzeFailed}
                    </Text>
                </View>
            </Container>
        </Card >
    );
};

export default QuizButton;