import React, { ReactChildren, ReactChild, useRef, useState } from 'react';
import { Animated, KeyboardAvoidingView, Platform, Pressable } from 'react-native';

import TabIndicator from './TabIndicator';

type TabableSideButtonProps = {
    side: 'LEFT' | 'RIGHT';
    viewWidth: number;
    onPress: (() => void);
    index?: number;
    tutorialTabIndicator: boolean;
};

type Props = {
    selectedIndex: number;
    onSelect: (index: number) => void;
    lazyLoadPage: (index: number) => boolean;
    tutorialTabIndicator: boolean;
    children: ReactChild[] | ReactChildren[];
};

const TapPager = ({
    selectedIndex,
    onSelect,
    lazyLoadPage,
    tutorialTabIndicator,
    children
}: Props) => {
    const [viewWidth, setViewWidth] = useState<number>(0);

    const panXValue = useRef(new Animated.Value(0)).current;

    let childrenLength = children.length;

    const renderChildren = (
        child: React.ReactNode,
        index: number
    ) => {
        const lazyContent = (lazyLoadPage(index) || lazyLoadPage(index + 1) || lazyLoadPage(index - 1)) ? child : null;

        return (
            <KeyboardAvoidingView
                key={index}
                behavior={Platform.OS === "ios" ? "padding" : "height"}
                keyboardVerticalOffset={64}
                style={{
                    flex: 1,
                    width: '100%',
                }}
            >
                <TabableSideButton
                    side='LEFT'
                    viewWidth={viewWidth}
                    onPress={() => springAnimationToIndex(selectedIndex - 1)}
                    tutorialTabIndicator={tutorialTabIndicator}
                />
                <TabableSideButton
                    side='RIGHT'
                    viewWidth={viewWidth}
                    onPress={() => springAnimationToIndex(selectedIndex + 1)}
                    index={index}
                    tutorialTabIndicator={tutorialTabIndicator}
                />
                {lazyContent}
            </KeyboardAvoidingView>
        );
    }

    const springAnimationToIndex = (index: number) => {
        let offsetValue: number = -(selectedIndex * viewWidth);
        if (index >= 0 && index <= children.length - 1 && index !== selectedIndex) {
            offsetValue = -(index * viewWidth);
            onSelect(index);
            panXValue.setValue(offsetValue);
        } else {
            Animated.sequence([
                Animated.timing(panXValue, {
                    toValue: index >= 0 ? offsetValue - 80 : offsetValue + 80,
                    duration: 100,
                    useNativeDriver: true
                }),
                springAnimation(panXValue, offsetValue)
            ]).start()
        }
    }

    return (
        <Animated.View
            style={{
                flex: 1,
                flexDirection: 'row',
                width: `${100 * childrenLength}%`,
                transform: [
                    {
                        translateX: panXValue
                    }
                ]
            }}
            onLayout={e => setViewWidth(e.nativeEvent.layout.width / childrenLength)}
        >
            {children.map(renderChildren)}
        </Animated.View>
    );
};

export default TapPager;

const springAnimation = (panXValue: Animated.Value, toValue: number): Animated.CompositeAnimation => {
    return Animated.spring(
        panXValue,
        {
            speed: 100,
            toValue: toValue,
            useNativeDriver: true,
        }
    );
}

const TabableSideButton = ({
    side,
    viewWidth,
    onPress,
    index,
    tutorialTabIndicator
}: TabableSideButtonProps) => {

    return (
        <Pressable
            style={{
                position: 'absolute',
                zIndex: 10,
                left: side === 'LEFT' ?
                    0
                    : undefined,
                right: side === 'RIGHT' ?
                    0
                    : undefined,
                top: 0,
                bottom: 0,
                width: viewWidth / 7,
                alignItems: 'center',
                justifyContent: 'center',
                paddingRight: 8
            }}
            onPress={onPress}
        >
            {((index === 0 || index === 1) && !tutorialTabIndicator) && <TabIndicator />}
        </Pressable>
    );
}