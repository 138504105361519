import React, { useState } from 'react';
import { View, StyleSheet, TouchableOpacity } from 'react-native';

import Container from './Container';
import Text from './Text';
import { Icons, Props as IconProps } from './Icon';
import Button from './Button';

import { Colors, hexProvider, shadowProvider } from '../utils/hexProvider';
import TextAnimator from './TextAnimator';

type Props = {
    textNormal?: string;
    textBold?: string;
    textBoldAnimated?: boolean;
    textColor?: Colors;
    button1: {
        icon?: {
            icon: Icons,
            color: Colors,
            style: IconProps['style'] | undefined
        },
        iconPosition?: "bottom" | "left" | "right" | "top",
        color: Colors,
        onPress?: (() => void),
        title?: string,
        disabled: boolean
    };
    button2?: {
        icon?: {
            icon: Icons,
            color: Colors,
            style: IconProps['style'] | undefined
        },
        iconPosition?: "bottom" | "left" | "right" | "top",
        color: Colors,
        onPress?: (() => void),
        title?: string,
        disabled: boolean
    };
    backgroundOnPress?: undefined | (() => void)
};

const ButtonCard = ({
    textNormal,
    textBold,
    textBoldAnimated = false,
    textColor = 'text',
    button1,
    button2,
    backgroundOnPress,
}: Props) => {

    const [squareSize, setSquareSize] = useState<number>(0);

    const shadow = shadowProvider();

    return (
        <TouchableOpacity
            style={[{
                flex: 1,
                overflow: 'hidden',
                marginHorizontal: 4,
                marginVertical: 4,
                height: squareSize
            }
            ]}
            onPress={backgroundOnPress}
            onLayout={(e) => {
                setSquareSize(e.nativeEvent.layout.width);
            }}
        >
            <View
                style={{
                    flex: 1,
                    opacity: (backgroundOnPress === undefined && button1.onPress === undefined) ? 0.4 : undefined
                }}
            >
                {(textBold || textNormal) &&
                    <Container style={{ flex: 1, height: 1 / 2, flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-end', padding: 8 }}>
                        <View style={{
                            flexShrink: 1, borderRadius: 1000, backgroundColor: hexProvider('grey'), padding: 16
                        }}>
                            {textNormal && <Text type='h5' color={textColor} >{textNormal}</Text>}
                            {textBold &&
                                textBoldAnimated ?
                                <TextAnimator textProps={{
                                    type: 'h5',
                                    style: 'bold',
                                    color: textColor,
                                    children: textBold
                                }} />
                                :
                                <Text type='h5' style='bold' color={textColor} >{textBold}</Text>
                            }

                        </View>
                    </Container>}
                <Container style={{
                    flex: 1,
                    height: 1 / 2,
                    flexDirection: 'row',
                    alignItems: (textBold || textNormal) ? 'flex-start' : 'center',
                    justifyContent: 'space-evenly',
                    padding: 8
                }}>
                    <Button
                        style={button1.title ? styles.buttonRectangle : styles.buttonRound}
                        title={button1.title}
                        textType='h4'
                        textColor='white'
                        bold={true}
                        type='solid'
                        icon={button1.icon}
                        iconPosition={button1.iconPosition}
                        color={button1.color}
                        disabled={button1.disabled}
                        onPress={button1.onPress}
                    />
                    {button2 !== undefined &&
                        <Button
                            style={button2.title ? styles.buttonRectangle : styles.buttonRound}
                            title={button2.title}
                            textType='h4'
                            textColor='white'
                            bold={true}
                            type='solid'
                            icon={button2.icon}
                            iconPosition={button2.iconPosition}
                            color={button2.color}
                            disabled={button2.disabled}
                            onPress={button2.onPress}
                        />}
                </Container>
            </View>
        </TouchableOpacity >
    );
};

const styles = StyleSheet.create({
    buttonRound: {
        height: 64, width: 64, borderRadius: 64 / 2
    },
    buttonRectangle: {
        height: 56,
        borderRadius: 56 / 2,
        padding: 16
    }
});

export default ButtonCard;