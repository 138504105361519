import React, { useState } from 'react';
import { View, Platform } from 'react-native';

import * as yup from 'yup';

import LinearGradient from './LinearGradient';
import Button from './Button';
import TextInput from './TextInput';

import { hexProvider } from '../utils/hexProvider';
import { translate } from '../services/localization/translations';

export type Props = {
    onCouponCodeVerify: (couponCode: string | undefined) => void;
};

/**
* This component is responsible for checking if a coupon code is valid.
* @param {function(string, undefined): void} onCouponCodeVerify 
*/
const ProductCouponCard = ({
    onCouponCodeVerify
}: Props) => {

    const [couponCode, setCouponCode] = useState('');
    const [couponCodeError, setCouponCodeError] = useState<string | undefined>(undefined);
    const [checkCouponCodeError, setCheckCouponCodeError] = useState<string | undefined>(undefined);

    const [submitPossible, setSubmitPossible] = useState<boolean>(false);
    const [submitted, setSubmitted] = useState<boolean>(false);

    let validationSchema = yup.object().shape({
        coupon: yup.string()
            .length(8, translate('couponExact8Chars')),
    });

    validationSchema
        .validate({
            coupon: couponCode,
        }, { abortEarly: true, strict: true })
        .then(() => {
            setCouponCodeError(undefined);

            setSubmitPossible(true);
        })
        .catch(err => {
            setCouponCodeError(err.path === 'coupon' ? err.message : undefined);

            setSubmitPossible(false);
        })

    const checkCouponCode = async () => {
        if (Platform.OS === 'web') {
            //get coupon code from server
            const res: string | undefined = '12345678';

            if (res) {
                setCheckCouponCodeError(undefined);
                setSubmitted(true);
                onCouponCodeVerify(res);
            } else {
                setCheckCouponCodeError(translate('couponNotExist'));
                setSubmitted(false);
                onCouponCodeVerify(undefined);
            }
        }
    }

    const reset = () => {
        setCouponCode('');
        setCouponCodeError(undefined);
        setCheckCouponCodeError(undefined);
        setSubmitPossible(false);
        setSubmitted(false);
        onCouponCodeVerify(undefined);
    }

    return (
        <View style={{
            height: 128
        }}>
            <LinearGradient
                colors={["#fc0", "#ff9500", "#ff2d55", "#af52de", "#3880ff"]}
                style={{
                    flex: 1,
                    borderRadius: 8 / 1,
                    height: 56,
                    padding: 4,
                    margin: 16
                }}
            >
                <View
                    style={{
                        flex: 1,
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'stretch',
                        padding: 8,
                        paddingHorizontal: 16,
                        borderRadius: 8 / 1,
                        backgroundColor: hexProvider('grey'),
                    }}
                >
                    <TextInput
                        label={(submitPossible && submitted) ? translate('couponCode') : undefined}
                        placeholder={translate('couponCode')}
                        disabled={submitted}
                        submitable={submitPossible && checkCouponCodeError === undefined}
                        errorMessage={couponCode === '' ? undefined : checkCouponCodeError !== undefined ? checkCouponCodeError : couponCodeError}
                        secureTextEntry={false}
                        icon={submitPossible && submitted ? 'check-circle' : 'star'}
                        onChangeText={value => setCouponCode(value)}
                        value={couponCode}
                        fontSize={16}
                        type={undefined}
                        maxLength={8}
                    />
                    {checkCouponCodeError === undefined ?
                        submitPossible &&
                            !submitted ?
                            <Button
                                title={translate('apply')}
                                color='primary'
                                textColor={submitPossible ? 'white' : 'text'}
                                bold={submitPossible}
                                type={!submitPossible ? 'clear' : 'solid'}
                                icon={!submitPossible ? undefined : {
                                    icon: 'check',
                                    color: couponCode === '' ? 'white' : 'text',
                                    style: {
                                        padding: 1,
                                        paddingLeft: 8
                                    }
                                }}
                                iconPosition='right'
                                style={{
                                    flex: 1,
                                    height: 56,
                                    borderRadius: 56 / 2,
                                    paddingHorizontal: 16
                                }}
                                onPress={() => checkCouponCode()}
                            />
                            :
                            <Button
                                textColor='primary'
                                color='text'
                                type={'clear'}
                                icon={{
                                    icon: 'undo',
                                    color: 'text',
                                    style: {
                                        padding: 1
                                    }
                                }}
                                style={{
                                    flex: 1,
                                    height: 56,
                                    borderRadius: 56 / 2,
                                    paddingHorizontal: 16
                                }}
                                onPress={reset}
                            />
                        :
                        <Button
                            title={translate('reset')}
                            color='error'
                            textColor='white'
                            bold={true}
                            type='solid'
                            icon={{
                                icon: 'undo',
                                color: 'white',
                                style: {
                                    padding: 1,
                                    paddingLeft: 8
                                }
                            }}
                            iconPosition='right'
                            style={{
                                flex: 1,
                                height: 56,
                                borderRadius: 56 / 2,
                                paddingHorizontal: 16
                            }}
                            onPress={reset}
                        />
                    }
                </View>
            </LinearGradient>
        </View>
    );
};

export default ProductCouponCard;