import React, { useState } from 'react';
import { View, StyleSheet } from 'react-native';

import Text from './Text';
import Button from './Button';
import UniversalAlert from './UniversalAlert';

import { translate } from '../services/localization/translations';
import { hexProvider } from '../utils/hexProvider';

type Props = {
    onPress: (() => void);
};

const DeleteCard = ({
    onPress
}: Props) => {
    const [confirmed, setConfirmed] = useState<boolean>(false);

    const handleDeleteUser = async () => {
        UniversalAlert(
            translate('deleteAccount') + '?',
            undefined,
            [
                {
                    text: translate('cancel'),
                    onPress: () => { return },
                    style: 'cancel'
                },
                {
                    text: translate('confirm'),
                    onPress: onPress
                }
            ]
        );
    };

    const styles = StyleSheet.create({
        container: {
            margin: 4,
            padding: 8,
            borderRadius: 8 / 1,
            backgroundColor: hexProvider('grey')
        }
    });

    return (
        <View
            style={styles.container}
        >
            {
                !confirmed ?
                    <View style={{ flexGrow: 1 }}>
                        <View style={{ flexShrink: 1, flexDirection: 'column', justifyContent: 'center', alignItems: 'center', margin: 8 }}>
                            <Text color='text' type='h5' textAlign='left'>{translate('deleteAccountDescription')}</Text>
                        </View>
                        <View style={{ flexShrink: 1, flexDirection: 'column', justifyContent: 'center', alignItems: 'center', margin: 8 }}>
                            <Button
                                title={translate('deleteAccount')}
                                textType='h5'
                                icon={{ icon: 'times', color: 'white', style: { padding: 8 } }}
                                type='solid'
                                color='error'
                                textColor='white'
                                bold={true}
                                style={{ height: 56, borderRadius: 56 / 4, paddingHorizontal: 16 }}
                                onPress={() => setConfirmed(true)} />
                        </View>
                    </View>
                    :
                    <View style={{ flexShrink: 1, flexDirection: 'column' }}>
                        <View style={{ flexShrink: 1, flexDirection: 'column', justifyContent: 'center', alignItems: 'center', margin: 4 }}>
                            <Button
                                title={translate('cancelDeleteAccount')}
                                icon={{ icon: 'check', color: 'white', style: { padding: 8 } }}
                                textType='h5'
                                type='solid'
                                color='success'
                                textColor='white'
                                bold={true}
                                style={{ height: 56, borderRadius: 56 / 4, paddingHorizontal: 16 }}
                                onPress={() => setConfirmed(false)} />
                        </View>
                        <View style={{ flexShrink: 1, flexDirection: 'column', justifyContent: 'center', alignItems: 'center', margin: 4 }}>
                            <Button
                                title={translate('confirmDeleteAccount')}
                                textType='h5'
                                type='clear'
                                textColor='error'
                                bold={true}
                                style={{ height: 56, borderRadius: 56 / 4, paddingHorizontal: 16 }}
                                onPress={handleDeleteUser} />
                        </View>
                    </View>
            }
        </View >
    );
};

export default DeleteCard;