import React from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';

import Text from './Text';

import { hexProvider, shadowProvider } from '../utils/hexProvider';

type Props = {
    languageCode: string;
    language: string;
    selected?: boolean;
    onPress?: (() => void);
};

const LanguageCard = ({
    languageCode,
    language,
    selected = false,
    onPress
}: Props) => {

    let shadow = shadowProvider();

    const styles = StyleSheet.create({
        container: {
            flex: 1,
            height: 160,
            overflow: 'hidden',
            marginHorizontal: 4,
            marginVertical: 4,
            paddingVertical: 16,
            borderRadius: 8 / 1,
            backgroundColor: hexProvider('grey')
        },
        border: {
            borderWidth: 4,
            borderColor: hexProvider('primary')
        }
    })

    return (
        <TouchableOpacity style={[
            styles.container,
            shadow,
            selected ? styles.border : undefined
        ]} onPress={onPress}>
            <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
                <View style={{ flexShrink: 1, padding: 8, paddingHorizontal: 24, borderRadius: 50 / 1, backgroundColor: selected ? hexProvider('primary') : hexProvider('text') }}>
                    <Text type='h5' style='bold' color={'textinverted'}>{languageCode.trim()}</Text>
                </View>
                <View style={{ flexShrink: 1, padding: 8 }}>
                    <Text type='h5' style='bold' color={selected ? 'primary' : 'text'}>{language.trim()}</Text>
                    <View />
                </View>
            </View>
        </TouchableOpacity >
    );
};

export default LanguageCard;