import { updateAPI } from '../api/data';
import { shorthash } from '../../utils/helpers';
import { storeData, getData } from '../store/store';

import { mutationTypes } from '../../models/api/mutationTypes';

type OfflineMutationItem<K extends keyof mutationTypes> = {
    query: mutationTypes[K]['queryType'];
    variables: mutationTypes[K]['variablesType']['input'];
    createQuery?: mutationTypes[K]['queryTypeCreate'];
    hash: string;
};

export type OfflineMutationItemsArray<K extends keyof mutationTypes> = Array<OfflineMutationItem<K>>;

const storeKey: string = 'offline-mutation';

/** 
 * Saves mutation if theres no internet connection in AsyncStorage for later sync lookup. Also merges offline mutions per query.
 * @param query GraphQL API supported mutation-query (same as in data.ts)
 * @param variables GraphQL API supported mutation variables (same as in data.ts)
 * @param createQuery optional, if there is the possibility of no DB entry and creation is needed before updating
*/
async function saveMutation<K extends keyof mutationTypes>(
    query: mutationTypes[K]['queryType'],
    variables: mutationTypes[K]['variablesType']['input'],
    createQuery?: mutationTypes[K]['queryTypeCreate']
): Promise<void> {
    try {
        let updateObject: OfflineMutationItem<K> = { query: query, variables: variables, hash: shorthash([query, JSON.stringify(variables)]), createQuery: createQuery };
        let store: OfflineMutationItemsArray<K> | null = await getData(storeKey);

        if (store !== null) {
            //check if there is already an entry regarding this query
            let existingEntry = store.find(e => e.query === updateObject.query);
            if (existingEntry !== undefined && existingEntry.hash !== updateObject.hash) {
                //check if ids are the same
                if (existingEntry.variables.id === updateObject.variables.id) {
                    //merge updated item for query with already existing one
                    updateObject.variables = { ...existingEntry.variables, ...updateObject.variables };
                    //remove already existing entry for query in store
                    let indexToRemove = store.findIndex(e => e.hash === existingEntry?.hash);
                    console.log('remove offline-mutation from storage: ', store[indexToRemove]);
                    store.splice(indexToRemove, 1);
                }
            }
            console.log('saving offline-mutations after merger to storage: ', updateObject.query, updateObject.variables);
            store.push(updateObject);
            await storeData(storeKey, store);
        } else {
            console.log('saving offline-mutations to storage: ', updateObject.query, updateObject.variables);
            await storeData(storeKey, [updateObject]);
        }
    } catch (error) {
        //@ts-ignore
        throw new Error(error);
    }
}

/** 
 * Syncs saved offline mutations to cloud 
 */
async function syncMutations<K extends keyof mutationTypes>(): Promise<void> {
    try {
        let store: OfflineMutationItemsArray<K> | null = await getData(storeKey);

        if (store !== null) {
            store.forEach(async e => {
                console.log('syncing offline-mutations to cloud: ', e.query, e.variables, e.createQuery);
                await updateAPI(e.query, e.variables, e.createQuery);
            });
            console.log('remove all offline-mutations from storage.');
            await storeData(storeKey, null);
        }
    } catch (error) {
        //@ts-ignore
        throw new Error(error);
    }
}

export { saveMutation, syncMutations };