import React from 'react';
import { Platform, TextStyle as TS } from 'react-native';

import { Text as T } from 'react-native-elements'

import { hexProvider, Colors } from '../utils/hexProvider';

export type TextType =
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'p';

export type TextDirection =
    | 'left'
    | 'right'
    | 'center'
    | 'auto';

type TextStyle =
    | 'normal'
    | 'italic'
    | 'bold';

export type Props = {
    type?: TextType;
    textAlign?: TextDirection;
    style?: TextStyle;
    color?: Colors;
    shadowColor?: Colors;
    children: React.ReactNode;
};

const Text = ({
    children,
    type = 'p',
    textAlign = 'center',
    style = 'normal',
    color = 'white',
    shadowColor,
}: Props) => {

    const tType = () => {
        switch (type) {
            case 'h1':
                return 40;
            case 'h2':
                return 34;
            case 'h3':
                return 28;
            case 'h4':
                return 22;
            case 'h5':
                return 16;
            default:
                return 11;
        }
    };

    const tStyle = style === 'italic' ? { fontStyle: style } : { fontWeight: style };
    const tColor = color === undefined ? { color: color } : { color: hexProvider(color) };
    const tShadow: TS | undefined = shadowColor !== undefined ? {
        textShadowColor: hexProvider(shadowColor),
        textShadowOffset: {
            width: 1,
            height: 1,
        },
        textShadowRadius: 1,
    } : undefined;

    return (
        <T
            adjustsFontSizeToFit={Platform.OS === 'android' ? undefined : true}
            style={[{
                fontSize: tType(),
                textAlign: textAlign,
            },
                tStyle,
                tColor,
                tShadow
            ]} >
            {children}
        </T>
    );
};

export default Text;