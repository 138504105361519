import React from "react";
import { StyleSheet, View } from 'react-native';

import { hexProvider } from '../utils/hexProvider';
import { translate } from "../services/localization/translations";

import Button from './Button';

import TeamType, { TeamUserType } from "../models/data/TeamType";
import { User as UserType } from "../models/data/UserType";

type TeamUser = TeamUserType & { quizProgessSharing?: UserType['quizProgessSharing'] | undefined };
type Team = Omit<TeamType, 'users'> & { users?: undefined | TeamUser[] };

type Props = {
    team?: Team | undefined;
    teamMode?: boolean;
    handleManageSubscription: () => void;
    handleAddTeamMember?: () => void;
}

const ManageCard = ({
    team,
    teamMode,
    handleManageSubscription,
    handleAddTeamMember
}: Props) => {

    const style = StyleSheet.create({
        container: {
            margin: 4,
            padding: 8,
            borderRadius: 8 / 1,
            backgroundColor: hexProvider('grey'),
            justifyContent: 'space-around',
        }
    });

    return (
        //@ts-ignore
        <View
            style={style.container}
        >
            <Button
                title={translate('manageSubscription')}
                bold
                type='solid'
                color={'primary'}
                textColor='white'
                style={{
                    height: 56,
                    borderRadius: 56 / 4,
                    margin: 4,
                    paddingRight: 8
                }}
                icon={{
                    icon: 'star',
                    color: 'white',
                    style: { padding: 8 }
                }}
                onPress={handleManageSubscription}
            />
            {handleAddTeamMember && (teamMode === true) && team?.quantity && (team.users ? (team.quantity > team.users.length) : true) &&
                <Button
                    title={translate('addMember')}
                    bold
                    type='solid'
                    color={'primary'}
                    textColor='white'
                    style={{
                        height: 56,
                        borderRadius: 56 / 4,
                        margin: 4,
                        paddingRight: 8
                    }}
                    icon={{
                        icon: 'user-plus',
                        color: 'white',
                        style: { padding: 8 }
                    }}
                    onPress={handleAddTeamMember}
                />}
        </View>
    );
}

export default ManageCard;