import React, { useState, /*useEffect,*/ useRef, ReactChild, ReactChildren } from 'react';
import { View, Animated, TouchableOpacity, /*Platform*/ } from 'react-native';

// import Icon from './Icon';
import Audio, { Props as AudioProps } from './Audio';
import Video, { Props as VideoProps } from './BasicVideo';
import Image, { Props as ImageProps } from './Image';
import { TextType, TextDirection } from './Text';
import QuestionCard from './QuestionCard';

import { hexProvider } from '../utils/hexProvider';
import { useNavigation } from '@react-navigation/core';

type MediaProviderProps = {
    media?: AudioType | VideoType | ImageType;
};

type PositionIndicatorProps = {
    segments: number;
    viewHeight: number;
    animatedScrollXValue: Animated.Value;
};

type AnimatedCardProps = {
    index: number;
    viewHeight: number;
    viewWidth: number;
    animatedScrollXValue: Animated.Value;
    children: ReactChild | ReactChild[] | ReactChildren | ReactChildren[];
};

// type AnimatedTutorialButtonProps = {
//     animatedHandContainerValue: Animated.Value;
//     animatedHandValue: Animated.Value;
// };

type AudioType = {
    type: 'Audio',
    props: AudioProps
};

type VideoType = {
    type: 'Video',
    props: VideoProps
};

type ImageType = {
    type: 'Image',
    props: ImageProps
};

type Props = {
    quizId: string;
    question: {
        question: string,
        description: string | undefined
    };
    media?: AudioType | VideoType | ImageType;
    textType: TextType;
    textAlign: Exclude<TextDirection, 'auto'>
    showQuizState: boolean;
    setShowQuizState: React.Dispatch<React.SetStateAction<boolean>>;
    rightQuizzesState: string[];
    wrongQuizzesState: string[];
};

const MediaProvider = ({
    media
}: MediaProviderProps) => {
    const navigation = useNavigation();

    switch (media?.type) {
        case 'Audio':
            return (
                <Audio
                    playlist={media.props.playlist}
                />
            );
        case 'Image':
            return (
                <Image
                    url={media.props.url}
                    description={media.props.description}
                    onPress={() => navigation.navigate('Story', {
                        screen: 'MediaScreen',
                        params: {
                            type: 'IMAGE',
                            url: media.props.url,
                            description: media.props.description
                        }
                    })}
                    square={false}
                />
            );
        case 'Video':
            return (
                <Video
                    videoUrl={media.props.videoUrl}
                />
            );
        default:
            return null;
    }
}

const PositionIndicator = ({
    segments,
    viewHeight,
    animatedScrollXValue
}: PositionIndicatorProps) => {

    type SegmentIndicatorProps = {
        index: number;
        viewHeight: number;
        animatedScrollXValue: Animated.Value;
    };

    const SegmentIndicator = ({
        index,
        viewHeight,
        animatedScrollXValue
    }: SegmentIndicatorProps) => {
        return (
            <Animated.View
                style={{
                    flexShrink: 1,
                    height: 8,
                    width: 8,
                    marginHorizontal: 2,
                    borderRadius: 8 / 2,
                    backgroundColor: hexProvider('text').concat('CC'),
                    opacity: animatedScrollXValue.interpolate({
                        inputRange: [(index - 1) * viewHeight, index * viewHeight, (index + 1) * viewHeight],
                        outputRange: [.8, 1, .8]
                    }),
                    transform: [{
                        scale: animatedScrollXValue.interpolate({
                            inputRange: [(index - 1) * viewHeight, index * viewHeight, (index + 1) * viewHeight],
                            outputRange: [0.5, 1, 0.5],
                        })
                    }]
                }}
            >
                <TouchableOpacity
                    style={{
                        flex: 1
                    }}
                />
            </Animated.View>
        );
    }

    return (
        <View
            style={{
                flexShrink: 1,
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'absolute',
                bottom: 0,
                zIndex: 3,
                marginBottom: 8,
            }}>
            {
                [...Array(segments)].map((e, i) =>
                    <SegmentIndicator
                        key={i}
                        index={i}
                        viewHeight={viewHeight}
                        animatedScrollXValue={animatedScrollXValue}
                    />
                )
            }
        </View>
    );
}

const AnimatedCard = ({
    index,
    viewHeight,
    viewWidth,
    animatedScrollXValue,
    children
}: AnimatedCardProps) => {
    return (
        <Animated.View
            style={{
                height: viewHeight,
                width: viewWidth,
                transform: [
                    {
                        rotate: animatedScrollXValue.interpolate({
                            inputRange: [(index - 1) * viewWidth, index * viewWidth, (index + 1) * viewWidth],
                            outputRange: ['8deg', '0deg', '-8deg'],
                        })
                    },
                    {
                        scale: animatedScrollXValue.interpolate({
                            inputRange: [(index - 1) * viewWidth, index * viewWidth, (index + 1) * viewWidth],
                            outputRange: [0.5, 1, 0.5],
                        })
                    }
                ]
            }}>
            {children && children}
        </Animated.View>
    );
};

// const AnimatedTutorialButton = ({
//     animatedHandContainerValue,
//     animatedHandValue
// }: AnimatedTutorialButtonProps) => {
//     const isWeb = !(Platform.OS === 'ios' || Platform.OS === 'android');

//     return (
//         <Animated.View
//             style={[{
//                 flexShrink: 1,
//                 alignItems: 'center',
//                 justifyContent: 'center',
//                 position: 'absolute',
//                 opacity: animatedHandContainerValue.interpolate({
//                     inputRange: [0, 1],
//                     outputRange: [0, 0.8]
//                 }),
//                 zIndex: animatedHandContainerValue.interpolate({
//                     inputRange: [0, 0.1, 1],
//                     outputRange: [0, 3, 3]
//                 }),
//                 height: 64,
//                 width: 64,
//                 borderRadius: 64 / 2,
//                 backgroundColor: hexProvider('textinverted'),
//             },
//             !isWeb ? {
//                 transform: [{
//                     rotate: animatedHandValue.interpolate({
//                         inputRange: [0, 1],
//                         outputRange: ['30deg', '-30deg']
//                     })
//                 }]
//             } : {}
//             ]}>
//             {isWeb &&
//                 <View
//                     style={{
//                         flex: 1,
//                         alignItems: 'center',
//                         justifyContent: 'center',
//                         position: 'absolute',
//                         top: 0,
//                         bottom: 0,
//                         right: 0,
//                         left: 0,
//                         zIndex: 1,
//                     }}>
//                     <Icon
//                         icon='mouse'
//                         type='material-community'
//                         color='grey'
//                         iconStyle={{
//                             fontSize: 32
//                         }}
//                     />
//                 </View>
//             }
//             <Animated.View
//                 style={{
//                     flex: 1,
//                     alignItems: 'center',
//                     justifyContent: 'center',
//                     position: 'absolute',
//                     top: 0,
//                     bottom: 0,
//                     right: 0,
//                     left: 0,
//                     zIndex: 2,
//                     transform: [isWeb ?
//                         {
//                             translateY: animatedHandValue.interpolate({
//                                 inputRange: [0, 1],
//                                 outputRange: [5, -5]
//                             })
//                         }
//                         : {
//                             translateX: animatedHandValue.interpolate({
//                                 inputRange: [0, 1],
//                                 outputRange: [5, -5]
//                             })
//                         }]
//                 }}>
//                 <Icon
//                     icon={isWeb ? 'gesture-swipe-vertical' : 'gesture-swipe'}
//                     type='material-community'
//                     color='text'
//                     iconStyle={{
//                         fontSize: 32,
//                     }}
//                 />
//             </Animated.View>
//         </Animated.View>
//     );
// }

export const QuestionMediaSwiper = ({
    quizId,
    question,
    media,
    textType,
    textAlign,
    showQuizState,
    setShowQuizState,
    rightQuizzesState,
    wrongQuizzesState
}: Props) => {

    // const [firstRenderDone, setFirstRenderDone] = useState<boolean>(false);

    const [viewHeight, setViewHeight] = useState<number>(0);
    const [viewWidth, setViewWidth] = useState<number>(0);

    let animatedScrollXValue = useRef(new Animated.Value(0)).current;
    // let animatedHandContainerValue = useRef(new Animated.Value(0)).current;
    // let animatedHandValue = useRef(new Animated.Value(0)).current;

    // useEffect(() => {
    //     if (!firstRenderDone) {
    //         const hintTimeout = setTimeout(() => {
    //             Animated.sequence([
    //                 Animated.timing(animatedHandContainerValue, {
    //                     toValue: 1,
    //                     duration: 300,
    //                     useNativeDriver: true
    //                 }),
    //                 Animated.timing(animatedHandValue, {
    //                     toValue: 1,
    //                     duration: 1000,
    //                     useNativeDriver: true
    //                 }),
    //                 Animated.timing(animatedHandValue, {
    //                     toValue: 0,
    //                     duration: 400,
    //                     useNativeDriver: true
    //                 }),
    //                 Animated.timing(animatedHandValue, {
    //                     toValue: 1,
    //                     duration: 1000,
    //                     useNativeDriver: true
    //                 }),
    //                 Animated.timing(animatedHandContainerValue, {
    //                     toValue: 0,
    //                     duration: 300,
    //                     useNativeDriver: true
    //                 })
    //             ]).start()
    //             clearTimeout(hintTimeout);
    //         }, 1000)
    //         setFirstRenderDone(true);
    //     }
    // }, [])

    return (
        <View
            style={{
                flex: 1,
                alignItems: 'center',
                justifyContent: 'center'
            }}
            onLayout={e => {
                setViewHeight(e.nativeEvent.layout.height);
                setViewWidth(e.nativeEvent.layout.width);
            }}
        >
            {/* {(media !== undefined &&
                !rightQuizzesState.includes(quizId) &&
                !wrongQuizzesState.includes(quizId)) &&
                <AnimatedTutorialButton
                    animatedHandContainerValue={animatedHandContainerValue}
                    animatedHandValue={animatedHandValue}
                />} */}
            <Animated.ScrollView
                style={{
                    height: viewHeight,
                    width: viewWidth
                }}
                decelerationRate="fast"
                contentContainerStyle={{
                    width: media ? '200%' : '100%'
                }}
                scrollEventThrottle={16}
                onScroll={
                    Animated.event(
                        [{ nativeEvent: { contentOffset: { x: animatedScrollXValue } } }],
                        {
                            useNativeDriver: true,
                        },
                    )
                }
                horizontal={true}
                pagingEnabled={true}
                snapToStart={true}
                snapToEnd={true}
                showsHorizontalScrollIndicator={false}
            >
                <AnimatedCard
                    index={0}
                    viewHeight={viewHeight}
                    viewWidth={viewWidth}
                    animatedScrollXValue={animatedScrollXValue}
                >
                    <QuestionCard
                        quizId={quizId}
                        question={question.question}
                        description={question.description}
                        textType={textType}
                        textAlign={textAlign}
                        showQuizState={showQuizState}
                        setShowQuizState={setShowQuizState}
                        rightQuizzesState={rightQuizzesState}
                        wrongQuizzesState={wrongQuizzesState}
                    />
                </AnimatedCard>
                {media !== undefined ?
                    <AnimatedCard
                        index={1}
                        viewHeight={viewHeight}
                        viewWidth={viewWidth}
                        animatedScrollXValue={animatedScrollXValue}
                    >
                        <MediaProvider media={media} />
                    </AnimatedCard>
                    : null
                }
            </Animated.ScrollView>
            {media !== undefined && <PositionIndicator
                segments={2}
                viewHeight={viewHeight}
                animatedScrollXValue={animatedScrollXValue}
            />}
        </View>
    );
};

export default QuestionMediaSwiper;