import React, { useEffect, useRef, useState } from 'react';

import { StyleSheet, View, Animated } from 'react-native';

import { hexProvider, Colors } from '../utils/hexProvider';

type Props = {
    progress: number,
    duration?: number;
    color?: Colors,
    thickness?: number,
    fullBorderRadius?: boolean,
    zIndex?: number,
    positionAbsolute?: boolean
};

const ProgressBar = ({ color = 'primary',
    progress = 0,
    duration = 500,
    positionAbsolute = false,
    thickness = 5,
    fullBorderRadius = false,
    zIndex = 1
}: Props) => {
    let translateX = useRef(new Animated.Value(0)).current;

    const [width, setWidth] = useState<number>(0);

    useEffect(() => {
        Animated.timing(translateX, {
            duration: duration,
            toValue: progress,
            useNativeDriver: true
        }).start();
    }, [progress])

    const translateXPosition = translateX.interpolate({
        inputRange: [0, 100],
        outputRange: [-width, 0],
    })

    return (
        <View
            style={positionAbsolute ? [styles.progressBarAbsolut, { height: thickness, zIndex: zIndex, borderRadius: thickness / 2, overflow: 'hidden' }] : [styles.progressBar, { height: thickness, borderRadius: thickness / 2, overflow: 'hidden' }]}
            onLayout={e => setWidth(e.nativeEvent.layout.width)}
        >
            <Animated.View
                style={{
                    position: 'absolute',
                    top: 0,
                    bottom: 0,
                    left: 0,
                    width: '100%',
                    borderRadius: fullBorderRadius ? thickness / 2 : undefined,
                    backgroundColor: hexProvider(color),
                    transform: [
                        {
                            translateX: translateXPosition
                        }
                    ]
                }}
            />
        </View >
    );
}

const styles = StyleSheet.create({
    progressBar: {
        flexDirection: 'row',
        width: '100%',
    },
    progressBarAbsolut: {
        flexDirection: 'row',
        width: '80%',
        position: 'absolute',
    }
});

export default ProgressBar;