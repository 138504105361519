import { Hub } from 'aws-amplify';

import UniversalAlert from '../components/UniversalAlert';
import { translate } from '../services/localization/translations';

const resetCache = async () => {
    UniversalAlert(
        translate('resetCache') + '?',
        undefined,
        [
            {
                text: translate('cancel'),
                onPress: () => { return },
                style: 'cancel'
            },
            {
                text: translate('confirm'),
                onPress: () => Hub.dispatch(
                    'premium',
                    {
                        event: 'premiumChange',
                        data: undefined,
                        message: undefined
                    })
            }
        ]
    );
};

export default resetCache;