import * as React from "react"
import { View } from "react-native";
import Svg, {
    SvgProps,
    Defs,
    LinearGradient,
    Stop,
    Path,
} from "react-native-svg"

const U1PlusLogo = (props: SvgProps) => {
    return (
        <View
            style={{
                flex: 1,
                alignItems: 'center',
                justifyContent: 'center',
                padding: 16
            }}
        >
            <Svg
                viewBox="0 0 172 193"

                {...props}>
                <Defs>
                    <LinearGradient
                        x1="19.727%"
                        y1="-3.356%"
                        x2="82.104%"
                        y2="87.429%"
                        id="prefix__a"
                    >
                        <Stop stopColor="#FC0" offset="0%" />
                        <Stop stopColor="#FF9500" offset="22.86%" />
                        <Stop stopColor="#FF2D55" offset="49.12%" />
                        <Stop stopColor="#AF52DE" offset="74.84%" />
                        <Stop stopColor="#3880FF" offset="100%" />
                    </LinearGradient>
                </Defs>
                <Path
                    d="M149.813 137a2 2 0 012 2l-.001 17.687H169.5a2 2 0 012 2v12.625a2 2 0 01-2 2h-17.688V191a2 2 0 01-2 2h-12.625a2 2 0 01-2-2v-17.688H117.5a2 2 0 01-2-2v-12.625a2 2 0 012-2h17.687V139a2 2 0 012-2h12.625zM61.73 0l.028 1.463v132.604a43.899 43.899 0 009.69 26.28 36.41 36.41 0 004.058 4.137 35.77 35.77 0 004.6 3.39c6.238 3.865 13.725 5.892 22.254 6.023l.12.002c.418.008.838.004 1.259-.004h.054l.2.004a39.757 39.757 0 004.71 11.696c-1.14.07-2.285.103-3.43.101l-.191.004c-.446.005-.892.005-1.337 0-.194.005-.399.005-.607.005-.225 0-.454 0-.684-.006l-.199-.003a55.676 55.676 0 01-25.512-6.144 47.615 47.615 0 01-14.354-11.646 49.985 49.985 0 01-3.343-4.507 56.577 56.577 0 01-9.115-30.821V11.799H11.8v120.779a43.88 43.88 0 009.717 27.768 36.385 36.385 0 004.058 4.138 35.807 35.807 0 004.6 3.39 39.21 39.21 0 0012.35 4.916l6.215 1.007v11.774l-1.599-.149a59.465 59.465 0 01-7.588-1.2 51.545 51.545 0 01-12.741-4.67 47.605 47.605 0 01-14.354-11.646 50.03 50.03 0 01-3.343-4.507A56.582 56.582 0 010 132.578V0h61.73zm95.871 0v127.749a39.792 39.792 0 00-11.8-2.652V11.8H112.74a23.722 23.722 0 01-2.392 5.844 32.58 32.58 0 01-2.379 3.585 31.268 31.268 0 01-4.856 5.068 31.3 31.3 0 01-.755.601 32.779 32.779 0 01-8.944 4.956 33.078 33.078 0 01-4.953 1.39v11.69h20.146l-.003 73.208c-.548 11.846-3.248 19.286-5.417 23.441a23.086 23.086 0 01-8.825 9.678l-2.934 1.68-5.863-10.24 2.935-1.68c2.42-1.386 5.67-5.124 7.41-15.06.475-2.773.775-5.573.898-8.383V56.733H76.66V22.027h5.9a19.753 19.753 0 006.799-1.256 20.868 20.868 0 006.21-3.549c.765-.64 1.48-1.337 2.138-2.087a20.473 20.473 0 002.4-3.356 11.533 11.533 0 001.578-5.88V0H157.6z"
                    fill="url(#prefix__a)"
                    fillRule="nonzero"
                />
            </Svg>
        </View>
    )
}

export default U1PlusLogo;