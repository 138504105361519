import React from 'react';
import { View, StyleSheet, TouchableOpacity, Platform } from 'react-native'
import * as WebBrowser from 'expo-web-browser';

import Button from './Button';
import Text from './Text';

import { hexProvider, shadowProvider } from '../utils/hexProvider';
import { translate } from '../services/localization/translations';
import constants from '../utils/constants';


import { User } from '../models/data/UserType';

type ProductType = {
    name: string;
    price: number;
    month: number;
    group: 'INDIVIDUAL' | 'TEAM';
    productIndentifier: string;
    UserPremium: User['premium'];
};

type Props = {
    productCategoryFilter: 'INDIVIDUAL' | 'TEAM';
    handleSetProductFilter: (filter: 'INDIVIDUAL' | 'TEAM') => void;
    products: ProductType[];
    selectedProduct: ProductType['productIndentifier'] | undefined;
    setSelectedProduct: (selectedProduct: ProductType['productIndentifier']) => void;
}

const ProductSelector = ({
    productCategoryFilter,
    handleSetProductFilter,
    products,
    selectedProduct,
    setSelectedProduct
}: Props) => {

    const styles = StyleSheet.create({
        container: {
            margin: 4,
            padding: 8,
            borderRadius: 8 / 1,
            backgroundColor: hexProvider('grey'),
            justifyContent: 'space-around',
        },
        productItem: {
            height: 112,
            margin: 4,
            padding: 8,
            borderRadius: 8 / 1,
            flexDirection: 'row',
            alignItems: 'center',
            backgroundColor: hexProvider('grey'),
        },
        productItemMonth: {
            margin: 4,
            height: 56,
            width: 56,
            borderRadius: 56 / 2,
            justifyContent: 'center',
            alignItems: 'center'
        },
        productItemMonthColorTeam: {
            backgroundColor: '#FF2D55'
        },
        productItemMonthColorIndividual: {
            backgroundColor: hexProvider('primary'),
        },
        productItemTeam: {
            borderColor: '#FF2D55',
            borderWidth: 4
        },
        productItemIndividual: {
            borderColor: hexProvider('primary'),
            borderWidth: 4
        },
        shadow: shadowProvider()
    });

    return (
        <View
            style={styles.container}
        >
            <View
                style={{
                    flexDirection: 'row',
                    justifyContent: 'space-evenly'
                }}
            >
                <View
                    style={{
                        flex: 1
                    }}
                >
                    <Button
                        title='Individual'
                        bold
                        type={productCategoryFilter === 'INDIVIDUAL' ? 'solid' : 'clear'}
                        color={productCategoryFilter === 'INDIVIDUAL' ? 'primary' : 'text'}
                        textColor={productCategoryFilter === 'INDIVIDUAL' ? 'white' : 'text'}
                        style={{
                            height: 56,
                            borderRadius: 56 / 4,
                            margin: 4
                        }}
                        //@ts-ignore
                        onPress={() => handleSetProductFilter('INDIVIDUAL')}
                    />
                </View>
                {
                    Platform.OS === 'web' &&
                    <View
                        style={{
                            flex: 1
                        }}
                    >
                        <Button
                            title='Team'
                            bold
                            type={productCategoryFilter === 'TEAM' ? 'solid' : 'clear'}
                            color={productCategoryFilter === 'TEAM' ? undefined : 'text'}
                            hexColor={productCategoryFilter === 'TEAM' ? '#FF2D55' : undefined}
                            textColor={productCategoryFilter === 'TEAM' ? 'white' : 'text'}
                            style={{
                                height: 56,
                                borderRadius: 56 / 4,
                                margin: 4
                            }}
                            //@ts-ignore
                            onPress={() => handleSetProductFilter('TEAM')}
                        />
                    </View>
                }
            </View>
            {!(Platform.OS == 'web' && productCategoryFilter === 'INDIVIDUAL') && <View
                style={{ paddingVertical: 8 }}
            >
                <Text
                    type='h5'
                    textAlign='center'
                    color='text'
                >
                    {productCategoryFilter === 'TEAM' ? translate('abonnementDescriptionTeam') : translate('aboonementDescriptionIndividual')}
                </Text>
            </View>}
            <View
                style={{ paddingVertical: 8 }}
            >
                {(Platform.OS == 'web' && productCategoryFilter === 'INDIVIDUAL') ?
                    <View style={{ flex: 1, justifyContent: 'space-around', alignItems: 'stretch' }}>
                        <Text
                            color='text'
                            textAlign='center'
                            style='bold'
                            type='h5'
                        >
                            {translate('completeInApp') + ':'}
                        </Text>
                        <Button
                            title={'Apple AppStore'}
                            bold
                            type='solid'
                            color='text'
                            textColor='textinverted'
                            style={{
                                height: 56,
                                borderRadius: 56 / 4,
                                margin: 4,
                                paddingRight: 8
                            }}
                            icon={
                                {
                                    icon: 'apple',
                                    color: 'textinverted',
                                    style: { padding: 8 }
                                }
                            }
                            onPress={() => WebBrowser.openBrowserAsync(constants.APPSTORE)}
                        />
                        <Button
                            title={'Google Play Store'}
                            bold
                            type='solid'
                            color='text'
                            textColor='textinverted'
                            style={{
                                height: 56,
                                borderRadius: 56 / 4,
                                margin: 4,
                                paddingRight: 8
                            }}
                            icon={
                                {
                                    icon: 'google',
                                    color: 'textinverted',
                                    style: { padding: 8 }
                                }
                            }
                            onPress={() => WebBrowser.openBrowserAsync(constants.PLAYSTORE)}
                        />
                    </View>
                    :
                    <>
                        {products.filter(p => p.group === productCategoryFilter).map(product =>
                            <TouchableOpacity
                                key={product.name}
                                style={[
                                    styles.productItem,
                                    styles.shadow,
                                    productCategoryFilter === 'INDIVIDUAL' ?
                                        selectedProduct === product.productIndentifier ?
                                            styles.productItemIndividual :
                                            null :
                                        selectedProduct === product.productIndentifier ?
                                            styles.productItemTeam :
                                            null]}
                                onPress={() => setSelectedProduct(product.productIndentifier)}
                            >
                                <View
                                    style={[styles.productItemMonth, productCategoryFilter === 'INDIVIDUAL' ? styles.productItemMonthColorIndividual : styles.productItemMonthColorTeam]}
                                >
                                    <Text
                                        type='h2'
                                        textAlign='center'
                                        style='bold'
                                        color='white'
                                    >
                                        {product.month}
                                    </Text>
                                </View>
                                <View
                                    style={{
                                        flex: 1,
                                        paddingLeft: 16,
                                        justifyContent: 'space-around'
                                    }}
                                >
                                    <Text color='text' style='bold' type='h4' textAlign='left'>{product.name}</Text>
                                    <Text color='text' style='normal' type='h5' textAlign='left'>{product.price + '€ ' + (productCategoryFilter === 'TEAM' ? translate('perMember') + ' ' : '') + (product.month > 1 ? `/ ${Math.round((product.price / product.month) * 100) / 100}€ ` + translate('monthly') : '')}</Text>
                                    <Text color='text' style='normal' type='h5' textAlign='left'>{translate('includesTaxes')}</Text>
                                </View>
                            </TouchableOpacity>
                        )}
                    </>
                }
            </View>
        </View>
    );
}

export default ProductSelector;