import React from 'react';
import { View, Platform, useColorScheme, ColorSchemeName, KeyboardAvoidingView } from 'react-native';

import { Input } from 'react-native-elements';

import Button from './Button';
import Text from './Text';

import { hexProvider } from '../utils/hexProvider';
import { translate } from '../services/localization/translations';

export type Props = {
    quantity: number;
    setQuantity: (quantity: number) => void;
    productCategory: 'TEAM' | 'INDIVIDUAL';
};

const ProductQuantityCard = ({
    quantity,
    setQuantity,
    productCategory
}: Props) => {

    const nativePlatformsProductQuantities: number[] = [1, 3, 5];

    let colorScheme: ColorSchemeName = useColorScheme();
    let textColor = hexProvider('text');

    const PlatformQuantitySelector = () => {
        if (true) {
            return (
                <View
                    style={{
                        flex: 1,
                        flexDirection: 'row',
                    }}
                >
                    <Button
                        icon={{
                            icon: 'minus',
                            color: 'white',
                            style: {
                                padding: 1
                            }
                        }}
                        bold
                        type={'solid'}
                        color={productCategory === 'TEAM' ? undefined : 'primary'}
                        hexColor={productCategory === 'TEAM' ? '#FF2D55' : undefined}
                        textColor={'white'}
                        style={{
                            height: 56,
                            width: 56,
                            borderRadius: 56 / 2,
                            zIndex: 1
                        }}
                        onPress={() => quantity > 1 ? setQuantity(quantity - 1) : undefined}
                    />
                    <KeyboardAvoidingView
                        behavior={Platform.OS === "ios" ? "padding" : "height"}
                        keyboardVerticalOffset={64}
                        style={{
                            flex: 1,
                            zIndex: -1
                        }}
                    >
                        <Input
                            keyboardType='numeric'
                            value={quantity ? quantity.toString() : '1'}
                            onChangeText={(text) => {
                                const quantity = parseInt(text, 10);
                                if (quantity > 0) {
                                    setQuantity(quantity);
                                }
                            }}
                            inputStyle={{
                                color: textColor,
                                fontSize: 28,
                                marginTop: 8,
                                fontWeight: 'bold',
                                textAlign: 'center',
                            }}
                            containerStyle={{
                                backgroundColor: 'transparent',
                                position: 'absolute',
                                top: 16,
                                left: 0,
                                right: 0,
                                alignItems: 'center'
                            }}
                            multiline={true}
                            rightIcon={undefined}
                            leftIcon={undefined}
                            placeholder='1'
                            placeholderTextColor={textColor}
                            selectionColor={textColor}
                            keyboardAppearance={colorScheme === 'dark' ? 'dark' : 'light'}
                            autoFocus={false}
                            autoCapitalize='none'
                            autoCorrect={false}
                            autoCompleteType={'off'}
                        />
                    </KeyboardAvoidingView>
                    <Button
                        icon={{
                            icon: 'plus',
                            color: 'white',
                            style: {
                                padding: 1
                            }
                        }}
                        bold
                        type={'solid'}
                        color={productCategory === 'TEAM' ? undefined : 'primary'}
                        hexColor={productCategory === 'TEAM' ? '#FF2D55' : undefined}
                        textColor={'white'}
                        style={{
                            height: 56,
                            width: 56,
                            borderRadius: 56 / 2,
                        }}
                        onPress={() => setQuantity(quantity + 1)}
                    />
                </View>
            );
        } else {
            return (
                <>
                    {
                        nativePlatformsProductQuantities.map((q) =>
                            <Button
                                key={q}
                                title={q.toString()}
                                bold
                                color={'primary'}
                                textColor={q === quantity ? 'white' : 'primary'}
                                type={q === quantity ? 'solid' : 'clear'}
                                style={{
                                    height: 56,
                                    width: 56,
                                    borderRadius: 56 / 2,
                                }}
                                onPress={() => setQuantity(q)}
                            />
                        )
                    }
                </>
            );
        }
    }

    return (
        <View
            style={{
                height: 128,
                margin: 4
            }}>
            <View
                style={{
                    flex: 1,
                    padding: 8,
                    paddingHorizontal: 16,
                    borderRadius: 8 / 1,
                    backgroundColor: hexProvider('grey'),
                }}
            >
                <View
                    style={{
                        paddingBottom: 8,
                    }}
                >
                    <Text
                        type='h5'
                        color='text'
                        style='bold'
                    >
                        {translate('teamLicenseQuantity') + ':'}
                    </Text>
                </View>
                <PlatformQuantitySelector />
            </View>
        </View >
    );
};

export default ProductQuantityCard;