import React, { useRef, useState } from 'react';
import { Animated, View, Platform } from 'react-native';
import { useNavigation } from '@react-navigation/native';

import ListCard from './ListCard';
import ListCardPremium from './ListCardPremium';
import Button from './Button';

import { shadowProvider, hexProvider } from '../utils/hexProvider';
import { useAuthState } from '../services/context/authContext';

const ITEM_SIZE = 112;
const ITEM_SIZE_PLUS_MARGIN = 120;

type ListCard = {
    id: string;
    name: string;
    description: string;
    position: number;
    podcastDate?: null | string
}[];

type Props = {
    expanded: boolean;
    expandedOnPress?: (() => void);
    data: ListCard;
    done?: [string];
    selectedItem: string;
    setSelectedIndex: ((item: number) => void);
    type?: 'category' | 'story' | 'quiz' | 'podcast';
    onRefresh?: (() => void);
};

const CardsList = ({
    expanded,
    expandedOnPress,
    data,
    done,
    selectedItem,
    setSelectedIndex,
    type,
    onRefresh
}: Props) => {
    const { data: auth } = useAuthState();

    const scrollY = useRef(new Animated.Value(0)).current;

    const [isRefreshing, setIsRefreshing] = useState<boolean>(false);

    const shadow = shadowProvider();
    const greyColor = hexProvider('grey');
    const navigation = useNavigation();

    const handleCardPress = (itemId?: string, index?: number) => {
        if (itemId && type === 'category') {
            //@ts-ignore
            navigation.navigate('CategoryScreen', {
                categoryId: itemId,
                lessonId: undefined
            })
        } else if ((index || index === 0) && type === 'story') {
            setSelectedIndex(index);
        } else if (itemId && type === 'quiz') {
            //@ts-ignore
            navigation.navigate('Story', {
                screen: "StoryScreen", params: {
                    ids: [itemId],
                    type: 'QUIZCATEGORY',
                    language: undefined
                }
            })
        } else if ((index || index === 0) && type === 'podcast') {
            setSelectedIndex(index);
        } else {
            undefined
        }
    }

    const RenderListFooterComponent = () => {
        let premium: boolean | undefined =
            auth?.signInUserSession?.
                idToken?.
                payload['cognito:groups']
                //@ts-ignore
                .some(e => e === 'premium');

        if (premium) {
            return null;
        } else {
            return <>
                {
                    [...Array((type === 'category' || type === 'quiz') ? 1 : 16)].map((e, i) => <ListCardPremium
                        //@ts-ignore
                        onPress={() => navigation.navigate('PremiumScreen')}
                        key={i}
                        style={[{
                            height: ITEM_SIZE,
                            overflow: 'hidden',
                            marginHorizontal: 8,
                            marginVertical: 4,
                        },
                            shadow]}
                    />)
                }
            </>;
        }

    }

    const handleOnRefresh = () => {
        setIsRefreshing(true);
        onRefresh && onRefresh();
        setIsRefreshing(false);
    }

    return (
        <View
            style={{
                flex: 1,
                overflow: 'hidden',
                backgroundColor: greyColor,
                borderRadius: 8 / 1
            }}
        >
            {expandedOnPress &&
                <View style={{ position: "absolute", zIndex: 1, top: 0, right: 0, alignItems: 'center', justifyContent: 'center' }}>
                    <Button type='solid' color='textinverted' icon={{ icon: !expanded ? 'expand' : 'compress', color: 'text', style: undefined }} style={{ height: 64, width: 64, borderRadius: 64 / 2, opacity: 0.8, margin: 8 }} onPress={expandedOnPress} />
                </View>
            }
            <Animated.FlatList
                refreshing={isRefreshing}
                onRefresh={handleOnRefresh}
                data={data}
                keyExtractor={item => item.id}
                style={{
                    height: 1
                }}
                initialScrollIndex={Platform.OS === 'web' ? undefined : data && data.findIndex(e => e.id === selectedItem)}
                getItemLayout={(data, index) => (
                    { length: ITEM_SIZE_PLUS_MARGIN, offset: ITEM_SIZE_PLUS_MARGIN * index, index }
                )}
                onScroll={Animated.event(
                    [{ nativeEvent: { contentOffset: { y: scrollY } } }],
                    { useNativeDriver: true }
                )}
                ListFooterComponent={RenderListFooterComponent}
                renderItem={({ item, index }) => {
                    const scale = scrollY.interpolate({
                        inputRange: [-1, 0, ITEM_SIZE_PLUS_MARGIN * index, ITEM_SIZE_PLUS_MARGIN * (index + 2)],
                        outputRange: [1, 1, 1, 0],
                    })
                    const opacity =
                        (type === 'podcast' && !item?.podcastDate) ?
                            0.4 :
                            scrollY.interpolate({
                                inputRange: [-1, 0, ITEM_SIZE_PLUS_MARGIN * index, ITEM_SIZE_PLUS_MARGIN * (index + 1)],
                                outputRange: [1, 1, 1, 0],
                            });

                    return <Animated.View
                        style={{
                            flexDirection: 'row',
                            alignItems: 'center',
                            opacity,
                            transform: [{ scale }]
                        }}>
                        <ListCard
                            key={index}
                            title={item.name}
                            description={item.description}
                            selected={selectedItem === item.id}
                            style={[{
                                height: ITEM_SIZE,
                                flex: 1,
                                padding: 8,
                                overflow: 'hidden',
                                marginHorizontal: 8,
                                marginVertical: 4,
                            },
                                shadow]}
                            done={done ? done.includes(item.id) : undefined}
                            disabled={type === 'podcast' && !item?.podcastDate}
                            onPress={() => handleCardPress(item.id, index)} />
                    </Animated.View>
                }}
            />
        </View>
    );
}

export default CardsList;