import React, { useEffect } from 'react';
import { Platform } from 'react-native';

import * as Linking from 'expo-linking';
import * as WebBrowser from 'expo-web-browser';

import { createStackNavigator } from '@react-navigation/stack';
import { useNavigation } from '@react-navigation/native';

import { HomeScreen } from '../screens/HomeScreen';
import { QuizzeScreen } from '../screens/QuizzeScreen';
import { CategoryScreen } from '../screens/CategoryScreen';
import { SettingsScreen } from '../screens/SettingsScreen';
import { PremiumScreen } from '../screens/PremiumScreen';
import { MemberScreen } from '../screens/MemberScreen';
import { AddMemberScreen } from '../screens/AddMemberScreen';
import { OrderScreen } from '../screens/OrderScreen';
import { SuccessScreen } from '../screens/SuccessScreen';
import { FeaturesScreen } from '../screens/FeaturesScreen';
import { CredentialsScreen } from '../screens/CredentialsScreen';

import Header from '../../components/Header';
import Button from '../../components/Button';

import constants from '../../utils/constants';

import { translate } from '../../services/localization/translations'
import { hexProvider } from '../../utils/hexProvider';

import { MainStackParamList } from '../../models/stackParams/MainStackParamListType';

const MainStack = createStackNavigator<MainStackParamList>();

export function MainStackNavigator() {
    const navigation = useNavigation();

    let bgColor = hexProvider('textinverted');
    let textColor = hexProvider('text');

    useEffect(() => {
        Linking.addEventListener('url', urlRedirect);

        return () => {
            Linking.removeEventListener('url', urlRedirect)
        }
    }, [])

    const urlRedirect = (event: Linking.EventType) => {
        if (!event.url) return;
        // parse and redirect to new url
        let { path, queryParams } = Linking.parse(event.url);
        //@ts-ignore
        if (constants.ALLOWEDROUTES.includes(path) && path !== 'Main') {
            Platform.OS === 'ios' && WebBrowser.dismissBrowser();
            //@ts-ignore
            navigation.navigate(path, queryParams);
            console.log(`Linked to OPEN app with path: ${path} and data: ${JSON.stringify(queryParams)}`);
        }
    };

    return (
        //@ts-ignore
        <MainStack.Navigator initialRouteName='HomeScreen' screenOptions={{
            headerTitleAllowFontScaling: true,
            headerBackAllowFontScaling: true,
            headerBackTitle: translate('back'),
            headerStyle: {
                backgroundColor: bgColor,
            },
            headerTintColor: textColor,
            headerTitleStyle: {
                fontWeight: 'bold',
            },
        }}>
            <MainStack.Screen name="HomeScreen" component={HomeScreen} options={
                Platform.OS === 'web' ?
                    ({ navigation }) => ({
                        title: translate('overview') + ' | u1Dent.app',
                        header: () => <Header
                            title={translate('overview')}
                            buttonLeft={{
                                icon: {
                                    icon: 'cog',
                                    color: 'text'
                                },
                                onPress: () => navigation.navigate('SettingsScreen')
                            }}
                        />
                    })
                    :
                    {
                        title: translate('overview'),
                        headerLeft: () => (
                            <Button
                                style={{ height: 64, width: 64, borderRadius: 64 / 2 }}
                                type='clear'
                                icon={{ icon: 'cog', color: 'text', style: undefined }}
                                //@ts-ignore
                                onPress={() => navigation.navigate('SettingsScreen')} />
                        )
                    }
            }
            />
            <MainStack.Screen name="QuizzeScreen" component={QuizzeScreen} options={
                Platform.OS === 'web' ?
                    ({ navigation }) => ({
                        title: translate('quizzes') + ' | u1Dent.app',
                        header: () => <Header
                            title={translate('quizzes')}
                            buttonLeft={{
                                icon: {
                                    icon: 'arrow-left',
                                    color: 'text'
                                },
                                onPress: () => navigation.goBack()
                            }}
                        />
                    })
                    :
                    {
                        title: translate('quizzes'),
                    }
            }
            />
            <MainStack.Screen name="CategoryScreen" component={CategoryScreen} options={
                Platform.OS === 'web' ?
                    ({ navigation }) => ({
                        title: 'u1Dent.app',
                        header: () => <Header
                            buttonLeft={{
                                icon: {
                                    icon: 'arrow-left',
                                    color: 'text'
                                },
                                onPress: () => navigation.goBack()
                            }}
                        />
                    })
                    :
                    {
                        title: '',
                    }
            }
            />
            <MainStack.Screen name="SettingsScreen" component={SettingsScreen} options={
                Platform.OS === 'web' ?
                    ({ navigation }) => ({
                        title: translate('settings') + ' | u1Dent.app',
                        header: () => <Header
                            title={translate('settings')}
                            buttonLeft={{
                                icon: {
                                    icon: 'arrow-left',
                                    color: 'text'
                                },
                                onPress: () => navigation.goBack()
                            }}
                        />
                    })
                    :
                    {
                        title: translate('settings'),
                    }
            }
            />
            <MainStack.Screen name="PremiumScreen" component={PremiumScreen} options={
                Platform.OS === 'web' ?
                    ({ navigation }) => ({
                        title: translate('subscription') + ' | u1Dent.app',
                        header: () => <Header
                            title={translate('subscription')}
                            buttonLeft={{
                                icon: {
                                    icon: 'arrow-left',
                                    color: 'text'
                                },
                                onPress: () => navigation.goBack()
                            }}
                        />
                    })
                    :
                    {
                        title: translate('subscription'),
                    }
            }
                initialParams={{ refresh: false }}
            />
            <MainStack.Screen name="MemberScreen" component={MemberScreen} options={
                Platform.OS === 'web' ?
                    ({ navigation }) => ({
                        title: translate('member') + ' | u1Dent.app',
                        header: () => <Header
                            title={translate('member')}
                            buttonLeft={{
                                icon: {
                                    icon: 'arrow-left',
                                    color: 'text'
                                },
                                onPress: () => navigation.goBack()
                            }}
                        />
                    })
                    :
                    {
                        title: translate('member'),
                    }
            }
            />
            <MainStack.Screen name="AddMemberScreen" component={AddMemberScreen} options={
                Platform.OS === 'web' ?
                    ({ navigation }) => ({
                        title: translate('addmember') + ' | u1Dent.app',
                        header: () => <Header
                            title={translate('addmember')}
                            buttonLeft={{
                                icon: {
                                    icon: 'arrow-left',
                                    color: 'text'
                                },
                                onPress: () => navigation.goBack()
                            }}
                        />
                    })
                    :
                    {
                        title: translate('addmember'),
                    }
            }
            />
            <MainStack.Screen name="OrderScreen" component={OrderScreen} options={
                Platform.OS === 'web' ?
                    ({ navigation }) => ({
                        title: translate('order') + ' | u1Dent.app',
                        header: () => <Header
                            title={translate('order')}
                            buttonLeft={{
                                icon: {
                                    icon: 'arrow-left',
                                    color: 'text'
                                },
                                onPress: () => navigation.goBack()
                            }}
                        />
                    })
                    :
                    {
                        title: translate('order'),
                    }
            }
            />
            <MainStack.Screen name='SuccessScreen' component={SuccessScreen} options={{
                title: 'u1Dent.app',
                headerShown: false
            }}
            />
            <MainStack.Screen name="FeaturesScreen" component={FeaturesScreen} options={
                Platform.OS === 'web' ?
                    ({ navigation }) => ({
                        title: translate('perks') + ' | u1Dent.app',
                        header: () => <Header
                            title={translate('perks')}
                            buttonLeft={{
                                icon: {
                                    icon: 'arrow-left',
                                    color: 'text'
                                },
                                onPress: () => navigation.goBack()
                            }}
                        />
                    })
                    :
                    {
                        title: translate('perks'),
                    }
            }
            />
            <MainStack.Screen name="CredentialsScreen" component={CredentialsScreen} options={
                Platform.OS === 'web' ?
                    ({ navigation }) => ({
                        title: translate('credential') + ' | u1Dent.app',
                        header: () => <Header
                            title={translate('credential')}
                            buttonLeft={{
                                icon: {
                                    icon: 'arrow-left',
                                    color: 'text'
                                },
                                onPress: () => navigation.goBack()
                            }}
                        />
                    })
                    :
                    {
                        title: translate('credential'),
                    }
            }
            />
        </MainStack.Navigator >
    );
}