import React from 'react';

import { Animated, Image } from 'react-native';
// import { GestureEvent, PinchGestureHandler, PinchGestureHandlerEventPayload } from 'react-native-gesture-handler';

import { ResponsiveContainer } from '../../components/ResponsiveContainer';

import { StackNavigationProp } from '@react-navigation/stack';
import { RouteProp } from '@react-navigation/native';

import { StoryStackParamList } from '../../models/stackParams/StoryStackParamListType';
import SourceProvider from '../../components/SourceProvider';

type MediaScreenNavigationProp = StackNavigationProp<
    StoryStackParamList,
    'MediaScreen'
>;

type MediaScreenRouteProp = RouteProp<
    StoryStackParamList,
    'MediaScreen'
>;


type Props = {
    navigation: MediaScreenNavigationProp;
    route: MediaScreenRouteProp;
};

export const MediaScreen = ({ navigation, route }: Props) => {

    const { type, url, description } = route.params;

    // const scale = new Animated.Value(1);

    // const onPinchEvent = Animated.event(
    //     [
    //         {
    //             nativeEvent: { scale: scale }
    //         }
    //     ],
    //     {
    //         useNativeDriver: true
    //     }
    // )

    // const onPinchStateChange = (event: GestureEvent<PinchGestureHandlerEventPayload>) => {
    //     Animated.spring(scale, {
    //         toValue: 1,
    //         bounciness: 1,
    //         useNativeDriver: true
    //     }).start()
    // }

    return (
        <ResponsiveContainer borderRadius={false}>
            {/* <PinchGestureHandler
                onGestureEvent={onPinchEvent}
                onHandlerStateChange={onPinchStateChange}> */}
            <Animated.View
                style={{
                    flex: 1,
                    // transform: [{ scale: scale }]
                }}
            >
                <Image
                    source={{ uri: url }}
                    style={{
                        flex: 1
                    }}
                    resizeMode='contain'
                />
            </Animated.View>
            {/* </PinchGestureHandler> */}
            <SourceProvider
                source={description}
                style={{
                    position: 'absolute',
                    bottom: 8,
                    left: 8,
                    padding: 32
                }}
            />
        </ResponsiveContainer >
    );
}