import React, {
    Dispatch,
    SetStateAction,
    useEffect,
    useState,
} from "react";

import {
    View,
    StyleSheet,
    KeyboardAvoidingView,
    Platform,
    TextInput,
} from 'react-native';

import * as yup from 'yup';

import Button from './Button';
import Text from './Text';

import { translate } from '../services/localization/translations';
import { hexProvider } from '../utils/hexProvider';

type Props = {
    emailInput: string;
    codeInput: string;
    setCodeInput: Dispatch<SetStateAction<string>>;
    setAuthMode: Dispatch<SetStateAction<"SIGNIN" | "CONFIRMSIGNIN">>
    handleAnswerCustomChallenge: () => void;
};

const PWlessEmailCard = ({
    emailInput,
    codeInput,
    setCodeInput,
    setAuthMode,
    handleAnswerCustomChallenge
}: Props) => {

    const [rendered, setRendered] = useState<boolean>(false);

    const [submitPossible, setSubmitPossible] = useState<boolean>(false);

    useEffect(() => {
        setRendered(true)
    }, [])


    let validationSchema = yup.object().shape({
        email: yup.string()
            .required(translate('requiredField'))
            .email(translate('emailNotValid')),
        code: yup.string()
            .required(translate('requiredField'))
            .matches(/[0-9]{6}/g, { message: translate('code6Numbers') })
    });

    rendered && validationSchema
        .validate({
            email: emailInput,
            code: codeInput
        })
        .then(() => {
            setSubmitPossible(true);
        })
        .catch(err => {
            setSubmitPossible(false);
        })

    const styles = StyleSheet.create({
        container: {
            margin: 4,
            padding: 16,
            borderRadius: 8 / 1,
            backgroundColor: hexProvider('grey')
        },
        textInputContainer: {
            alignItems: 'center',
            justifyContent: 'center',
            alignSelf: 'center',
            width: '60%',
            height: 56,
            margin: 16,
            marginTop: 4,
            marginBottom: 24,
            borderColor: hexProvider('primary'),
            borderWidth: 4,
            borderRadius: 8 / 1
        },
        codeInput: {
            flex: 1,
            width: '100%',
            fontSize: 24,
            letterSpacing: 8,
            textAlign: 'center',
            color: hexProvider('text')
        },
    });

    return (
        //@ts-ignore
        <KeyboardAvoidingView
            behavior={Platform.OS === "ios" ? "padding" : undefined}
            enabled
            keyboardVerticalOffset={64}
            style={[styles.container]}
        >
            <View
                style={{
                    padding: 8,
                    paddingBottom: 0
                }}
            >
                <Text
                    children={translate('checkEmailsCode')}
                    type='h5'
                    color='text'
                />
            </View>
            <Button
                title={emailInput}
                type='clear'
                color='primary'
                textColor='primary'
                textType='h5'
                style={{
                    margin: 8,
                    padding: 8,
                    borderRadius: 8 / 1,
                }}
                //@ts-ignore
                onPress={() => setAuthMode('SIGNIN')}
            />
            <View
                style={{
                    marginTop: 4
                }}
            >
                <Text
                    children={translate('verificationCodePlaceholder') + ':'}
                    type='p'
                    color='text'
                />
            </View>
            <View
                style={styles.textInputContainer}
            >
                <TextInput
                    value={codeInput}
                    onChangeText={setCodeInput}
                    keyboardType="number-pad"
                    returnKeyType="done"
                    textContentType="oneTimeCode"
                    allowFontScaling={true}
                    maxLength={6}
                    textAlign='center'
                    style={styles.codeInput}
                />
            </View>
            <Button
                title={translate('signIn')}
                bold
                type='solid'
                color='primary'
                textColor='white'
                style={{
                    height: 56,
                    borderRadius: 56 / 4,
                    margin: 4,
                }}
                disabled={!submitPossible}
                //@ts-ignore
                onPress={handleAnswerCustomChallenge}
            />
            {/*@ts-ignore */}
            <View
                style={{
                    paddingBottom: 8
                }}
            >
                <Text
                    color='text'
                    type='p'
                >
                    {translate('agbHint')}
                </Text>
            </View>
        </KeyboardAvoidingView>
    );
}

export default PWlessEmailCard;