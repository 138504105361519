import React from 'react';
import { View } from 'react-native';

import Card from './Card';
import Text from './Text';

import { translate } from '../services/localization/translations';

const WelcomeCard = ({ }) => {
    return (
        <Card
            square={false}
        >
            <View
                style={{
                    flex: 1,
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                    padding: 8,
                    paddingHorizontal: 16
                }}
            >
                <Text
                    type='h4'
                    style='bold'
                    textAlign='left'
                    color='text'
                >{translate('welcome') + ' 👋'}</Text>
                <Text
                    type='h4'
                    textAlign='left'
                    color='text'
                >{translate('chooseLesson')}</Text>
            </View>
        </Card>
    );
}

export default WelcomeCard;