import React, { /*useState,*/ useRef, ReactNode } from 'react';
import { View, Animated, ViewStyle, Platform } from 'react-native';

import Text from './Text';
import Button from './Button';

import { hexProvider } from '../utils/hexProvider';
import { translate } from '../services/localization/translations';

type StoryEndPageType = {
    currentIndex: number;
    totalIndex: number;
    rightQuizzesState: string[];
    wrongQuizzesState: string[];
    onPressClose: () => void;
};

type CardType = {
    style: ViewStyle;
    showBackground?: boolean;
    children: ReactNode;
};

const Card = ({
    style,
    showBackground = true,
    children
}: CardType) => {
    return (
        <View
            style={[
                style,
                {
                    margin: 4,
                    backgroundColor: showBackground ? hexProvider('grey') : undefined,
                    borderRadius: 8 / 1,
                    justifyContent: 'center'
                }]}
        >
            {children}
        </View>
    );
}

const StoryEndPage = ({
    currentIndex,
    totalIndex,
    rightQuizzesState,
    wrongQuizzesState,
    onPressClose,
}: StoryEndPageType) => {
    const animatedStoryEndPageValue = useRef(new Animated.Value(0)).current;

    // const [rightQuizzes, setRightQuizzes] = useState<number>(0);
    // const [wrongQuizzes, setWrongQuizzes] = useState<number>(0);

    let containsQuizzes: boolean = rightQuizzesState.length > 0 || wrongQuizzesState.length > 0;

    if (currentIndex < totalIndex - 1) {
        animatedStoryEndPageValue.setValue(0);

        return null;
    } else {
        Animated.timing(animatedStoryEndPageValue, {
            toValue: 1,
            duration: 400,
            useNativeDriver: true
        }).start();

        // const animateResults = (rightQuizzesStateLength: number, wrongQuizzesStateLength: number) => {
        //     const quizResult1Animator = setTimeout(() => {
        //         rightQuizzesStateLength > wrongQuizzesStateLength ?
        //             setRightQuizzes(rightQuizzesStateLength) :
        //             setWrongQuizzes(wrongQuizzesStateLength);
        //         clearTimeout(quizResult1Animator);
        //     }, 1000);

        //     const quizResult2Animator = setTimeout(() => {
        //         rightQuizzesStateLength > wrongQuizzesStateLength ?
        //             setWrongQuizzes(wrongQuizzesStateLength) :
        //             setRightQuizzes(rightQuizzesStateLength);
        //         clearTimeout(quizResult2Animator);
        //     }, 2000);
        // }

        // animateResults(rightQuizzesState.length, wrongQuizzesState.length);

        return (
            <Animated.View
                key={totalIndex}
                style={{
                    flex: 1,
                    justifyContent: 'center',
                    opacity: animatedStoryEndPageValue,
                    transform: [{
                        translateY: animatedStoryEndPageValue.interpolate({
                            inputRange: [0, 1],
                            outputRange: [80, 0]
                        })
                    }]
                }}
            >
                <Card
                    style={{
                        flex: 1
                    }}
                    showBackground={false}
                >
                    <Text
                        type='h1'
                        style='bold'
                        color={Platform.OS === 'web' ? 'white' : 'text'}
                        textAlign='center'
                        shadowColor={Platform.OS === 'web' ? 'black' : undefined}
                    >
                        {`${translate('storyDone')}! 🎉`}
                    </Text>
                </Card>
                {containsQuizzes &&
                    <Card
                        style={{
                            flexShrink: 1,
                            padding: 32,
                            flexDirection: 'row'
                        }}
                    >
                        <Card
                            style={{
                                flex: 1
                            }}
                        >
                            <Text
                                type='h3'
                                style='bold'
                                color='success'
                            >
                                {rightQuizzesState.length}
                            </Text>
                            <Text
                                type='h5'
                                textAlign='center'
                                color='success'
                                style='bold'
                            >
                                {translate('storyDoneRight')}
                            </Text>
                        </Card>
                        <Card
                            style={{
                                flex: 1
                            }}
                        >
                            <Text
                                type='h3'
                                style='bold'
                                color='error'
                            >
                                {wrongQuizzesState.length}
                            </Text>
                            <Text
                                type='h5'
                                textAlign='center'
                                color='error'
                                style='bold'
                            >
                                {translate('storyDoneWrong')}
                            </Text>
                        </Card>
                    </Card>
                }
                <Card
                    showBackground={containsQuizzes ? true : false}
                    style={{
                        flexShrink: 1,
                        paddingVertical: 32,
                        paddingHorizontal: containsQuizzes ? 16 : undefined
                    }}
                >
                    <Button
                        title={`${translate('story')} ${translate('exit')}`}
                        textType='h5'
                        icon={{ icon: 'check', color: 'white', style: { padding: 8 } }}
                        type='solid'
                        color='primary'
                        textColor='white'
                        bold={true}
                        style={{ height: 56, borderRadius: 56 / 4 }}
                        onPress={onPressClose} />
                </Card>
            </Animated.View >
        );
    }
};

export default StoryEndPage;