import React, { useEffect, useState } from "react";
import { View, StyleSheet } from 'react-native';

import * as yup from 'yup';

import Button from './Button';
import Text from './Text';

import { translate } from '../services/localization/translations';
import { hexProvider } from '../utils/hexProvider';

type Props = {
    emailInput: string;
    handleSignUp: () => void;
    handleResetError: () => void;
};

const PWlessSignUpCard = ({
    emailInput,
    handleSignUp,
    handleResetError,
}: Props) => {
    const [rendered, setRendered] = useState<boolean>(false);
    const [submitPossible, setSubmitPossible] = useState<boolean>(false);

    useEffect(() => {
        setRendered(true)
    }, [])

    let validationSchema = yup.object().shape({
        email: yup.string()
            .required(translate('requiredField'))
            .email(translate('emailNotValid')),
    });

    rendered && validationSchema
        .validate({
            email: emailInput,
        })
        .then(() => {
            setSubmitPossible(true);
        })
        .catch(err => {
            setSubmitPossible(false);
        })

    const styles = StyleSheet.create({
        container: {
            margin: 4,
            padding: 16,
            borderRadius: 8 / 1,
            backgroundColor: hexProvider('grey')
        }
    });

    return (
        //@ts-ignore
        <View
            style={[styles.container]}
        >
            <View
                style={{
                    padding: 8,
                    paddingBottom: 0
                }}
            >
                <Text
                    children={translate('UserNotFoundException')}
                    type='h5'
                    color='text'
                />
            </View>
            <Button
                title={translate('tryOtherMail')}
                type='clear'
                color='primary'
                textColor='primary'
                textType='h5'
                style={{
                    margin: 4,
                    padding: 8,
                    borderRadius: 8 / 1,
                }}
                //@ts-ignore
                onPress={handleResetError}
            />
            <View
                style={{
                    padding: 8,
                    paddingBottom: 0
                }}
            >
                <Text
                    type='h5'
                    color='text'
                    style='bold'
                >
                    {emailInput}
                </Text>
            </View>
            <Button
                title={translate('signUp') + ' & ' + translate('signIn')}
                bold
                type='solid'
                color='primary'
                textColor='white'
                style={{
                    height: 56,
                    borderRadius: 56 / 4,
                    margin: 4,
                }}
                disabled={!submitPossible}
                //@ts-ignore
                onPress={handleSignUp}
            />
            {/*@ts-ignore */}
            <View
                style={{
                    paddingBottom: 8
                }}
            >
                <Text
                    color='text'
                    type='p'
                >
                    {translate('agbHint')}
                </Text>
            </View>
        </View>
    );
}

export default PWlessSignUpCard;