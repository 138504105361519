import React from 'react';
import { View } from 'react-native';

import { ResponsiveContainer } from '../../components/ResponsiveContainer';
import SocialMediaFooter from '../../components/SocialMediaFooter';
import Divider from '../../components/Divider';
import LegalFooter from '../../components/LegalFooter';
import SupportCard from '../../components/SupportCard';
import SettingsCard from '../../components/SettingsCard';
// import ColorSelector from '../../components/ColorSelector';

import constants from '../../utils/constants';
import { translate } from '../../services/localization/translations';

import { StackNavigationProp } from '@react-navigation/stack';
import { RouteProp } from '@react-navigation/native';
import { MainStackParamList } from '../../models/stackParams/MainStackParamListType';

type SettingsScreenNavigationProp = StackNavigationProp<
    MainStackParamList,
    'SettingsScreen'
>;

type SettingsScreenRouteProp = RouteProp<
    MainStackParamList,
    'SettingsScreen'
>;


type Props = {
    navigation: SettingsScreenNavigationProp;
    route: SettingsScreenRouteProp;
};

export const SettingsScreen = ({ navigation, route }: Props) => {

    return (
        <ResponsiveContainer
        >
            <SupportCard />
            <View
                style={{
                    flex: 2,
                    flexDirection: 'row'
                }}>
                <SettingsCard
                    title={translate('subscription')}
                    icon='star'
                    onPress={() => navigation.navigate('PremiumScreen', { refresh: true })}
                />
                <SettingsCard
                    title={translate('credential')}
                    icon='user-circle'
                    onPress={() => navigation.navigate('CredentialsScreen')}
                />
            </View>
            {/* <Divider/>
            <ColorSelector/> */}
            <SocialMediaFooter
                social={[
                    {
                        icon: 'instagram',
                        color: '#E1306C',
                        link: constants.INSTAGRAM
                    },
                    {
                        icon: 'facebook',
                        color: '#4267B2',
                        link: constants.FACEBOOK
                    }
                ]}
            />
            <LegalFooter />
            <View
                style={{
                    padding: 4
                }}
            />
        </ResponsiveContainer >
    );
}