import React, { useState } from 'react';
import { Platform } from 'react-native';

import Constants from 'expo-constants';
import * as WebBrowser from 'expo-web-browser';
import * as Linking from 'expo-linking';

import ExpiredCard from './ExpiredCard.Subscription';
import { ResponsiveContainer } from './ResponsiveContainer';
import ManageCard from './ManageCard.Subcription';

import { queryAPI } from '../services/api/data';
import createUrl from '../utils/createUrl';
import constants from '../utils/constants';

import TeamType, { TeamUserType } from "../models/data/TeamType";
import { User as UserType } from "../models/data/UserType";
import { RCAPISubscriberType } from '../models/data/PurchaseTypes';

type TeamUser = TeamUserType & { quizProgessSharing?: UserType['quizProgessSharing'] | undefined };
type Team = Omit<TeamType, 'users'> & { users?: undefined | TeamUser[] };

type Props = {
    webSubInfos: Team | undefined;
    mobileSubInfos: RCAPISubscriberType | undefined;
    isLoading: boolean;
}

const IndividualView = ({
    webSubInfos,
    mobileSubInfos,
    isLoading
}: Props) => {

    const [isLoadingUrl, setIsLoadingUrl] = useState<boolean>(false);

    const handleManageSubscription = async () => {
        setIsLoadingUrl(true);
        try {
            if (webSubInfos) {
                const redirecturl = createUrl('PremiumScreen', { refresh: true });
                const mobileRedirectUrl = Platform.OS === 'web' ? undefined : generateMobileRedirectUrl(redirecturl);
                const data = await queryAPI('createCustomerportalSession', { stripePK: Constants.manifest?.extra?.STRIPE_PK, domain: Platform.OS === 'web' ? redirecturl : mobileRedirectUrl }, { refresh: true });
                const response = JSON.parse(data);
                if (response.statusCode === 200) {
                    if (Platform.OS === 'web') {
                        Linking.openURL(response.body);
                    } else {
                        await WebBrowser.openBrowserAsync(response.body);
                        setIsLoadingUrl(false);
                    }
                }
            } else if (mobileSubInfos && mobileSubInfos.management_url) {
                await WebBrowser.openBrowserAsync(mobileSubInfos.management_url);
                setIsLoadingUrl(false);
            }
        } catch (error) {
            console.log(error)
        }
    };

    const generateMobileRedirectUrl = (redirecturl: string): string => {
        const params = new URLSearchParams()
        params.append('directToUrl', redirecturl);
        return `${constants.MOBILEREDIRECTHOST}?${params.toString()}`;
    };

    return (
        <ResponsiveContainer
            isLoading={isLoading || isLoadingUrl}
            scrollable
        >
            <ExpiredCard
                expirationWeb={webSubInfos?.current_period_end}
                expirationMobile={mobileSubInfos?.entitlements['individual'].expires_date}
            />
            {(webSubInfos || mobileSubInfos?.management_url) &&
                <ManageCard
                    handleManageSubscription={handleManageSubscription}
                    teamMode={false}
                />
            }
        </ResponsiveContainer>
    );
}

export default IndividualView;