import React from 'react';
import { FlatList, View } from 'react-native';

import { ResponsiveContainer } from '../../components/ResponsiveContainer';
import FeatureListCard from '../../components/FeatureListCard';
import { Icons } from '../../components/Icon';

import { translate } from '../../services/localization/translations';

import { StackNavigationProp } from '@react-navigation/stack';
import { RouteProp } from '@react-navigation/native';

import { MainStackParamList } from '../../models/stackParams/MainStackParamListType';
import { shadowProvider } from '../../utils/hexProvider';

type FeaturesScreenNavigationProp = StackNavigationProp<
    MainStackParamList,
    'FeaturesScreen'
>;

type FeaturesScreenRouteProp = RouteProp<
    MainStackParamList,
    'FeaturesScreen'
>;


type Props = {
    navigation: FeaturesScreenNavigationProp;
    route: FeaturesScreenRouteProp;
};

type Perk = {
    title: string;
    description: string;
    icon: Icons;
};

export const FeaturesScreen = ({ navigation, route }: Props) => {

    const perks: Perk[] = [
        {
            title: translate('perkList.1.title'),
            description: translate('perkList.1.description'),
            icon: 'play-circle',
        },
        {
            title: translate('perkList.2.title'),
            description: translate('perkList.2.description'),
            icon: 'podcast',
        },
        {
            title: translate('perkList.3.title'),
            description: translate('perkList.3.description'),
            icon: 'question-circle',
        },
        {
            title: translate('perkList.4.title'),
            description: translate('perkList.4.description'),
            icon: 'language',
        },
        {
            title: translate('perkList.5.title'),
            description: translate('perkList.5.description'),
            icon: 'repeat',
        },
        {
            title: translate('perkList.6.title'),
            description: translate('perkList.6.description'),
            icon: 'star',
        },
        {
            title: translate('perkList.7.title'),
            description: translate('perkList.7.description'),
            icon: 'users',
        },
    ];

    const shadow = shadowProvider();

    return (
        <ResponsiveContainer>
            <FlatList
                style={{ height: 1 }}
                data={perks}
                keyExtractor={(item, index) => index.toString()}
                renderItem={({ item }) =>
                    <FeatureListCard
                        title={item.title}
                        description={item.description}
                        icon={item.icon}
                        style={[{
                            height: 112,
                            overflow: 'hidden',
                            marginHorizontal: 8,
                            marginVertical: 4,
                        },
                            shadow
                        ]}
                    />
                }
            />
        </ResponsiveContainer >
    );
}