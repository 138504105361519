import React, { useEffect, useState } from 'react';
import { Platform } from 'react-native';

import axios from "axios";
import Constants from 'expo-constants';
import * as Linking from 'expo-linking';

import OrderView from '../../components/OrderView.Subcription';
import MemberView from '../../components/MemberView.Subscription';
import IndividualView from '../../components/IndividualView.Subscription';
import AdminView from '../../components/AdminView.Subcription';

import { queryAPI } from '../../services/api/data';
import { useUserDispatch, useUserState, getUser } from '../../services/context/userContext';

import { StackNavigationProp } from '@react-navigation/stack';
import { RouteProp } from '@react-navigation/native';
import { MainStackParamList } from '../../models/stackParams/MainStackParamListType';

import TeamType, { TeamUserType } from "../../models/data/TeamType";
import { User as UserType } from "../../models/data/UserType";
import { RCAPISubscriberType } from '../../models/data/PurchaseTypes';

type PremiumScreenNavigationProp = StackNavigationProp<
    MainStackParamList,
    'PremiumScreen'
>;

type PremiumScreenRouteProp = RouteProp<
    MainStackParamList,
    'PremiumScreen'
>;

type TeamUser = TeamUserType & { quizProgessSharing?: UserType['quizProgessSharing'] | undefined };
type Team = Omit<TeamType, 'users'> & { users?: undefined | TeamUser[] };

type Props = {
    navigation: PremiumScreenNavigationProp;
    route: PremiumScreenRouteProp;
};

export const PremiumScreen = ({ navigation, route }: Props) => {

    const { refresh } = route.params;

    const { data, isLoading: isLoadingUser } = useUserState();
    const userDispatch = useUserDispatch();

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [team, setTeam] = useState<Team | undefined>(undefined);
    const [mobileSub, setMobileSub] = useState<RCAPISubscriberType | undefined>(undefined);

    let user = data as UserType;

    useEffect(() => {
        const loadInitialData = async () => {
            setIsLoading(true);
            await getUser(userDispatch);
            console.log('refreshed User');
            if (user?.premium && (user.premium === 'ADMIN' || user.premium === `TEAM:${user.owner}`)) {
                await getTeam();
                console.log('refreshed Subscription Team');
            } else if (user?.premium && user.premium === 'INDIVIDUAL:WEB') {
                await getTeam();
                console.log('refreshed Subscription Individual Web');
            } else if (user?.premium && (user.premium === 'INDIVIDUAL:APPLE' || user.premium === 'INDIVIDUAL:ANDROID')) {
                const purchasedPlatform: 'ios' | 'android' = user.premium.split(':')[1] === 'APPLE' ? 'ios' : 'android';
                await getMobileSubcription(purchasedPlatform);
                console.log('refreshed Subscription Individual ' + purchasedPlatform);
            }
            setIsLoading(false);
        }

        if (refresh) {
            loadInitialData();
            navigation.setParams({ refresh: false });
            Platform.OS === 'web' && window.history.pushState(null, '', Linking.createURL('/'));
        }
    }, [refresh]);

    const getTeam = async () => {
        try {
            const data = await queryAPI('getTeam', {}, { refresh: true });
            const response = JSON.parse(data);
            if (response.statusCode === 200) {
                setTeam(response.body);
                return;
            }
            console.log(response.body);
        } catch (error) {
            console.log(error);
        }
    }

    const getMobileSubcription = async (purchasedPlatform: 'ios' | 'android') => {
        try {
            const options = {
                url: 'https://api.revenuecat.com/v1/subscribers/' + user.id,
                headers: {
                    Accept: 'application/json',
                    'X-Platform': purchasedPlatform,
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + purchasedPlatform === 'ios' ? Constants.manifest?.extra?.RC_PK_IOS as string : Constants.manifest?.extra?.RC_PK_ANDROID as string
                }
            };

            const response = await axios.request(options);
            const subscriber: RCAPISubscriberType = response.data.subscriber;

            setMobileSub(subscriber);

        } catch (error) {
            console.log(error);
        }
    }

    if (user?.premium && (user.premium === 'ADMIN' || user.premium === `TEAM:${user.owner}`)) {
        return (
            <AdminView
                team={team}
                getTeam={getTeam}
                isLoading={isLoadingUser || isLoading} />
        );
    } else if (user?.premium && user.premium.startsWith('TEAM:')) {
        return (
            <MemberView
                isLoading={isLoadingUser || isLoading}
            />
        );
    } else if (user?.premium && (user.premium === 'INDIVIDUAL:WEB' || user.premium === 'INDIVIDUAL:APPLE' || user.premium === 'INDIVIDUAL:ANDROID')) {
        return (
            <IndividualView
                webSubInfos={team}
                mobileSubInfos={mobileSub}
                isLoading={isLoadingUser || isLoading}
            />
        );
    } else {
        return (
            <OrderView
                isLoading={isLoadingUser || isLoading}
            />
        );
    }
}