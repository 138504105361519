import * as React from 'react';

import { queryAPI } from '../api/data';

import { reducer } from '../reducers/reducer';
import { ReducerActions, ReducerState } from '../../models/reducers/ReducerType';
import { errorDispatcher } from '../../utils/errorDispatcher';

type State = ReducerState;
type Dispatch = (action: ReducerActions) => void;
type StoryListProviderProps = { children: React.ReactNode };

const StoryListStateContext = React.createContext<State | undefined>(undefined);
const StoryListDispatchContext = React.createContext<Dispatch | undefined>(
    undefined,
);

const initialState = { isLoading: false, data: undefined };

function StoryListProvider({ children }: StoryListProviderProps) {
    const [state, dispatch] = React.useReducer(reducer, initialState);

    return (
        <StoryListStateContext.Provider value={state}>
            <StoryListDispatchContext.Provider value={dispatch}>
                {children}
            </StoryListDispatchContext.Provider>
        </StoryListStateContext.Provider>
    )
}

function useStoryListState() {
    const context = React.useContext(StoryListStateContext)
    if (context === undefined) {
        throw new Error('useStoryListState must be used within a StoryListProvider')
    }
    return context
}

function useStoryListDispatch() {
    const context = React.useContext(StoryListDispatchContext)
    if (context === undefined) {
        throw new Error('useStoryListDispatch must be used within a StoryListProvider')
    }
    return context
}

async function getLessonsForCategory(dispatch: Dispatch, categoryId: string, refresh?: boolean) {
    dispatch({ type: 'REQUEST' });
    try {
        const data: any = await queryAPI('getLessonsForCategory', { categoryId }, { refresh: refresh });
        //@ts-ignore
        data.sort((a, b) => a.position - b.position);
        dispatch({ type: 'SUCCESS', data: data })
    } catch (error) {
        //@ts-ignore
        errorDispatcher(error, dispatch);
    }
}

export {
    StoryListProvider,
    useStoryListState,
    useStoryListDispatch,
    getLessonsForCategory
};