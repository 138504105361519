import React from 'react';
import { View } from 'react-native';

import * as WebBrowser from 'expo-web-browser';

import { Icons } from './Icon';
import Text from './Text';
import Button from './Button';

import { translate } from '../services/localization/translations';

type SocialType = {
    icon: Icons,
    color: string,
    link: string
};

type Props = {
    social: SocialType[]
};

const SocialMediaFooter = ({
    social
}: Props) => {

    const handleOpenLink = (link: string) => {
        WebBrowser.openBrowserAsync(link);
    }

    return (
        <View
            style={{
                flexShrink: 1,
                padding: 8,
                flexDirection: 'column'
            }}>
            <View
                style={{
                    padding: 8
                }}
            >
                <Text
                    type='h5'
                    style='bold'
                    color='text'
                >
                    {`${translate('followUs')}:`}
                </Text>
            </View>
            <View style={{
                justifyContent: 'space-evenly',
                flexDirection: 'row',
                margin: 8,
                alignItems: 'center',
                flexShrink: 1
            }}>
                {social.map(s =>
                    <Button
                        key={s.color + s.icon}
                        style={{
                            height: 64,
                            width: 64,
                            borderRadius: 64 / 2
                        }}
                        icon={{
                            icon: s.icon,
                            color: 'white',
                            style: undefined
                        }}
                        type='solid'
                        hexColor={s.color}
                        onPress={() => handleOpenLink(s.link)}
                    />
                )}
            </View>
        </View>
    );
};

export default SocialMediaFooter;