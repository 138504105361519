import React, { useRef, useState, useEffect } from 'react';
import { View, Animated } from 'react-native';
import Icon, { Icons } from './Icon';

import { Colors } from '../utils/hexProvider';

type Props = {
    zIndex?: number;
    timeInMs?: number;
    confettiNumber?: number;
    icon: Icons;
}

const Confetti = ({
    zIndex = 1,
    timeInMs = 2000,
    confettiNumber = 25,
    icon
}: Props) => {
    const [parentWidth, setParentWidth] = useState<number>(0);
    const [parentHeight, setParentHeight] = useState<number>(0);

    const colors = ['#3880FF', '#FFCC00', '#FF9500', '#FF2D55', '#AF52DE'];

    return (
        <View
            style={{
                flex: 1,
                position: 'absolute',
                top: 0,
                bottom: 0,
                right: 0,
                left: 0,
                zIndex: zIndex,
            }}
            onLayout={e => {
                setParentHeight(e.nativeEvent.layout.height);
                setParentWidth(e.nativeEvent.layout.width);
            }}
        >
            {[...Array(confettiNumber)].map((e, i) =>
                <AnimatedConfetti
                    key={i}
                    delay={(2000 / 50) * i}
                    icon={icon}
                    color={colors[randomIntValue(1, colors.length) - 1]}
                    timeInMs={timeInMs}
                    parentHeight={parentHeight}
                    randomX={randomIntValue(0, parentWidth)} />
            )}
        </View>
    );
}

type AnimatedConfettiProps = {
    color: string;
    icon: Icons;
    timeInMs: number;
    delay: number;
    randomX: number;
    parentHeight: number;
}

const AnimatedConfetti = ({
    color,
    icon,
    timeInMs,
    delay,
    randomX,
    parentHeight
}: AnimatedConfettiProps) => {
    const animatedYValue = useRef(new Animated.Value(0)).current;
    const animatedOpaycityValue = useRef(new Animated.Value(0)).current;

    const yVal = animatedYValue.interpolate({
        inputRange: [0, 1],
        outputRange: [0, parentHeight]
    })

    const opacityVal = animatedOpaycityValue.interpolate({
        inputRange: [0, 0.1, 0.9, 1],
        outputRange: [0, 1, 1, 0]
    })

    Animated.sequence([
        Animated.delay(delay),
        Animated.parallel([
            Animated.timing(animatedYValue, {
                toValue: 1,
                duration: (timeInMs * .5),
                useNativeDriver: true
            }),
            Animated.timing(animatedOpaycityValue, {
                toValue: 1,
                duration: (timeInMs * .5),
                useNativeDriver: true
            })
        ]),

    ]).start()


    return (
        <Animated.View style={{
            position: 'absolute',
            marginTop: 0,
            marginLeft: randomX,
            opacity: opacityVal,
            transform: [
                {
                    translateY: yVal,
                }
            ]
        }}>
            <Icon icon={icon} hexColor={color} />
        </Animated.View>
    );
}

const randomIntValue = (min: number, max: number): number => {
    return Math.floor(Math.random() * (max - min) + min);
}

export default Confetti;