import React, { useState } from 'react';
import { Platform, View } from 'react-native';

import Purchases, { PurchasesPackage } from 'react-native-purchases';
import Constants from 'expo-constants';
import * as Linking from 'expo-linking';
import * as WebBrowser from 'expo-web-browser';

import { useNavigation } from '@react-navigation/native';

import { ResponsiveContainer } from './ResponsiveContainer';
import Button from './Button';
import ProductFeatureLinkCard from './ProductFeatureLinkCard';
import ProductSelector from './ProductSelector';
import ProductQuantityCard from './ProductQuantityCard';
import LegalFooter from './LegalFooter';
import Text from './Text';

import { translate } from '../services/localization/translations';
import createUrl from '../utils/createUrl';
import { queryAPI } from '../services/api/data';
import constants from '../utils/constants';

import { User } from '../models/data/UserType';
import Divider from './Divider';

type ProductType = {
    name: string;
    price: number;
    month: number;
    group: 'INDIVIDUAL' | 'TEAM';
    productIndentifier: string;
    UserPremium: User['premium'];
};

type Props = {
    isLoading: boolean;
};

const OrderView = ({
    isLoading,
}: Props) => {

    const navigation = useNavigation();

    const [isLoadingPurchase, setIsLoadingPurchase] = useState<boolean>(false);

    const [productCategoryFilter, setProductCategoryFilter] = useState<'INDIVIDUAL' | 'TEAM'>(Platform.OS === 'web' ? 'TEAM' : 'INDIVIDUAL');
    const [selectedProduct, setSelectedProduct] = useState<ProductType['productIndentifier'] | undefined>(undefined);
    const [quantity, setQuantity] = useState<number>(1);
    const [buyAsCompany, setBuyAsCompany] = useState<boolean>(false);

    const handleSetProductFilter = (filter: 'TEAM' | 'INDIVIDUAL') => {
        setProductCategoryFilter(filter);
        setSelectedProduct(undefined);
        setQuantity(1);
        setBuyAsCompany(false);
    }

    const products: ProductType[] = [
        {
            name: translate('OneMonth'),
            price: 6.99,
            month: 1,
            group: 'INDIVIDUAL',
            productIndentifier: Constants.manifest?.extra?.RC_PRICING_INDIVIDUAL_OFFERING_IDENTIFIER_MONTHLY,
            UserPremium: Platform.select({ ios: 'INDIVIDUAL:APPLE', android: 'INDIVIDUAL:ANDROID' })
        },
        {
            name: translate('TwelveMonths'),
            price: 59.99,
            month: 12,
            group: 'INDIVIDUAL',
            productIndentifier: Constants.manifest?.extra?.RC_PRICING_INDIVIDUAL_OFFERING_IDENTIFIER_YEARLY,
            UserPremium: Platform.select({ ios: 'INDIVIDUAL:APPLE', android: 'INDIVIDUAL:ANDROID' })
        },
        {
            name: translate('TwelveMonths'),
            price: 59.99,
            month: 12,
            group: 'TEAM',
            productIndentifier: Constants.manifest?.extra?.STRIPE_PRICING_TEAM_YEAR,
            UserPremium: 'ADMIN'
        },
    ];

    const handlePurchase = async () => {
        setIsLoadingPurchase(true);
        let selectedForPuchase = products.filter(e => e.productIndentifier === selectedProduct)[0];
        try {
            if (Platform.OS === 'web') {
                const success_url = createUrl('SuccessScreen', { premium: selectedForPuchase.UserPremium });
                const cancel_url = createUrl('PremiumScreen', { refresh: false });

                const data = await queryAPI('createCheckoutSession', {
                    stripePK: Constants.manifest?.extra?.STRIPE_PK,
                    price: selectedForPuchase.productIndentifier,
                    quantity: quantity,
                    success_url: success_url,
                    cancel_url: cancel_url
                }, { refresh: true });
                const response = JSON.parse(data);

                if (response.statusCode === 200) {
                    return Linking.openURL(response.body);
                }
                setIsLoadingPurchase(false);

            } else if (Platform.OS === "ios" || Platform.OS === "android") {

                const offerings = await Purchases.getOfferings();
                const monthlypackage = offerings.current?.monthly as PurchasesPackage;
                const yearlypackage = offerings.current?.annual as PurchasesPackage;

                const { purchaserInfo } = await Purchases.purchasePackage(selectedProduct === 'individual_monthly' ? monthlypackage : yearlypackage);
                if (typeof purchaserInfo.entitlements.active.individual !== "undefined") {
                    //@ts-ignore
                    navigation.navigate('SuccessScreen', { premium: selectedForPuchase.UserPremium });
                }
            }
            setIsLoadingPurchase(false);

        } catch (error) {
            console.log(error);
            setIsLoadingPurchase(false);
        }
    }

    const handleRestoreSubcription = async () => {
        setIsLoadingPurchase(true);
        try {
            const restore = await Purchases.restoreTransactions();
            if (typeof restore.entitlements.active.individual !== "undefined") {
                //@ts-ignore
                navigation.navigate('SuccessScreen', { premium: products[0].UserPremium });
            }

            setIsLoadingPurchase(false);
        } catch (error) {
            console.log(error);
            setIsLoadingPurchase(false);
        }
    }

    return (
        <ResponsiveContainer
            isLoading={isLoading || isLoadingPurchase}
            scrollable
        >
            <ProductFeatureLinkCard />
            <ProductSelector
                productCategoryFilter={productCategoryFilter}
                products={products}
                selectedProduct={selectedProduct}
                setSelectedProduct={setSelectedProduct}
                handleSetProductFilter={handleSetProductFilter}
            />
            {!(Platform.OS == 'web' && productCategoryFilter === 'INDIVIDUAL') &&
                <>
                    {productCategoryFilter === 'TEAM' &&
                        <ProductQuantityCard
                            quantity={quantity}
                            setQuantity={setQuantity}
                            productCategory={productCategoryFilter}
                        />}
                    {productCategoryFilter === 'TEAM' &&
                        <Button
                            title={translate('buyAsCompany')}
                            bold
                            type='clear'
                            color='text'
                            textColor='text'
                            style={{
                                height: 56,
                                borderRadius: 56 / 4,
                                margin: 4,
                                paddingRight: 8
                            }}
                            icon={buyAsCompany ?
                                {
                                    icon: 'check-square',
                                    color: 'text',
                                    style: { padding: 8 }
                                } :
                                {
                                    icon: 'square-o',
                                    color: 'text',
                                    style: { padding: 8 }
                                }
                            }
                            onPress={() => setBuyAsCompany(buyAsCompany === true ? false : true)}
                        />
                    }
                    <Button
                        title={translate('orderSelection')}
                        bold
                        type='solid'
                        color={productCategoryFilter === 'TEAM' ? undefined : 'primary'}
                        hexColor={productCategoryFilter === 'TEAM' ? '#FF2D55' : undefined}
                        textColor='white'
                        style={{
                            height: 56,
                            borderRadius: 56 / 4,
                            margin: 4,
                        }}
                        icon={{
                            icon: 'shopping-cart',
                            color: 'white',
                            style: { padding: 8 }
                        }}
                        disabled={!selectedProduct || (productCategoryFilter === 'TEAM' && !buyAsCompany)}
                        //@ts-ignore
                        onPress={handlePurchase}
                    />
                </>
            }
            {!(Platform.OS == 'web' && productCategoryFilter === 'INDIVIDUAL') && <View
                style={{
                    paddingBottom: 8
                }}
            >
                <Text
                    color='text'
                    type='p'
                >
                    {translate('agbHint')}
                </Text>
            </View>}
            <View
                style={{ paddingVertical: 8 }}
            />
            {Platform.OS === 'android' || Platform.OS === 'ios' &&
                <Button
                    title={translate('restoreSubscription')}
                    textType='h5'
                    textAlign='center'
                    type='clear'
                    color='primary'
                    textColor='primary'
                    style={{
                        height: 48,
                    }}
                    //@ts-ignore
                    onPress={handleRestoreSubcription}
                />
            }
            <Divider />
            <Button
                title={translate('pricingSupport')}
                textType='p'
                textAlign='center'
                type='clear'
                color='text'
                textColor='text'
                style={{
                    height: 48,
                }}
                //@ts-ignore
                onPress={() => WebBrowser.openBrowserAsync(constants.PRICES)}
            />
            <LegalFooter />
            <View
                style={{ paddingVertical: 16 }}
            />
        </ResponsiveContainer>
    );
}

export default OrderView;