import React, { useEffect, useState } from 'react';
import { FlatList, View } from 'react-native';

import { ResponsiveContainer } from '../../components/ResponsiveContainer';
import LoadingIndicator from '../../components/LoadingIndicator';
import Button from '../../components/Button';
import LanguageCard from '../../components/LanguageCard';

import { supportedLanguages } from '../../services/localization/supportedLanguages';
import { translate } from '../../services/localization/translations';
import { languagesObjectToArray, StructuredLanguagesType } from '../../utils/helpers';

import { StackNavigationProp } from '@react-navigation/stack';
import { RouteProp } from '@react-navigation/native';

import { StoryStackParamList } from '../../models/stackParams/StoryStackParamListType';

type TranslationScreenNavigationProp = StackNavigationProp<
    StoryStackParamList,
    'TranslationScreen'
>;

type TranslationScreenRouteProp = RouteProp<
    StoryStackParamList,
    'TranslationScreen'
>;


type Props = {
    navigation: TranslationScreenNavigationProp;
    route: TranslationScreenRouteProp;
};

export const TranslationScreen = ({ navigation, route }: Props) => {

    const { selectedLanguageCode, defaultLanguageCode } = route.params;
    const [data, setData] = useState<StructuredLanguagesType | undefined>(undefined);

    useEffect(() => {
        setData(languagesObjectToArray(supportedLanguages));
    }, []);

    const handleSelectLanguage = (languageCode: string) => {
        navigation.navigate({
            name: 'StoryScreen',
            params: { language: languageCode },
            //@ts-ignore
            merge: true
        });
    };

    return (
        <ResponsiveContainer>
            {data ?
                <View style={{ flex: 1 }}>
                    <FlatList
                        style={{ height: 1 }}
                        data={data}
                        keyExtractor={item => item.languageCode}
                        initialNumToRender={data.length}
                        numColumns={2}
                        renderItem={({ item }) =>
                            item.language === '' ?
                                <View style={{
                                    flex: 1, height: 160, marginHorizontal: 4,
                                    marginVertical: 4,
                                    paddingVertical: 16,
                                }} />
                                :
                                <LanguageCard
                                    key={item.languageCode}
                                    languageCode={item.languageCode}
                                    language={item.language}
                                    selected={selectedLanguageCode === item.languageCode}
                                    onPress={() => handleSelectLanguage(item.languageCode)}
                                />
                        }
                    />
                </View>
                :
                <LoadingIndicator />
            }
            {data && selectedLanguageCode !== defaultLanguageCode &&
                <View style={{ position: "absolute", zIndex: 1, bottom: 16, width: '100%' }}>
                    <Button
                        title={translate('resetLanguage') + ' ' + data.find(x => x.languageCode === defaultLanguageCode)?.language}
                        bold
                        type='solid'
                        color='primary'
                        textColor='white'
                        icon={{
                            icon: 'repeat',
                            color: 'white',
                            style: { padding: 8 }
                        }}
                        style={{ height: 64, borderRadius: 64 / 2, margin: 8 }}
                        onPress={() => handleSelectLanguage(defaultLanguageCode)}
                    />
                </View>
            }
        </ResponsiveContainer >
    );
}