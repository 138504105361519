import React, { useState, useEffect } from 'react';
import { Image as I, Platform, TouchableOpacity, View, ViewStyle } from 'react-native';

import * as FileSystem from 'expo-file-system';

import LoadingIndicator from './LoadingIndicator';
import SourceProvider from './SourceProvider';

const shorthash = require('shorthash');

type ResizeMode =
    | 'cover'
    | 'contain'
    | 'stretch'
    | 'repeat'
    | 'center';

export type Props = {
    url: string,
    style?: ViewStyle,
    margin?: number;
    square?: boolean,
    borderRadius?: boolean,
    resizeMode?: ResizeMode,
    description?: string,
    onPress?: (() => void)
};

function Image({
    url,
    style = {
        flex: 1,
        overflow: 'hidden',
    },
    margin,
    square = true,
    borderRadius = true,
    resizeMode = 'cover',
    description,
    onPress
}: Props) {

    const [source, setSource] = useState<string | undefined>(undefined);

    const [squareSize, setSquareSize] = useState<number>(0);

    async function getImage(): Promise<void> {
        const name = shorthash.unique(url);
        const path = `${FileSystem.cacheDirectory}${name}`;
        const image = await FileSystem.getInfoAsync(path);
        if (image.exists) {
            setSource(image.uri);
            return;
        }
        const newImage = await FileSystem.downloadAsync(url, path);
        setSource(newImage.uri);
    }

    useEffect(() => {
        if (url !== undefined) {
            if (Platform.OS === 'web') {
                setSource(url);
            } else {
                getImage();
            }
        }
    }, [url]);

    return (
        <TouchableOpacity
            style={[style, { margin: margin }]}
            onPress={onPress}
            disabled={onPress === undefined ? true : false}
            onLayout={(e) => square ? setSquareSize(e.nativeEvent.layout.height) : null}>
            {source ?
                <I source={{ uri: source }}
                    style={[{ flex: 1 }, borderRadius ? { borderRadius: 8 / 1 } : null, square ? { width: squareSize, alignSelf: 'center' } : null]}
                    resizeMode={resizeMode} />
                :
                <View
                    style={{
                        flex: 1,
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    <LoadingIndicator
                        color='primary'
                    />
                </View>
            }
            <SourceProvider
                source={description}
                style={{
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    zIndex: 10,
                    padding: 8
                }}
            />
        </TouchableOpacity >
    );
}

export default Image;