import React, { useState } from 'react';
import { Platform } from 'react-native';

import Constants from 'expo-constants';
import * as WebBrowser from 'expo-web-browser';
import * as Linking from 'expo-linking';

import { useNavigation } from '@react-navigation/native';

import { ResponsiveContainer } from './ResponsiveContainer';
import TeamNameCard from './TeamNameCard.Subscription';
import ExpiredCard from './ExpiredCard.Subscription';
import ManageCard from './ManageCard.Subcription';
import TeamListCard from './TeamListCard.Subscription';

import constants from '../utils/constants';

import { queryAPI } from '../services/api/data';
import createUrl from '../utils/createUrl';

import TeamType, { TeamUserType } from "../models/data/TeamType";
import { User as UserType } from "../models/data/UserType";

type TeamUser = TeamUserType & { quizProgessSharing?: UserType['quizProgessSharing'] | undefined };
type Team = Omit<TeamType, 'users'> & { users?: undefined | TeamUser[] };

type Props = {
    team: Team | undefined;
    getTeam: () => Promise<void>;
    isLoading: boolean;
}

const AdminView = ({
    team,
    getTeam,
    isLoading
}: Props) => {

    const [isLoadingUrl, setIsLoadingUrl] = useState<boolean>(false);
    const navigation = useNavigation();

    const handleManageSubscription = async () => {
        setIsLoadingUrl(true);
        try {
            const redirecturl = createUrl('PremiumScreen', { refresh: true });
            const mobileRedirectUrl = Platform.OS === 'web' ? undefined : generateMobileRedirectUrl(redirecturl);
            const data = await queryAPI('createCustomerportalSession', { stripePK: Constants.manifest?.extra?.STRIPE_PK, domain: Platform.OS === 'web' ? redirecturl : mobileRedirectUrl }, { refresh: true });
            const response = JSON.parse(data);
            if (response.statusCode === 200) {
                if (Platform.OS === 'web') {
                    Linking.openURL(response.body);
                } else {
                    await WebBrowser.openBrowserAsync(response.body);
                }
            }
        } catch (error) {
            console.log(error)
        }
        setIsLoadingUrl(false);
    }

    const handleAddTeamMember = async () => {
        //@ts-ignore
        navigation.navigate('AddMemberScreen', undefined)
    }

    const generateMobileRedirectUrl = (redirecturl: string): string => {
        const params = new URLSearchParams()
        params.append('directToUrl', redirecturl);
        return `${constants.MOBILEREDIRECTHOST}?${params.toString()}`;
    }

    return (
        <ResponsiveContainer
            isLoading={isLoading || isLoadingUrl}
            scrollable
        >
            <ExpiredCard
                expirationWeb={team?.current_period_end}
            />
            <TeamNameCard
                team={team}
                getTeam={getTeam}
            />
            <ManageCard
                team={team}
                teamMode={true}
                handleManageSubscription={handleManageSubscription}
                handleAddTeamMember={handleAddTeamMember}
            />
            <TeamListCard
                team={team}
            />
        </ResponsiveContainer>
    );
}

export default AdminView;