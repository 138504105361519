import React, { useEffect, useState } from 'react';
import { View, Platform } from 'react-native';

import * as StoreReview from 'expo-store-review';
import * as Linking from 'expo-linking';

import { ResponsiveContainer } from '../../components/ResponsiveContainer';
import Text from '../../components/Text';
import Confetti from '../../components/Confetti';
import Button from '../../components/Button';

import { translate } from '../../services/localization/translations';
import { premiumStatusChange, useUserDispatch, updateUser, currentProclaimedPremium } from '../../services/context/userContext';

import { StackNavigationProp } from '@react-navigation/stack';
import { RouteProp } from '@react-navigation/native';
import { MainStackParamList } from '../../models/stackParams/MainStackParamListType';

type SuccessScreenNavigationProp = StackNavigationProp<
    MainStackParamList,
    'SuccessScreen'
>;

type SuccessScreenRouteProp = RouteProp<
    MainStackParamList,
    'SuccessScreen'
>;

type Props = {
    navigation: SuccessScreenNavigationProp;
    route: SuccessScreenRouteProp;
};

export const SuccessScreen = ({ navigation, route }: Props) => {

    const { premium } = route.params;
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [delayedNotification, setDelayedNotification] = useState<boolean>(false);

    const userDispatch = useUserDispatch();

    useEffect(() => {
        setIsLoading(false);
        Platform.OS === 'web' && window.history.pushState(null, '', Linking.createURL('/'));
        if (Platform.OS === 'web') {
            checkStatus();
        }
        // requestAppReview();
    }, []);

    const checkStatus = async () => {
        setIsLoading(true);
        const premiumStatus = await currentProclaimedPremium();
        !(premiumStatus === 'ADMIN' || premiumStatus === 'INDIVIDUAL:WEB') && setDelayedNotification(true);
        setIsLoading(false);
    }

    const handleChangePremium = async () => {
        setIsLoading(true);
        try {
            if (Platform.OS === 'ios' || Platform.OS === 'android') {
                await updateUser(userDispatch, {
                    id: '',
                    premium: premium
                });
            }
            const change = await premiumStatusChange();
            if (change === false) {
                navigation.navigate('HomeScreen');
            }
            setIsLoading(false);
        } catch (error) {
            console.log(error);
            setIsLoading(false);
        }
    };

    // const requestAppReview = async () => {
    //     if (Platform.OS === 'ios' || Platform.OS ==='android')
    //         if (await StoreReview.hasAction()) {
    //             //Message: would be awesome to review. Ok?
    //             await StoreReview.requestReview()
    //             //Message: thanks for rating
    //         }
    // }

    return (
        <ResponsiveContainer
            isLoading={isLoading}
        >
            <View
                style={{
                    flex: 1,
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: 16
                }}
            >
                <Confetti
                    icon='star'
                />
                <Text
                    type={'h3'}
                    textAlign='center'
                    style='bold'
                    color='text'
                >
                    {translate('PurchaseSuccessTitle')}
                </Text>
                {delayedNotification &&
                    <>
                        <View style={{ paddingVertical: 4 }} />
                        <Text
                            type='h5'
                            textAlign='left'
                            style='bold'
                            color='text'
                        >
                            {translate('PurchaseSuccessDelayed')}
                        </Text>
                    </>
                }
            </View>
            <View
                style={{
                    flexShrink: 1,
                    paddingVertical: 32
                }}
            >
                <Button
                    title={translate('continue')}
                    bold
                    type='solid'
                    color={'primary'}
                    textColor='white'
                    style={{
                        height: 56,
                        borderRadius: 56 / 4,
                        margin: 4,
                        paddingRight: 8
                    }}
                    onPress={handleChangePremium}
                />
            </View>
        </ResponsiveContainer>
    );
}