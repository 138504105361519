import { QuizProgress } from '../models/data/ProgressType';
import { ReducerActions } from '../models/reducers/ReducerType';

import { updateQuizProgress } from '../services/context/quizProgressContext';

var sh = require("shorthash");

export const objectExactlyInObject = (toCompareObject: object, originalObject: object): boolean => {
    const i: any = toCompareObject;
    const s: any = originalObject;

    let differs: boolean = false;

    Object.keys(i).map((key, index) => {
        originalObject !== undefined ? i[key] !== s[key] ? differs = true : null : null;
    })
    return !differs;
}

export type StructuredLanguagesType = { languageCode: string, language: string }[];

export const languagesObjectToArray = (languagesObject: { [language: string]: string }): StructuredLanguagesType => {
    let languagesArray: { languageCode: string, language: string }[] | undefined = [];

    for (const property in languagesObject) {
        languagesArray.push({ language: property.trim(), languageCode: languagesObject[property].trim() });
    }

    languagesArray.length % 2 !== 0 && languagesArray.push({ languageCode: '', language: '' });

    return languagesArray;
}

export const shuffleArray = <T>(array: Array<T>): Array<T> => {
    return array.sort(() => Math.random() - 0.5);
}


export const sameArrays = <T>(array1: Array<T>, array2: Array<T>): boolean => {
    if (array1.length === array2.length) {
        for (let i = 0; i < array1.length; i++) {
            if (array1[i] !== array2[i]) return false;
        }
    } else {
        return false;
    }
    return true;
}

export const sameStrings = (string1: string, string2: string): boolean => {
    return string1.trim().toLowerCase() === string2.trim().toLowerCase();
}

export const shorthash = (strings: string[]): string => {
    const stringsToHash: string = strings.join('');
    return sh.unique(stringsToHash);
}

export const handleAddStoryToProgess = async (storiesProgress: string[] | null, storyId: string): Promise<string[]> => {
    if (storiesProgress) {
        return storiesProgress.includes(storyId) ? storiesProgress : [...storiesProgress, storyId];
    } else {
        return [storyId];
    }
}

type Dispatch = (action: ReducerActions) => void;

export const handleAddQuizzeProgress = async (
    quizzeProgress: QuizProgress[] | null,
    rightQuizzesState: string[],
    wrongQuizzesState: string[],
    quizProgressDispatch: Dispatch
): Promise<QuizProgress[] | null> => {
    if (rightQuizzesState || wrongQuizzesState) {
        let resArr: QuizProgress[] | null = quizzeProgress ? [...quizzeProgress] : [];

        let quizzeDonePlus: number = 0;
        let quizzeFailedPlus: number = 0;
        let quizzeDoneMinus: number = 0;
        let quizzeFailedMinus: number = 0;

        rightQuizzesState.forEach(e => {

            let existingItem: QuizProgress | undefined = resArr ? resArr.filter(q => q.id === e)[0] : undefined;

            if (existingItem !== undefined && resArr) {
                const failed: number = existingItem.failed ? existingItem.failed : 0;
                const doneBefore: boolean = existingItem?.done ? existingItem.done : false;

                let resObj: QuizProgress = {
                    id: e,
                    done: true,
                    failed: failed
                }

                if (doneBefore === false) {
                    quizzeFailedMinus += 1;
                    quizzeDonePlus += 1;
                }

                let index = resArr.findIndex(el => el.id === e);

                if (index >= 0) {
                    resArr[index] = resObj;
                }
            } else {
                let resObj: QuizProgress = {
                    id: e,
                    done: true,
                    failed: 0
                }

                quizzeDonePlus += 1;

                resArr?.push(resObj);
            }
        });

        wrongQuizzesState.forEach(e => {

            let existingItem: QuizProgress | undefined = resArr ? resArr.filter(q => q.id === e)[0] : undefined;

            if (existingItem !== undefined && resArr) {
                const failed: number = existingItem.failed ? existingItem.failed : 0;
                const doneBefore: boolean = existingItem?.done ? existingItem.done : false;

                let resObj: QuizProgress = {
                    id: e,
                    done: false,
                    failed: failed + 1
                }

                if (doneBefore === true) {
                    quizzeDoneMinus += 1;
                    quizzeFailedPlus += 1;
                }

                let index = resArr.findIndex(el => el.id === e);

                if (index >= 0) {
                    resArr[index] = resObj;
                }
            } else {
                let resObj: QuizProgress = {
                    id: e,
                    done: false,
                    failed: 1
                }

                quizzeFailedPlus += 1;

                resArr?.push(resObj);
            }
        });

        await updateQuizProgress(
            quizProgressDispatch,
            quizzeDonePlus === 0 ? undefined : quizzeDonePlus,
            quizzeFailedPlus === 0 ? undefined : quizzeFailedPlus,
            quizzeDoneMinus === 0 ? undefined : quizzeDoneMinus,
            quizzeFailedMinus === 0 ? undefined : quizzeFailedMinus
        );

        return resArr ? resArr : null;
    } else {
        return quizzeProgress;
    }
};