import React, { useState, useRef, useEffect } from 'react';
import { View, Animated } from 'react-native';

import Text, { Props as TextProps } from './Text';

type Props = {
    textProps: TextProps;
    width?: number;
    includeScaleAnimation?: boolean;
}

const TextAnimator = ({
    textProps,
    width,
    includeScaleAnimation = false
}: Props) => {
    const [firstRenderDone, setFirstRenderDone] = useState<boolean>(false);

    const animatedYValue = useRef(new Animated.Value(1)).current;
    const animatedOpacity = useRef(new Animated.Value(1)).current;

    const [previousValue, setPreviousValue] = useState<React.ReactNode>(textProps.children);

    const yVal = animatedYValue.interpolate({
        inputRange: [0, 1],
        outputRange: [8, 0]
    })

    useEffect(() => {
        if (firstRenderDone) {
            animation();
        } else {
            setFirstRenderDone(true);
        }
    }, [textProps.children])

    const animation = () => {
        Animated.parallel([
            Animated.timing(animatedOpacity, {
                toValue: 0,
                duration: 200,
                useNativeDriver: true
            }),
            Animated.timing(animatedYValue, {
                toValue: 0,
                duration: 200,
                useNativeDriver: true
            }),
        ]).start(() => {
            setPreviousValue(textProps.children);
            Animated.parallel([
                Animated.timing(animatedOpacity, {
                    toValue: 1,
                    duration: 200,
                    useNativeDriver: true
                }),
                Animated.timing(animatedYValue, {
                    toValue: 1,
                    duration: 200,
                    useNativeDriver: true
                })
            ]).start();
        });
    }

    return (
        <View style={{ flexShrink: 1, justifyContent: 'center', alignItems: 'center', width: width }}>
            <Animated.View
                style={{
                    flexShrink: 1,
                    transform: includeScaleAnimation ?
                        [{
                            scale: animatedOpacity.interpolate({
                                inputRange: [0, 1],
                                outputRange: [2, 1]
                            })
                        }] : undefined
                }}
            >
                <Animated.View
                    style={{
                        flexShrink: 1,
                        justifyContent: 'center',
                        alignItems: 'center',
                        opacity: animatedOpacity,
                        transform: [{
                            translateY: yVal
                        }]
                    }}
                >
                    <Text {...textProps} children={previousValue}></Text>
                </Animated.View>
            </Animated.View>
        </View>
    );
}

export default TextAnimator;