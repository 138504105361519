import React, { useRef, useState } from 'react';
import { View, Animated } from 'react-native';

import QuestionMediaSwiper from './QuestionMediaSwiper';
import SingleChoiceQuizCard from './SingleChoiceQuizCard';
import SortQuizCard from './SortQuizCard';
import TextQuizCard from './TextQuizCard';

import { Props as AudioProps } from './Audio';
import { Props as VideoProps } from './Video';
import { Props as ImageProps } from './Image';
import { TextDirection, TextType } from './Text';

export type ChoiceQuizType = {
    type: 'ChoiceQuiz',
    props: {
        answers: string[];
        correct: number
    };
}
export type SortQuizType = {
    type: 'SortQuiz',
    props: {
        answers: string[];
    }
}
export type TextQuizType = {
    type: 'TextQuiz',
    props: {
        answer: string;
    }
}

type AudioType = {
    type: 'Audio',
    props: AudioProps
};

type VideoType = {
    type: 'Video',
    props: VideoProps
};

type ImageType = {
    type: 'Image',
    props: ImageProps
};

type QuizProviderProps = {
    quizId: string;
    quizType: ChoiceQuizType | SortQuizType | TextQuizType;
    rightQuizzesState: string[];
    wrongQuizzesState: string[];
    setRightQuizzesState: React.Dispatch<React.SetStateAction<string[]>>;
    setWrongQuizzesState: React.Dispatch<React.SetStateAction<string[]>>;
}

type Props = {
    quizId: string;
    question: string;
    description?: string;
    quizType: ChoiceQuizType | SortQuizType | TextQuizType;
    media?: AudioType | VideoType | ImageType;
    textType: TextType;
    textAlign: Exclude<TextDirection, 'auto'>
    rightQuizzesState: string[];
    wrongQuizzesState: string[];
    setRightQuizzesState: React.Dispatch<React.SetStateAction<string[]>>;
    setWrongQuizzesState: React.Dispatch<React.SetStateAction<string[]>>;
};

const QuizProvider = ({
    quizId,
    quizType,
    rightQuizzesState,
    wrongQuizzesState,
    setRightQuizzesState,
    setWrongQuizzesState
}: QuizProviderProps) => {
    switch (quizType.type) {
        case 'ChoiceQuiz':
            return (
                <SingleChoiceQuizCard
                    quizId={quizId}
                    answers={quizType.props.answers}
                    correct={quizType.props.correct}
                    rightQuizzesState={rightQuizzesState}
                    wrongQuizzesState={wrongQuizzesState}
                    setRightQuizzesState={setRightQuizzesState}
                    setWrongQuizzesState={setWrongQuizzesState}
                />
            );
        case 'SortQuiz':
            return (
                <SortQuizCard
                    quizId={quizId}
                    answers={quizType.props.answers}
                    rightQuizzesState={rightQuizzesState}
                    wrongQuizzesState={wrongQuizzesState}
                    setRightQuizzesState={setRightQuizzesState}
                    setWrongQuizzesState={setWrongQuizzesState}
                />
            );
        case 'TextQuiz':
            return (
                <TextQuizCard
                    quizId={quizId}
                    answer={quizType.props.answer}
                    rightQuizzesState={rightQuizzesState}
                    wrongQuizzesState={wrongQuizzesState}
                    setRightQuizzesState={setRightQuizzesState}
                    setWrongQuizzesState={setWrongQuizzesState}
                />
            );
    }
}

const QuizHandler = ({
    quizId,
    question,
    description,
    quizType,
    media,
    textType,
    textAlign,
    rightQuizzesState,
    wrongQuizzesState,
    setRightQuizzesState,
    setWrongQuizzesState
}: Props) => {
    const [viewHeight, setViewHeight] = useState<number>(0);
    const [showQuizState, setShowQuizState] = useState<boolean>(false);

    const animatedContainerValue = useRef(new Animated.Value(0)).current;

    if (showQuizState) {
        Animated.timing(animatedContainerValue, {
            toValue: 1,
            duration: 500,
            useNativeDriver: true
        }).start()
    }

    return (
        <View
            style={{
                flex: 1,
                padding: 4,
            }}
            onLayout={e => setViewHeight(e.nativeEvent.layout.height)}
        >
            <Animated.View
                style={{
                    flex: 1,
                    paddingBottom: 4,
                    zIndex: 2,
                    transform: [{
                        translateY: animatedContainerValue.interpolate({
                            inputRange: [0, 1],
                            outputRange: [viewHeight / 4, 0]
                        })
                    }]
                }}
            >
                <QuestionMediaSwiper
                    quizId={quizId}
                    question={{
                        question: question,
                        description: description
                    }}
                    media={media}
                    textType={textType}
                    textAlign={textAlign}
                    showQuizState={showQuizState}
                    setShowQuizState={setShowQuizState}
                    rightQuizzesState={rightQuizzesState}
                    wrongQuizzesState={wrongQuizzesState}
                />
            </Animated.View>
            <Animated.View
                style={{
                    flex: 1,
                    paddingTop: 4,
                    zIndex: 1,
                    transform: [{
                        translateY: animatedContainerValue.interpolate({
                            inputRange: [0, 1],
                            outputRange: [viewHeight, 0]
                        })
                    }]
                }}
            >
                <QuizProvider
                    quizId={quizId}
                    quizType={quizType}
                    rightQuizzesState={rightQuizzesState}
                    wrongQuizzesState={wrongQuizzesState}
                    setRightQuizzesState={setRightQuizzesState}
                    setWrongQuizzesState={setWrongQuizzesState}

                />
            </Animated.View>
        </View>
    );
}

export default QuizHandler;