import React, { useState, useEffect, useRef } from 'react';

import { Animated, useWindowDimensions, TouchableOpacity, Platform, Linking } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import Container from './Container';
import ButtonCard from './ButtonCard';
import Button from './Button';
import { TextType, TextDirection } from './Text';

import { hexProvider, shadowProvider } from '../utils/hexProvider'
import { translate } from '../services/localization/translations';


type Props = {
    isVisible: boolean;
    textAlignState: Exclude<TextDirection, 'auto'>;
    textTypeState: TextType;
    setTextAlignState: React.Dispatch<React.SetStateAction<Exclude<TextDirection, 'auto'>>>
    setTextTypeState: React.Dispatch<React.SetStateAction<TextType>>;
    setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
    onPressTranslation?: (() => void);
    onPressClose: (() => void);
};

const StoryOptionsCard = ({
    isVisible = false,
    textAlignState,
    textTypeState,
    setTextAlignState,
    setTextTypeState,
    setIsVisible,
    onPressTranslation,
    onPressClose
}: Props) => {
    let insets = useSafeAreaInsets();

    const [initialRender, setInitialRender] = useState<boolean>(false);

    const window = useWindowDimensions();
    const y = useRef<Animated.Value>(new Animated.Value(isVisible ? 0 : window.height)).current;

    useEffect(() => {
        if (initialRender) {
            Animated.spring(y, {
                toValue: !isVisible ? window.height : 0,
                useNativeDriver: true,
                bounciness: 0
            }).start();
        } else {
            setInitialRender(true);
        }
    }, [isVisible])

    const tType = (): number => {
        switch (textTypeState) {
            case 'h1':
                return 40;
            case 'h2':
                return 34;
            case 'h3':
                return 28;
            case 'h4':
                return 22;
            case 'h5':
                return 16;
            default:
                return 11;
        }
    };

    const handleSmallerTextType = (): void => {
        switch (textTypeState) {
            case 'h1':
                setTextTypeState('h2')
                break;
            case 'h2':
                setTextTypeState('h3')
                break;
            case 'h3':
                setTextTypeState('h4')
                break;
            case 'h4':
                setTextTypeState('h5')
                break;
            default:
                setTextTypeState('p')
                break;
        }
    };

    const handleLargerTextType = (): void => {
        switch (textTypeState) {
            case 'h2':
                setTextTypeState('h1')
                break;
            case 'h3':
                setTextTypeState('h2')
                break;
            case 'h4':
                setTextTypeState('h3')
                break;
            case 'h5':
                setTextTypeState('h4')
                break;
            default:
                setTextTypeState('h5')
                break;
        }
    };

    const toggleTextAlign = (): void => {
        switch (textAlignState) {
            case 'left':
                setTextAlignState('center');
                break;
            case 'center':
                setTextAlignState('right');
                break;
            default:
                setTextAlignState('left');
                break;
        }
    }

    const shadow = shadowProvider();

    return (
        <>
            {isVisible &&
                <TouchableOpacity
                    onPress={() => setIsVisible(false)}
                    style={{
                        flex: 1,
                        position: 'absolute',
                        top: 0,
                        bottom: 0,
                        right: 0,
                        left: 0,
                        zIndex: 0,
                    }}
                />
            }
            <Animated.View
                style={[{
                    flexShrink: 1,
                    alignSelf: 'center',
                    position: 'absolute',
                    bottom: 0,
                    right: 0,
                    left: 0,
                    zIndex: 1,
                    padding: 8,
                    paddingBottom: Platform.OS === 'web' ? undefined : insets.bottom,
                    backgroundColor: hexProvider('grey'),
                    overflow: 'hidden',
                    borderRadius: 8 / 1,
                    transform: [
                        {
                            translateY: y
                        }
                    ]
                }, shadow
                ]}>
                <Button
                    title={translate('reportProblem') + '?'}
                    textType='h5'
                    bold
                    type='clear'
                    textColor='text'
                    textAlign='center'
                    onPress={() => Linking.openURL('mailto:support@u1dent.ap')}
                />
                <Container style={{ flex: 1, flexDirection: 'row' }} >
                    <ButtonCard
                        textNormal={`${translate('fontSize')}:`}
                        textBold={`${tType()} pt`}
                        textBoldAnimated={true}
                        button1={{
                            icon: {
                                icon: 'minus',
                                color: 'white',
                                style: undefined
                            },
                            color: 'primary',
                            onPress: () => handleSmallerTextType(),
                            disabled: textTypeState === 'p'
                        }}
                        button2={{
                            icon: {
                                icon: 'plus',
                                color: 'white',
                                style: undefined
                            },
                            color: 'primary',
                            onPress: () => handleLargerTextType(),
                            disabled: textTypeState === 'h1'
                        }}
                    />
                    <ButtonCard
                        // textAlign
                        // textAlignCenter
                        // textAlignLeft
                        // textAlignRight
                        textNormal={`${translate('textAlign')}:`}
                        textBold={translate(
                            textAlignState == 'left' ?
                                'textAlignLeft' :
                                textAlignState === 'center' ?
                                    'textAlignCenter' :
                                    'textAlignRight'
                        )}
                        button1={{
                            icon: {
                                icon:
                                    textAlignState == 'left' ?
                                        'align-left' :
                                        textAlignState === 'center' ?
                                            'align-center' :
                                            'align-right',
                                color: 'white',
                                style: undefined
                            },
                            color: 'primary',
                            onPress: toggleTextAlign,
                            disabled: false
                        }}
                        backgroundOnPress={toggleTextAlign}
                    />
                </Container>
                <Container style={{ flex: 1, flexDirection: 'row' }}>
                    <ButtonCard
                        textNormal={translate('story')}
                        textBold={translate('translate')}
                        button1={{
                            icon: {
                                icon: 'language',
                                color: 'white',
                                style: undefined
                            },
                            color: 'primary',
                            onPress: onPressTranslation,
                            disabled: false
                        }}
                        backgroundOnPress={onPressTranslation}
                    />
                    <ButtonCard
                        textNormal={translate('story')}
                        textBold={translate('exit')}
                        button1={{
                            icon: {
                                icon: 'times',
                                color: 'white',
                                style: undefined
                            },
                            color: 'error',
                            onPress: onPressClose,
                            disabled: false
                        }}
                        backgroundOnPress={onPressClose}
                    />
                </Container>
            </Animated.View>
        </>
    );
}

export default StoryOptionsCard;