import 'react-native-gesture-handler';
import React, { useEffect } from 'react';

import NetInfo from "@react-native-community/netinfo";
import * as Device from 'expo-device';
import Constants from 'expo-constants';
import Purchases from 'react-native-purchases';
import { GestureHandlerRootView } from 'react-native-gesture-handler';

import { AuthProvider } from './src/services/context/authContext';
import { UserProvider } from './src/services/context/userContext';
import { ProgressProvider } from './src/services/context/progressContext';
import { QuizProgressProvider } from './src/services/context/quizProgressContext';
import { CategoryListProvider } from './src/services/context/categoryListContext';
import { StoryListProvider } from './src/services/context/storyListContext';
import { StoryProvider } from './src/services/context/storyContext';
import { TranslationProvider } from './src/services/context/translationContext';
import { SafeAreaProvider } from 'react-native-safe-area-context';

import { ThemeProvider } from 'react-native-elements';

import { Platform, useColorScheme } from 'react-native';
import { theme } from './src/styling/theme';

import { NavigationContainer, DefaultTheme, DarkTheme } from '@react-navigation/native';
import { RootStackNavigator } from './src/navigation/navigator/RootStackNavigator';

import { Amplify } from 'aws-amplify';
import awsconfig from './aws-exports.js';

import { syncMutations } from './src/services/store/offlineMutations';
import CognitoStorage from './src/services/store/CognitoStorage';

Amplify.configure({
  ...awsconfig,
  authenticationFlowType: 'CUSTOM_AUTH',
  storage: CognitoStorage,
  oauth: {
    ...awsconfig.oauth,
    domain: 'auth.u1dent.app',
  },
});

export default function App() {

  useEffect(() => {
    //listening for saved mutations to sync when internet down
    const unsubscribe = NetInfo.addEventListener(state => {
      if (state.isConnected === true) {
        syncMutations();
      }
    });

    //configure RevenueCat IAP tool
    configureRCIAP();

    return () => {
      unsubscribe();
    }
  }, [])

  const configureRCIAP = async () => {
    try {
      if (Platform.OS === 'ios' && Device.isDevice) {
        Purchases.setup(Constants.manifest?.extra?.RC_PK_IOS);
      } else if (Platform.OS === 'android' && Device.isDevice) {
        Purchases.setup(Constants.manifest?.extra?.RC_PK_ANDROID);
      }
    } catch (error) {
      console.log(error);
    }
  }

  let colorScheme: 'light' | 'dark' | null | undefined = useColorScheme();

  return (
    <GestureHandlerRootView style={{ flex: 1 }}>
      {/* @ts-ignore */}
      <ThemeProvider useDark={colorScheme === 'dark'} theme={theme[colorScheme === 'dark' ? 'dark' : 'light']}>
        <AuthProvider>
          <UserProvider>
            <ProgressProvider>
              <QuizProgressProvider>
                <CategoryListProvider>
                  <StoryListProvider>
                    <StoryProvider>
                      <TranslationProvider>
                        <SafeAreaProvider>
                          <NavigationContainer theme={colorScheme === 'dark' ? DarkTheme : DefaultTheme}>
                            <RootStackNavigator />
                          </NavigationContainer>
                        </SafeAreaProvider>
                      </TranslationProvider>
                    </StoryProvider>
                  </StoryListProvider>
                </CategoryListProvider>
              </QuizProgressProvider>
            </ProgressProvider>
          </UserProvider>
        </AuthProvider>
      </ ThemeProvider>
    </GestureHandlerRootView>
  );
}
