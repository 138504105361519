import React from 'react';
import { View, ViewStyle } from 'react-native';

type Props = {
    style?: ViewStyle;
    children?: React.ReactNode;
};

const Container = ({ style = { flex: 1, flexDirection: 'column', justifyContent: 'space-evenly', alignItems: 'stretch' }, children }: Props) => {
    return (
        <View style={style}>
            {children}
        </View>
    );
};

export default Container;