import React, { useState } from 'react';
import { StyleSheet, View } from 'react-native';

import { StackNavigationProp } from '@react-navigation/stack';
import { RouteProp } from '@react-navigation/native';

import { MainStackParamList } from '../../models/stackParams/MainStackParamListType';

import { ResponsiveContainer } from '../../components/ResponsiveContainer';
import DeleteCard from '../../components/DeleteCard';
import LogoutCard from '../../components/LogoutCard';
import EmailCard from '../../components/EmailCard';
import Button from '../../components/Button';

import { signOutUser } from '../../services/api/auth';
import { queryAPI } from '../../services/api/data';
import { hexProvider } from '../../utils/hexProvider';
import { translate } from '../../services/localization/translations';
import { useAuthState } from '../../services/context/authContext';
import resetCache from '../../utils/resetCache';

type CredentialsScreenNavigationProp = StackNavigationProp<
    MainStackParamList,
    'CredentialsScreen'
>;

type CredentialsScreenRouteProp = RouteProp<
    MainStackParamList,
    'CredentialsScreen'
>;


type Props = {
    navigation: CredentialsScreenNavigationProp;
    route: CredentialsScreenRouteProp;
};

export const CredentialsScreen = ({ navigation, route }: Props) => {

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { data: auth, error, isLoading: isLoadingAuth } = useAuthState();

    async function handleSignOut(global: boolean = false): Promise<void> {
        try {
            setIsLoading(true);
            await signOutUser(global);
        } catch (error) {
            console.log(error);
        }
    }

    async function handleDeleteUser(): Promise<void> {
        try {
            setIsLoading(true);
            await queryAPI('deleteUser', {}, { refresh: true });
            await signOutUser(true);
        } catch (error) {
            console.log(error);
        }
    }

    const styles = StyleSheet.create({
        container: {
            margin: 4,
            padding: 8,
            borderRadius: 8 / 1,
            backgroundColor: hexProvider('grey'),
            justifyContent: 'space-around',
        }
    });

    return (
        <ResponsiveContainer
            scrollable={true}
            isLoading={isLoading || isLoadingAuth}
        >
            <EmailCard
                auth={auth}
            />
            <LogoutCard
                signOutOnPress={() => handleSignOut(false)}
                signOutGlobalOnPress={() => handleSignOut(true)}
            />
            <DeleteCard
                onPress={() => handleDeleteUser()} />
            <View
                style={styles.container}
            >
                <Button
                    title={translate('resetCache')}
                    bold
                    type='clear'
                    color='text'
                    textColor='text'
                    style={{
                        height: 56,
                        borderRadius: 56 / 4,
                        margin: 4,
                        paddingRight: 8
                    }}
                    onPress={resetCache}
                />
            </View>
        </ResponsiveContainer >
    );
}