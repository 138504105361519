import React from "react";
import { StyleSheet, View } from "react-native";

import { ResponsiveContainer } from "./ResponsiveContainer";
import Text from "./Text";
import Button from "./Button";
import UniversalAlert from './UniversalAlert';

import { hexProvider } from "../utils/hexProvider";
import { translate } from "../services/localization/translations";

import { useUserDispatch, useUserState, updateUser } from '../services/context/userContext';

import { User as UserType } from '../models/data/UserType';

type Props = {
    isLoading: boolean;
};

const MemberView = ({
    isLoading
}: Props) => {

    const { data } = useUserState();
    const userDispatch = useUserDispatch();

    let user = data as UserType;

    const styles = StyleSheet.create({
        container: {
            margin: 4,
            padding: 16,
            borderRadius: 8 / 1,
            backgroundColor: hexProvider('grey'),
            justifyContent: 'space-around',
        }
    });

    const handleToggleShareProgress = async () => {
        if (user?.sharing !== false) {
            UniversalAlert(
                translate('ProgressSharing'),
                translate('ProgressSharingDisable'),
                [
                    {
                        text: translate('cancel'),
                        onPress: () => { return },
                        style: 'cancel'
                    },
                    {
                        text: translate('confirm'),
                        onPress: async () => await updateUser(userDispatch, {
                            id: '',
                            sharing: false
                        })
                    }
                ]
            )

        } else {
            await updateUser(userDispatch, {
                id: '',
                sharing: true
            })
        }
    }

    return (
        <ResponsiveContainer
            isLoading={isLoading}
            scrollable
        >
            <View
                style={styles.container}
            >
                <Text
                    type='h5'
                    style='bold'
                    color='text'
                >
                    {translate('memberStatusCardTitle')}
                </Text>
                <View style={{ marginVertical: 4 }} />
                <Text
                    type='h5'
                    style='normal'
                    color='text'
                    textAlign='left'
                >
                    {translate('memberStatusCardTextExplain')}
                </Text>
                <View style={{ marginVertical: 4 }} />
                <Text
                    type='h5'
                    style='normal'
                    color='text'
                    textAlign='left'
                >
                    {translate('memberStatusCardTextLeave')}
                </Text>
            </View>
            <View
                style={[styles.container]}
            >
                <Button
                    title={translate('ProgressSharing')}
                    bold
                    type='clear'
                    color='primary'
                    textColor='primary'
                    style={{
                        height: 56,
                        borderRadius: 56 / 4,
                        margin: 4,
                        paddingRight: 8
                    }}
                    icon={user?.sharing !== false ?
                        {
                            icon: 'check-square',
                            color: 'white',
                            style: { padding: 8 }
                        } :
                        {
                            icon: 'square-o',
                            color: 'white',
                            style: { padding: 8 }
                        }
                    }
                    onPress={handleToggleShareProgress}
                />
                <Text
                    type='h5'
                    style='normal'
                    color='text'
                    textAlign='center'
                >
                    {translate('ProgressSharingDescription')}
                </Text>
            </View>
        </ResponsiveContainer>
    );
}

export default MemberView;