import React, { useState } from 'react';
import { View } from 'react-native';

import { BlurView } from 'expo-blur';

import Text from './Text';
import Button from './Button';
import Icon from './Icon';
import Image from './Image';
import SourceProvider from './SourceProvider';

import { translate } from '../services/localization/translations';
import { hexProvider } from '../utils/hexProvider';

type Props = {
    storyName: string;
    storyMinutes: number;
    onPressStory: (() => void);
    onPressPodcast?: (() => void);
    imageBackgroundUrl: string | undefined;
};

const ResumeStoryCard = ({
    storyName,
    storyMinutes,
    onPressStory,
    onPressPodcast,
    imageBackgroundUrl
}: Props) => {
    const [buttonsContainerWidth, setButtonsContainerWidth] = useState<number | undefined>(undefined);

    return (
        <View
            style={{ flex: 1, overflow: 'hidden' }}
        >
            <BlurView
                intensity={40}
                tint='dark'
                style={{ flex: 1 }}>
                <View style={{ flex: 1, height: 1 / 2, flexDirection: 'column', alignItems: 'stretch', justifyContent: 'flex-end', padding: 8 }}>
                    <View style={{
                        flexShrink: 1,
                        borderRadius: 56 / 4,
                        padding: 8
                    }}>
                        <View style={{ flexShrink: 1, flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', paddingVertical: 4, paddingHorizontal: 8 }}>
                            <Icon
                                icon='clock-o'
                                color='white'
                                style={{
                                    paddingRight: 8
                                }}
                                iconStyle={{
                                    textShadowColor: hexProvider('black'),
                                    textShadowOffset: {
                                        width: 1,
                                        height: 1,
                                    },
                                    textShadowRadius: 1,
                                }}
                            />
                            <Text
                                type='h4'
                                style='bold'
                                color='white'
                                textAlign='left'
                                shadowColor='black'
                            >{`${storyMinutes} ${translate('minutes')}`}</Text>
                        </View>
                        <View style={{ flexShrink: 1, alignSelf: 'flex-start', paddingVertical: 4, paddingHorizontal: 8 }}>
                            <Text
                                type='h4'
                                style='bold'
                                color='white'
                                textAlign='left'
                                shadowColor='black'
                            >{storyName}</Text>
                        </View>
                    </View>
                </View>
                <View
                    style={{ flex: 1, height: 1 / 2, flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'space-evenly', padding: 8 }}
                    onLayout={(e) => setButtonsContainerWidth((e.nativeEvent.layout.width / 2) - 16)}>
                    <Button
                        title={translate('podcast')}
                        bold={true}
                        style={[
                            {
                                width: buttonsContainerWidth,
                                height: 56,
                                borderRadius: 56 / 2,
                            },
                        ]}
                        type='solid'
                        icon={{
                            icon: 'podcast',
                            color: 'white',
                            style: { paddingRight: 8 }
                        }}
                        color='primary'
                        textColor='white'
                        onPress={onPressPodcast}
                        disabled={onPressPodcast === undefined} />
                    <Button
                        title={translate('story')}
                        bold={true}
                        style={[
                            {
                                width: buttonsContainerWidth,
                                height: 56,
                                borderRadius: 56 / 2,
                            },
                        ]}
                        type='solid'
                        icon={{
                            icon: 'play-circle',
                            color: 'white',
                            style: { paddingRight: 8 }
                        }}
                        color='primary'
                        textColor='white'
                        onPress={onPressStory} />
                </View>
            </BlurView>
            <SourceProvider
                style={{
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    zIndex: 10,
                    padding: 8
                }}
            />
            <Image square={false} style={{ position: 'absolute', left: 0, right: 0, top: 0, bottom: 0, zIndex: -1 }} url={imageBackgroundUrl ? imageBackgroundUrl : ''} />
        </View>
    );
};

export default ResumeStoryCard;