import { Auth } from 'aws-amplify';
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth/lib/types";
import uuid from 'react-native-uuid';

const getUser: any = () => {
    return Auth.currentAuthenticatedUser()
        .then(userData => userData)
        .catch(err => err);
}

const getUserSub: any = () => {
    return Auth.currentAuthenticatedUser()
        .then(userData => userData.attributes.sub)
        .catch(err => err);
}

const signOutUser: any = (global: boolean = false) => {
    return Auth.signOut({ global: global })
        .then(res => res)
        .catch(err => err);
}

type ProviderType =
    | 'Google'
    | 'Facebook'
    | 'Apple';

const federateSignIn: any = (provider: ProviderType) => {
    return Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider[provider] })
        .then(res => res)
        .catch(err => err);
}

const signInUser = (email: string) => {
    let mail = email.toLowerCase().trim();
    return Auth.signIn(mail)
        .then(res => res)
        .catch(err => err);
}

const signUpUser = (email: string, name: string) => {
    let mail = email.toLowerCase().trim();
    return Auth.signUp({
        username: mail,
        password: uuid.v4().toString(),
        attributes: { name: name },
    })
        .then(res => res)
        .catch(err => err);
}

const confirmSignUpUser = (email: string, code: string) => {
    let mail = email.toLowerCase();
    return Auth.confirmSignUp(mail, code)
        .then(res => res)
        .catch(err => err);
}

const forgotPasswordUser = (email: string) => {
    let mail = email.toLowerCase();
    return Auth.forgotPassword(mail)
        .then(res => res)
        .catch(err => err);
}

const forgotPasswordUserSubmit = (email: string, code: string, newPassword: string) => {
    let mail = email.toLowerCase();
    return Auth.forgotPasswordSubmit(mail, code, newPassword)
        .then(res => res)
        .catch(err => err);
}

const resendSignUpCode = (email: string) => {
    let mail = email.toLowerCase();
    return Auth.resendSignUp(mail)
        .then(res => res)
        .catch(err => err);
}

const changeUserPassword = async (currentPW: string, newPW: string) => {
    try {
        const user = await Auth.currentAuthenticatedUser();

        return Auth.changePassword(user, currentPW, newPW);
    } catch (error) {
        return {
            //@ts-ignore
            name: error.name,
            //@ts-ignore
            message: error.message
        }
    }
}

export {
    getUser,
    getUserSub,
    signOutUser,
    signInUser,
    signUpUser,
    confirmSignUpUser,
    forgotPasswordUser,
    forgotPasswordUserSubmit,
    resendSignUpCode,
    changeUserPassword,
    federateSignIn,
    ProviderType
};