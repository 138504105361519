import React from 'react';
import { View } from 'react-native';

import { ResponsiveContainer } from '../../components/ResponsiveContainer';
import QuizButton from '../../components/QuizButton';
import CardsList from '../../components/CardsList';
import QuizzeScreenCard from '../../components/QuizzeScreenCard';

import { StackNavigationProp } from '@react-navigation/stack';
import { RouteProp } from '@react-navigation/native';
import { MainStackParamList } from '../../models/stackParams/MainStackParamListType';

import { useCategoryListState } from '../../services/context/categoryListContext';
import { useQuizProgressState } from '../../services/context/quizProgressContext';

type QuizzeScreenNavigationProp = StackNavigationProp<
    MainStackParamList,
    'QuizzeScreen'
>;

type QuizzeScreenRouteProp = RouteProp<
    MainStackParamList,
    'QuizzeScreen'
>;


type Props = {
    navigation: QuizzeScreenNavigationProp;
    route: QuizzeScreenRouteProp;
};

export const QuizzeScreen = ({ navigation, route }: Props) => {

    const { data: list, isLoading: isLoadingCategories, error: listError } = useCategoryListState();

    const { data: quizProgress, isLoading: isLoadingQuizProgress, error: quizProgressError } = useQuizProgressState();

    return (
        <ResponsiveContainer
            isLoading={isLoadingCategories || isLoadingQuizProgress}
        >
            <View
                style={{
                    flexShrink: 1,
                    flexDirection: 'row'
                }}>
                {quizProgress ?
                    <QuizButton
                        quizzeDone={quizProgress.quizzeDone}
                        quizzeFailed={quizProgress.quizzeFailed}
                        quizzeTotal={quizProgress.quizzeTotal}
                        parentFlexDirectionColumn={false}
                    />
                    :
                    <View
                        style={{
                            flex: 1
                        }} />
                }
                < QuizzeScreenCard />
            </View>
            <View
                style={{
                    flex: 2,
                    padding: 4
                }}>
                <CardsList
                    expanded={false}
                    data={list}
                    selectedItem={''}
                    setSelectedIndex={() => { }}
                    type='quiz'
                />
            </View>
        </ResponsiveContainer >
    );
}