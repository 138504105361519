import * as React from 'react';

import { Auth, Hub } from 'aws-amplify';
import CognitoStorage from '../store/CognitoStorage';

import {
    getUser,
    signInUser,
    signUpUser,
    confirmSignUpUser,
    forgotPasswordUser,
    forgotPasswordUserSubmit,
    resendSignUpCode,
    changeUserPassword,
    federateSignIn,
    ProviderType
} from '../api/auth';

import { storeData, getData } from '../store/store';

import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js'
import awsconfig from '../../../aws-exports';

import { reducer } from '../reducers/reducer';
import { ReducerActions, ReducerState } from '../../models/reducers/ReducerType';

import { errorDispatcher } from '../../utils/errorDispatcher';
import { Platform } from 'react-native';

type State = ReducerState;
type Dispatch = (action: ReducerActions) => void;
type AuthProviderProps = { children: React.ReactNode };

const AuthStateContext = React.createContext<State | undefined>(undefined);
const AuthDispatchContext = React.createContext<Dispatch | undefined>(
    undefined,
);

const initialState = { isLoading: false, data: undefined };

function AuthProvider({ children }: AuthProviderProps) {
    const [state, dispatch] = React.useReducer(reducer, initialState);

    return (
        <AuthStateContext.Provider value={state}>
            <AuthDispatchContext.Provider value={dispatch}>
                {children}
            </AuthDispatchContext.Provider>
        </AuthStateContext.Provider>
    )
}

function useAuthState() {
    const context = React.useContext(AuthStateContext)
    if (context === undefined) {
        throw new Error('useAuthState must be used within a AuthProvider')
    }
    return context
}

function useAuthDispatch() {
    const context = React.useContext(AuthDispatchContext)
    if (context === undefined) {
        throw new Error('useAuthDispatch must be used within a AuthProvider')
    }
    return context
}

type AuthProvider = ProviderType;

async function federatedSignIn(type: AuthProvider, authDispatch: Dispatch) {
    try {
        authDispatch({ type: 'REQUEST' });
        await federateSignIn(type);
    } catch (error) {
        //@ts-ignore
        errorDispatcher(error, authDispatch);
    }
}

async function signIn(email: string, authDispatch: Dispatch, setAuthMode: React.Dispatch<React.SetStateAction<"SIGNIN" | "CONFIRMSIGNIN">>) {
    try {
        authDispatch({ type: 'REQUEST' });

        const cognitoUser = await signInUser(email);

        if (cognitoUser && cognitoUser?.challengeParam?.USERNAME && cognitoUser?.Session) {
            await storeData(
                'cognitoUser',
                {
                    username: cognitoUser!.challengeParam!.USERNAME,
                    session: cognitoUser!.Session,
                }
            );
            setAuthMode('CONFIRMSIGNIN');
            authDispatch({ type: 'SUCCESS', data: cognitoUser });
        } else {
            errorDispatcher(cognitoUser, authDispatch);
        }
    } catch (error) {
        //@ts-ignore
        errorDispatcher(error, authDispatch);
    }
}

async function signUp(email: string, authDispatch: Dispatch, setAuthMode: React.Dispatch<React.SetStateAction<"SIGNIN" | "CONFIRMSIGNIN">>) {
    try {
        authDispatch({ type: 'REQUEST' });
        const res = await signUpUser(email, '');
        if (res?.codeDeliveryDetails) {
            authDispatch({ type: 'SUCCESS', data: undefined });
            setAuthMode('SIGNIN');
        } else {
            errorDispatcher(res, authDispatch);
        }
    } catch (error) {
        //@ts-ignore
        errorDispatcher(error, authDispatch);
    }
}

async function confirmSignUp(email: string, code: string, authDispatch: Dispatch) {
    try {
        authDispatch({ type: 'REQUEST' });
        const res = await confirmSignUpUser(email, code);
        if (res === 'SUCCESS') {
            authDispatch({ type: 'SUCCESS', data: 'CODECONFIRMED' });
        } else {
            errorDispatcher(res, authDispatch);
        }
    } catch (error) {
        //@ts-ignore
        errorDispatcher(error, authDispatch)
    }
}

async function forgotPassword(email: string, authDispatch: Dispatch) {
    try {
        authDispatch({ type: 'REQUEST' });
        const res = await forgotPasswordUser(email);
        if (res?.CodeDeliveryDetails) {
            authDispatch({ type: 'SUCCESS', data: res?.CodeDeliveryDetails })
        } else {
            errorDispatcher(res, authDispatch);
        }
    } catch (error) {
        //@ts-ignore
        errorDispatcher(error, authDispatch)
    }
}

async function forgotPasswordSubmit(email: string, code: string, newPassword: string, authDispatch: Dispatch) {
    try {
        authDispatch({ type: 'REQUEST' });
        const res = await forgotPasswordUserSubmit(email, code, newPassword);
        if (res === undefined) {
            authDispatch({ type: 'SUCCESS', data: 'CODECONFIRMED' })
        } else {
            errorDispatcher(res, authDispatch);
        }
    } catch (error) {
        //@ts-ignore
        errorDispatcher(error, authDispatch)
    }
}


async function resendCode(email: string, authDispatch: Dispatch) {
    try {
        authDispatch({ type: 'REQUEST' });
        const res = await resendSignUpCode(email);
        if (res?.CodeDeliveryDetails) {
            authDispatch({ type: 'SUCCESS', data: res?.CodeDeliveryDetails });
        } else {
            errorDispatcher(res, authDispatch);
        }
    } catch (error) {
        //@ts-ignore
        errorDispatcher(error, authDispatch)
    }
}

async function changePassword(authDispatch: Dispatch, currentPW: string, newPW: string) {
    try {
        authDispatch({ type: 'REQUEST' });

        await changeUserPassword(currentPW, newPW);
        const data: any = await getUser();

        authDispatch({ type: 'SUCCESS', data });
    } catch (error) {
        //@ts-ignore
        errorDispatcher(error, authDispatch);
    }
}

async function answerCustomChallenge(
    code: string,
    authDispatch: Dispatch
) {
    try {
        authDispatch({ type: 'REQUEST' });
        const {
            username,
            session
        } = await getData('cognitoUser');

        // rehydrate the CognitoUser
        const authenticationData = {
            Username: username,
        }
        const authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails(
            authenticationData
        )
        const poolData = {
            UserPoolId: awsconfig.aws_user_pools_id as string,
            ClientId: awsconfig.aws_user_pools_web_client_id as string,
        }
        const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData)
        const userData = {
            Username: username,
            Pool: userPool,
            Storage: CognitoStorage
        }
        //@ts-ignore
        const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData)
        // After this set the session to the previously stored user session
        //@ts-ignore
        cognitoUser.Session = session;
        // rehydrating the user and sending the auth challenge answer directly will not
        // trigger a new email
        cognitoUser.setAuthenticationFlowType('CUSTOM_AUTH');

        cognitoUser.sendCustomChallengeAnswer(code, {
            async onSuccess(success: any) {
                // console.log('sendCustomChallengeAnswer SUCCESS: ', success);
                // // If we get here, the answer was sent successfully,
                // but it might have been wrong (1st or 2nd time)
                // So we should test if the user is authenticated now

                // This will throw an error if the user is not yet authenticated:
                await Auth.currentAuthenticatedUser({ bypassCache: true });
                Hub.dispatch('auth', {
                    event: 'signInchallengeSuccess'
                });
            },
            onFailure(failure: any) {
                // console.log('sendCustomChallengeAnswer FAILURE: ', failure);
                // console.log('failure: ', failure)
                errorDispatcher(failure, authDispatch);
            },
        })
    } catch (error) {
        // console.log('error: ', error)
        //@ts-ignore
        errorDispatcher(error, authDispatch);
    }
};

export {
    AuthProvider,
    useAuthState,
    useAuthDispatch,
    federatedSignIn,
    signIn,
    signUp,
    confirmSignUp,
    forgotPassword,
    forgotPasswordSubmit,
    resendCode,
    changePassword,
    answerCustomChallenge
};