import React from 'react'
import { Platform, View } from 'react-native';

import { openBrowserAsync } from 'expo-web-browser';

import { useNavigation } from '@react-navigation/core';

import Button from './Button';

import { translate } from '../services/localization/translations';
import constants from '../utils/constants';

const LegalFooter = () => {

    const navigation = useNavigation();

    const handleOpenLink = async (url: string) => {
        await openBrowserAsync(url);
    }

    return (
        <View
            style={{
                paddingVertical: 8,
                flexShrink: 1,
                flexWrap: 'wrap',
                flexDirection: 'row',
                justifyContent: 'space-around'
            }}
        >
            <Button
                title={translate('privacy')}
                textType='p'
                color='text'
                type='clear'
                onPress={() => handleOpenLink(constants.PRIVACY)}
            />
            <Button
                title={translate('cookiePolicy')}
                textType='p'
                color='text'
                type='clear'
                onPress={() => handleOpenLink(constants.COOKIE)}
            />
            <Button
                title={'AGB'}
                textType='p'
                color='text'
                type='clear'
                onPress={() => handleOpenLink(constants.AGB)}
            />
            <Button
                title={translate('imprint')}
                textType='p'
                color='text'
                type='clear'
                onPress={() => handleOpenLink(constants.IMPRINT)}
            />
            {Platform.OS === 'ios' && <Button
                title='EULA'
                textType='p'
                color='text'
                type='clear'
                onPress={() => handleOpenLink(constants.EULA)}
            />}
            <Button
                title={translate('licenses')}
                textType='p'
                color='text'
                type='clear'
                onPress={() =>
                    //@ts-ignore
                    navigation.navigate('LegalScreen', {
                        type: 'LICENSES'
                    })
                }
            />
        </View>
    );
}

export default LegalFooter;