import { useNavigation } from '@react-navigation/native';

import { Lesson } from '../models/data/LessonType';
import { Story as S } from '../models/data/StoryType';
import { ChoiceQuiz as CQ } from '../models/data/ChoiceQuizType';
import { SortQuiz as SQ } from '../models/data/SortQuizType';
import { TextQuiz as TQ } from '../models/data/TextQuizType';
import { MediaType } from '../models/data/MediaTypes';

import { Props as AudioProps } from '../components/Audio';
import { Props as VideoProps } from '../components/Video';
import { Props as ImageProps } from '../components/Image';

import { shuffleArray } from '../utils/helpers';

type QuizData = {
    type: 'quiz',
    data: ({ 'ChoiceQuiz': ChoiceQuiz } | { 'SortQuiz': SortQuiz } | { 'TextQuiz': TextQuiz })[]
};

type StoryData = {
    type: 'story',
    data: Lesson
};

interface Story extends S {
    __typename: 'Story';
}

interface ChoiceQuiz extends CQ {
    __typename: 'ChoiceQuiz';
}

interface SortQuiz extends SQ {
    __typename: 'SortQuiz';
}

interface TextQuiz extends TQ {
    __typename: 'TextQuiz';
}

export type RefactoredDataType = | Story | ChoiceQuiz | SortQuiz | TextQuiz;

export const refactorData = (storyData: QuizData | StoryData): RefactoredDataType[] => {
    let returnArray: RefactoredDataType[] = [];
    if (storyData.type === 'story') {
        let stories = storyData.data.stories;
        let choiceQuizzes = storyData.data.choiceQuizzes;
        let sortQuizzes = storyData.data.sortQuizzes;
        let textQuizzes = storyData.data.textQuizzes;

        let arrayToShuffle: RefactoredDataType[] = [];

        stories && stories.forEach(story => {
            returnArray.push({
                __typename: 'Story',
                ...story
            })
        });

        choiceQuizzes && choiceQuizzes.forEach(quiz => {
            arrayToShuffle.push({
                __typename: 'ChoiceQuiz',
                ...quiz
            });
        });

        sortQuizzes && sortQuizzes.forEach(quiz => {
            arrayToShuffle.push({
                __typename: 'SortQuiz',
                ...quiz
            })
        });

        textQuizzes && textQuizzes.forEach(quiz => {
            arrayToShuffle.push({
                __typename: 'TextQuiz',
                ...quiz
            })
        });

        returnArray.sort((a, b) => a.position < b.position ? -1 : 1);

        if (arrayToShuffle.length > 0) {
            arrayToShuffle = shuffleArray(arrayToShuffle);

            returnArray = returnArray.concat(arrayToShuffle);
        }

        return returnArray;
    } else {

        let quizze = storyData.data;

        quizze && quizze.forEach(quiz => {
            Object.entries(quiz).forEach(([key, value]) =>
                returnArray.push({
                    __typename: key === 'ChoiceQuiz' ? 'ChoiceQuiz' : key === 'SortQuiz' ? 'SortQuiz' : 'TextQuiz',
                    ...value
                })
            )
        });

        return returnArray = shuffleArray(returnArray);
    }
}

export type MediaTypes = {
    type: 'AudioMedia' | 'VideoMedia' | 'ImageMedia',
    props: {
        description: string;
        url: string;
    }
};

export const refactorMediaStory = (media: MediaType | string): MediaTypes | undefined => {
    let refactoredMedia: MediaTypes | undefined;

    if (media === null) {
        refactoredMedia = undefined;
    } else {
        //@ts-ignore
        const key = Object.keys(JSON.parse(media))[0];
        //@ts-ignore
        const value = Object.values(JSON.parse(media))[0];

        refactoredMedia = {
            //@ts-ignore
            type: key,
            //@ts-ignore
            props: value
        }
    }

    return refactoredMedia;
}

type AudioType = {
    type: 'Audio',
    props: AudioProps
};

type VideoType = {
    type: 'Video',
    props: VideoProps
};

type ImageType = {
    type: 'Image',
    props: ImageProps
};

export const refactorMediaQuizHandler = (rM: MediaTypes | undefined): AudioType | VideoType | ImageType | undefined => {
    const navigation = useNavigation();
    switch (rM?.type) {
        case 'AudioMedia':
            return {
                type: 'Audio',
                props: {
                    playlist: [{
                        title: rM.props.description,
                        url: rM.props.url,
                        type: 'URL'
                    }]
                }
            };
        case 'ImageMedia':
            return {
                type: 'Image',
                props: {
                    url: rM.props.url,
                    description: rM.props.description,
                    //@ts-ignore
                    onPress: () => navigation.navigate("MediaScreen", { type: 'IMAGE', url: rM.props.url, desciption: rM.props.description })
                }
            };
        case 'VideoMedia':
            return {
                type: 'Video',
                props: {
                    videoUrl: rM.props.url

                }
            };
        default:
            return undefined;
    }
}