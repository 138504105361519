import React, { useEffect, useState } from 'react';
import { View, Platform } from 'react-native';

import * as Linking from 'expo-linking';
import Purchases from 'react-native-purchases';
import * as Device from 'expo-device';

import WelcomeCard from '../../components/WelcomeCard';
import QuizButton from '../../components/QuizButton';
import CategoryCard from '../../components/CategoryCard';
import CardsList from '../../components/CardsList';
import SourceFooter from '../../components/SourceFooter';
import { ResponsiveContainer } from '../../components/ResponsiveContainer';

import constants from '../../utils/constants';

import { MainStackParamList } from '../../models/stackParams/MainStackParamListType';
import { StackNavigationProp } from '@react-navigation/stack';
import { RouteProp } from '@react-navigation/native';

import { useAuthState } from '../../services/context/authContext';
import { useCategoryListDispatch, useCategoryListState, getCategories } from '../../services/context/categoryListContext';
import { useUserDispatch, useUserState, getUser, premiumStatusChange } from '../../services/context/userContext';
import { useQuizProgressDispatch, useQuizProgressState, getQuizProgress } from '../../services/context/quizProgressContext';

import { translate } from '../../services/localization/translations';
import resetCache from '../../utils/resetCache';

type HomeScreenNavigationProp = StackNavigationProp<
    MainStackParamList,
    'HomeScreen'
>;

type HomeScreenRouteProp = RouteProp<
    MainStackParamList,
    'HomeScreen'
>;


type Props = {
    navigation: HomeScreenNavigationProp;
    route: HomeScreenRouteProp;
};

export const HomeScreen = ({ navigation, route }: Props) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const { data: auth } = useAuthState();

    const { data: list, isLoading: isLoadingCategories, error: listError } = useCategoryListState();
    const listDispatch = useCategoryListDispatch();

    const { data: user, isLoading: isLoadingUser, error: userError } = useUserState();
    const userDispatch = useUserDispatch();

    const { data: quizProgress, isLoading: isLoadingQuizProgress, error: quizProgressError } = useQuizProgressState();
    const quizProgressDispatch = useQuizProgressDispatch();

    useEffect(() => {
        loadInitialData();
        (Device.isDevice && (Platform.OS === 'ios' || Platform.OS === 'android')) && Purchases.logIn(auth.username);
    }, []);

    const loadInitialData = async () => {
        setIsLoading(true);
        await getCategories(listDispatch);
        await getUser(userDispatch);
        await getQuizProgress(quizProgressDispatch, userDispatch);
        await premiumStatusChange();
        await Linking.getInitialURL().then(urlRedirect);
        setIsLoading(false);
    };

    const urlRedirect = (url: string | null) => {
        if (!url) return;
        // parse and redirect to new url
        let { path, queryParams } = Linking.parse(url);
        //@ts-ignore
        if (constants.ALLOWEDROUTES.includes(path)) {
            //@ts-ignore
            navigation.navigate(path, queryParams);
            console.log(`Linked to app with path: ${path} and data: ${JSON.stringify(queryParams)}`);
        }
    };

    return (
        <ResponsiveContainer
            isLoading={isLoading || isLoadingCategories || isLoadingUser || isLoadingQuizProgress}
        >
            <View
                style={{
                    flexShrink: 1,
                    flexDirection: 'row'
                }}>
                {quizProgress ?
                    <QuizButton
                        quizzeDone={quizProgress.quizzeDone}
                        quizzeFailed={quizProgress.quizzeFailed}
                        quizzeTotal={quizProgress.quizzeTotal}
                        parentFlexDirectionColumn={false}
                        onPress={() => navigation.navigate("QuizzeScreen")}
                    />
                    :
                    <View
                        style={{
                            flex: 1
                        }} />
                }
                {user?.currentCategory && list ?
                    <CategoryCard
                        categoryId={user?.currentCategory}
                        //@ts-ignore
                        categoryName={list.find(x => x.id === user?.currentCategory).name}
                        //@ts-ignore
                        categoryImageUrl={list.find(x => x.id === user?.currentCategory).thumbnailUrl}
                    />
                    :
                    <WelcomeCard />
                }
            </View>
            <View
                style={{
                    flex: 2,
                    padding: 4
                }}>
                <CardsList
                    expanded={false}
                    data={list}
                    selectedItem={user?.currentCategory}
                    setSelectedIndex={() => { }}
                    type='category'
                    onRefresh={resetCache}
                />
            </View>
            <SourceFooter
                text={translate('bzaekHint')}
                link={constants.BZAEKRAHMENLEHRPLAN}
            />
        </ResponsiveContainer >
    );
}