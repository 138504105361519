import React, { useState } from 'react';
import { View } from 'react-native';
import { Input } from 'react-native-elements';

import Icon, { Icons } from './Icon';
import Button from './Button';

import { hexProvider, Colors } from '../utils/hexProvider';

type Props = {
	placeholder?: string;
	label?: string;
	errorMessage?: string;
	disabled?: boolean;
	secureTextEntry?: boolean;
	submitable?: boolean;
	submitableColor?: Colors;
	onChangeText: ((text: string) => void);
	value: string,
	icon?: Icons;
	iconRight?: Icons;
	fontSize?: number;
	maxLength?: number;
	type: 'password' | 'email' | 'name' | 'oneTimeCode' | undefined
};

export const TextInput = ({
	label,
	placeholder,
	errorMessage,
	disabled,
	secureTextEntry,
	submitable,
	submitableColor = 'success',
	onChangeText,
	value,
	icon,
	iconRight,
	fontSize = undefined,
	maxLength,
	type
}: Props) => {
	const [isFocused, setisFocused] = useState<boolean>(false);
	const [pwshown, setPwshown] = useState<boolean>(true);

	let renderErrorMessage = errorMessage !== undefined ? true : false;

	const showPassword = () => {
		setPwshown(pwshown ? false : true);
	}

	return (
		<View
			style={{
				flexDirection: 'row'
			}}
		>
			<Input
				label={label}
				placeholder={placeholder}
				errorMessage={renderErrorMessage ? errorMessage : undefined}
				renderErrorMessage={renderErrorMessage}
				disabled={disabled}
				secureTextEntry={(!secureTextEntry && type === 'password') ? pwshown : secureTextEntry}
				onChangeText={onChangeText}
				value={value}
				leftIcon={icon === undefined ? undefined : <Icon icon={icon} color={submitable ? submitableColor : renderErrorMessage ? 'error' : isFocused ? 'primary' : 'text'} style={{ paddingRight: 8, padding: 1 }} />}
				rightIcon={iconRight === undefined ? undefined : <Icon icon={iconRight} color={submitable ? submitableColor : renderErrorMessage ? 'error' : isFocused ? 'primary' : 'text'} style={{ paddingRight: 8, padding: 1 }} />}
				autoCompleteType={type === 'oneTimeCode' ? undefined : type}
				textContentType={type === 'email' ? 'emailAddress' : type}
				returnKeyType='done'
				labelStyle={{ color: hexProvider('text') }}
				placeholderTextColor={hexProvider(submitable ? submitableColor : renderErrorMessage ? 'error' : isFocused ? 'primary' : 'text')}
				inputStyle={{ color: hexProvider(submitable ? submitableColor : renderErrorMessage ? 'error' : 'text') }}
				inputContainerStyle={{ borderColor: hexProvider(submitable ? submitableColor : renderErrorMessage ? 'error' : isFocused ? 'primary' : 'text') }}
				errorStyle={{ color: hexProvider('error') }}
				containerStyle={{ marginVertical: 8 }}
				style={fontSize ? { fontSize: fontSize } : undefined}
				onFocus={() => !disabled ? setisFocused(true) : setisFocused(false)}
				onBlur={() => setisFocused(false)}
				maxLength={maxLength ? maxLength : type === 'oneTimeCode' ? 6 : undefined}
			/>
			{
				(!secureTextEntry && type === 'password') &&
				<View
					style={{
						position: 'absolute',
						right: 0,
						top: 0,
						bottom: 0,
						justifyContent: 'center',
					}}
				>
					<Button
						icon={{
							icon: ((!secureTextEntry && type === 'password') ? !pwshown : secureTextEntry) ? 'eye-slash' : 'eye',
							color: 'text',
							style: {
								padding: 1,
								paddingBottom: 16
							}
						}}
						type='clear'
						onPress={showPassword}
					/>
				</View>
			}
		</View>
	);
};

export default TextInput;