import React, { useState, useEffect } from 'react';
import { View, TouchableOpacity, Animated, Platform } from 'react-native';

import ReactPlayer from 'react-player'
import { Video as V } from 'expo-av';
import Button from './Button';

import { hexProvider } from '../utils/hexProvider';
import { useRef } from 'react';

export type Props = {
    videoUrl: string;
    margin?: number;
};

const Video = ({
    videoUrl,
    margin = 4
}: Props) => {
    const animatedOpacity = useRef(new Animated.Value(1)).current;

    const [viewDimension, setViewDimension] = useState<{ height: number, width: number }>({ height: 0, width: 0 });

    const greyColor = hexProvider('grey');

    const [shouldPlay, setShouldPlay] = useState<boolean>(false);

    const handleShouldPlay = () => {
        setShouldPlay(shouldPlay ? false : true);
    }

    useEffect(() => {
        if (!shouldPlay) {
            Animated.timing(animatedOpacity, {
                toValue: 1,
                duration: 500,
                useNativeDriver: true
            }).start()
        } else {
            Animated.timing(animatedOpacity, {
                toValue: 0,
                duration: 500,
                useNativeDriver: true
            }).start()
        }
    }, [shouldPlay])

    return (
        <View
            style={{
                flex: 1,
                margin: margin ? margin : undefined,
                overflow: 'hidden',
                borderRadius: 8 / 1,
                backgroundColor: greyColor
            }}
            onLayout={e => setViewDimension({
                height: e.nativeEvent.layout.height,
                width: e.nativeEvent.layout.width
            })}
        >
            <TouchableOpacity
                style={{
                    position: 'absolute',
                    zIndex: 2,
                    top: 0,
                    bottom: 0,
                    right: 0,
                    left: 0,
                }}
                onPress={handleShouldPlay}
            >
                <Animated.View
                    style={{
                        position: "absolute",
                        zIndex: 3,
                        top: '25%',
                        bottom: '25%',
                        right: '25%',
                        left: '25%',
                        alignItems: 'stretch',
                        justifyContent: 'center',
                        opacity: animatedOpacity
                    }}
                >
                    <Button
                        type='solid'
                        color='textinverted'
                        icon={{
                            icon: shouldPlay ? 'pause' : 'play',
                            color: 'text',
                            style: { padding: 1 }
                        }}
                        style={{
                            height: 64,
                            width: 64,
                            borderRadius: 64 / 2,
                            opacity: .8,
                            alignSelf: 'center'
                        }}
                        onPress={handleShouldPlay} />
                </Animated.View>
            </TouchableOpacity>
            {Platform.OS === 'web' ?
                <ReactPlayer
                    url={videoUrl}
                    playing={shouldPlay}
                    loop={true}
                    controls={false}

                />
                :
                <V
                    style={{
                        height: viewDimension.height,
                        width: viewDimension.width,
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                    source={{
                        uri: videoUrl
                    }}
                    useNativeControls={false}
                    resizeMode='contain'
                    isLooping
                    shouldPlay={shouldPlay}
                />
            }
        </View>
    );
}

export default Video;