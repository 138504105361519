import React from 'react';
import { StyleSheet, View } from 'react-native';

import Button from './Button';

import { translate } from '../services/localization/translations';
import { hexProvider } from '../utils/hexProvider';

type Props = {
    signOutOnPress: (() => void)
    signOutGlobalOnPress: (() => void)
};

const LogoutCard = ({
    signOutOnPress,
    signOutGlobalOnPress,
}: Props) => {

    const style = StyleSheet.create({
        container: {
            margin: 4,
            padding: 8,
            borderRadius: 8 / 1,
            backgroundColor: hexProvider('grey'),
            justifyContent: 'space-around',
        }
    });

    return (
        <View
            style={style.container}
        >
            <Button
                title={translate('signOut')}
                bold
                type='solid'
                color='primary'
                textColor='white'
                icon={{ icon: 'sign-out', color: 'white', style: { padding: 8 } }}
                style={{
                    height: 56,
                    borderRadius: 56 / 4,
                    margin: 4,
                    paddingRight: 8
                }}
                onPress={signOutOnPress} />
            <Button
                title={translate('signOutGlobal')}
                type='clear'
                color='primary'
                textColor='primary'
                style={{
                    height: 56,
                    borderRadius: 56 / 4,
                    margin: 4,
                    paddingRight: 8
                }}
                onPress={signOutGlobalOnPress} />
        </View>
    );
};

export default LogoutCard;