import React, { useRef } from 'react';
import { View, TouchableOpacity, Animated } from 'react-native';

import Text from './Text';

import { hexProvider } from '../utils/hexProvider';

import Icon, { Icons } from './Icon';

type Props = {
    icon: Icons;
    title: string;
    onPress: () => void;
};

const SettingsCard = ({
    icon,
    title,
    onPress
}: Props) => {

    const animatedScaleValue = useRef(new Animated.Value(1)).current

    const scaleVal = animatedScaleValue.interpolate({
        inputRange: [0, 1],
        outputRange: [.8, 1]
    })

    const onPressIn = () =>
        Animated.timing(animatedScaleValue, {
            toValue: 0,
            duration: 200,
            useNativeDriver: true
        }).start();

    const onPressOut = () =>
        Animated.timing(animatedScaleValue, {
            toValue: 1,
            duration: 200,
            useNativeDriver: true
        }).start();

    let bgcolor = hexProvider('grey');

    return (
        <Animated.View style={{
            flex: 1,
            margin: 4,
            padding: 8,
            paddingVertical: 16,
            borderRadius: 8 / 1,
            backgroundColor: bgcolor,
            transform: [
                {
                    scale: scaleVal
                }
            ]
        }}>
            <TouchableOpacity
                style={{
                    flex: 1,
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
                onPress={onPress}
                onPressIn={onPressIn}
                onPressOut={onPressOut}
            >
                <View
                    style={{
                        flexShrink: 1,
                        paddingVertical: 16
                    }}
                >
                    <Text
                        type='h4'
                        style='bold'
                        color='text'>
                        {title}
                    </Text>
                </View>
                <View style={[
                    {
                        flexShrink: 1,
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: 8,
                        paddingHorizontal: 16,
                        borderRadius: 64 / 2,
                        height: 64,
                        width: 64,
                        backgroundColor: hexProvider('primary'),
                    },
                ]}>
                    <Icon
                        icon={icon}
                        color='white'
                    />
                </View>
            </TouchableOpacity>
        </Animated.View>
    );
}
export default SettingsCard;