import React, { useState, useEffect } from 'react';
import { Platform } from 'react-native';

import { StackNavigationProp } from '@react-navigation/stack';
import { RouteProp } from '@react-navigation/native';

import { ResponsiveContainer } from '../../components/ResponsiveContainer';
import StoryHeader from '../../components/StoryHeader';
import StoryHandler from '../../components/StoryHandler';
import StoryOptionsCard from '../../components/StoryOptionsCard';

import { TextDirection, TextType } from '../../components/Text';
import { TextAlign, TextSize } from '../../models/graphql/graphql';
import { StoryStackParamList } from '../../models/stackParams/StoryStackParamListType';
import { User } from '../../models/data/UserType';

import { useCategoryListState } from '../../services/context/categoryListContext';
import { useStoryDispatch, useStoryState, getLessonContent, getQuizzesForCategory } from '../../services/context/storyContext';
import { useUserDispatch, useUserState, updateUser, } from '../../services/context/userContext';
import { useProgressDispatch, useProgressState, getProgress, updateProgress } from '../../services/context/progressContext';
import { useQuizProgressDispatch } from '../../services/context/quizProgressContext';
import { useTranslationDispatch, useTranslationState, getTranslation } from '../../services/context/translationContext';
import { useAuthState } from '../../services/context/authContext';

import { handleAddStoryToProgess, handleAddQuizzeProgress } from '../../utils/helpers';
import { translate } from '../../services/localization/translations';

type StoryScreenNavigationProp = StackNavigationProp<
    StoryStackParamList,
    'StoryScreen'
>;

type StoryScreenRouteProp = RouteProp<
    StoryStackParamList,
    'StoryScreen'
>;

type Props = {
    navigation: StoryScreenNavigationProp;
    route: StoryScreenRouteProp;
};

export const StoryScreen = ({ navigation, route }: Props) => {

    const { ids, type } = route.params;

    const { data: auth } = useAuthState();

    const { data: user, isLoading: isLoadingUser } = useUserState();
    const userDispatch = useUserDispatch();

    const { data: progress, isLoading: isLoadingProgress } = useProgressState();
    const progressDispatch = useProgressDispatch();

    const { isLoading: isLoadingQuizProgress } = useProgressState();
    const quizProgressDispatch = useQuizProgressDispatch();

    const { data: categories, isLoading: isLoadingCategories } = useCategoryListState();

    const { data: story, isLoading: isLoadingStory, error: storyError } = useStoryState();
    const storyDispatch = useStoryDispatch();

    const { data: translation, isLoading: isLoadingTranslation } = useTranslationState();
    const translationDispatch = useTranslationDispatch();

    const [storyOptionsCardVisible, setStoryOptionsCardVisible] = useState<boolean>(false);
    const [textType, setTextType] = useState<TextType>('h3');
    const [textAlign, setTextAlign] = useState<Exclude<TextDirection, 'auto'>>('center');
    const [selectedLanguageCode, setSelectedLanguageCode] = useState<string>('de');
    const defaultLanguageCode: string = 'de';

    const [totalIndex, setTotalIndex] = useState<number>(0);
    const [currentIndex, setCurrentIndex] = useState<number>(0);

    const [rightQuizzesState, setRightQuizzesState] = useState<string[]>([]);
    const [wrongQuizzesState, setWrongQuizzesState] = useState<string[]>([]);

    const [tutorialTabIndicator, setTutorialTabIndicator] = useState<boolean>(true);

    useEffect(() => {
        if (ids) {
            translationDispatch({ type: 'RESET' });

            let u: User = user;
            if (!u?.tutorials && !u.tutorials?.find(e => (e.name == 'tabindicator' && e.done))) {
                setTutorialTabIndicator(false);
            }

            switch (type) {
                case 'QUIZCATEGORY':
                    let categoryID: string = (ids[0]);

                    progressDispatch({ type: 'RESET' });
                    getProgress(progressDispatch, categoryID, true);

                    getQuizzesForCategory(storyDispatch, categoryID, true);
                    break;
                case 'QUIZ':

                    break;
                default:
                    let lessonID: string = (ids[0]);
                    getLessonContent(storyDispatch, lessonID, true);
                    break;
            }
            user?.textAlign && setTextAlign(user?.textAlign);
            user?.textSize && setTextType(user?.textSize);
        }
        return () => {
            storyDispatch({ type: 'RESET' });
        }
    }, [ids]);

    useEffect(() => {
        let selectedLanguage = route.params?.language;
        if (selectedLanguage) {
            if (selectedLanguage !== defaultLanguageCode) {
                if (ids) {
                    let lessonID: string = (ids[0]);
                    getTranslation(translationDispatch, lessonID, selectedLanguage);
                    setSelectedLanguageCode(selectedLanguage);
                    setCurrentIndex(0);
                    setStoryOptionsCardVisible(false);
                }
            } else {
                translationDispatch({ type: 'REQUEST' });
                translationDispatch({ type: 'RESET' });
                setSelectedLanguageCode(selectedLanguage);
                setCurrentIndex(0);
                setStoryOptionsCardVisible(false);
            }
        }
    }, [route.params?.language]);

    const handleStoryExit = async (storyFinished: boolean) => {
        try {
            if (type === 'STORY') {
                await updateUser(userDispatch, {
                    id: '',
                    currentCategory: story.categoryId,
                    nextLesson: story.id,
                    textAlign: TextAlign[textAlign],
                    textSize: TextSize[textType],
                    tutorials: [{
                        name: 'tabindicator',
                        done: true
                    }]
                });

                if (storyFinished) {
                    progressDispatch({ type: 'REQUEST' });

                    let storyProgress = await handleAddStoryToProgess(progress.storiesProgress, story.id);
                    let quizzeProgress = await handleAddQuizzeProgress(progress.quizzeProgress, rightQuizzesState, wrongQuizzesState, quizProgressDispatch);

                    await updateProgress(progressDispatch, {
                        id: story.categoryId,
                        storiesProgress: storyProgress,
                        quizzeProgress: quizzeProgress
                    });
                }
            } else if (type === 'QUIZCATEGORY') {
                await updateUser(userDispatch, {
                    id: '',
                    textAlign: TextAlign[textAlign],
                    textSize: TextSize[textType],
                    tutorials: [{
                        name: 'tabindicator',
                        done: true
                    }]
                });

                if (rightQuizzesState.length > 0 || wrongQuizzesState.length > 0) {
                    progressDispatch({ type: 'REQUEST' });

                    //@ts-ignore
                    let categoryID = ids[0];
                    let quizzeProgress = await handleAddQuizzeProgress(progress.quizzeProgress, rightQuizzesState, wrongQuizzesState, quizProgressDispatch);

                    await updateProgress(progressDispatch, {
                        id: categoryID,
                        quizzeProgress: quizzeProgress,
                    });
                }
            }

            await navigation.goBack();
        } catch (error) {
            console.log(error);
        }
    }

    let storyTitle =
        story?.name ?
            story?.name :
            type === 'QUIZCATEGORY' ?
                //@ts-ignore
                categories.find(e => e.id === ids[0]).name ?
                    //@ts-ignore
                    translate('quizzes') + ': ' + categories.find(e => e.id === ids[0]).name
                    :
                    '' :
                '';

    let premium: boolean | undefined =
        auth?.signInUserSession?.
            idToken?.
            payload['cognito:groups']
            //@ts-ignore
            .some(e => e === 'premium');

    return (
        <ResponsiveContainer
            linearGradientHexColors={
                Platform.OS !== 'web' ?
                    undefined
                    :
                    (type === 'STORY' && story && categories) ?
                        //@ts-ignore
                        categories.find(x => x.id === story.categoryId).thumbnailColors
                        :
                        [
                            '#9FADA9',
                            '#D9E5E2',
                            '#E6B794',
                            '#4E5356'
                        ]
            }
            isLoading={
                isLoadingStory
                || isLoadingUser
                || isLoadingProgress
                || isLoadingQuizProgress
                || isLoadingTranslation
                || isLoadingCategories
                || story === undefined
            }
        >
            <StoryHeader
                title={storyTitle}
                current={currentIndex}
                total={totalIndex}
                storyAction={{
                    isVisibleState: storyOptionsCardVisible,
                    setIsVisibleState: setStoryOptionsCardVisible
                }}
            />
            {story &&
                <StoryHandler
                    storyData={{
                        type: type === 'STORY' ? 'story' : 'quiz',
                        data: story
                    }}
                    translation={translation}
                    textAlign={textAlign}
                    textType={textType}
                    currentIndex={currentIndex}
                    totalIndex={totalIndex}
                    setCurrentIndex={setCurrentIndex}
                    setTotalIndex={setTotalIndex}
                    rightQuizzesState={rightQuizzesState}
                    setRightQuizzesState={setRightQuizzesState}
                    wrongQuizzesState={wrongQuizzesState}
                    setWrongQuizzesState={setWrongQuizzesState}
                    onPressClose={() => handleStoryExit(true)}
                    tutorialTabIndicator={tutorialTabIndicator}
                />}
            <StoryOptionsCard
                isVisible={storyOptionsCardVisible}
                setIsVisible={setStoryOptionsCardVisible}
                textAlignState={textAlign}
                textTypeState={textType}
                setTextAlignState={setTextAlign}
                setTextTypeState={setTextType}
                onPressTranslation={type === 'STORY' && premium ?
                    () => navigation.navigate('TranslationScreen', {
                        selectedLanguageCode: selectedLanguageCode,
                        defaultLanguageCode: defaultLanguageCode
                    }) : undefined}
                onPressClose={() => handleStoryExit(false)}
            />
        </ResponsiveContainer >
    );
}