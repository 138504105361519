import React from "react";
import { StyleSheet, View } from "react-native";

import Text from "./Text";

import { hexProvider } from "../utils/hexProvider";
import { translate } from "../services/localization/translations";

import TeamType from "../models/data/TeamType";
import { RCAPISubscriberType } from "../models/data/PurchaseTypes";

type Props = {
    expirationWeb?: TeamType['current_period_end'] | undefined;
    expirationMobile?: RCAPISubscriberType['entitlements']['individual']['expires_date'] | undefined;
}

const ExpiredCard = ({
    expirationWeb,
    expirationMobile
}: Props) => {
    if (expirationWeb || expirationMobile) {
        const date: string = expirationWeb ? new Date((expirationWeb * 1000)).toString() : new Date(expirationMobile as string).toString()

        const style = StyleSheet.create({
            container: {
                margin: 4,
                padding: 8,
                borderRadius: 8 / 1,
                backgroundColor: hexProvider('grey')
            }
        });

        return (
            <View
                style={style.container}
            >
                <Text
                    type='h5'
                    style='bold'
                    color='text'
                >
                    {translate('expiryDate')}
                </Text>
                <View style={{ marginVertical: 4 }} />
                <Text
                    type='h5'
                    style='normal'
                    color='text'
                >
                    {date}
                </Text>
            </View>
        );
    } else {
        return null;
    }
}

export default ExpiredCard;