import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { View, StyleSheet, KeyboardAvoidingView, Platform } from 'react-native';

import * as yup from 'yup';

import TextInput from './TextInput';
import Button from './Button';
import Text from './Text';

import { translate } from '../services/localization/translations';
import { hexProvider } from '../utils/hexProvider';

type Props = {
    emailInput: string;
    setEmailInput: Dispatch<SetStateAction<string>>;
    handleSignIn: () => void;
};

const PWlessSignInCard = ({
    emailInput,
    setEmailInput,
    handleSignIn,
}: Props) => {
    const [rendered, setRendered] = useState<boolean>(false);

    const [submitPossible, setSubmitPossible] = useState<boolean>(false);
    const [emailInputErr, setEmailInputErr] = useState<string | undefined>(undefined);

    useEffect(() => {
        setRendered(true)
    }, [])

    let validationSchema = yup.object().shape({
        email: yup.string()
            .required(translate('requiredField'))
            .email(translate('emailNotValid')),
    });

    rendered && validationSchema
        .validate({
            email: emailInput,
        })
        .then(() => {
            setEmailInputErr(undefined)

            setSubmitPossible(true);
        })
        .catch(err => {
            setEmailInputErr(err.path === 'email' ? err.message : undefined);

            setSubmitPossible(false);
        })

    const styles = StyleSheet.create({
        container: {
            margin: 4,
            padding: 16,
            borderRadius: 8 / 1,
            backgroundColor: hexProvider('grey')
        }
    });

    return (
        //@ts-ignore
        <KeyboardAvoidingView
            behavior={Platform.OS === "ios" ? "padding" : "height"}
            enabled
            keyboardVerticalOffset={64}
            style={[styles.container]}
        >
            <TextInput
                label={translate('email')}
                placeholder={translate('emailPlaceholder')}
                submitable={submitPossible}
                errorMessage={emailInput === '' ? undefined : emailInputErr}
                secureTextEntry={false}
                icon='envelope'
                onChangeText={value => setEmailInput(value)}
                value={emailInput}
                type='email'
            />
            <Button
                title={translate('signIn')}
                bold
                type='solid'
                color='primary'
                textColor='white'
                style={{
                    height: 56,
                    borderRadius: 56 / 4,
                    margin: 4,
                }}
                disabled={!submitPossible}
                //@ts-ignore
                onPress={handleSignIn}
            />
            {/*@ts-ignore */}
            <View
                style={{
                    paddingBottom: 8
                }}
            >
                <Text
                    color='text'
                    type='p'
                >
                    {translate('agbHint')}
                </Text>
            </View>
        </KeyboardAvoidingView>
    );
}

export default PWlessSignInCard;