import React from 'react';
import { View, Image } from 'react-native';

import Text from './Text';

type Props = {
    logoSize?: number;
    showName?: boolean;
};

const Logo = ({
    logoSize = 128,
    showName = true
}: Props) => {
    return (
        <View
            style={{
                flex: 1,
                alignContent: 'center',
                justifyContent: 'center',
            }}
        >
            <Image
                style={{
                    alignSelf: 'center',
                    height: logoSize,
                    width: logoSize
                }}
                source={require('../../assets/icon.png')}
                resizeMode='contain'
            />
            {showName &&
                <View
                    style={{
                        flexShrink: 1,
                        paddingVertical: 8
                    }}
                >
                    <Text
                        type='h3'
                        style='bold'
                        color='text'
                    >u1Dent.app
                    </Text>
                </View>}
        </View>
    );
}

export default Logo;