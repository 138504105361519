import React from 'react';
import { View, ViewProps } from 'react-native';

import Text from './Text';

import { translate } from '../services/localization/translations';

type SourceProviderProps = ViewProps & {
    source?: string
};

const SourceProvider = ({
    source = 'unsplash.com',
    ...props
}: SourceProviderProps) => {
    return (
        <View
            {...props}
        >
            <Text
                type='p'
                style='italic'
                textAlign='left'
                color='white'
                shadowColor='black'
            >
                {translate('source') + ': ' + source}
            </Text>
        </View>
    )
}

export default SourceProvider;