import React, { useState, useEffect, useRef, Dispatch, SetStateAction } from 'react';
import { View } from 'react-native';

import { Audio as A } from 'expo-av';

import LoadingIndicator from './LoadingIndicator';
import Container from './Container';
import Button from './Button';
import Text from './Text';
import ProgressBar from './ProgressBar';

import { queryAPI } from '../services/api/data'
import msToTime from '../utils/msToTime';
import { hexProvider } from '../utils/hexProvider';

type Playlist = {
    title: string,
    url?: string,
    lessonId?: string,
    premium?: boolean,
    type: 'URL' | 'LESSONID'
};

type ComponentPosition =
    | 'flex-start'
    | "center"
    | "flex-end";

export type Props = {
    playlist: Playlist[];
    playlistPosition?: number;
    setPlaylistPosition?: Dispatch<SetStateAction<number>>
    margin?: number;
};

const Audio = ({
    playlist,
    playlistPosition = 0,
    setPlaylistPosition,
    margin = 4
}: Props) => {
    const [initialRenderDone, setInitialRenderDone] = useState<boolean>(false);

    const [AVPlaybackObject, setAVPlaybackObject] = useState<any>(null);
    const [currentProgress, setCurrentProgress] = useState<number>(0);
    const [playing, setPlaying] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [autoplay, setAutoplay] = useState<boolean>(true);

    const [rateIndex, setRateIndex] = useState<number>(0);

    const soundObjectRef = useRef(new A.Sound());

    const getUrl = async (lessonID: string | undefined, premium: boolean | undefined): Promise<string> => {
        try {
            const data: any = await queryAPI('getPodcastUrl', { lessonID, premium }, { refresh: true });
            const url = JSON.parse(data);
            if (url.statusCode === 200) {
                const u = url.body;
                return (u);
            } else {
                return '';
            }
        } catch (error) {
            console.log(error);
            //@ts-ignore
            return error;
        }
    }

    const playbackRate = [
        {
            rate: 1.0,
            rateName: '1,0x'
        },
        {
            rate: 1.5,
            rateName: '1,5x'
        },
        {
            rate: 2.0,
            rateName: '2,0x'
        },
        {
            rate: 0.5,
            rateName: '0,5x'
        }
    ];

    const playAudio = async (newTrack: boolean = false, indexToSet?: number) => {
        try {
            setIsLoading(true);
            if (newTrack) {
                soundObjectRef.current.unloadAsync();
            }
            if (playing === true && !newTrack) {
                await soundObjectRef.current.pauseAsync();
                setPlaying(false);
            } else {
                setPlaying(true);
                if (newTrack === true || AVPlaybackObject.isLoaded === false) {
                    await soundObjectRef.current.loadAsync(
                        { uri: `${playlist[playlistPosition].type === 'URL' ? playlist[indexToSet === undefined ? playlistPosition : indexToSet].url : await getUrl(playlist[indexToSet === undefined ? playlistPosition : indexToSet].lessonId, playlist[indexToSet === undefined ? playlistPosition : indexToSet].premium)}` }, {}, true
                    );
                }
                await soundObjectRef.current.setRateAsync(playbackRate[rateIndex].rate, true);
                await soundObjectRef.current.playAsync();
            }
            setIsLoading(false);
        } catch (error) {
            console.log(error);
            setIsLoading(false);
        }
    };

    const rePlayAudio = async () => {
        try {
            setIsLoading(true);
            setPlaying(true);
            if (AVPlaybackObject.isLoaded === false) {
                await soundObjectRef.current.loadAsync(
                    { uri: `${playlist[playlistPosition].type === 'URL' ? playlist[playlistPosition].url : await getUrl(playlist[playlistPosition].lessonId, playlist[playlistPosition].premium)}` }, {}, true
                );
            }
            await soundObjectRef.current.setRateAsync(playbackRate[rateIndex].rate, true);
            await soundObjectRef.current.replayAsync();
            setIsLoading(false);
        } catch (error) {
            console.log(error);
            setIsLoading(false);
        }
    };

    const skipForward = async () => {
        try {
            setIsLoading(true);
            await soundObjectRef.current.setPositionAsync(AVPlaybackObject.positionMillis + 15000);
            setIsLoading(false);
        } catch (error) {
            console.log(error);
            setIsLoading(false);
        }
    }

    const skipBack = async () => {
        try {
            setIsLoading(true);
            await soundObjectRef.current.setPositionAsync(AVPlaybackObject.positionMillis - 15000);
            setIsLoading(false);
        } catch (error) {
            console.log(error);
            setIsLoading(false);
        }
    }

    const nextForward = () => {
        const indexToSet = (playlistPosition + 1 === playlist.length) ? playlistPosition : playlistPosition + 1;
        setPlaylistPosition && setPlaylistPosition(indexToSet);
    }

    const nextBack = () => {
        const indexToSet = (playlistPosition === 0) ? playlistPosition : playlistPosition - 1;
        setPlaylistPosition && setPlaylistPosition(indexToSet);
    }

    const changePlaybackRate = async () => {
        try {
            setIsLoading(true);
            const indexToSet = (rateIndex + 1 === playbackRate.length) ? 0 : rateIndex + 1;
            setRateIndex(indexToSet);
            await soundObjectRef.current.setRateAsync(playbackRate[indexToSet].rate, true);
            setIsLoading(false);
        } catch (error) {
            console.log(error);
            setIsLoading(false);
        }
    }

    useEffect(() => {
        let isMounted = true; // note this flag denote mount status
        if (isMounted) {
            setIsLoading(true);
            if (!initialRenderDone) {
                //pass AVPlaybackObject-State for playback-meta
                const playbackUpdate = (playbackObject: any) => {
                    setAVPlaybackObject(playbackObject);
                    const progress = Math.round(100 * ((playbackObject.positionMillis) / playbackObject.durationMillis));
                    setCurrentProgress(isNaN(progress) ? 0 : progress);
                }
                soundObjectRef.current.setOnPlaybackStatusUpdate(playbackUpdate);
                A.setAudioModeAsync({
                    allowsRecordingIOS: false,
                    playsInSilentModeIOS: true,
                    shouldDuckAndroid: true,
                    staysActiveInBackground: true,
                    playThroughEarpieceAndroid: true
                })
                setInitialRenderDone(true);
            }

            setIsLoading(false);
        }
        return () => {
            //clear soundObjectRef
            soundObjectRef.current.unloadAsync();
            isMounted = false // use effect cleanup to set flag false, if unmounted

        }
    }, []);

    useEffect(() => {
        if (initialRenderDone) {
            playAudio(true, playlistPosition);
        }
    }, [playlistPosition]);

    if (playlist.length > 1 && (playlistPosition + 1) < playlist.length && currentProgress >= 100 && autoplay) {
        setAutoplay(false);
        nextForward();
        setTimeout(() => setAutoplay(true), 5000);
    }

    const greyColor = hexProvider('grey');

    return (
        <View
            style={{
                flex: 1,
                margin: margin ? margin : undefined,
                overflow: 'hidden',
                borderRadius: 8 / 1,
                backgroundColor: greyColor
            }}>
            {isLoading ?
                <Container>
                    <LoadingIndicator />
                </Container>
                :
                <>
                    <Container style={{ flex: 1, height: 1 / 3, flexDirection: 'column', justifyContent: 'flex-end', alignItems: 'center' }}>
                        <View style={{ flex: 1, flexWrap: 'wrap', margin: 4, overflow: 'hidden', alignItems: 'stretch', justifyContent: 'space-evenly' }}>
                            <Text type='h5' style='bold' textAlign='center' color='text'>{playlist[playlistPosition].title}</Text>
                        </View>
                    </Container>
                    <Container style={{ flex: 1, height: 1 / 3, flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center' }}>
                        {playlist.length > 1 && <Button type='clear' icon={{ color: 'text', icon: 'backward', style: undefined }} onPress={() => nextBack()} disabled={playlistPosition <= 0} />}
                        <Button type='clear' icon={{ color: 'text', icon: 'undo', style: undefined }} onPress={() => skipBack()} disabled={!playing} />
                        {currentProgress < 99 ? <Button type='clear' icon={{ color: 'text', icon: !playing ? 'play' : 'pause', style: undefined }} onPress={() => playAudio()} /> : <Button type='clear' icon={{ color: 'text', icon: 'play', style: undefined }} onPress={() => rePlayAudio()} />}
                        <Button type='clear' icon={{ color: 'text', icon: 'repeat', style: undefined }} onPress={() => skipForward()} disabled={!playing} />
                        {playlist.length > 1 && <Button type='clear' icon={{ color: 'text', icon: 'forward', style: undefined }} onPress={() => nextForward()} disabled={playlist.length === playlistPosition + 1} />}
                    </Container>
                    <Container style={{ flex: 1, height: 1 / 3, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'stretch' }}>
                        <View style={{ flex: 1, flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                            <Button title={playbackRate[rateIndex].rateName} textType='h5' bold={true} type='outline' style={{ width: 48, height: 48, borderRadius: 48 / 2 }} onPress={() => { changePlaybackRate(); }} disabled={!playing} />
                        </View>
                        {/* <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                            <Text type='h5' color='primary'> {isNaN(AVPlaybackObject?.positionMillis) ? '00:00:00' : msToTime(AVPlaybackObject.positionMillis)}</Text>
                            <Text type='h5' color='text'>/</Text>
                            <Text type='h5' color='text'>{isNaN(AVPlaybackObject?.durationMillis) ? '00:00:00' : msToTime(AVPlaybackObject.durationMillis)}</Text>
                        </View>
                        <View style={{ flexShrink: 1, justifyContent: 'flex-end' }}>
                            <ProgressBar progress={currentProgress} duration={1000} />
                        </View> */}
                    </Container>
                </>
            }
        </View>
    );
};

export default Audio;