import React, { useRef, useState } from 'react';

import { ViewStyle, TouchableOpacity, Animated } from 'react-native';

import Image from './Image';
import LinearGradient from './LinearGradient';
import AnimatedGradient from './AnimatedLinearGradient.js';

import { hexProvider, Colors } from '../utils/hexProvider';

type Props = {
    style?: | ViewStyle | ViewStyle[];
    backgroundColor?: Colors;
    borderColor?: Colors;
    borderRadius?: boolean;
    square?: boolean;
    parentFlexDirectionColumn?: boolean;
    margin?: boolean;
    blur?: boolean;
    imageUrl?: string;
    linearGradient?: { hexColors: string[], animated: boolean };
    disabled?: boolean;
    onPress?: (() => void)
    children?: React.ReactNode;
};

const Card = ({
    style = { flex: 1, overflow: 'hidden' },
    backgroundColor = 'grey',
    borderColor,
    borderRadius = true,
    square = true,
    parentFlexDirectionColumn = true,
    margin = true,
    imageUrl,
    linearGradient,
    disabled,
    onPress,
    children
}: Props) => {
    const animatedScaleValue = useRef(new Animated.Value(1)).current;

    const scaleVal = animatedScaleValue.interpolate({
        inputRange: [0, 1],
        outputRange: [.8, 1]
    });

    const [squareSize, setSquareSize] = useState<number>(0);

    let borderRadiusStyle = borderRadius ? { borderRadius: 8 / 1 } : undefined;
    const squareStyle = square ? parentFlexDirectionColumn ? { width: squareSize } : { height: squareSize } : undefined;
    let borderStyle = borderColor !== undefined ? { borderWidth: 4, borderColor: hexProvider(borderColor) } : undefined;
    let marginStyle = margin ? { margin: 4 } : undefined;
    let bgColor = hexProvider(backgroundColor);

    const onPressIn = () =>
        Animated.timing(animatedScaleValue, {
            toValue: 0,
            duration: 200,
            useNativeDriver: true
        }).start();

    const onPressOut = () =>
        Animated.timing(animatedScaleValue, {
            toValue: 1,
            duration: 200,
            useNativeDriver: true
        }).start();

    return (
        <TouchableOpacity
            style={square ? [{ flex: 1, alignSelf: 'center' }, marginStyle, squareStyle] : [style, { backgroundColor: bgColor }, borderRadiusStyle, borderStyle, marginStyle]}
            onLayout={(e) => {
                setSquareSize(parentFlexDirectionColumn ? e.nativeEvent.layout.height : e.nativeEvent.layout.width);
            }}
            onPress={onPress}
            disabled={onPress === undefined || disabled === true ? true : false}
            onPressIn={onPressIn}
            onPressOut={onPressOut}
        >
            <Animated.View
                style={square ?
                    [style,
                        {
                            backgroundColor: hexProvider(backgroundColor),
                            transform: [{
                                scale: scaleVal
                            }]
                        },
                        borderRadiusStyle,
                        borderStyle,
                        squareStyle] :
                    {
                        flex: 1,
                        transform: [{
                            scale: scaleVal
                        }]
                    }}
            >
                {children}
                {imageUrl !== undefined && <Image square={square} style={{ position: 'absolute', left: 0, right: 0, top: 0, bottom: 0, zIndex: -10 }} url={imageUrl} />}
                {linearGradient !== undefined ?
                    linearGradient.animated === true ?
                        <AnimatedGradient style={{ position: 'absolute', left: 0, right: 0, top: 0, bottom: 0, zIndex: -1 }} customColors={linearGradient?.hexColors} />
                        :
                        <LinearGradient style={{ position: 'absolute', left: 0, right: 0, top: 0, bottom: 0, zIndex: -1 }} colors={linearGradient?.hexColors} />
                    :
                    null
                }

            </Animated.View>
        </TouchableOpacity >
    );
};

export default Card;