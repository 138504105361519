import React from 'react';
import { View, ViewStyle } from 'react-native';

import Card from './Card';
import Container from './Container';
import Icon from './Icon';
import Text from './Text';

type Props = {
    title: string;
    description: string;
    selected?: boolean;
    done?: boolean;
    disabled?: boolean;
    onPress?: (() => void);
    style: ViewStyle | ViewStyle[];
};

const ListCard = ({
    title,
    description,
    selected = false,
    done = false,
    disabled = false,
    onPress,
    style = { flex: 1, padding: 8, overflow: 'hidden' }
}: Props) => {

    return (
        <Card
            margin={false}
            disabled={disabled}
            style={style}
            square={false}
            onPress={onPress}
            borderColor={selected ? 'primary' : undefined}
        >
            <View
                style={{
                    flex: 1,
                    flexDirection: 'row'
                }}
            >


                <View
                    style={{
                        flex: 1,
                        justifyContent: 'space-evenly'
                    }}>
                    <Container
                        style={{
                            flexShrink: 1,
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'flex-start'
                        }}>
                        <Text color='text' style='bold' type='h5' textAlign='left'>{title}</Text>
                    </Container>
                    <Container
                        style={{
                            flexShrink: 1,
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'flex-start'
                        }}>
                        <Text color='text' style='normal' type='h5' textAlign='left'>{description}</Text>
                    </Container>
                </View>
                {done &&
                    <View
                        style={{
                            flexShrink: 1,
                            padding: 8,
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                    >
                        <Icon
                            icon='check-circle'
                            color='text'
                            style={{ padding: 1 }}
                        />
                    </View>}
            </View>
        </Card>
    );
};

export default ListCard;