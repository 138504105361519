import React, { useState, useEffect } from 'react';
import { View } from 'react-native';

import { ResponsiveContainer } from '../../components/ResponsiveContainer';
import Audio from '../../components/Audio';
import CardsList from '../../components/CardsList';
import StoryHeader from '../../components/StoryHeader';

import { StackNavigationProp } from '@react-navigation/stack';
import { RouteProp } from '@react-navigation/native';

import { RootStackParamList } from '../../models/stackParams/RootStackParamListType';

type PodcastScreenNavigationProp = StackNavigationProp<
    RootStackParamList,
    'PodcastScreen'
>;

type PodcastScreenRouteProp = RouteProp<
    RootStackParamList,
    'PodcastScreen'
>;


type Props = {
    navigation: PodcastScreenNavigationProp;
    route: PodcastScreenRouteProp;
};

export const PodcastScreen = ({ navigation, route }: Props) => {

    const { lessons, selectedIndex } = route.params;

    const [playlistPosition, setPlaylistPosition] = useState<number>(0);

    type Playlist = {
        title: string,
        lessonId: string,
        premium: boolean,
        type: 'LESSONID'
    }

    let playlist: Playlist[] = lessons
        .filter(lesson => lesson.podcastDate)
        .map(lesson => ({
            title: lesson.name,
            lessonId: lesson.id,
            premium: lesson.premium,
            type: 'LESSONID'
        }));

    useEffect(() => {
        if (!lessons[selectedIndex].podcastDate) {
            for (let index = selectedIndex; index > 0; index--) {
                if (lessons[index].podcastDate) {
                    let pp = playlist.findIndex(e => e.lessonId === lessons[index].id);
                    setPlaylistPosition(pp >= 0 && pp < playlist.length ? pp : 0);
                    break;
                }
            }
        } else {
            let pp = playlist.findIndex(e => e.lessonId === lessons[selectedIndex].id);
            setPlaylistPosition(pp >= 0 && pp < playlist.length ? pp : 0);
        }
    }, [selectedIndex]);

    const setSelectedIndex = (index: number) => {
        let pp = playlist.findIndex(e => e.lessonId === lessons[index].id);
        setPlaylistPosition(pp >= 0 && pp < playlist.length ? pp : 0);
    }

    return (
        <ResponsiveContainer
            linearGradientHexColors={[
                '#9FADA9',
                '#D9E5E2',
                '#E6B794',
                '#4E5356'
            ]}
        >
            <StoryHeader
                title={playlist[playlistPosition].title}
                current={lessons.findIndex(l => l.id === playlist[playlistPosition].lessonId)}
                total={lessons.length}
                podcastAction={() => navigation.goBack()}
            />
            <View
                style={{
                    flex: 1,
                    flexDirection: 'column',
                    padding: 4
                }}
            >
                <Audio
                    playlistPosition={playlistPosition}
                    setPlaylistPosition={setPlaylistPosition}
                    playlist={playlist}
                />
            </View>
            <View
                style={{
                    flex: 1,
                    paddingHorizontal: 8,
                    paddingBottom: 8
                }}>
                <CardsList
                    type='podcast'
                    data={lessons}
                    selectedItem={lessons ? playlist[playlistPosition].lessonId : ''}
                    setSelectedIndex={setSelectedIndex}
                    expanded={false}
                />
            </View>
        </ResponsiveContainer >
    );
}