/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUserProgress = /* GraphQL */ `
  mutation CreateUserProgress($input: CreateProgressInput!) {
    createUserProgress(input: $input) {
      id
      storiesProgress
      quizzeProgress {
        id
        done
        failed
      }
      owner
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      currentCategory
      nextLesson
      textAlign
      textSize
      primaryColor
      tutorials {
        name
        done
      }
      premium
      quizProgessSharing {
        right
        wrong
        done
        updatedAt
      }
      sharing
      owner
      createdAt
      updatedAt
    }
  }
`;
export const updateUserProgress = /* GraphQL */ `
  mutation UpdateUserProgress(
    $input: UpdateProgressInput!
    $condition: ModelProgressConditionInput
  ) {
    updateUserProgress(input: $input, condition: $condition) {
      id
      storiesProgress
      quizzeProgress {
        id
        done
        failed
      }
      owner
      createdAt
      updatedAt
    }
  }
`;
