import i18n from 'i18n-js';

interface languageObjects {
    [locale: string]: object;
}

export const translate = (locale: string): string => {
    return i18n.t(locale);
}

export const translations: languageObjects = {
    en: {
        welcome: 'Welcome!',
        chooseLesson: 'Choose one of the lessons below.',
        quizzeScreen: 'Get tested',
        chooseCategory: 'Choose your category below.',
        manage: 'manage',
        credential: 'Credentials',
        translation: 'Translate',
        noAccount: 'No account yet? Sign up!',
        hasAccount: 'Have an account? Sign in!',
        signIn: 'sign in',
        signUp: 'sign up',
        completeSignUp: 'Complete sign up',
        signOut: 'sign out',
        signOutGlobal: 'sign out all',
        deleteAccount: 'Delete account',
        deleteAccountDescription: 'Your account and your data will be irrevocably deleted. Subscriptions are non-refundable and may not be reinstated. Caution: u1Dent+ | Premium access subscriptions are not automatically canceled by deleting your account.',
        confirmDeleteAccount: 'confirm deletion',
        cancelDeleteAccount: 'cancel deletion',
        showPasswords: 'show passwords',
        hidePasswords: 'hide passwords',
        changePassword: 'change password',
        requestPasswordReset: 'Request password reset',
        confirmPasswordReset: 'Confirm password reset',
        forgotPW: 'Forgot password?',
        tryAgainPassword: 'try again',
        successfullPassword: 'Changed password successfully!',
        namePlaceholder: 'Nickname',
        emailPlaceholder: 'Email',
        passwordPlaceholder: 'Password',
        verificationCodePlaceholder: 'Verification code',
        name: 'Your nickname:',
        email: 'Your email:',
        memberEmail: 'Member email:',
        password: 'Your password:',
        repeatPassword: 'Repeat your password:',
        currentPassword: 'Your current password:',
        verificationCode: 'Verification code (email):',
        checkEmailsCode: 'We have send you an access code via email. Go, check your Inbox! If not found check your Spam.',
        signUpConfirmed: 'Registration complete! You can now log in.',
        resendVerificationCode: 'Resend verification code?',
        newPassword: 'Your new password:',
        repeatNewPassword: 'Repeat your new password:',
        emailNotValid: 'Email is not valid (name@email.com)',
        couponExact8Chars: 'Coupon must be exactly 8 characters long',
        code6Numbers: 'Code consists of 6 numbers',
        requiredField: 'Field is required',
        passwordMin: 'Password should be at least 8 characters long',
        passwordMax: 'Password should not be larger than 99 characters',
        passwordLowercase: 'Password requires a lowercase character',
        passwordUppercase: 'Password requires a uppercase character',
        passwordNumber: 'Password requires a number',
        passwordSpecial: 'Password requires a special character from this set:',
        passwordNewMustUnique: 'The new password should not match the current one.',
        passwordReenterExactly: 'Please re-enter your Password exactly',
        DefaultException: 'An error occured. Please try again later.',
        Error: 'An error occured. Please try again later.',
        InvalidParameterException: 'An error occured. Please try again later.',
        UserNotFoundException: 'This account does not exist. Please create a new account.',
        UserNotConfirmedException: 'Your email has not yet been verified. Please request a code again!',
        UserLambdaValidationException: 'The ReCaptcha expired. Please try again.',
        NotAuthorizedException: 'This password and email does not match with our data.',
        CodeMismatchException: 'This email or code does not match with our data.',
        ExpiredCodeException: 'This code is expired. Please request a new code.',
        UsernameExistsException: 'There already exists an account for this email. Please sign in. If you forgot your password, please request a reset at the sign-in screen.',
        LimitExceededException: 'Attempt limit exceeded, please try after some time',
        QuotaExceededError: 'Attempt limit exceeded, please try after some time',
        TypeError: 'An error occured. Please try again later.',
        settings: 'Settings',
        quizzes: 'Quizzes',
        podcast: 'Podcast',
        story: 'Story',
        minutes: 'minutes',
        translate: 'translation',
        changeTo: 'change to',
        storyMode: 'story-mode',
        textMode: 'text-mode',
        textAlign: 'text align',
        textAlignCenter: 'center',
        textAlignLeft: 'left',
        textAlignRight: 'right',
        fontSize: 'font size',
        exit: 'exit',
        showQuestion: 'Answer question',
        check: 'check',
        quizWrong: 'Unfortunately, wrong!',
        quizRight: 'Nice, thats right!',
        quizWrongAlready: 'Already answered wrong.',
        quizRightAlready: 'Already answered right.',
        textQuizLabel: 'Your answer:',
        textQuizLabelRight: 'Correct answer:',
        textQuizPlaceholder: 'Answer',
        textQuizMax: 'Answer should not be larger than 99 characters',
        storyDone: 'Done',
        storyDoneRight: 'right',
        storyDoneWrong: 'wrong',
        notARobot: 'I am not a robot.',
        imprint: 'Imprint',
        privacy: 'Privacy policy',
        cookiePolicy: 'Cookie policy',
        agbHint: 'Our general terms and conditions apply (AGB).',
        colorSelector: 'select your color',
        followUs: 'follow us on',
        signInMail: 'Email & password',
        resetLanguage: 'reset to:',
        premiumCardTitle: 'u1Dent+ | Premium-Access',
        premiumCardDescription: 'Unlock all stories (+ translations in over 50 languages), all podcasts and over 950 quizzes.',
        premiumScreenDescription: 'Get premium access:\n Unlocks all features, access to all lessons, and more.',
        support: 'Support',
        subscription: 'Subscription',
        back: 'Back',
        overview: 'Overview',
        networkError: 'Internet connection required!',
        networkErrorMessage: 'An active internet connection is required to update the data. Please try again when you are reconnected to the internet.',
        source: 'Source',
        licenses: 'Licenses',
        perks: 'Perks',
        showPerks: 'Show perks',
        perkList: {
            1: {
                title: 'All stories',
                description: 'Unlock access to all stories.',
            },
            2: {
                title: 'All podcasts',
                description: 'Unlock access all podcasts for hands-free learning.',
            },
            3: {
                title: 'All quizzes',
                description: 'Unlock access to over 900 quizzes.',
            },
            4: {
                title: 'Translations',
                description: 'Translate all stories in more than 50 languages.',
            },
            5: {
                title: 'Sync',
                description: 'Sync your progress with all your devices.',
            },
            6: {
                title: 'Updates',
                description: 'Get regular updates and new features.',
            },
            7: {
                title: 'Team-Manager',
                description: 'With a team-subscription you can manage your team and add members.',
            }
        },
        abonnementDescriptionTeam: 'With this team subscription you can activate premium access for yourself and / or team members as well as manage team members of a team.',
        aboonementDescriptionIndividual: 'With this individual subscription you get premium access.',
        order: 'Order',
        orderSelection: 'Order',
        continue: 'continue',
        OneMonth: '1 month',
        ThreeMonths: '3 months',
        SixMonths: '6 months',
        TwelveMonths: '12 months',
        subcribe: 'Subscribe',
        teamLicenseQuantity: 'Team license quantity',
        couponCode: 'Coupon code',
        apply: 'apply',
        couponNotExist: 'Coupon does not exist',
        reset: 'reset',
        teamName: 'Your teamname:',
        teamNamePlaceholder: 'Enter teamname...',
        teamNameMin: 'Teamname should be at least 3 characters long',
        teamNameMax: 'Teamname should not be larger than 255 characters',
        changeTeamName: 'change name',
        expiryDate: 'Date of Expiry:',
        member: 'Member',
        members: 'Members',
        addMember: 'add member',
        manageSubscription: 'manage subscription',
        removeMember: 'remove member',
        removeMemberAlert: 'Really want to remove member from team?',
        cancel: 'cancel',
        confirm: 'confirm',
        addmember: 'add Member',
        memberName: 'Enter membername:',
        memberNamePlaceholder: 'Preferred membername',
        memberNameMin: 'Membername should be at least 3 characters long',
        memberNameMax: 'Membername should not be larger than 255 characters',
        memberStatusCardTitle: 'You are a team member!',
        memberStatusCardTextExplain: 'As long as you are a team member you have access to all premium features of u1Dent.app. If the team admin removes you from the team, you lose access to all premium features, but you can still use u1Dent.app as part of the basic account.',
        memberStatusCardTextLeave: 'If you want to be removed from the team, speak to your team admin.',
        addMemberExplain: 'Members can be added to your team if they already have an account in u1Dent.app. Registration for a basic account is free. Members can be added to your team if they are already have an account in u1Dent.app. Registration for a basic account is free of charge. They can not be added if they are already bought premium by themselves or are members in another team.',
        ProgressSharing: 'Share progress',
        ProgressSharingDescription: 'If activated your learn-progress will be shared with your Team-Admin.',
        ProgressSharingDisable: 'Would you really like to disable progress sharing?',
        PurchaseSuccessTitle: 'Thank you for purchasing u1Dent+!',
        PurchaseSuccessDelayed: 'As soon as we have received your payment, we will activate your access and you will receive an email notification from us. Until then, your account will remain in the free basic mode.',
        resetCache: 'reset chache',
        monthly: 'monthly',
        perMember: 'per Member',
        pricingSupport: 'Learn more about our pricing',
        restoreSubscription: 'Restore Subscription',
        buyAsCompany: 'Buy as a company',
        tryOtherMail: 'Try another email',
        showSource: 'show source',
        bzaekHint: 'Our content is based on the requirements of the Ordinance on Vocational Training for Dental Specialists and Dental Specialists in Germany (ZahnmedAusbV).',
        includesTaxes: 'incl. taxes',
        completeInApp: 'Complete in app',
        reportProblem: 'Report a problem'
    },
    de: {
        welcome: 'Willkommen!',
        chooseLesson: 'Wähle eine der Lektionen aus.',
        quizzeScreen: 'Lass dich prüfen',
        chooseCategory: 'Wählen deine Kategorie aus.',
        manage: 'Verwaltung',
        credential: 'Anmeldedaten',
        translation: 'Übersetzen',
        noAccount: 'Noch kein Konto? Registrieren!',
        hasAccount: 'Bereits registriert? Anmelden!',
        signIn: 'Anmelden',
        signUp: 'Registrieren',
        completeSignUp: 'Registrierung abschließen',
        signOut: 'Abmelden',
        signOutGlobal: 'überall Abmelden',
        deleteAccount: 'Account löschen',
        deleteAccountDescription: 'Dein Konto und deine Daten werden unwiderruflich gelöscht. Abonnenments werden nicht erstattet und können ggf. nicht wieder hergestellt werden. Vorsicht: u1Dent+ | Premium-Zugang Abonnements werden durch das Löschen Deines Accounts nicht automatisch gekündigt.',
        confirmDeleteAccount: 'Löschen bestätigen',
        cancelDeleteAccount: 'Löschen abbrechen',
        showPasswords: 'Eingaben zeigen',
        hidePasswords: 'Eingabe verstecken',
        changePassword: 'Passwort ändern',
        requestPasswordReset: 'Passwort zurücksetzen anfordern',
        confirmPasswordReset: 'Passwort zurücksetzen bestätigen',
        forgotPW: 'Passwort vergessen?',
        tryAgainPassword: 'Erneut versuchen',
        successfullPassword: 'Passwort wurde erfolgreich geändert!',
        namePlaceholder: 'Spitzname',
        emailPlaceholder: 'E-Mail',
        passwordPlaceholder: 'Passwort',
        verificationCodePlaceholder: 'Bestätigungscode',
        name: 'Dein Spitzname:',
        email: 'Deine E-Mail:',
        memberEmail: 'Mitglied E-Mail:',
        password: 'Dein Passwort:',
        repeatPassword: 'Wiederhole dein Passwort:',
        currentPassword: 'Dein derzeitiges Passwort:',
        verificationCode: 'Bestätigungscode (E-Mail):',
        checkEmailsCode: 'Wir haben dir einen Zugangscode per E-Mail zugesendet. Überprüfe deinen Posteingang! Schau ggf. auch im Spam-Ordner nach.',
        signUpConfirmed: 'Registrierung abgeschlossen! Du kannst dich nun anmelden.',
        resendVerificationCode: 'Verfikationscode erneut senden?',
        newPassword: 'Dein neues Passwort:',
        repeatNewPassword: 'Wiederhole dein neues Passwort:',
        emailNotValid: 'E-Mail erfüllt nicht das Format (name@email.com)',
        code6Numbers: 'Code besteht aus 6 Zahlen',
        couponExact8Chars: 'Code muss genau 8 Zeichen lang sein',
        requiredField: 'Eingabe wird benötigt',
        passwordMin: 'Passwort muss mindestens 8 Ziffern umfassen',
        passwordMax: 'Passwort darf nicht länger als 99 Ziffern lang sein',
        passwordLowercase: 'mind. eine klein geschriebene Ziffer benötigt',
        passwordUppercase: 'mind. eine groß geschriebene Ziffer benötigt',
        passwordNumber: 'mind. eine Zahl benötigt',
        passwordSpecial: 'mind. ein Sonderzeichen dieser Auswahl:',
        passwordNewMustUnique: 'neues Passwort darf nicht dem Alten entsprechen',
        passwordReenterExactly: 'Bitte wiederhole dein Passwort',
        DefaultException: 'Es ist ein Fehler aufgetreten. Bitte versuche es erneut.',
        Error: 'Es ist ein Fehler aufgetreten. Bitte versuche es erneut.',
        InvalidParameterException: 'Es ist ein Fehler aufgetreten. Bitte versuche es erneut.',
        UserNotFoundException: 'Diese Konto existiert nicht. Bitte leg dir ein neues Konto an.',
        UserNotConfirmedException: 'Deine E-Mail wurde noch nicht verifiziert. Bitte fordere erneut einen Code an!',
        UserLambdaValidationException: 'Der ReCaptcha ist abgelaufen. Bitte versuche es erneut.',
        NotAuthorizedException: 'Deine angegebene E-Mail oder Passwort stimmen nicht mit unseren Daten überein.',
        CodeMismatchException: 'Deine angegebene E-Mail oder Code stimmen nicht mit unseren Daten überein.',
        ExpiredCodeException: 'Der von dir angegebene Code ist abgelaufen. Bitte forder einen neuen Code an.',
        UsernameExistsException: 'Es besteht bereits ein Konto für die angegebene E-Mail. Bitte melde dich an. Wenn du dein Passwort vergessen hast, kannst du dein Passwort zurücksetzen lassen (Bilschirm: Anmeldung).',
        LimitExceededException: 'Zu viele Anfragen. Bitte versuche es später erneut.',
        QuotaExceededError: 'Zu viele Anfragen. Bitte versuche es später erneut.',
        TypeError: 'Es ist ein Fehler aufgetreten. Bitte versuche es erneut.',
        settings: 'Einstellungen',
        quizzes: 'Quizze',
        podcast: 'Podcast',
        story: 'Story',
        minutes: 'Minuten',
        translate: 'übersetzen',
        changeTo: 'wechseln auf',
        storyMode: 'Story-Modus',
        textMode: 'Text-Modus',
        textAlign: 'Textausrichtung',
        textAlignCenter: 'zentriert',
        textAlignLeft: 'links',
        textAlignRight: 'rechts',
        fontSize: 'Schriftgröße',
        exit: 'beenden',
        showQuestion: 'Frage beantworten',
        check: 'überprüfen',
        quizWrong: 'Leider falsch.',
        quizRight: 'Super, korrekt beantwortet!',
        quizWrongAlready: 'Bereits falsch beantwortet.',
        quizRightAlready: 'Bereits richtig beantwortet.',
        textQuizLabel: 'Deine Antwort:',
        textQuizLabelRight: 'Richtige Antwort:',
        textQuizPlaceholder: 'Antwort',
        textQuizMax: 'Antwort darf nicht länger als 99 Ziffern lang sein',
        storyDone: 'Geschafft',
        storyDoneRight: 'richtig',
        storyDoneWrong: 'falsch',
        notARobot: 'Ich bin kein Roboter.',
        imprint: 'Impressum',
        privacy: 'Datenschutzerklärung',
        cookiePolicy: 'Cookie-Richtlinie',
        agbHint: 'Es gelten unsere Allgemeinen Geschäftsbedingungen (AGB).',
        colorSelector: 'Wähle deine Farbe',
        followUs: 'Folge uns auf',
        signInMail: 'E-Mail & Passwort',
        resetLanguage: 'zurücksetzen auf:',
        premiumCardTitle: 'u1Dent+ | Premium-Zugang',
        premiumCardDescription: 'Schalte alle Stories (+ Übersetzungen in über 50 Sprachen), alle Podcasts und über 950 Quizze frei.',
        premiumScreenDescription: 'Erhalte Premium-Zugang:\n Schaltet alle Funktionen, Zugriff auf alle Lektionen und mehr frei.',
        support: 'Support',
        subscription: 'Abonnement',
        back: 'zurück',
        overview: 'Übersicht',
        networkError: 'Internetverbindung benötigt!',
        networkErrorMessage: 'Zur Aktualisierung der Daten wird eine aktive Internetverbindung benötigt. Versuche es bitte erneut, wenn du wieder mit dem Internet verbunden bist.',
        source: 'Quelle',
        licenses: 'Lizenzen',
        perks: 'Vorteile',
        showPerks: 'Vorteile anzeigen',
        perkList: {
            1: {
                title: 'Alle Stories',
                description: 'Zugriff auf alle Stories freischalten.',
            },
            2: {
                title: 'Alle Podcasts',
                description: 'Zugriff auf alle Podcasts freischalten.',
            },
            3: {
                title: 'Alle Quizze',
                description: 'Zugriff auf über 900 Quizze freischalten.',
            },
            4: {
                title: 'Übersetzungen',
                description: 'Übersetzungen in über 50 Sprachen.',
            },
            5: {
                title: 'Synchronisierung',
                description: 'Synchronisiere deinen Fortschritt mit all deinen Geräten.',
            },
            6: {
                title: 'Updates',
                description: 'Erhalte regelmäßig Updates und bleib auf dem neusten Stand.',
            },
            7: {
                title: 'Team-Manager',
                description: 'Mit einem Team-Abonnement kannst du dein Team verwalten und Mitglieder hinzufügen.',
            }
        },
        abonnementDescriptionTeam: 'Mit diesem Team-Abonnement kannst du für dich und/oder Team-Mitgliedern einen Premium-Zugang freischalten als auch Team-Mitglieder eines Teams verwalten.',
        aboonementDescriptionIndividual: 'Mit diesem Individual-Abonnement erhälst du einen Premium-Zugang.',
        order: 'Bestellen',
        orderSelection: 'Kostenpflichtig bestellen',
        continue: 'weiter',
        OneMonth: '1 Monat',
        ThreeMonths: '3 Monate',
        SixMonths: '6 Monate',
        TwelveMonths: '12 Monate',
        subcribe: 'Abonnieren',
        teamLicenseQuantity: 'Lizenzen für Team-Mitglieder',
        couponCode: 'Gutscheincode',
        apply: 'anwenden',
        couponNotExist: 'Der angegebene Gutscheincode existiert nicht.',
        reset: 'zurücksetzen',
        teamName: 'Dein Team-Name:',
        teamNamePlaceholder: 'Team-Name eingeben...',
        teamNameMin: 'Team-Name muss mindestens 3 Ziffern umfassen',
        teamNameMax: 'Team-Name darf nicht länger als 255 Ziffern lang sein',
        changeTeamName: 'Namen ändern',
        expiryDate: 'Gültig bis:',
        member: 'Mitglied',
        members: 'Mitglieder',
        addMember: 'Mitglied hinzufügen',
        manageSubscription: 'Abonnement verwalten',
        removeMember: 'Mitglied entfernen',
        removeMemberAlert: 'Möchtest du wirklich dein Mitglied aus dem Team entfernen?',
        cancel: 'Abbrechen',
        confirm: 'Bestätigen',
        addmember: 'Mitglied hinzufügen',
        memberName: 'Mitglied-Name vergeben:',
        memberNamePlaceholder: 'Mitglied-Name',
        memberNameMin: 'Mitglied-Name muss mindestens 3 Ziffern umfassen',
        memberNameMax: 'Mitglied-Name darf nicht länger als 255 Ziffern lang sein',
        memberStatusCardTitle: 'Du bist Team-Mitglied!',
        memberStatusCardTextExplain: 'Solange du Team-Mitglied bist, hast du Zugriff auf alle Premium-Funktionen von u1Dent+ | Premium-Zugang. Wenn der Team-Administrator dich aus dem Team entfernt, verlierst du den Zugriff auf alle Premium-Funktionen, kannst u1Dent.app aber noch im Rahmen des Basis-Accounts nutzen.',
        memberStatusCardTextLeave: 'Möchtest du aus dem Team entfernt werden, spreche mit deinem Team-Administrator.',
        addMemberExplain: 'Mitglieder können deinem Team hinzugefügt werden, wenn sie bereits ein Konto in u1Dent.app haben. Die Registrierung für ein Basiskonto ist kostenlos. Sie können nicht hinzugefügt werden, wenn sie bereits Premium gekauft haben oder Mitglieder in einem anderen Team sind.',
        ProgressSharing: 'Fortschritt teilen',
        ProgressSharingDescription: 'Wenn aktiviert, wird dein Lernfortschritt mit deinem Team-Administrator geteilt.',
        ProgressSharingDisable: 'Möchtest du die Fortschrittsmitteilung wirklich deaktivieren?',
        PurchaseSuccessTitle: 'Vielen Dank, dass du dich für u1Dent+ entschieden hast!',
        PurchaseSuccessDelayed: 'Sobald wir deine Zahlung erhalten haben, schalten wir deinen Zugang frei und du erhältst von uns eine Benachrichtigung per E-Mail. Bis dahin verbleibt dein Account im kostenlosen Basismodus.',
        resetCache: 'Cache zurücksetzen',
        monthly: 'monatlich',
        perMember: 'pro Mitglied',
        pricingSupport: 'Erfahre mehr über unsere Preise',
        restoreSubscription: 'Abonnement wiederherstellen',
        buyAsCompany: 'Als Firma einkaufen',
        tryOtherMail: 'Andere Email angeben',
        showSource: 'Quelle anzeigen',
        bzaekHint: 'Unsere Inhalte orientieren sich an den Vorgaben der Verordnung über die Berufsausbildung zum Zahnmedizinischen Fachangestellten und zur Zahnmedizinischen Fachangestellten (ZahnmedAusbV).',
        includesTaxes: 'inkl. MwSt.',
        completeInApp: 'In der App abschließen',
        reportProblem: 'Problem melden'
    },
}

