import React, { useRef, useEffect } from 'react';
import { View, Animated, useWindowDimensions, Platform, StyleSheet } from 'react-native';

import '@expo/match-media'
import { useMediaQuery } from "react-responsive";

import Button from './Button';
import Text from './Text';
import { Icons } from './Icon';

import { Colors } from '../utils/hexProvider';

type SizeObject = {
    maxWidth?: number,
    minWidth?: number,
    query?: string
}

let mobile: SizeObject = { maxWidth: 599 }
let tablet: SizeObject = { minWidth: 600, maxWidth: 999 }
let desktop: SizeObject = { minWidth: 1000 }
let portrait: SizeObject = { query: "(orientation: portrait)" };

if (Platform.OS !== 'web') {
    mobile = { query: `(max-device-width: ${mobile.maxWidth}px)` }
    tablet = { query: `(min-device-width: ${tablet.minWidth}px) and (max-device-width: ${tablet.maxWidth}px)` }
    desktop = { query: `(min-device-width: ${desktop.minWidth}px)` }
}

type ButtonProps = {
    icon: {
        icon: Icons,
        color: Colors
    },
    onPress: (() => void)
}

type Props = {
    buttonLeft?: ButtonProps;
    title?: string;
    buttonRigth?: ButtonProps;
}

const Header = ({
    buttonLeft,
    title,
    buttonRigth
}: Props) => {
    const animatedOpacityText = useRef(new Animated.Value(0)).current;
    const animatedYValueText = useRef(new Animated.Value(0)).current;

    const animatedOpacityButtonLeft = useRef(new Animated.Value(0)).current;
    const animatedXValueButtonLeft = useRef(new Animated.Value(0)).current;

    const animatedOpacityButtonRight = useRef(new Animated.Value(0)).current;
    const animatedXValueButtonRight = useRef(new Animated.Value(0)).current;

    const yValueText = animatedYValueText.interpolate({
        inputRange: [0, 1],
        outputRange: [8, 0]
    })

    const xValueButtonLeft = animatedXValueButtonLeft.interpolate({
        inputRange: [0, 1],
        outputRange: [0, -8]
    })

    const xValueButtonRight = animatedXValueButtonRight.interpolate({
        inputRange: [0, 1],
        outputRange: [0, 8]
    })

    useEffect(() => {
        Animated.sequence([
            Animated.delay(250),
            Animated.parallel([
                Animated.timing(animatedOpacityText, {
                    toValue: 1,
                    duration: 400,
                    useNativeDriver: true
                }),
                Animated.timing(animatedYValueText, {
                    toValue: 1,
                    duration: 400,
                    useNativeDriver: true
                }),
                Animated.timing(animatedOpacityButtonLeft, {
                    toValue: 1,
                    duration: 400,
                    useNativeDriver: true
                }),
                Animated.timing(animatedXValueButtonLeft, {
                    toValue: 1,
                    duration: 400,
                    useNativeDriver: true
                }),
                Animated.timing(animatedOpacityButtonRight, {
                    toValue: 1,
                    duration: 400,
                    useNativeDriver: true
                }),
                Animated.timing(animatedXValueButtonRight, {
                    toValue: 1,
                    duration: 400,
                    useNativeDriver: true
                }),
            ])
        ]).start();
    }, [])

    const window = useWindowDimensions();

    // const isMobile = useMediaQuery(mobile);
    const isTablet = useMediaQuery(tablet);
    const isDesktop = useMediaQuery(desktop);
    const isPortrait = useMediaQuery(portrait);


    const specific = () => {
        if (isTablet && isPortrait) {
            return [styles.mediumSpecific];
        }
        if (isDesktop || isTablet) {
            return [styles.bigSpecific];
        }
        return styles.smallSpecific;
    }


    return (
        <View style={[styles.responsiveContainer]}>
            <View style={[styles.container, specific()]}>
                <View style={styles.headerContainer}>
                    {buttonLeft === undefined ?
                        <View style={{ height: 64, width: 64 }} />
                        :
                        <View style={{
                            flexShrink: 1,
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>
                            <Animated.View style={{
                                flex: 1,
                                justifyContent: 'center',
                                alignItems: 'center',
                                opacity: animatedOpacityButtonLeft,
                                transform: [{
                                    translateX: xValueButtonLeft
                                }]
                            }}>
                                <Button
                                    style={{ height: 64, width: 64, borderRadius: 64 / 2 }}
                                    type='clear'
                                    icon={{ icon: buttonLeft.icon.icon, color: buttonLeft.icon.color, style: undefined }}
                                    onPress={buttonLeft.onPress} />
                            </Animated.View>
                        </View>
                    }
                    <View style={{
                        flex: 1,
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <Animated.View style={{
                            flex: 1,
                            justifyContent: 'center',
                            alignItems: 'center',
                            opacity: animatedOpacityText,
                            transform: [
                                { translateY: yValueText }
                            ]
                        }}>
                            <Text type='h1' style='bold' textAlign='center' color='text'>{title}</Text>
                        </Animated.View>
                    </View>
                    {buttonRigth === undefined ?
                        <View style={{ height: 64, width: 64 }} />
                        :
                        <View style={{
                            flexShrink: 1,
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>
                            <Animated.View style={{
                                flex: 1,
                                justifyContent: 'center',
                                alignItems: 'center',
                                opacity: animatedOpacityButtonRight,
                                transform: [{
                                    translateX: xValueButtonRight
                                }]
                            }}>
                                <Button
                                    style={{ height: 64, width: 64, borderRadius: 64 / 2 }}
                                    type='clear'
                                    icon={{ icon: buttonRigth.icon.icon, color: buttonRigth.icon.color, style: undefined }}
                                    onPress={buttonRigth.onPress} />
                            </Animated.View>
                        </View>
                    }
                </View>
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    responsiveContainer: {
        flex: 1,
        width: '100%',
        alignItems: 'center'
    },
    container: {
        flex: 1,
        width: '100%',
        justifyContent: 'center'
    },
    headerContainer: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    smallSpecific: {
        margin: 8,
    },
    mediumSpecific: {
        overflow: 'hidden',
        margin: 16,
        maxWidth: 599,
    },
    bigSpecific: {
        overflow: 'hidden',
        margin: 16,
        maxWidth: 599,
    }
});

export default Header;