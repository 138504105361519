import React from 'react';
import { View } from 'react-native';

import { Divider as D } from 'react-native-elements';

import { hexProvider } from '../utils/hexProvider';

const Divider = () => {
    return (
        <View
            style={{
                padding: 8
            }}
        >
            <D
                color={hexProvider('text')}
            />
        </View>
    );
}

export default Divider;