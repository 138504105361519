import React, { useState, useEffect } from 'react';
import { StyleSheet, View } from 'react-native';

import * as yup from 'yup';

import { queryAPI } from '../services/api/data';
import { hexProvider } from '../utils/hexProvider';
import { translate } from '../services/localization/translations';

import TextInput from './TextInput';
import Button from './Button';

import TeamType, { TeamUserType } from "../models/data/TeamType";
import { User as UserType } from "../models/data/UserType";

type Props = {
    team: Team | undefined;
    getTeam: () => Promise<void>;
};

type TeamUser = TeamUserType & { quizProgessSharing?: UserType['quizProgessSharing'] | undefined };
type Team = Omit<TeamType, 'users'> & { users?: undefined | TeamUser[] };

const TeamNameCard = ({
    team,
    getTeam
}: Props) => {

    const [rendered, setRendered] = useState<boolean>(false);
    const [submitted, setSubmitted] = useState<boolean>(false);
    const [submitPossible, setSubmitPossible] = useState<boolean>(false);
    const [newTeamName, setNewTeamName] = useState<string>('');
    const [newTeamNameErr, setNewTeamNameErr] = useState<string | undefined>(undefined);
    const [teamNameSubmissionError, setTeamNameSubmissionError] = useState<string | undefined>(undefined);

    useEffect(() => {
        setRendered(true)
    }, [])

    const changeTeamName = async () => {
        try {
            const data = await queryAPI('setTeamName', { name: newTeamName }, { refresh: true });
            const response = JSON.parse(data);
            if (response.statusCode === 200) {
                await getTeam();
                handleResetChangeTeamName();
                return;
            }
            setTeamNameSubmissionError(response.body);
        } catch (error) {
            console.log(error);
        }
    }

    const handleChangeTeamName = () => {
        setSubmitted(true);
        changeTeamName()
    }

    const handleResetChangeTeamName = () => {
        setSubmitPossible(false);
        setSubmitted(false);

        setNewTeamName('')

        setNewTeamNameErr(undefined);
        setTeamNameSubmissionError(undefined);
    }

    let validationSchema = yup.object().shape({
        teamName: yup.string()
            .required(translate('requiredField'))
            .min(3, translate('teamNameMin'))
            .max(255, translate('teamNameMax')),
    });

    rendered && validationSchema
        .validate({
            teamName: newTeamName,
        }, { abortEarly: true, strict: true })
        .then(() => {
            setNewTeamNameErr(undefined);

            setSubmitPossible(true);
        })
        .catch(err => {
            setNewTeamNameErr(err.path === 'teamName' ? err.message : undefined);

            setSubmitPossible(false);
        })

    const style = StyleSheet.create({
        container: {
            margin: 4,
            padding: 8,
            borderRadius: 8 / 1,
            backgroundColor: hexProvider('grey')
        }
    });

    return (
        <View
            style={[style.container]}
        >
            <TextInput
                label={translate('teamName')}
                placeholder={team?.teamname ? team.teamname : translate('teamNamePlaceholder')}
                disabled={submitted}
                submitable={submitPossible}
                errorMessage={newTeamName === '' ? undefined : newTeamNameErr}
                secureTextEntry={false}
                icon='users'
                onChangeText={value => setNewTeamName(value)}
                value={newTeamName}
                type='name'
            />
            <Button
                title={teamNameSubmissionError ? translate('tryAgainPassword') : translate('changeTeamName')}
                bold
                type='clear'
                color={teamNameSubmissionError ? 'error' : 'primary'}
                textColor={teamNameSubmissionError ? 'error' : 'primary'}
                style={{ height: 56, borderRadius: 56 / 4, marginVertical: 8 }}
                icon={teamNameSubmissionError ?
                    {
                        icon: 'repeat',
                        color: teamNameSubmissionError ? 'error' : 'primary',
                        style: { padding: 8 }
                    }
                    : undefined}
                disabled={teamNameSubmissionError ? false : !submitPossible || submitted}
                onPress={teamNameSubmissionError ? handleResetChangeTeamName : handleChangeTeamName}
            />
        </View>
    );
}

export default TeamNameCard;