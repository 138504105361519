import React from 'react';
import { View, ViewStyle } from 'react-native';

import Text from './Text';
import Icon, { Icons } from './Icon';

import { hexProvider } from '../utils/hexProvider';

type Props = {
    title: string;
    description: string;
    icon: Icons;
    style: ViewStyle | ViewStyle[];
};

const FeatureListCard = ({
    title,
    description,
    icon,
    style
}: Props) => {

    let bgcolorContainer = hexProvider('grey');

    return (
        <View
            style={style}
        >
            <View
                style={{
                    flex: 1,
                    borderRadius: 8 / 1,
                    padding: 8,
                    backgroundColor: bgcolorContainer,
                    justifyContent: 'space-around'
                }}
            >
                <View
                    style={{
                        flex: 1,
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        padding: 8
                    }}
                >
                    <Icon
                        icon={icon}
                        color='text'
                        iconStyle={{
                            fontSize: 28
                        }}
                    />
                    <View
                        style={{
                            flex: 1,
                            paddingLeft: 16
                        }}
                    >
                        <Text
                            color='text'
                            style='bold'
                            type='h5'
                            textAlign='left'>
                            {title}
                        </Text>
                        <Text
                            color='text'
                            style='normal'
                            type='h5'
                            textAlign='left'>
                            {description}
                        </Text>
                    </View>
                </View>
            </View>
        </View>
    );
};

export default FeatureListCard;