import React from 'react';
import { ActivityIndicator } from 'react-native';

import { hexProvider, Colors } from '../utils/hexProvider';

type Props = {
    color?: Colors
}

const LoadingIndicator = ({ color = 'primary' }: Props) => {
    return (
        <ActivityIndicator color={hexProvider(color)} />
    );
};

export default LoadingIndicator;