import React from 'react';
import { View, StyleSheet } from 'react-native';

import { useNavigation } from '@react-navigation/native';

import Text from './Text';
import Button from './Button';

import { hexProvider } from '../utils/hexProvider';
import { translate } from '../services/localization/translations';

const ProductFeatureLinkCard = () => {

    const navigation = useNavigation();

    const styles = StyleSheet.create({
        container: {
            margin: 4,
            padding: 8,
            borderRadius: 8 / 1,
            backgroundColor: hexProvider('grey'),
            justifyContent: 'space-around',
        }
    });

    return (
        <View
            style={styles.container}
        >
            <View style={{ paddingVertical: 8 }} />
            <Text
                type='h5'
                textAlign='center'
                color='text'
            >{translate('premiumScreenDescription')}</Text>
            <Button
                textType='h5'
                type='clear'
                textColor='primary'
                textAlign='center'
                title={translate('showPerks')}
                style={{
                    height: 48,
                    margin: 4
                }}
                //@ts-ignore
                onPress={() => navigation.navigate('FeaturesScreen')}
            />
        </View>
    );
}

export default ProductFeatureLinkCard;