import * as React from 'react';

import { Hub } from 'aws-amplify';

import { queryAPI, updateAPI } from '../api/data';
import { storeData, getData } from '../store/store';
import { getUser as getCurrentUser } from '../api/auth'

import { reducer } from '../reducers/reducer';
import { ReducerActions, ReducerState } from '../../models/reducers/ReducerType';
import { errorDispatcher } from '../../utils/errorDispatcher';

import { mutationTypes } from '../../models/api/mutationTypes';
import { User as UserType } from '../../models/data/UserType';

type State = ReducerState;
type Dispatch = (action: ReducerActions) => void;
type UserProviderProps = { children: React.ReactNode };

const UserStateContext = React.createContext<State | undefined>(undefined);
const UserDispatchContext = React.createContext<Dispatch | undefined>(
    undefined,
);

const initialState = { isLoading: false, data: undefined };

function UserProvider({ children }: UserProviderProps) {
    const [state, dispatch] = React.useReducer(reducer, initialState);

    return (
        <UserStateContext.Provider value={state}>
            <UserDispatchContext.Provider value={dispatch}>
                {children}
            </UserDispatchContext.Provider>
        </UserStateContext.Provider>
    )
}

function useUserState() {
    const context = React.useContext(UserStateContext)
    if (context === undefined) {
        throw new Error('useUserState must be used within a UserProvider')
    }
    return context
}

function useUserDispatch() {
    const context = React.useContext(UserDispatchContext)
    if (context === undefined) {
        throw new Error('useUserDispatch must be used within a UserProvider')
    }
    return context
}

async function getUser(dispatch: Dispatch, refresh?: boolean) {
    dispatch({ type: 'REQUEST' });
    try {
        const { username } = await getCurrentUser();

        const user = await queryAPI('getUser', { id: username }, { refresh: refresh })
        dispatch({ type: 'SUCCESS', data: user })
    } catch (error) {
        //@ts-ignore
        errorDispatcher(error, dispatch);
    }
}

async function updateUser(dispatch: Dispatch, input: mutationTypes['updateUser']['variablesType']['input']) {
    dispatch({ type: 'REQUEST' });
    try {
        const { username } = await getCurrentUser();

        await updateAPI('updateUser', { ...input, id: username });
        const user = await queryAPI('getUser', { id: username }, { refresh: true, mutationQuery: 'updateUser' })
        dispatch({ type: 'SUCCESS', data: user })
    } catch (error) {
        //@ts-ignore
        errorDispatcher(error, dispatch);
    }
}

async function currentProclaimedPremium() {
    try {
        const { username } = await getCurrentUser();
        const user: UserType | undefined = await queryAPI('getUser', { id: username }, { refresh: true })

        return user?.premium;

    } catch (error) {
        console.log(error);
    }
}

type PremiumStatusResponseType = {
    statusCode: number;
    body: {
        premium: boolean;
        changed: boolean;
        message: string;
    }
}
async function premiumStatusChange(): Promise<boolean | void> {
    try {

        const store = await getData('savedPremiumStatus');

        const data = await queryAPI('premiumStatus', {}, { refresh: true });
        const response: PremiumStatusResponseType = JSON.parse(data);

        if (response.statusCode === 200 && (response.body.premium !== store || response.body.changed === true)) {
            (response.body.premium !== undefined) && await storeData('savedPremiumStatus', response.body.premium);
            store !== null && Hub.dispatch(
                'premium',
                {
                    event: 'premiumChange',
                    data: undefined,
                    message: undefined
                });
            return;
        }
        return false;
    } catch (error) {
        console.log(error);
    }
}

export {
    UserProvider,
    useUserState,
    useUserDispatch,
    getUser,
    updateUser,
    currentProclaimedPremium,
    premiumStatusChange
};