import React, { useEffect, useRef, useState } from 'react';
import { Animated, View } from 'react-native';

import * as yup from 'yup';

import Text from './Text';
import Icon from './Icon';
import TextInput from './TextInput';
import Confetti from './Confetti';
import Button from './Button';

import { hexProvider, shadowProvider } from '../utils/hexProvider';
import { translate } from '../services/localization/translations';
import { sameStrings } from '../utils/helpers';

type Props = {
    quizId: string;
    answer: string;
    rightQuizzesState: string[];
    wrongQuizzesState: string[];
    setRightQuizzesState: React.Dispatch<React.SetStateAction<string[]>>
    setWrongQuizzesState: React.Dispatch<React.SetStateAction<string[]>>
};

export const TextQuizCard = ({
    quizId,
    answer,
    rightQuizzesState,
    wrongQuizzesState,
    setRightQuizzesState,
    setWrongQuizzesState,
}: Props) => {
    const [firstRenderDone, setFirstRenderDone] = useState<boolean>(false);

    const [submitted, setSubmitted] = useState<boolean>(false);
    const [submitPossible, setSubmitPossible] = useState<boolean>(false);

    const [answeredCorrrect, setAnsweredCorrect] = useState<boolean | undefined>(undefined);
    const [showConfetti, setShowConfetti] = useState<boolean>(false);
    const [showAnswer, setShowAnswer] = useState<boolean>(false);

    const [userAnswer, setUserAnswer] = useState<string>('');
    const [userAnswerErr, setUserAnswerErr] = useState<string | undefined>(undefined);


    useEffect(() => {
        if (firstRenderDone) {
            if (answeredCorrrect === true) {
                setShowConfetti(true);
                const timeOut = setTimeout(() => setShowConfetti(false), 2000);
                return () => {
                    clearTimeout(timeOut);
                };
            }
        } else {
            setFirstRenderDone(true);
        }
    }, [answeredCorrrect])

    let validationSchema = yup.object().shape({
        userAnswer: yup.string()
            .required(translate('requiredField'))
            .min(1, translate('requiredField'))
            .max(200, translate('textQuizMax'))
            .trim()
            .lowercase(),
    });

    validationSchema
        .validate({
            userAnswer: userAnswer,
        }, { abortEarly: true })
        .then(() => {
            setUserAnswerErr(undefined);
            setSubmitPossible(true);
            !submitted && showButtonAnimation();
        })
        .catch(err => {
            setUserAnswerErr(err.path === 'userAnswer' ? err.message : undefined);
            setSubmitPossible(false);
            !submitted && hideButtonAnimation();
        })

    const animatedOpacityValueButton = useRef(new Animated.Value(0)).current;
    const animatedYValueButton = useRef(new Animated.Value(0)).current;

    const animatedOpacityValueText = useRef(new Animated.Value(0)).current;
    const animatedYValueText = useRef(new Animated.Value(0)).current;

    const animatedOpacityValueAnswerButton = useRef(new Animated.Value(0)).current;
    const animatedXValueAnswerButton = useRef(new Animated.Value(0)).current;

    const yValButton = animatedYValueButton.interpolate({
        inputRange: [0, 1],
        outputRange: [100, 0]
    });

    const yValtext = animatedYValueText.interpolate({
        inputRange: [0, 1],
        outputRange: [100, 1]
    });

    const xValAnswerButton = animatedXValueAnswerButton.interpolate({
        inputRange: [0, 1],
        outputRange: [-72, 0]
    });

    const showButtonAnimation = () => {
        Animated.parallel([
            Animated.timing(animatedOpacityValueButton, {
                toValue: 1,
                duration: 500,
                useNativeDriver: true
            }),
            Animated.timing(animatedYValueButton, {
                toValue: 1,
                duration: 500,
                useNativeDriver: true
            })
        ]).start()
    }

    const hideButtonAnimation = () => {
        Animated.parallel([
            Animated.timing(animatedOpacityValueButton, {
                toValue: 0,
                duration: 500,
                useNativeDriver: true
            }),
            Animated.timing(animatedYValueButton, {
                toValue: 0,
                duration: 500,
                useNativeDriver: true
            })
        ]).start()
    }

    const hideTextAnimation = () => {
        Animated.parallel([
            Animated.timing(animatedOpacityValueText, {
                toValue: 0,
                duration: 250,
                useNativeDriver: true
            }),
            Animated.timing(animatedYValueText, {
                toValue: 0,
                duration: 250,
                useNativeDriver: true
            })
        ]).start()
    }

    const showTextAnimation = () => {
        Animated.parallel([
            Animated.timing(animatedOpacityValueText, {
                toValue: 1,
                duration: 250,
                useNativeDriver: true
            }),
            Animated.timing(animatedYValueText, {
                toValue: 1,
                duration: 250,
                useNativeDriver: true
            })
        ]).start()
    }

    const showAnswerButtonAnimation = () => {
        Animated.parallel([
            Animated.timing(animatedOpacityValueAnswerButton, {
                toValue: .8,
                duration: 250,
                useNativeDriver: true
            }),
            Animated.timing(animatedXValueAnswerButton, {
                toValue: 1,
                duration: 250,
                useNativeDriver: true
            })
        ]).start()
    }

    const toggleAnswerButtonAnimation = () => {
        Animated.parallel([
            Animated.timing(animatedOpacityValueAnswerButton, {
                toValue: 0,
                duration: 250,
                useNativeDriver: true
            }),
            Animated.timing(animatedXValueAnswerButton, {
                toValue: 0,
                duration: 250,
                useNativeDriver: true
            })
        ]).start(() => setShowAnswer(showAnswer ? false : true));
        Animated.sequence([
            Animated.delay(500),
            Animated.parallel([
                Animated.timing(animatedOpacityValueAnswerButton, {
                    toValue: .8,
                    duration: 250,
                    useNativeDriver: true
                }),
                Animated.timing(animatedXValueAnswerButton, {
                    toValue: 1,
                    duration: 250,
                    useNativeDriver: true
                })
            ])
        ]).start();
    }

    const handleSubmitAnimation = () => {
        Animated.sequence([
            Animated.parallel([
                Animated.timing(animatedOpacityValueButton, {
                    toValue: 0,
                    duration: 500,
                    useNativeDriver: true
                }),
                Animated.timing(animatedYValueButton, {
                    toValue: 0,
                    duration: 500,
                    useNativeDriver: true
                })
            ]),
            Animated.parallel([
                Animated.timing(animatedOpacityValueText, {
                    toValue: 1,
                    duration: 500,
                    useNativeDriver: true
                }),
                Animated.timing(animatedYValueText, {
                    toValue: 1,
                    duration: 500,
                    useNativeDriver: true
                })
            ]),
        ]).start();
    }

    const successColor = hexProvider('success');
    const errorColor = hexProvider('error');
    const greyColor = hexProvider('grey');

    const shadow = shadowProvider();

    const handleSubmitAnswer = () => {
        setSubmitted(true);
        handleSubmitAnimation();
        if (sameStrings(answer, userAnswer)) {
            setAnsweredCorrect(true);
            setRightQuizzesState([...rightQuizzesState, quizId]);
        } else {
            setAnsweredCorrect(false);
            setWrongQuizzesState([...wrongQuizzesState, quizId]);
            showAnswerButtonAnimation();
        }
    }

    const handleShowAnswer = () => {
        if (!showAnswer) {
            hideTextAnimation();
            toggleAnswerButtonAnimation();
        } else {
            showTextAnimation();
            toggleAnswerButtonAnimation();
        }
    }

    return (
        <View
            style={{
                flex: 1,
                borderRadius: 8 / 1,
                overflow: 'hidden',
                backgroundColor: greyColor
            }}
        >
            {showConfetti && <Confetti
                icon='check-circle'
                timeInMs={2000}
                zIndex={3}
            />}
            <Animated.View
                style={{
                    flexShrink: 1,
                    position: "absolute",
                    zIndex: 1,
                    top: 0,
                    right: 0,
                    left: 0,
                    alignItems: 'center',
                    justifyContent: 'center',
                    opacity: animatedOpacityValueAnswerButton,
                    transform: [{
                        translateY: xValAnswerButton
                    }]
                }}>
                <Button
                    type='solid'
                    color={!showAnswer ? 'success' : 'error'}
                    icon={{
                        icon: !showAnswer ? 'check-circle' : 'times-circle',
                        color: 'white',
                        style: {
                            padding: 1
                        }
                    }}
                    style={{
                        height: 64,
                        width: 64,
                        borderRadius: 64 / 2,
                        margin: 8
                    }}
                    onPress={() => handleShowAnswer()} />
            </Animated.View>
            <View
                style={{
                    flex: 1,
                    justifyContent: 'center',
                }}>
                <View
                    style={[{
                        flexShrink: 1,
                        borderRadius: 8 / 1,
                        overflow: 'hidden',
                        backgroundColor: greyColor,
                        padding: 16,
                        margin: 10,
                        borderColor: submitted ?
                            showAnswer ?
                                successColor :
                                answeredCorrrect ?
                                    successColor :
                                    errorColor
                            : undefined,
                        borderWidth: submitted ? 4 : undefined,
                    }, shadow]}>
                    <View
                        style={{
                            flexShrink: 1,
                            flexDirection: 'row'
                        }}>
                        <View
                            style={{ flex: 1 }}>
                            <TextInput
                                label={showAnswer ? translate('textQuizLabelRight') : translate('textQuizLabel')}
                                placeholder={translate('textQuizPlaceholder')}
                                disabled={submitted}
                                submitable={submitPossible}
                                submitableColor={submitted ?
                                    showAnswer ?
                                        'success' :
                                        answeredCorrrect ?
                                            'success' :
                                            'error'
                                    : 'primary'}
                                errorMessage={userAnswer === '' ? undefined : userAnswerErr}
                                secureTextEntry={false}
                                onChangeText={value => setUserAnswer(value)}
                                value={showAnswer ? answer : userAnswer}
                                type={undefined}
                            />
                        </View>
                        {submitted && <View
                            style={{
                                flexShrink: 1,
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                            <Icon icon={answeredCorrrect ?
                                'check-circle' :
                                showAnswer ?
                                    'check-circle' :
                                    'times-circle'
                            }
                                color={answeredCorrrect ?
                                    'success' :
                                    showAnswer ?
                                        'success' :
                                        'error'
                                }
                                style={{ padding: 1 }} />
                        </View>}

                    </View>
                </View>
            </View>
            <Animated.View
                style={{
                    flexShrink: 1,
                    justifyContent: 'center',
                    alignItems: 'center',
                    alignSelf: 'center',
                    marginBottom: 16,
                    position: 'absolute',
                    bottom: 0,
                    zIndex: 2,
                    opacity: animatedOpacityValueButton,
                    transform: [
                        {
                            translateY: yValButton
                        }
                    ],
                }}>
                <Button
                    title={translate('check')}
                    bold={true}
                    icon={{
                        icon: 'check',
                        color: 'white',
                        style: { padding: 8 }
                    }}
                    type='solid'
                    color='primary'
                    textColor='white'
                    style={{ height: 56, borderRadius: 56 / 4 }}
                    onPress={!submitted ? () => handleSubmitAnswer() : undefined}
                />
            </Animated.View>
            <Animated.View
                style={{
                    flexShrink: 1,
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: 16,
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    zIndex: 1,
                    opacity: animatedOpacityValueText,
                    transform: [
                        {
                            translateY: yValtext
                        }
                    ],
                    backgroundColor: greyColor.concat('CC')
                }}>
                <Text
                    type='h4'
                    style='bold'
                    color={answeredCorrrect ?
                        'success' :
                        'error'} >
                    {answeredCorrrect ?
                        translate('quizRight') :
                        translate('quizWrong')}
                </Text>
            </Animated.View>
        </View>
    );
};

export default TextQuizCard;