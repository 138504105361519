import React from 'react';
import { View } from 'react-native';

import { BlurView } from 'expo-blur';

import { useNavigation } from '@react-navigation/core';

import Text from './Text';
import Card from './Card';
import SourceProvider from './SourceProvider';

import { hexProvider } from '../utils/hexProvider'

type Props = {
    categoryId: string;
    categoryName: string;
    categoryImageUrl: string;
    parentFlexDirectionColumn?: boolean;
};

const CategoryCard = ({
    categoryId,
    categoryName,
    categoryImageUrl,
    parentFlexDirectionColumn = false
}: Props) => {

    let navigation = useNavigation();

    const handleClick = () => {
        navigation.navigate("CategoryScreen", {
            //@ts-ignore
            categoryId: categoryId,
            lessonId: undefined
        })
    }

    return (
        <Card style={{
            flex: 1,
            overflow: 'hidden'
        }}
            imageUrl={categoryImageUrl}
            parentFlexDirectionColumn={parentFlexDirectionColumn}
            onPress={handleClick} >
            <BlurView
                intensity={40}
                tint='dark'
                style={[
                    {
                        flex: 1,
                        alignItems: 'center',
                        justifyContent: 'center',
                    },
                ]}>
                <View style={[
                    {
                        flexShrink: 1,
                        padding: 8,
                        paddingHorizontal: 24,
                        borderRadius: 50 / 2,
                        minHeight: 56,
                        backgroundColor: hexProvider('primary'),
                        justifyContent: 'center'
                    },
                ]}>
                    <Text
                        type='h4'
                        style='bold'
                        color='white'>
                        {categoryName + ' →'}
                    </Text>
                </View>
            </BlurView>
            <SourceProvider
                style={{
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    zIndex: 10,
                    padding: 8
                }}
            />
        </Card >
    );
}

export default CategoryCard;