import React, { useState, useEffect, useRef } from 'react';
import { View } from 'react-native';

import { Translation } from '../models/data/TranslationType';
import { Lesson } from '../models/data/LessonType';
import { Story } from '../models/data/StoryType';
import { ChoiceQuiz as CQ } from '../models/data/ChoiceQuizType';
import { SortQuiz as SQ } from '../models/data/SortQuizType';
import { TextQuiz as TQ } from '../models/data/TextQuizType';
import { ChoiceQuizType, SortQuizType, TextQuizType } from './QuizHandler';

import TapPager from './TapPager';
import QuizHandler from './QuizHandler';
import StoryEndPage from './StoryEndPage';
import TextCard from './TextCard';
import Video from './BasicVideo';
import Audio from './Audio';
import Image from './Image';
import { TextType, TextDirection } from './Text';

import { refactorData, RefactoredDataType, refactorMediaStory, refactorMediaQuizHandler, MediaTypes } from '../utils/refactorData';
import { useNavigation } from '@react-navigation/core';

type QuizData = {
    type: 'quiz',
    data: ({ 'ChoiceQuiz': ChoiceQuiz } | { 'SortQuiz': SortQuiz } | { 'TextQuiz': TextQuiz })[]
};

type StoryData = {
    type: 'story',
    data: Lesson
};

interface ChoiceQuiz extends CQ {
    __typename: 'ChoiceQuiz';
}

interface SortQuiz extends SQ {
    __typename: 'SortQuiz';
}

interface TextQuiz extends TQ {
    __typename: 'TextQuiz';
}


type Props = {
    storyData: QuizData | StoryData;
    translation?: Translation;
    textAlign: Exclude<TextDirection, 'auto'>;
    textType: TextType;
    currentIndex: number;
    totalIndex: number;
    setCurrentIndex: React.Dispatch<React.SetStateAction<number>>;
    setTotalIndex: React.Dispatch<React.SetStateAction<number>>
    rightQuizzesState: string[];
    setRightQuizzesState: React.Dispatch<React.SetStateAction<string[]>>;
    wrongQuizzesState: string[];
    setWrongQuizzesState: React.Dispatch<React.SetStateAction<string[]>>;
    onPressClose: () => void;
    tutorialTabIndicator: boolean;
}

const StoryHandler = ({
    storyData,
    translation,
    textAlign,
    textType,
    currentIndex,
    totalIndex,
    setCurrentIndex,
    setTotalIndex,
    rightQuizzesState,
    setRightQuizzesState,
    wrongQuizzesState,
    setWrongQuizzesState,
    onPressClose,
    tutorialTabIndicator
}: Props) => {
    const data = useRef(refactorData(storyData)).current;

    const navigation = useNavigation();

    const [firstRenderDone, setFirstRenderDone] = useState<boolean>(false);

    const lazyLoadPage = (index: number) => index === currentIndex;

    useEffect(() => {
        let isMounted = true;
        if (!firstRenderDone && isMounted) {
            setTotalIndex(data.length + 1);
            setFirstRenderDone(true);
        }
        return () => { isMounted = false };
    }, [])

    const RenderItem = (item: RefactoredDataType, index: number) => {

        const media: MediaTypes | undefined = item?.media ? refactorMediaStory(item.__typename === 'Story' ? item.media : JSON.stringify(item.media)) : undefined;

        const typeHandler = (item: RefactoredDataType) => {
            if (item.__typename === 'Story') {
                const storyTypeMediaHandler = (media: MediaTypes | undefined): JSX.Element | null => {
                    switch (media?.type) {
                        case 'AudioMedia':
                            return <Audio
                                playlist={[{
                                    title: media.props.description,
                                    url: media.props.url,
                                    type: 'URL'
                                }]} />;
                        case 'ImageMedia':
                            return <Image
                                url={media.props.url}
                                description={media.props.description}
                                square={false}
                                margin={4}
                                //@ts-ignore
                                onPress={() => navigation.navigate('Story', {
                                    screen: 'MediaScreen',
                                    params: {
                                        type: 'IMAGE',
                                        url: media.props.url,
                                        description: media.props.description
                                    }
                                })}
                            />;
                        case 'VideoMedia':
                            return <Video
                                videoUrl={media.props.url}
                            />;
                        default:
                            return null;
                    }
                }

                const handleTranlation = (item: Story, translation: Translation | undefined) => {
                    const translatedText = translation !== undefined ? translation.content.find(c => c.position === index + 1)?.text : undefined;

                    if (translatedText !== undefined) {
                        return translatedText;
                    } else {
                        return item.text;
                    }
                }

                return (
                    <>
                        {storyTypeMediaHandler(media)}
                        <TextCard
                            text={handleTranlation(item, translation)}
                            textType={textType}
                            textAlign={textAlign}
                        />
                    </>
                );
            } else {
                type RefactoredDataTypeExludingStory = Exclude<RefactoredDataType, Story>;
                const quizTypeHandler = (item: RefactoredDataTypeExludingStory): ChoiceQuizType | SortQuizType | TextQuizType => {
                    if (item.__typename === 'ChoiceQuiz') {
                        return {
                            type: item.__typename,
                            props: {
                                answers: item.answers,
                                correct: item.correct
                            }
                        };
                    } else if (item.__typename === 'SortQuiz') {
                        return {
                            type: item.__typename,
                            props: {
                                answers: item.answers
                            }
                        };
                    } else {
                        return {
                            type: item.__typename,
                            props: {
                                answer: item.correct
                            }
                        };
                    }
                }

                return <QuizHandler
                    quizId={item.id}
                    question={item.question}
                    description={item.description}
                    quizType={quizTypeHandler(item)}
                    media={refactorMediaQuizHandler(media)}
                    textType={textType}
                    textAlign={textAlign}
                    rightQuizzesState={rightQuizzesState}
                    wrongQuizzesState={wrongQuizzesState}
                    setRightQuizzesState={setRightQuizzesState}
                    setWrongQuizzesState={setWrongQuizzesState}
                />
            }
        }

        return (
            <View
                key={index}
                style={{
                    flex: 1
                }}
            >
                {typeHandler(item)}
            </View>
        );
    }

    return (
        <View
            style={{
                flex: 1,
                padding: 4
            }}>
            <TapPager
                selectedIndex={currentIndex}
                onSelect={index => setCurrentIndex(index)}
                lazyLoadPage={lazyLoadPage}
                tutorialTabIndicator={tutorialTabIndicator}
            >
                {[
                    ...data.map(RenderItem),
                    <StoryEndPage
                        key={'StoryEndPage'}
                        currentIndex={currentIndex}
                        totalIndex={totalIndex}
                        rightQuizzesState={rightQuizzesState}
                        wrongQuizzesState={wrongQuizzesState}
                        onPressClose={onPressClose}
                    />
                ]}
            </TapPager>
        </View>
    );
}

export default StoryHandler;