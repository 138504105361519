import AsyncStorage from '@react-native-async-storage/async-storage';
import constants from '../../utils/constants';

import { Platform } from 'react-native';

const storeData = async (key: string, value: any) => {
    try {
        const v = value === undefined ? null : value;
        const jsonValue = JSON.stringify(v);
        await AsyncStorage.setItem(key, jsonValue);
    } catch (e) {
        //@ts-ignore
        throw new Error(e);
    }
}


const getData = async (key: string): Promise<any | null> => {
    try {
        const jsonValue = await AsyncStorage.getItem(key)
        return jsonValue !== null ? JSON.parse(jsonValue) : null;
    } catch (e) {
        //@ts-ignore
        throw new Error(e);
    }
}

const clearAppData = async function (excludingAuthKeys: boolean = false): Promise<void> {
    try {
        let keys = await AsyncStorage.getAllKeys();

        if (excludingAuthKeys) {
            keys = keys.filter(key => !key.startsWith(Platform.OS === 'web' ? constants.AUTHSTORAGEKEYWEB : constants.AUTHSTORAGEKEYMOBILE));
            keys = keys.filter(key => !key.startsWith('savedPremiumStatus'));
        }

        await AsyncStorage.multiRemove(keys);
        console.log('Sucess cleaning app data.');
    } catch (error) {
        console.log('Error clearing app data.');
    }
}

export { storeData, getData, clearAppData };