import React, { useRef } from 'react';
import { ViewStyle, Animated } from 'react-native';
import { Button as B } from 'react-native-elements';

import Icon, { Icons, Props as IconProps } from './Icon';

import { hexProvider, Colors } from '../utils/hexProvider';

type ButtonType =
    | "solid"
    | "clear"
    | "outline";

type TextType =
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'p';

type Props = {
    title?: string;
    bold?: boolean;
    textType?: TextType;
    type?: ButtonType;
    icon?: { icon: Icons, color: Colors | undefined, style: IconProps['style'] | undefined }
    iconPosition?: | "top" | "left" | "bottom" | "right";
    color?: Colors | undefined;
    hexColor?: string;
    textColor?: Colors;
    textAlign?: "left" | "right" | "auto" | "center" | "justify";
    disabled?: boolean;
    loading?: boolean;
    touchFeedback?: boolean;
    style?: ViewStyle | ViewStyle[];
    onPress?: (() => void)
};

const Button = ({
    title,
    bold = false,
    textType,
    type,
    icon,
    iconPosition,
    color = 'primary',
    hexColor,
    textColor = 'text',
    textAlign,
    disabled = false,
    loading = false,
    style,
    onPress
}: Props) => {
    const animatedScaleValue = useRef(new Animated.Value(1)).current

    const scaleVal = animatedScaleValue.interpolate({
        inputRange: [0, 1],
        outputRange: [.8, 1]
    })

    const onPressIn = () =>
        Animated.timing(animatedScaleValue, {
            toValue: 0,
            duration: 200,
            useNativeDriver: true
        }).start();

    const onPressOut = () =>
        Animated.timing(animatedScaleValue, {
            toValue: 1,
            duration: 200,
            useNativeDriver: true
        }).start();

    const tType = () => {
        switch (textType) {
            case 'h1':
                return 40;
            case 'h2':
                return 34;
            case 'h3':
                return 28;
            case 'h4':
                return 22;
            case 'h5':
                return 16;
            default:
                return 11;
        }
    };

    return (
        <Animated.View style={{
            flexShrink: 1,
            justifyContent: 'center',
            transform: [
                {
                    scale: scaleVal
                }
            ]
        }}>
            <B
                iconPosition={iconPosition}
                title={title}
                titleProps={{ allowFontScaling: true }}
                titleStyle={[
                    { color: hexProvider(textColor) },
                    textType === undefined ? undefined : { fontSize: tType() },
                    bold ? { fontWeight: 'bold' } : { fontWeight: 'normal' },
                    { textAlign: textAlign }
                ]}
                type={type}
                disabled={disabled}
                loading={loading}
                icon={
                    icon !== undefined ?
                        <Icon
                            icon={icon?.icon !== undefined ? icon.icon : 'star'}
                            color={icon?.color !== undefined ? icon.color : undefined}
                            style={icon?.style !== undefined ? icon.style : undefined} />
                        : undefined}
                buttonStyle={[
                    style,
                    type === 'solid' ? { backgroundColor: hexColor ? hexColor : hexProvider(color) } : null]}
                onPress={onPress}
                onPressIn={onPressIn}
                onPressOut={onPressOut}
            />
        </Animated.View>
    );
};

export default Button;