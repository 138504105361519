import { API, graphqlOperation } from 'aws-amplify';

import { hasInternet } from '../network/checkNetwork';
import { shorthash } from '../../utils/helpers';
import { storeData, getData } from '../store/store';
import { saveMutation, OfflineMutationItemsArray } from '../store/offlineMutations';

import { queryTypes } from '../../models/api/queryTypes';
import { mutationTypes } from '../../models/api/mutationTypes';

import * as queries from '../../models/graphql/queries';
import * as mutations from '../../models/graphql/mutations';

/**
 * Get GraphQL Database entries & directly saves them to local storage.
 * @param query Supported get-queries of connected GraphQL-Services.
 * @param variables Supported variables for get-queries.
 * @param refresh optional, tries to get fresh-data. If NOINTERNET it will try to use storage.
 */
async function queryAPI<Q extends keyof queryTypes, K extends keyof mutationTypes>(
    query: queryTypes[Q]['queryType'],
    variables: queryTypes[Q]['variablesType'],
    refresh?: {
        refresh?: boolean,
        mutationQuery?: mutationTypes[K]['queryType']
    }
) {
    try {
        const storeKey = shorthash([query, Object.values(variables)[0]]);
        let store = await getData(storeKey);
        if (refresh?.refresh === true || (store === null)) {
            const internet: boolean = await hasInternet();
            if (internet) {
                const result = await API.graphql(graphqlOperation(
                    queries[query],
                    variables)) as {
                        data: queryTypes[Q]['dataType'];
                    };
                await storeData(storeKey, Object.values(result.data)[0]);
                store = await getData(storeKey);
            } else {
                //check for offline mutation
                const offlineMutationStoreKey: string = 'offline-mutation';
                let mutationStore: OfflineMutationItemsArray<K> | null = await getData(offlineMutationStoreKey);

                //check if query matches mutation entry
                //check if id exist
                if ("id" in variables && variables?.id) {
                    //@ts-ignore
                    let existingEntryMutation = mutationStore !== null ? mutationStore.find(e => e.variables.id === variables?.id) : undefined;
                    if (existingEntryMutation?.variables || store !== null) {
                        //merge with existing data
                        const updatedStore = { ...store, ...existingEntryMutation?.variables };
                        //override store
                        await storeData(storeKey, updatedStore);
                        //return new data
                        store = await getData(storeKey);
                        return store;
                    } else {
                        //else
                        throw new Error('NOINTERNET');
                    }
                } else {
                    //else
                    throw new Error('NOINTERNET');
                }
            }
        }
        return store;
    } catch (error) {
        //@ts-ignore
        throw new Error(error);
    }
}

/**
 * Mutate GraphQL Database entry. Handling offline-mutations also syncing if connection is aviable.
 * @param query Supported mutation-queries of connected GraphQL-Services
 * @param variables Supported variables for mutation-queries.
 * @param createQuery optional, if there is the possibility of no DB entry and creation is needed before updating.
 */
async function updateAPI<Q extends keyof mutationTypes>(
    query: mutationTypes[Q]['queryType'],
    variables: mutationTypes[Q]['variablesType']['input'],
    createQuery?: mutationTypes[Q]['queryTypeCreate']
) {
    try {
        const internet: boolean = await hasInternet();
        if (internet) {
            await API.graphql(graphqlOperation(mutations[query], { input: variables }));
        } else {
            await saveMutation(query, variables, createQuery);
        }
    } catch (error) {
        //@ts-ignore
        if (error?.errors[0]?.errorType === 'DynamoDB:ConditionalCheckFailedException' && createQuery !== undefined) {
            console.log('entry does not exist, trying to create: ', query, variables);
            //@ts-ignore
            await API.graphql(graphqlOperation(mutations[createQuery], { input: variables }));
        } else {
            console.log(error);
            //@ts-ignore
            throw new Error(error);
        }
    }
}

export { queryAPI, updateAPI };