import React from 'react';
import { Platform } from 'react-native';
import { createStackNavigator } from '@react-navigation/stack';

import { StoryScreen } from '../screens/StoryScreen';
import { MediaScreen } from '../screens/MediaScreen';
import { TranslationScreen } from '../screens/TranslationScreen';

import Header from '../../components/Header';

import { hexProvider } from '../../utils/hexProvider';
import { translate } from '../../services/localization/translations';

import { StoryStackParamList } from '../../models/stackParams/StoryStackParamListType';

const StoryStack = createStackNavigator<StoryStackParamList>();

export function StoryStackNavigator() {

    let bgColor = hexProvider('textinverted');
    let textColor = hexProvider('text');

    return (
        <StoryStack.Navigator initialRouteName="StoryScreen"
            screenOptions={{
                headerTitleAllowFontScaling: true,
                headerBackAllowFontScaling: true,
                headerBackTitle: translate('back'),
                headerStyle: {
                    backgroundColor: bgColor,
                },
                headerTintColor: textColor,
                headerTitleStyle: {
                    fontWeight: 'bold',
                }
            }}>
            <StoryStack.Screen
                name="StoryScreen"
                options={{
                    title: 'u1Dent.app',
                    headerShown: false
                }}
                component={StoryScreen} />
            <StoryStack.Screen
                name="MediaScreen"
                component={MediaScreen}
                options={
                    Platform.OS === 'web' ?
                        ({ navigation }) => ({
                            title: 'u1Dent.app',
                            header: () => <Header
                                buttonLeft={{
                                    icon: {
                                        icon: 'arrow-left',
                                        color: 'text'
                                    },
                                    onPress: () => navigation.goBack()
                                }}
                            />
                        })
                        :
                        {
                            title: ''
                        }
                }

            />
            <StoryStack.Screen
                name="TranslationScreen"
                component={TranslationScreen}
                options={Platform.OS === 'web' ?
                    ({ navigation }) => ({
                        title: translate('translation') + ' | u1Dent.app',
                        header: () => <Header
                            title={translate('translation')}
                            buttonLeft={{
                                icon: {
                                    icon: 'arrow-left',
                                    color: 'text'
                                },
                                onPress: () => navigation.goBack()
                            }}
                        />
                    })
                    :
                    {
                        title: translate('translation'),
                    }} />
        </StoryStack.Navigator>
    );
}