/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CreateProgressInput = {
  id: string,
  storiesProgress?: Array< string | null > | null,
  quizzeProgress?: Array< QuizProgressInput | null > | null,
  owner: string,
};

export type QuizProgressInput = {
  id: string,
  done?: boolean | null,
  failed?: number | null,
};

export type ProgressResponse = {
  __typename: "ProgressResponse",
  id?: string,
  storiesProgress?: Array< string | null > | null,
  quizzeProgress?:  Array<QuizProgress | null > | null,
  owner?: string,
};

export type QuizProgress = {
  __typename: "QuizProgress",
  id?: string,
  done?: boolean | null,
  failed?: number | null,
};

export type UpdateUserInput = {
  id: string,
  currentCategory?: string | null,
  nextLesson?: string | null,
  textAlign?: TextAlign | null,
  textSize?: TextSize | null,
  primaryColor?: string | null,
  tutorials?: Array< TutorialInput | null > | null,
  premium?: string | null,
  quizProgessSharing?: QuizProgessSharingInput | null,
  sharing?: boolean | null,
  owner?: string | null,
};

export enum TextAlign {
  center = "center",
  right = "right",
  left = "left",
}


export enum TextSize {
  h1 = "h1",
  h2 = "h2",
  h3 = "h3",
  h4 = "h4",
  h5 = "h5",
  p = "p",
}


export type TutorialInput = {
  name?: string | null,
  done?: boolean | null,
};

export type QuizProgessSharingInput = {
  right?: number | null,
  wrong?: number | null,
  done?: number | null,
  updatedAt?: number | null,
};

export type ModelUserConditionInput = {
  currentCategory?: ModelStringInput | null,
  nextLesson?: ModelStringInput | null,
  textAlign?: ModelTextAlignInput | null,
  textSize?: ModelTextSizeInput | null,
  primaryColor?: ModelStringInput | null,
  premium?: ModelStringInput | null,
  sharing?: ModelBooleanInput | null,
  and?: Array< ModelUserConditionInput | null > | null,
  or?: Array< ModelUserConditionInput | null > | null,
  not?: ModelUserConditionInput | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelTextAlignInput = {
  eq?: TextAlign | null,
  ne?: TextAlign | null,
};

export type ModelTextSizeInput = {
  eq?: TextSize | null,
  ne?: TextSize | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type User = {
  __typename: "User",
  id?: string,
  currentCategory?: string | null,
  nextLesson?: string | null,
  textAlign?: TextAlign | null,
  textSize?: TextSize | null,
  primaryColor?: string | null,
  tutorials?:  Array<Tutorial | null > | null,
  premium?: string | null,
  quizProgessSharing?: QuizProgessSharing,
  sharing?: boolean | null,
  owner?: string | null,
  createdAt?: string,
  updatedAt?: string,
};

export type Tutorial = {
  __typename: "Tutorial",
  name?: string | null,
  done?: boolean | null,
};

export type QuizProgessSharing = {
  __typename: "QuizProgessSharing",
  right?: number | null,
  wrong?: number | null,
  done?: number | null,
  updatedAt?: number | null,
};

export type UpdateProgressInput = {
  id: string,
  storiesProgress?: Array< string | null > | null,
  quizzeProgress?: Array< QuizProgressInput | null > | null,
  owner: string,
};

export type ModelProgressConditionInput = {
  storiesProgress?: ModelStringInput | null,
  and?: Array< ModelProgressConditionInput | null > | null,
  or?: Array< ModelProgressConditionInput | null > | null,
  not?: ModelProgressConditionInput | null,
};

export type Progress = {
  __typename: "Progress",
  id?: string,
  storiesProgress?: Array< string | null > | null,
  quizzeProgress?:  Array<QuizProgress | null > | null,
  owner?: string,
  createdAt?: string,
  updatedAt?: string,
};

export type Category = {
  __typename: "Category",
  id?: string | null,
  name?: string | null,
  description?: string | null,
  thumbnailUrl?: string | null,
  thumbnailColors?: Array< string | null > | null,
  position?: number | null,
  lessons?:  Array<Lesson | null > | null,
};

export type Lesson = {
  __typename: "Lesson",
  id?: string | null,
  name?: string | null,
  description?: string | null,
  premium?: boolean | null,
  time?: number | null,
  position?: number | null,
  podcastDate?: string | null,
  categoryId?: string | null,
  stories?:  Array<Story | null > | null,
  choiceQuizzes?:  Array<ChoiceQuiz | null > | null,
  sortQuizzes?:  Array<SortQuiz | null > | null,
  textQuizzes?:  Array<TextQuiz | null > | null,
};

export type Story = {
  __typename: "Story",
  id?: string | null,
  text?: string | null,
  media?: string | null,
  position?: number | null,
  lessonId?: string | null,
};

export type ChoiceQuiz = {
  __typename: "ChoiceQuiz",
  id?: string | null,
  answers?: Array< string | null > | null,
  correct?: number | null,
  question?: string | null,
  description?: string | null,
  media?: string | null,
  position?: number | null,
  lessonId?: string | null,
};

export type SortQuiz = {
  __typename: "SortQuiz",
  id?: string | null,
  answers?: Array< string | null > | null,
  question?: string | null,
  description?: string | null,
  media?: string | null,
  position?: number | null,
  lessonId?: string | null,
};

export type TextQuiz = {
  __typename: "TextQuiz",
  id?: string | null,
  correct?: string | null,
  question?: string | null,
  description?: string | null,
  media?: string | null,
  position?: number | null,
  lessonId?: string | null,
};

export type Translation = {
  __typename: "Translation",
  lessonIDLanguage?: string,
  content?:  Array<LessonContent > | null,
  createdAt?: string,
  updatedAt?: string,
};

export type LessonContent = {
  __typename: "LessonContent",
  position?: number,
  text?: string,
};

export type CreateUserProgressMutationVariables = {
  input?: CreateProgressInput,
};

export type CreateUserProgressMutation = {
  createUserProgress?:  {
    __typename: "ProgressResponse",
    id: string,
    storiesProgress?: Array< string | null > | null,
    quizzeProgress?:  Array< {
      __typename: "QuizProgress",
      id: string,
      done?: boolean | null,
      failed?: number | null,
    } | null > | null,
    owner: string,
  } | null,
};

export type UpdateUserMutationVariables = {
  input?: UpdateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type UpdateUserMutation = {
  updateUser?:  {
    __typename: "User",
    id: string,
    currentCategory?: string | null,
    nextLesson?: string | null,
    textAlign?: TextAlign | null,
    textSize?: TextSize | null,
    primaryColor?: string | null,
    tutorials?:  Array< {
      __typename: "Tutorial",
      name?: string | null,
      done?: boolean | null,
    } | null > | null,
    premium?: string | null,
    quizProgessSharing?:  {
      __typename: "QuizProgessSharing",
      right?: number | null,
      wrong?: number | null,
      done?: number | null,
      updatedAt?: number | null,
    } | null,
    sharing?: boolean | null,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateUserProgressMutationVariables = {
  input?: UpdateProgressInput,
  condition?: ModelProgressConditionInput | null,
};

export type UpdateUserProgressMutation = {
  updateUserProgress?:  {
    __typename: "Progress",
    id: string,
    storiesProgress?: Array< string | null > | null,
    quizzeProgress?:  Array< {
      __typename: "QuizProgress",
      id: string,
      done?: boolean | null,
      failed?: number | null,
    } | null > | null,
    owner: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type GetCategoriesQuery = {
  getCategories?:  Array< {
    __typename: "Category",
    id?: string | null,
    name?: string | null,
    description?: string | null,
    thumbnailUrl?: string | null,
    thumbnailColors?: Array< string | null > | null,
    position?: number | null,
    lessons?:  Array< {
      __typename: "Lesson",
      id?: string | null,
      name?: string | null,
      description?: string | null,
      premium?: boolean | null,
      time?: number | null,
      position?: number | null,
      podcastDate?: string | null,
      categoryId?: string | null,
      stories?:  Array< {
        __typename: "Story",
        id?: string | null,
        text?: string | null,
        media?: string | null,
        position?: number | null,
        lessonId?: string | null,
      } | null > | null,
      choiceQuizzes?:  Array< {
        __typename: "ChoiceQuiz",
        id?: string | null,
        answers?: Array< string | null > | null,
        correct?: number | null,
        question?: string | null,
        description?: string | null,
        media?: string | null,
        position?: number | null,
        lessonId?: string | null,
      } | null > | null,
      sortQuizzes?:  Array< {
        __typename: "SortQuiz",
        id?: string | null,
        answers?: Array< string | null > | null,
        question?: string | null,
        description?: string | null,
        media?: string | null,
        position?: number | null,
        lessonId?: string | null,
      } | null > | null,
      textQuizzes?:  Array< {
        __typename: "TextQuiz",
        id?: string | null,
        correct?: string | null,
        question?: string | null,
        description?: string | null,
        media?: string | null,
        position?: number | null,
        lessonId?: string | null,
      } | null > | null,
    } | null > | null,
  } | null > | null,
};

export type GetLessonsForCategoryQueryVariables = {
  categoryId?: string | null,
};

export type GetLessonsForCategoryQuery = {
  getLessonsForCategory?:  Array< {
    __typename: "Lesson",
    id?: string | null,
    name?: string | null,
    description?: string | null,
    premium?: boolean | null,
    time?: number | null,
    position?: number | null,
    podcastDate?: string | null,
    categoryId?: string | null,
    stories?:  Array< {
      __typename: "Story",
      id?: string | null,
      text?: string | null,
      media?: string | null,
      position?: number | null,
      lessonId?: string | null,
    } | null > | null,
    choiceQuizzes?:  Array< {
      __typename: "ChoiceQuiz",
      id?: string | null,
      answers?: Array< string | null > | null,
      correct?: number | null,
      question?: string | null,
      description?: string | null,
      media?: string | null,
      position?: number | null,
      lessonId?: string | null,
    } | null > | null,
    sortQuizzes?:  Array< {
      __typename: "SortQuiz",
      id?: string | null,
      answers?: Array< string | null > | null,
      question?: string | null,
      description?: string | null,
      media?: string | null,
      position?: number | null,
      lessonId?: string | null,
    } | null > | null,
    textQuizzes?:  Array< {
      __typename: "TextQuiz",
      id?: string | null,
      correct?: string | null,
      question?: string | null,
      description?: string | null,
      media?: string | null,
      position?: number | null,
      lessonId?: string | null,
    } | null > | null,
  } | null > | null,
};

export type GetLessonContentQueryVariables = {
  lessonId?: string | null,
};

export type GetLessonContentQuery = {
  getLessonContent?:  Array< {
    __typename: "Lesson",
    id?: string | null,
    name?: string | null,
    description?: string | null,
    premium?: boolean | null,
    time?: number | null,
    position?: number | null,
    podcastDate?: string | null,
    categoryId?: string | null,
    stories?:  Array< {
      __typename: "Story",
      id?: string | null,
      text?: string | null,
      media?: string | null,
      position?: number | null,
      lessonId?: string | null,
    } | null > | null,
    choiceQuizzes?:  Array< {
      __typename: "ChoiceQuiz",
      id?: string | null,
      answers?: Array< string | null > | null,
      correct?: number | null,
      question?: string | null,
      description?: string | null,
      media?: string | null,
      position?: number | null,
      lessonId?: string | null,
    } | null > | null,
    sortQuizzes?:  Array< {
      __typename: "SortQuiz",
      id?: string | null,
      answers?: Array< string | null > | null,
      question?: string | null,
      description?: string | null,
      media?: string | null,
      position?: number | null,
      lessonId?: string | null,
    } | null > | null,
    textQuizzes?:  Array< {
      __typename: "TextQuiz",
      id?: string | null,
      correct?: string | null,
      question?: string | null,
      description?: string | null,
      media?: string | null,
      position?: number | null,
      lessonId?: string | null,
    } | null > | null,
  } | null > | null,
};

export type GetQuizzesQueryVariables = {
  quizIds?: Array< string | null > | null,
};

export type GetQuizzesQuery = {
  getQuizzes?: string | null,
};

export type GetQuizzesForCategoryQueryVariables = {
  categoryId?: string | null,
};

export type GetQuizzesForCategoryQuery = {
  getQuizzesForCategory?: string | null,
};

export type GetPodcastUrlQueryVariables = {
  lessonID?: string | null,
  premium?: boolean | null,
};

export type GetPodcastUrlQuery = {
  getPodcastURL?: string | null,
};

export type CountQuizzesQuery = {
  countQuizzes?: number | null,
};

export type PremiumStatusQuery = {
  premiumStatus?: string | null,
};

export type GetTeamQuery = {
  getTeam?: string | null,
};

export type SetTeamNameQueryVariables = {
  name?: string | null,
};

export type SetTeamNameQuery = {
  setTeamName?: string | null,
};

export type SetTeamMemberQueryVariables = {
  email?: string | null,
  name?: string | null,
  add?: boolean | null,
};

export type SetTeamMemberQuery = {
  setTeamMember?: string | null,
};

export type CreateCheckoutSessionQueryVariables = {
  stripePK?: string | null,
  price?: string | null,
  quantity?: string | null,
  success_url?: string | null,
  cancel_url?: string | null,
};

export type CreateCheckoutSessionQuery = {
  createCheckoutSession?: string | null,
};

export type CreateCustomerportalSessionQueryVariables = {
  stripePK?: string | null,
  domain?: string | null,
};

export type CreateCustomerportalSessionQuery = {
  createCustomerportalSession?: string | null,
};

export type GenerateTranslationQueryVariables = {
  lessonIDLanguage?: string | null,
};

export type GenerateTranslationQuery = {
  generateTranslation?: string | null,
};

export type DeleteUserQuery = {
  deleteUser?: string | null,
};

export type GetUserQueryVariables = {
  id?: string,
};

export type GetUserQuery = {
  getUser?:  {
    __typename: "User",
    id: string,
    currentCategory?: string | null,
    nextLesson?: string | null,
    textAlign?: TextAlign | null,
    textSize?: TextSize | null,
    primaryColor?: string | null,
    tutorials?:  Array< {
      __typename: "Tutorial",
      name?: string | null,
      done?: boolean | null,
    } | null > | null,
    premium?: string | null,
    quizProgessSharing?:  {
      __typename: "QuizProgessSharing",
      right?: number | null,
      wrong?: number | null,
      done?: number | null,
      updatedAt?: number | null,
    } | null,
    sharing?: boolean | null,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type GetUserProgressQueryVariables = {
  owner?: string,
  id?: string,
};

export type GetUserProgressQuery = {
  getUserProgress?:  {
    __typename: "Progress",
    id: string,
    storiesProgress?: Array< string | null > | null,
    quizzeProgress?:  Array< {
      __typename: "QuizProgress",
      id: string,
      done?: boolean | null,
      failed?: number | null,
    } | null > | null,
    owner: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type GetTranslationQueryVariables = {
  lessonIDLanguage?: string,
};

export type GetTranslationQuery = {
  getTranslation?:  {
    __typename: "Translation",
    lessonIDLanguage: string,
    content?:  Array< {
      __typename: "LessonContent",
      position: number,
      text: string,
    } > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateUserSubscriptionVariables = {
  owner?: string,
};

export type OnUpdateUserSubscription = {
  onUpdateUser?:  {
    __typename: "User",
    id: string,
    currentCategory?: string | null,
    nextLesson?: string | null,
    textAlign?: TextAlign | null,
    textSize?: TextSize | null,
    primaryColor?: string | null,
    tutorials?:  Array< {
      __typename: "Tutorial",
      name?: string | null,
      done?: boolean | null,
    } | null > | null,
    premium?: string | null,
    quizProgessSharing?:  {
      __typename: "QuizProgessSharing",
      right?: number | null,
      wrong?: number | null,
      done?: number | null,
      updatedAt?: number | null,
    } | null,
    sharing?: boolean | null,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};
