import * as React from 'react';

import { queryAPI } from '../api/data';

import { reducer } from '../reducers/reducer';
import { ReducerActions, ReducerState } from '../../models/reducers/ReducerType';
import { errorDispatcher } from '../../utils/errorDispatcher';

type State = ReducerState;
type Dispatch = (action: ReducerActions) => void;
type CategoryListProviderProps = { children: React.ReactNode };

const CategoryListStateContext = React.createContext<State | undefined>(undefined);
const CategoryListDispatchContext = React.createContext<Dispatch | undefined>(
    undefined,
);

const initialState = { isLoading: false, data: undefined };

function CategoryListProvider({ children }: CategoryListProviderProps) {
    const [state, dispatch] = React.useReducer(reducer, initialState);

    return (
        <CategoryListStateContext.Provider value={state}>
            <CategoryListDispatchContext.Provider value={dispatch}>
                {children}
            </CategoryListDispatchContext.Provider>
        </CategoryListStateContext.Provider>
    )
}

function useCategoryListState() {
    const context = React.useContext(CategoryListStateContext)
    if (context === undefined) {
        throw new Error('useCategoryListState must be used within a CategoryListProvider')
    }
    return context
}

function useCategoryListDispatch() {
    const context = React.useContext(CategoryListDispatchContext)
    if (context === undefined) {
        throw new Error('useCategoryListDispatch must be used within a CategoryListProvider')
    }
    return context
}

async function getCategories(dispatch: Dispatch, refresh?: boolean) {
    dispatch({ type: 'REQUEST' });
    try {
        const data: any = await queryAPI('getCategories', {}, { refresh: refresh });
        //@ts-ignore
        data ? data.sort((a, b) => a.position - b.position) : undefined;
        dispatch({ type: 'SUCCESS', data: data })
    } catch (error) {
        //@ts-ignore
        errorDispatcher(error, dispatch);
    }
}

export {
    CategoryListProvider,
    useCategoryListState,
    useCategoryListDispatch,
    getCategories
};