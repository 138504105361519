import React from "react";
import { StyleSheet, View, TouchableOpacity } from "react-native";

import { hexProvider, shadowProvider } from "../utils/hexProvider";
import { translate } from '../services/localization/translations';
import { useNavigation } from "@react-navigation/native";

import Text from './Text';

import TeamType, { TeamUserType } from "../models/data/TeamType";
import { User as UserType } from "../models/data/UserType";

type TeamUser = TeamUserType & { quizProgessSharing?: UserType['quizProgessSharing'] | undefined };
type Team = Omit<TeamType, 'users'> & { users?: undefined | TeamUser[] };

type TeamListItemType = {
    name: string;
    email: string;
    quizProgessSharing: UserType['quizProgessSharing'];
    onPress: () => void;
}

type Props = {
    team: Team | undefined;
}

const TeamListCard = ({
    team
}: Props) => {

    let members = team?.users;

    const navigation = useNavigation();

    const TeamListItem = ({
        name,
        email,
        quizProgessSharing,
        onPress
    }: TeamListItemType) => {

        return (
            <TouchableOpacity
                style={[
                    style.container,
                    style.shadow,
                    {
                        height: 112,
                        flexDirection: 'row',
                        marginVertical: 8,
                        alignItems: 'center',
                        justifyContent: 'space-between'
                    }]}
                onPress={onPress}
            >
                <View
                    style={{
                        flexDirection: 'column'
                    }}
                >
                    <Text color='text' style='bold' type='h5' textAlign='left'>{name}</Text>
                    <View style={{ marginVertical: 4 }} />
                    <Text color='text' style='normal' type='p' textAlign='left'>{email}</Text>
                </View>
                {(quizProgessSharing && quizProgessSharing?.done !== undefined && quizProgessSharing?.right !== undefined && quizProgessSharing?.wrong !== undefined) &&
                    <View
                        style={[
                            style.bgcolor,
                            {
                                position: 'absolute',
                                top: 0,
                                bottom: 0,
                                right: 0,
                                padding: 8,
                                paddingRight: 16,
                                flexDirection: 'column',
                                justifyContent: 'center'
                            }]}
                    >
                        <Text
                            style='bold'
                            type='h5'
                            color='success'
                        >
                            {quizProgessSharing.right}
                        </Text>
                        <View style={{ marginVertical: 4 }} />
                        <Text
                            style='bold'
                            type='h5'
                            color='error'
                        >
                            {quizProgessSharing.wrong}
                        </Text>
                        <View style={{ marginVertical: 4 }} />
                        <Text
                            style='bold'
                            type='h5'
                            color='text'
                        >
                            {quizProgessSharing.done}
                        </Text>
                    </View>
                }
            </TouchableOpacity>
        );
    }

    const style = StyleSheet.create({
        container: {
            margin: 4,
            padding: 8,
            borderRadius: 8 / 1,
            backgroundColor: hexProvider('grey'),
        },
        shadow: shadowProvider(),
        bgcolor: {
            backgroundColor: hexProvider('grey')
        }
    });

    if (members) {
        return (
            <View
                style={style.container}
            >
                <View
                    style={[style.container, { flexDirection: "row" }]}
                >
                    <Text
                        type='h5'
                        color='text'
                    >
                        {translate('members') + ': '}
                    </Text>
                    <Text
                        type='h5'
                        color='text'
                        style='bold'
                    >
                        {`${members ? members?.length : '-'} / ${team?.quantity ? team.quantity : '-'}`}
                    </Text>
                </View>
                {
                    members.map(m =>
                        <TeamListItem
                            key={m.username}
                            name={m.name}
                            email={m.email}
                            quizProgessSharing={m.quizProgessSharing}
                            //@ts-ignore
                            onPress={() => navigation.navigate('MemberScreen', {
                                name: m.name,
                                username: m.username,
                                email: m.email,
                                quizProgessSharing: m.quizProgessSharing
                            })}
                        />
                    )
                }
            </View>
        );
    } else {
        return null;
    }
}

export default TeamListCard; 