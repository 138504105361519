type ThemeType = {
    light: object;
    dark: object;
}

export const theme: ThemeType = {
    light: {
        colors: {
            primary: '#3880FF',
            grey0: '#FFFFFF', //white
            grey1: '#242424', //black
            grey3: '#E1E8EE', //grey
            grey4: '#242424', //text: black
            grey5: '#FFFFFF', //text: white
            success: '#4CD964',
            warning: '#FFCC00',
            error: '#FF3B30',
        }
    },
    dark: {
        colors: {
            primary: '#3880FF',
            grey0: '#F2F2F2', //white
            grey1: '#080808', //black
            grey3: '#242424', //grey
            grey4: '#F2F2F2', //text: white
            grey5: '#080808', //text: black
            success: '#439946',
            warning: '#CFBE27',
            error: '#BF2C24',
        }
    }
}