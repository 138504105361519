import React from 'react';
import { StyleSheet, Platform, View, ScrollView } from 'react-native';

import '@expo/match-media'
import { useMediaQuery } from "react-responsive";
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import LoadingIndicator from './LoadingIndicator';
import AnimatedLinearGradient from './AnimatedLinearGradient';

type Props = {
  borderRadius?: boolean;
  isLoading?: boolean;
  scrollable?: boolean;
  linearGradientHexColors?: string[];
  insets?: boolean;
  children: React.ReactNode;
};

type SizeObject = {
  maxWidth?: number,
  minWidth?: number,
  query?: string
}

let mobile: SizeObject = { maxWidth: 599 }
let tablet: SizeObject = { minWidth: 600, maxWidth: 999 }
let desktop: SizeObject = { minWidth: 1000 }
let portrait: SizeObject = { query: "(orientation: portrait)" };

if (Platform.OS !== 'web') {
  mobile = { query: `(max-device-width: ${mobile.maxWidth}px)` }
  tablet = { query: `(min-device-width: ${tablet.minWidth}px) and (max-device-width: ${tablet.maxWidth}px)` }
  desktop = { query: `(min-device-width: ${desktop.minWidth}px)` }
}

export const ResponsiveContainer = ({
  borderRadius = true,
  isLoading = false,
  scrollable = false,
  linearGradientHexColors,
  insets,
  children
}: Props) => {
  const safeAreaInsets = useSafeAreaInsets();

  // const isMobile = useMediaQuery(mobile);
  const isTablet = useMediaQuery(tablet);
  const isDesktop = useMediaQuery(desktop);
  const isPortrait = useMediaQuery(portrait);

  const isWeb = Platform.OS === 'web';

  const borderRadiusStyle = borderRadius ? { borderRadius: 8 / 1 } : null;

  const specific = () => {
    if (isTablet && isPortrait) {
      return [styles.mediumSpecific, borderRadiusStyle];
    }
    if (isDesktop || isTablet) {
      return [styles.bigSpecific, borderRadiusStyle];
    }
    return styles.smallSpecific;
  }

  return (
    <View style={[styles.responsiveContainer]}>
      <View style={[styles.container, specific(), {
        paddingBottom: insets ? safeAreaInsets.bottom : undefined,
        paddingTop: insets ? safeAreaInsets.top : undefined
      }]} >
        {(linearGradientHexColors && isWeb) && <AnimatedLinearGradient customColors={linearGradientHexColors} />}
        {isLoading ?
          <LoadingIndicator />
          :
          scrollable ?
            <ScrollView style={{
              height: 1
            }} >
              {children}
            </ScrollView>
            :
            children
        }
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  responsiveContainer: {
    flex: 1,
    width: '100%',
    alignItems: 'center'
  },
  container: {
    flex: 1,
    width: '100%',
    justifyContent: 'center'
  },
  smallSpecific: {
    // margin: 8,
  },
  mediumSpecific: {
    overflow: 'hidden',
    margin: 16,
    maxWidth: 599,
  },
  bigSpecific: {
    overflow: 'hidden',
    margin: 16,
    maxWidth: 599,
  }
});