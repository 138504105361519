import React from 'react';

import { Icon as I, IconProps } from 'react-native-elements'

import { hexProvider, Colors } from '../utils/hexProvider';

export type Icons =
    | 'play'
    | 'pause'
    | 'align-left'
    | 'podcast'
    | 'align-center'
    | 'cog'
    | 'align-right'
    | 'users'
    | 'bars'
    | 'question-circle'
    | 'question'
    | 'check-circle'
    | 'check-square'
    | 'check'
    | 'times-circle'
    | 'times'
    | 'repeat'
    | 'forward'
    | 'undo'
    | 'backward'
    | 'arrow-right'
    | 'user-circle'
    | 'arrow-left'
    | 'star'
    | 'expand'
    | 'external-link'
    | 'compress'
    | 'comments'
    | 'clock-o'
    | 'envelope'
    | 'file'
    | 'instagram'
    | 'exchange'
    | 'facebook'
    | 'language'
    | 'key'
    | 'plus'
    | 'shopping-cart'
    | 'minus'
    | 'sign-out'
    | 'sign-in'
    | 'eye'
    | 'eye-slash'
    | 'lock'
    | 'gesture-swipe'
    | 'gesture-swipe-vertical'
    | 'mouse'
    | 'square-o'
    | 'check-square-o'
    | 'apple'
    | 'google'
    | 'commenting'
    | 'play-circle'
    | 'globe'
    | 'user-plus'
    | 'hand-pointer-o'
    | 'mouse-pointer'
    | 'apple'
    | 'google';

export type Props = {
    icon: Icons;
    color?: | Colors;
    type?: 'font-awesome' | 'material-community';
    hexColor?: string;
    style?: IconProps['style'];
    iconStyle?: IconProps['iconStyle']
};

const Icon = ({
    icon,
    color,
    type = 'font-awesome',
    hexColor,
    style,
    iconStyle
}: Props) => {
    return (
        <I
            iconStyle={iconStyle}
            style={style}
            name={icon}
            type={type}
            color={color === undefined ? hexColor === undefined ? undefined : hexColor : hexProvider(color)} />
    );
};

export default Icon;