import React, { useRef } from 'react';
import { TouchableOpacity, View, Animated } from 'react-native';

import LinearGradient from './LinearGradient';
import U1PlusLogo from './U1PlusLogo';
import Text from './Text';

import { hexProvider } from '../utils/hexProvider';

export type Props = {
    product: {
        name: string;
        price: number;
        month: number;
        group: 'INDIVIDUAL' | 'TEAM';
        productIndentifier: string;
    };
    selected: boolean;
    onPress?: () => void;
};

const ProductCard = ({
    product,
    selected,
    onPress
}: Props) => {

    const animatedScaleValue = useRef(new Animated.Value(1)).current

    const scaleVal = animatedScaleValue.interpolate({
        inputRange: [0, 1],
        outputRange: [.8, 1]
    });

    const onPressIn = () =>
        Animated.timing(animatedScaleValue, {
            toValue: 0,
            duration: 200,
            useNativeDriver: true
        }).start();

    const onPressOut = () =>
        Animated.timing(animatedScaleValue, {
            toValue: 1,
            duration: 200,
            useNativeDriver: true
        }).start();

    return (
        <Animated.View style={{
            height: 128,
            transform: [
                {
                    scale: scaleVal
                }
            ]
        }}>
            <TouchableOpacity
                style={{
                    flex: 1,
                }}
                onPress={onPress}
                onPressIn={onPressIn}
                onPressOut={onPressOut}
            >
                <LinearGradient
                    colors={["#fc0", "#ff9500", "#ff2d55", "#af52de", "#3880ff"]}
                    style={{
                        flex: 1,
                        borderRadius: 8 / 1,
                        height: 56,
                        padding: selected ? 4 : undefined,
                        margin: 16
                    }}
                >
                    <View style={{
                        flex: 1,
                        flexDirection: 'row',
                        justifyContent: 'space-evenly',
                        alignItems: 'center',
                        padding: 8,
                        paddingHorizontal: 16,
                        borderRadius: selected ? 8 / 1 : undefined,
                        backgroundColor: hexProvider('grey'),
                    }}
                    >
                        <View
                            style={{
                                flex: 1 / 2,
                                maxHeight: 96
                            }}
                        >
                            <U1PlusLogo />
                        </View>
                        <View
                            style={{
                                flex: 1,
                                justifyContent: 'space-evenly',
                            }}
                        >
                            <Text
                                type='h4'
                                style='bold'
                                textAlign='center'
                                color='text'
                            >{product.name}
                            </Text>
                            <Text
                                type='h4'
                                textAlign='center'
                                color='text'
                            >{product.price}€ {product.group === 'TEAM' ? 'p. P.' : ''}
                            </Text>
                        </View>
                    </View>
                </LinearGradient>
            </TouchableOpacity>
        </Animated.View>
    );
};

export default ProductCard;