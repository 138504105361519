import React, { useState, useRef } from 'react';
import { View, Animated, ScrollView } from 'react-native';

import Text, { TextType, TextDirection } from './Text';
import Button from './Button';

import { translate } from '../services/localization/translations';
import { Colors, shadowProvider, hexProvider } from '../utils/hexProvider';

type Props = {
    quizId: string;
    question: string;
    description?: string;
    textType?: TextType;
    textAlign?: Exclude<TextDirection, 'auto'>
    showQuizState: boolean;
    setShowQuizState: React.Dispatch<React.SetStateAction<boolean>>;
    rightQuizzesState: string[];
    wrongQuizzesState: string[];
};

export const QuestionCard = ({
    quizId,
    question,
    description,
    textType = 'h5',
    textAlign = 'left',
    showQuizState,
    setShowQuizState,
    rightQuizzesState,
    wrongQuizzesState,
}: Props) => {
    const [viewHeight, setViewHeight] = useState<number>(0);

    const animatedOpacityValueButton = useRef(new Animated.Value(0)).current;
    const animatedYValueButton = useRef(new Animated.Value(0)).current;

    const animatedOpacityValueText = useRef(new Animated.Value(0)).current;
    const animatedYValueText = useRef(new Animated.Value(0)).current;

    const yValButton = animatedYValueButton.interpolate({
        inputRange: [0, 1],
        outputRange: [100, 0]
    });

    const yValtext = animatedYValueText.interpolate({
        inputRange: [0, 1],
        outputRange: [100, 1]
    });

    const showButtonAnimation = () => {
        Animated.parallel([
            Animated.timing(animatedOpacityValueButton, {
                toValue: 1,
                duration: 500,
                useNativeDriver: true
            }),
            Animated.timing(animatedYValueButton, {
                toValue: 1,
                duration: 500,
                useNativeDriver: true
            })
        ]).start()
    };

    const hideButtonAnimation = () => {
        Animated.parallel([
            Animated.timing(animatedOpacityValueButton, {
                toValue: 0,
                duration: 500,
                useNativeDriver: true
            }),
            Animated.timing(animatedYValueButton, {
                toValue: 0,
                duration: 500,
                useNativeDriver: true
            })
        ]).start()
    };

    const hideTextAnimation = () => {
        Animated.parallel([
            Animated.timing(animatedOpacityValueText, {
                toValue: 0,
                duration: 250,
                useNativeDriver: true
            }),
            Animated.timing(animatedYValueText, {
                toValue: 0,
                duration: 250,
                useNativeDriver: true
            })
        ]).start()
    };

    const showTextAnimation = () => {
        Animated.parallel([
            Animated.timing(animatedOpacityValueText, {
                toValue: 1,
                duration: 250,
                useNativeDriver: true
            }),
            Animated.timing(animatedYValueText, {
                toValue: 1,
                duration: 250,
                useNativeDriver: true
            })
        ]).start()
    };

    const greyColor = hexProvider('grey');
    const successColor = hexProvider('success');
    const errorColor = hexProvider('error');
    const primaryColor = hexProvider('primary');
    const shadow = shadowProvider();

    const borderColorProvider = (): undefined | string => {
        if (wrongQuizzesState?.includes(quizId)) return errorColor
        else if (rightQuizzesState?.includes(quizId)) return successColor;
        else if (showQuizState === false) return primaryColor;
        return undefined;
    }

    const quizTextProvider = (): Colors => {
        if (wrongQuizzesState?.includes(quizId)) return 'error';
        if (rightQuizzesState?.includes(quizId)) return 'success';
        return 'primary';
    }

    if (wrongQuizzesState.includes(quizId)) {
        hideButtonAnimation();
        showQuizState === false && showTextAnimation();
    } else if (rightQuizzesState.includes(quizId)) {
        hideButtonAnimation();
        showQuizState === false && showTextAnimation();
    } else if (showQuizState === false) {
        hideTextAnimation();
        showButtonAnimation();
    } else {
        hideTextAnimation();
        hideButtonAnimation();
    }

    return (
        <View
            style={{
                flex: 1
            }}
            onLayout={e => setViewHeight(e.nativeEvent.layout.height)}
        >
            <Animated.View
                style={{
                    flexShrink: 1,
                    justifyContent: 'center',
                    alignItems: 'center',
                    alignSelf: 'center',
                    marginBottom: 24,
                    position: 'absolute',
                    bottom: 0,
                    zIndex: 3,
                    opacity: animatedOpacityValueButton,
                    transform: [
                        {
                            translateY: yValButton
                        }
                    ],
                }}>
                <Button
                    title={translate('showQuestion')}
                    type='solid'
                    color='primary'
                    textColor='white'
                    bold={true}
                    icon={{
                        icon: 'question-circle',
                        color: 'white',
                        style: { padding: 8 }
                    }}
                    style={{ height: 56, borderRadius: 56 / 4 }}
                    onPress={() => setShowQuizState(true)}
                />
            </Animated.View>
            <View
                style={[
                    {
                        flex: 1,
                        overflow: 'hidden',
                        borderRadius: 8 / 1,
                        backgroundColor: greyColor,
                        borderColor: borderColorProvider(),
                        borderWidth: borderColorProvider() !== undefined ? 4 : undefined
                    },

                ]}
            >
                <ScrollView
                    style={{
                        flex: 1,
                        height: viewHeight,
                        zIndex: 2
                    }}
                    contentContainerStyle={{
                        padding: 10
                    }}
                >
                    <View
                        style={[{
                            flexShrink: 1,
                            backgroundColor: greyColor,
                            borderRadius: 8 / 1
                        }, shadow]}>

                        <View
                            style={{
                                flexShrink: 1,
                                flexDirection: 'row',
                                justifyContent: 'space-evenly',
                                alignItems: 'center',
                                padding: 16,
                            }}>
                            <Text
                                type={textType}
                                style='bold'
                                textAlign={textAlign}
                                color={quizTextProvider()}
                            >
                                {question}
                            </Text>
                        </View>
                    </View>
                    {description &&
                        <View
                            style={{
                                flex: 1,
                                padding: 8,
                                marginTop: 8,
                                paddingBottom: 64
                            }}>
                            <Text
                                type={textType}
                                style='normal'
                                textAlign={textAlign}
                                color='text'>
                                {description}
                            </Text>
                        </View>
                    }
                </ScrollView>
                <Animated.View
                    style={{
                        flexShrink: 1,
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: 16,
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        right: 0,
                        zIndex: 1,
                        opacity: animatedOpacityValueText,
                        transform: [
                            {
                                translateY: yValtext
                            }
                        ],
                        backgroundColor: greyColor.concat('CC')
                    }}>
                    <Text
                        type='h4'
                        style='bold'
                        color={wrongQuizzesState.includes(quizId) ?
                            'error' :
                            'success'
                        }>
                        {wrongQuizzesState.includes(quizId) ?
                            translate('quizWrongAlready') :
                            translate('quizRightAlready')}
                    </Text>
                </Animated.View>
            </View>
        </View>
    );
};

export default QuestionCard;