import React from 'react';
import { TouchableOpacity, View, ViewStyle } from 'react-native';

import LinearGradient from './LinearGradient';
import Text from './Text';
import Icon from './Icon';

import { translate } from '../services/localization/translations';
import { hexProvider } from '../utils/hexProvider';

type Props = {
    onPress?: (() => void);
    style: ViewStyle | ViewStyle[];
};

const ListCardPremium = ({
    onPress,
    style
}: Props) => {

    let bgcolorContainer = hexProvider('grey');

    return (
        <TouchableOpacity
            style={style}
            onPress={onPress}
        >
            <LinearGradient
                colors={["#fc0", "#ff9500", "#ff2d55", "#af52de", "#3880ff"]}
                style={{
                    flex: 1,
                    padding: 4,
                    borderRadius: 8 / 1
                }}
            >
                <View
                    style={{
                        flex: 1,
                        borderRadius: 8 / 1,
                        padding: 8,
                        backgroundColor: bgcolorContainer,
                        justifyContent: 'space-around'
                    }}
                >
                    <View
                        style={{
                            flex: 1,
                            flexDirection: 'row',
                            justifyContent: 'flex-start',
                            alignItems: 'center'
                        }}
                    >
                        <Icon
                            icon='star'
                            color='text'
                            iconStyle={{
                                fontSize: 28
                            }}
                        />
                        <View
                            style={{
                                flex: 1,
                                paddingLeft: 8
                            }}
                        >
                            <Text
                                color='text'
                                style='bold'
                                type='h5'
                                textAlign='left'>
                                {translate('premiumCardTitle')}
                            </Text>
                            <Text
                                color='text'
                                style='normal'
                                type='h5'
                                textAlign='left'>
                                {translate('premiumCardDescription')}
                            </Text>
                        </View>
                    </View>
                </View>
            </LinearGradient>
        </TouchableOpacity>
    );
};

export default ListCardPremium;