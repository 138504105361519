import React, { useEffect } from 'react';
import { Platform } from 'react-native';

import { Hub, Auth } from 'aws-amplify';
import Purchases from 'react-native-purchases';
import * as Device from 'expo-device';

import { getUser } from '../../services/api/auth';
import { clearAppData } from '../../services/store/store';

import { useAuthState, useAuthDispatch } from '../../services/context/authContext';
import { useCategoryListState, useCategoryListDispatch } from '../../services/context/categoryListContext';
import { useProgressState, useProgressDispatch } from '../../services/context/progressContext';
import { useQuizProgressState, useQuizProgressDispatch } from '../../services/context/quizProgressContext';
import { useStoryState, useStoryDispatch } from '../../services/context/storyContext';
import { useStoryListState, useStoryListDispatch } from '../../services/context/storyListContext';
import { useTranslationState, useTranslationDispatch } from '../../services/context/translationContext';
import { useUserState, useUserDispatch } from '../../services/context/userContext';

import { createStackNavigator, TransitionPresets } from '@react-navigation/stack';

import { MainStackNavigator } from './MainStackNavigator';
import { StoryStackNavigator } from './StoryStackNavigator';

import { AuthScreen } from '../screens/AuthScreen';
import { PodcastScreen } from '../screens/PodcastScreen';
import { InfoScreen } from '../screens/InfoScreen';
import { LegalScreen } from '../screens/LegalScreen';

import Header from '../../components/Header';

import { hexProvider } from '../../utils/hexProvider';
import { translate } from '../../services/localization/translations';

import { RootStackParamList } from '../../models/stackParams/RootStackParamListType';

const RootStack = createStackNavigator<RootStackParamList>();

export function RootStackNavigator() {
    const { data: user, internet: internetAuth } = useAuthState();
    const { internet: internetCategoryList } = useCategoryListState();
    const { internet: internetProgress } = useProgressState();
    const { internet: internetQuizProgress } = useQuizProgressState();
    const { internet: internetStory } = useStoryState();
    const { internet: internetStoryList } = useStoryListState();
    const { internet: internetTranslation } = useTranslationState();
    const { internet: internetUser } = useUserState();

    const authDispatch = useAuthDispatch();
    const categoryListDispatch = useCategoryListDispatch();
    const progressDispatch = useProgressDispatch();
    const quizProgressDispatch = useQuizProgressDispatch();
    const storyDispatch = useStoryDispatch();
    const storyListDispatch = useStoryListDispatch();
    const translationDispatch = useTranslationDispatch();
    const userDispatch = useUserDispatch();

    const handleLogoutEvent = async () => {
        (Device.isDevice && (Platform.OS === 'ios' || Platform.OS === 'android')) && Purchases.logOut();
        categoryListDispatch({ type: 'RESET' });
        progressDispatch({ type: 'RESET' });
        quizProgressDispatch({ type: 'RESET' });
        storyDispatch({ type: 'RESET' });
        storyListDispatch({ type: 'RESET' });
        translationDispatch({ type: 'RESET' });
        userDispatch({ type: 'RESET' });
        authDispatch({ type: 'RESET' });

        await clearAppData();
    }

    const handlePremiumRefresh = async () => {
        console.log('handling premium change');
        categoryListDispatch({ type: 'RESET' });
        quizProgressDispatch({ type: 'RESET' });
        storyDispatch({ type: 'RESET' });
        storyListDispatch({ type: 'RESET' });
        translationDispatch({ type: 'RESET' });
        authDispatch({ type: 'RESET' });

        await clearAppData(true);

        await Auth.currentSession();
        return Auth.currentAuthenticatedUser({ bypassCache: true }).then((userData: any) => authDispatch({ type: 'SUCCESS', data: userData }));
    };

    useEffect(() => {
        Hub.listen('auth', ({ payload: { event, data } }) => {
            switch (event) {
                // case 'signIn':
                // case 'cognitoHostedUI':
                //     getUser().then((userData: any) => authDispatch({ type: 'SUCCESS', data: userData }));
                //     break;
                case 'signInchallengeSuccess':
                    getUser().then((userData: any) => authDispatch({ type: 'SUCCESS', data: userData }));
                    break;
                case 'signOut':
                    handleLogoutEvent();
                    break;
                case 'signIn_failure':
                case 'cognitoHostedUI_failure':
                    authDispatch({ type: 'FAILURE', error: data })
                    break;
            }
        });
        Hub.listen('premium', ({ payload: { event, data } }) => {
            switch (event) {
                case 'premiumChange':
                    handlePremiumRefresh();
                    break;
            }
        });
        authDispatch({ type: 'REQUEST' });
        getUser().then((userData: any) => authDispatch({ type: 'SUCCESS', data: userData }));
    }, []);

    let bgColor = hexProvider('textinverted');
    let textColor = hexProvider('text');

    const screenOptions = {
        presentation: 'modal',
        headerTitleAllowFontScaling: true,
        headerBackAllowFontScaling: true,
        headerBackTitle: translate('back'),
        headerStyle: {
            backgroundColor: bgColor,
        },
        headerTintColor: textColor,
        headerTitleStyle: {
            fontWeight: 'bold',
        },
        ...TransitionPresets.ModalSlideFromBottomIOS
    };

    return (
        <RootStack.Navigator
            screenOptions={
                Platform.OS === 'ios' ?
                    {
                        presentation: 'modal',
                        headerTitleAllowFontScaling: true,
                        headerBackAllowFontScaling: true,
                        headerBackTitle: translate('back'),
                        headerStyle: {
                            backgroundColor: bgColor,
                        },
                        headerTintColor: textColor,
                        headerTitleStyle: {
                            fontWeight: 'bold',
                        },
                        ...TransitionPresets.ModalSlideFromBottomIOS
                    }
                    :
                    {
                        presentation: 'modal',
                        headerTitleAllowFontScaling: true,
                        headerBackAllowFontScaling: true,
                        headerBackTitle: translate('back'),
                        headerStyle: {
                            backgroundColor: bgColor,
                        },
                        headerTintColor: textColor,
                        headerTitleStyle: {
                            fontWeight: 'bold',
                        },
                    }
            }
        >
            {
                user?.attributes === undefined
                    ?
                    <>
                        <RootStack.Screen
                            name='AuthScreen'
                            component={AuthScreen}
                            options={{
                                title: 'u1Dent.app',
                                headerShown: false
                            }}
                        />
                        <RootStack.Screen
                            name='LegalScreen'
                            component={LegalScreen}
                            options={
                                Platform.OS === 'web' ?
                                    ({ navigation }) => ({
                                        title: translate('licenses') + ' | u1Dent.app',
                                        header: () => <Header
                                            title={translate('licenses')}
                                            buttonLeft={{
                                                icon: {
                                                    icon: 'arrow-left',
                                                    color: 'text'
                                                },
                                                onPress: () => navigation.goBack()
                                            }}
                                        />
                                    })
                                    :
                                    {
                                        title: translate('licenses'),
                                    }
                            }
                        />
                    </>
                    :
                    (
                        internetAuth === false ||
                        internetCategoryList === false ||
                        internetProgress === false ||
                        internetQuizProgress === false ||
                        internetStory === false ||
                        internetStoryList === false ||
                        internetTranslation === false ||
                        internetUser === false
                    ) ?
                        <RootStack.Screen
                            name="InfoScreen"
                            component={InfoScreen}
                            options={{
                                title: 'u1Dent.app',
                                headerShown: false
                            }}
                        />
                        :
                        <>
                            <RootStack.Screen
                                name="Main"
                                component={MainStackNavigator}
                                options={{
                                    title: '',
                                    headerShown: false
                                }}
                            />
                            <RootStack.Screen
                                name="Story"
                                component={StoryStackNavigator}
                                options={{
                                    title: '',
                                    headerShown: false
                                }}
                            />
                            <RootStack.Screen
                                name="PodcastScreen"
                                component={PodcastScreen}
                                options={{
                                    title: translate('podcast') + ' | u1Dent.app',
                                    headerShown: false
                                }} />
                            <RootStack.Screen
                                name='LegalScreen'
                                component={LegalScreen}
                                options={
                                    Platform.OS === 'web' ?
                                        ({ navigation }) => ({
                                            title: translate('licenses') + ' | u1Dent.app',
                                            header: () => <Header
                                                title={translate('licenses')}
                                                buttonLeft={{
                                                    icon: {
                                                        icon: 'arrow-left',
                                                        color: 'text'
                                                    },
                                                    onPress: () => navigation.goBack()
                                                }}
                                            />
                                        })
                                        :
                                        {
                                            title: translate('licenses')
                                        }
                                }
                            />
                        </>
            }
        </RootStack.Navigator >
    );
}