import { ReducerState, ReducerActions } from '../../models/reducers/ReducerType';

export function reducer(state: ReducerState, action: ReducerActions): ReducerState {
    switch (action.type) {
        case 'REQUEST': {
            return {
                ...state,
                isLoading: true,
                error: undefined
            };
        }
        case 'SUCCESS': {
            return {
                ...state,
                isLoading: false,
                data: action.data,
                internet: true
            }
        }
        case 'FAILURE': {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            }
        }
        case 'NETWORK': {
            return {
                ...state,
                isLoading: false,
                internet: false
            }
        }
        case 'NETWORKRESET': {
            return {
                ...state,
                isLoading: false,
                internet: undefined
            }
        }
        case 'RESET': {
            return {
                ...state,
                isLoading: false,
                data: undefined,
                error: undefined
            }
        }
        default: {
            throw new Error('Unhandled action type')
        }
    }
}